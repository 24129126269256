import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';

import { defaultMessages } from '../../../../libs/i18n/default';
import { loginByEmail } from '../../api/auth';
import AuthModalSocials from './AuthModalSocials';
import AuthModalInput from './AuthModalInput';
import AuthModalTabs from './AuthModalTabs';

class AuthModalLoginByEmail extends React.Component {
  state = {
    canSubmit: true,
    errors: '',
  };

  constructor(props) {
    super(props);

    this.setValidationRules();
  }

  getEmailValue() {
    const { email } = this.props;

    return this.loginForm ? this.loginForm.getModel().user.email : email;
  }

  setValidationRules() {
    const {
      intl: { formatMessage },
    } = this.props;

    const fieldRequiredMessage = formatMessage(
      defaultMessages.jsAuthModalValidationFieldRequired,
    );
    const fieldWrongFormatMessage = formatMessage(
      defaultMessages.jsAuthModalValidationFieldWrongFormat,
    );

    // Обязательные поля валидируются автоматически, если установлен аттрибут required
    this.validations = {
      email: {
        isEmail: true,
      },
    };

    this.validationErrors = {
      email: {
        isDefaultRequiredValue: fieldRequiredMessage,
        isEmail: fieldWrongFormatMessage,
      },
      password: {
        isDefaultRequiredValue: fieldRequiredMessage,
      },
    };
  }

  handleRegisterLinkClick = e => {
    const { onChangeForm } = this.props;

    e.preventDefault();

    onChangeForm('registerByPhone', this.getEmailValue());
  };

  handleRecoveryLinkClick = e => {
    const { onChangeForm } = this.props;

    e.preventDefault();

    onChangeForm('recovery', this.getEmailValue());
  };

  handleTabsClick = tab => {
    const { onChangeForm } = this.props;

    switch (tab) {
      case 'email':
        onChangeForm('loginByEmail');
        break;
      case 'phone':
        onChangeForm('loginByPhone');
    }
  };

  handleChange = () => {
    const { errors } = this.state;

    if (errors !== '') {
      this.setState({ errors: '' });
    }
  };

  handleValid = () => {
    this.setState({ canSubmit: true });
  };

  handleInvalid = () => {
    this.setState({ canSubmit: false });
  };

  handleSubmit = (model, reset, invalidate) => {
    const { canSubmit } = this.state;
    const { toggleLoading, redirectPath } = this.props;

    if (!canSubmit) {
      return;
    }

    toggleLoading();

    loginByEmail(model)
      .then(data => {
        const redirectLocation =
          redirectPath || data.redirect_location || window.location.href;

        toggleLoading();

        window.location.href = redirectLocation;
      })
      .catch(errors => {
        toggleLoading();

        this.setState({
          errors: errors && errors.email ? errors.email : '',
          canSubmit: true,
        });
      });
  };

  renderErrors() {
    const { errors } = this.state;

    return (
      errors !== '' && (
        <div className="modal-form-item">
          <div className="error-message">{errors}</div>
        </div>
      )
    );
  }

  render() {
    const { intl, email } = this.props;
    const { formatMessage } = intl;

    return (
      <Formsy
        ref={loginForm => (this.loginForm = loginForm)}
        className="modal-form"
        acceptCharset="UTF-8"
        method="post"
        onChange={this.handleChange}
        onValid={this.handleValid}
        onInvalid={this.handleInvalid}
        onSubmit={this.handleSubmit}
      >
        <div className="modal-form-header">
          <div className="modal-form-title">
            {formatMessage(defaultMessages.jsAuthModalLoginTitle)}
          </div>
          <div className="modal-form-text">
            <span>
              {formatMessage(defaultMessages.jsAuthModalLoginTitleText)}{' '}
            </span>
            <a
              href="#"
              className="link link-green"
              onClick={this.handleRegisterLinkClick}
            >
              {formatMessage(defaultMessages.jsAuthModalLoginTitleLink)}
            </a>
          </div>
        </div>
        <div className="modal-form-content">
          <div className="modal-form-item">
            <AuthModalTabs
              intl={intl}
              active="email"
              onTabClick={this.handleTabsClick}
            />
          </div>
          <div className="modal-form-item">
            <AuthModalInput
              name="user[email]"
              intl={intl}
              placeholder={formatMessage(
                defaultMessages.jsAuthModalEmailPlaceholder,
              )}
              required
              type="email"
              validations={this.validations.email}
              validationErrors={this.validationErrors.email}
              value={email}
            />
          </div>
          <div className="modal-form-item">
            <AuthModalInput
              intl={intl}
              name="user[password]"
              placeholder={formatMessage(
                defaultMessages.jsAuthModalPasswordPlaceholder,
              )}
              required
              type="password"
              validationErrors={this.validationErrors.password}
              value=""
            />
          </div>
          {this.renderErrors()}
          <div className="modal-form-item">
            <div className="modal-form-text">
              <span>
                {formatMessage(
                  defaultMessages.jsAuthModalDoNotRememberPassword,
                )}{' '}
              </span>
              <a
                className="link link-green"
                href="#"
                onClick={this.handleRecoveryLinkClick}
              >
                {formatMessage(
                  defaultMessages.jsAuthModalDoNotRememberPasswordLink,
                )}
              </a>
            </div>
          </div>
          <div className="modal-form-item modal-form-item-actions">
            <div className="modal-form-item-cell">
              <button
                className="button button-green modal-form-submit"
                type="submit"
                formNoValidate
              >
                {formatMessage(defaultMessages.jsAuthModalSignInButton)}
              </button>
            </div>
          </div>
          {/*<AuthModalSocials intl={intl} type="login" />*/}
        </div>
      </Formsy>
    );
  }
}

AuthModalLoginByEmail.propTypes = {
  email: PropTypes.string,
  intl: intlShape.isRequired,
  redirectPath: PropTypes.string,
  toggleLoading: PropTypes.func.isRequired,
  onChangeForm: PropTypes.func.isRequired,
};

export default AuthModalLoginByEmail;
