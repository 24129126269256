/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

const AdFormCustomCheckbox = React.memo(function({
  onChange,
  checked,
  value,
  text,
}) {
  return (
    <button
      type="button"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #C6C6C6',
        margin: '0 -1px -1px 0',
        cursor: 'pointer',
        height: '44px',
        backgroundColor: 'white',
        fontSize: '1em',
        lineHeight: '1,467em',
        ...(checked ? { backgroundColor: '#24A14F', color: 'white' } : {}),
      }}
      onClick={event => onChange(event, !checked)}
    >
      {text}
    </button>
  );
});

AdFormCustomCheckbox.propTypes = {
  checked: PropTypes.bool,
  text: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default AdFormCustomCheckbox;
