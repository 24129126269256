import axios, { CancelToken } from './axios';

export const fetchAds = params => {
  return axios
    .post('/api/frontend/rooms', params)
    .then(({ data }) => data)
    .catch(({ response: { data } }) =>
      Promise.reject(data === null ? data : data.errors),
    );
};

export const fetchAdById = id => {
  return axios
    .get(`/api/frontend/rooms/${id}`)
    .then(({ data }) => data)
    .catch(({ response: { data: { errors } } }) => Promise.reject(errors));
};

export const fetchAdByIdWithCancelation = id => {
  let cancel;

  return [
    axios
      .get(`/api/frontend/rooms/${id}`, {
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      })
      .then(({ data }) => data)
      .catch(({ response: { data: { errors } } }) => Promise.reject(errors)),
    cancel,
  ];
};

export const fetchYandexMapAds = params => {
  let cancel;

  return [
    axios
      .post('/api/frontend/rooms/map', params, {
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      })
      .then(({ data }) => data)
      .catch(({ response: { data: { errors } } }) => Promise.reject(errors)),
    cancel,
  ];
};

export const fetchGoogleMapAds = params => {
  let cancel;

  return [
    axios
      .post('/api/frontend/rooms/map_google', params, {
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      })
      .then(({ data }) => data)
      .catch(({ response: { data: { errors } } }) => Promise.reject(errors)),
    cancel,
  ];
};

export const favoriteAd = id => {
  let cancel;

  return [
    axios
      .get(`/api/frontend/rooms/${id}/favorite`, {
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      })
      .then(({ data }) => data)
      .catch(({ response: { data: { errors } } }) => Promise.reject(errors)),
    cancel,
  ];
};

export const unfavoriteAd = id => {
  let cancel;

  return [
    axios
      .get(`/api/frontend/rooms/${id}/unfavorite`)
      .then(({ data }) => data)
      .catch(({ response: { data: { errors } } }) => Promise.reject(errors)),
    cancel,
  ];
};

export const fetchAdContacts = id => {
  return axios
    .get(`/api/frontend/rooms/${id}/contacts`)
    .then(({ data }) => data)
    .catch(({ response: { data: { errors } } }) => Promise.reject(errors));
};

export const sendAdFeedback = (id, { kind, comment }) => {
  // kind: 'like' | 'dislike'
  return axios
    .post(`/api/frontend/rooms/${id}/feedback`, { kind, comment })
    .then(({ data }) => data)
    .catch(({ response: { data: { errors } } }) => Promise.reject(errors));
};

export const sendNewAdRequest = ad => {
  const { slug } = ad;

  return axios({
    method: slug ? 'PUT' : 'POST',
    url: `/rooms${slug ? `/${slug}` : ''}`,
    data: { ad },
  })
    .then(({ data }) => data)
    .catch(({ response: { data: { errors } } }) => Promise.reject(errors));
};

export const geocodeAddress = address => {
  return axios
    .get(`/api/frontend/rooms/geocode`, { params: { address } })
    .then(({ data }) => data)
    .catch(({ response: { data: { errors } } }) => Promise.reject(errors));
};

export const reverseGeocode = ({ lat, lng }) => {
  return axios
    .get(`/api/frontend/rooms/reverse_geocode`, { params: { lat, lng } })
    .then(({ data }) => data)
    .catch(({ response: { data: { errors } } }) => Promise.reject(errors));
};
