/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import AdHistoryModal from './AdHistoryModal';

class AdHistoryButton extends React.Component {
  onClick = () => {
    const { id } = this.props;

    ReactDOM.render(
      <AdHistoryModal id={id} />,
      document.getElementById('modals'),
    );
  };

  render() {
    return (
      <span className="link link-dotted" onClick={this.onClick}>
        Показать
      </span>
    );
  }
}

AdHistoryButton.propTypes = {
  id: PropTypes.number.isRequired,
};

export default AdHistoryButton;
