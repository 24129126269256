/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FIELD_NAMES, ANIMALS_VALUES } from '../constants';
import SvgDog from '../../../../../inline-svgs/client/RentSearchApplication/dog.svg';
import SvgCat from '../../../../../inline-svgs/client/RentSearchApplication/cat.svg';
import Switcher from '../Switcher';
import styles from '../styles.scss';

const ANIMALS_VALUES_WITH_ICONS = ANIMALS_VALUES.map(({ value, title }) => {
  return {
    value,
    title:
      value === 'dog' ? (
        <SvgDog aria-hidden="true" />
      ) : (
        <SvgCat aria-hidden="true" />
      ),
  };
});

const StepAnimals = ({ animals, errors, setMainValues, goToNextStep }) => {
  const isEmpty = animals.length === 0;

  const handleAnimalsChange = value => {
    setMainValues({ [FIELD_NAMES.ANIMALS]: value });
  };

  const handleSubmitClick = () => {
    goToNextStep();
  };

  return (
    <React.Fragment>
      <div className={classNames(styles.inner, styles.innerTopbarSpace)}>
        <div className={styles.title}>У вас есть домашние животные?</div>
        <div className={styles.formItem}>
          <div className={styles.formLabel}>Питомцы</div>
          <Switcher
            multiple
            itemClassName={styles.animalsSwitcherItem}
            values={ANIMALS_VALUES_WITH_ICONS}
            initialActiveValue={animals}
            onChange={handleAnimalsChange}
          />
        </div>
      </div>
      <div className={styles.cta}>
        <div className={styles.buttonWrapper}>
          {isEmpty ? (
            <button
              className={classNames(styles.button, styles.buttonGrey)}
              type="button"
              onClick={handleSubmitClick}
            >
              Нет питомцев
            </button>
          ) : (
            <button
              className={classNames(styles.button)}
              type="button"
              onClick={handleSubmitClick}
            >
              Дальше
            </button>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

StepAnimals.propTypes = {
  animals: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  goToNextStep: PropTypes.func.isRequired,
  setMainValues: PropTypes.func.isRequired,
};

export default StepAnimals;
