import anime from 'animejs';
import classNames from 'classnames';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import AssistantAdvantageItem from './AssistantAdvantageItem';
import AssistantChat from './AssistantChat';
import AssistantChatRow from './AssistantChatRow';
import AssistantChatAnimatedBlock from './AssistantChatAnimatedBlock';
import AboutAssistantButton from './AboutAssistantButton';
import AssistantSelection from './AssistantSelection';
import clientAvatar from '../../../../images/client/AssistantChat/avatar-1.png';
import managerAvatar from '../../../../images/client/AssistantChat/avatar-2.png';
import styles from './AssistantAdvantages.scss';

const PROGRESS_TIMINGS = {
  0: 13500,
  1: 16500,
  2: 13200,
  3: 20000,
};

const BLOCK_SHOW_DELAY = 400;

class AssistantAdvantages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: -1,
      isVisible: false,
      progressAnimationEnded: false,
    };

    this.chatContentRef = React.createRef();
    this.chatPartRefs = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
    ];
    this.typingMessageRefs = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
    ];

    this.chatPartAnime = [null, null, null, null];
  }

  handleProgressEnd = () => {
    const { activeIndex, progressAnimationEnded } = this.state;

    if (progressAnimationEnded) {
      return null;
    }

    if (activeIndex === 3) {
      this.setState({ progressAnimationEnded: true });

      return null;
    }

    const typingFadeAnime = anime({
      targets: this.typingMessageRefs[activeIndex].current,
      opacity: [1, 0],
      duration: 200,
      easing: 'easeInOutSine',
    });

    typingFadeAnime.finished.then(() => {
      this.chatPartAnime[activeIndex] = anime({
        targets: this.chatPartRefs[activeIndex].current,
        opacity: [1, 0],
        translateY: [0, -600],
        duration: 500,
        easing: 'easeInOutSine',
        complete: () => {
          this.setState({ activeIndex: Math.min(activeIndex + 1, 3) });
        },
      });
    });
  };

  handleVisibilityChange = isVisible => {
    if (isVisible) {
      this.setState({ isVisible: true, activeIndex: 0 });
    }
  };

  handleAdvantageClick = id => {
    this.setState({ activeIndex: id, progressAnimationEnded: true });
  };

  render() {
    const { activeIndex, isVisible, progressAnimationEnded } = this.state;

    const forceVisible = progressAnimationEnded === true;

    return (
      <VisibilitySensor
        partialVisibility
        active={!isVisible}
        onChange={this.handleVisibilityChange}
      >
        <div className={styles.container}>
          <div className={classNames(styles.col, styles.colDetails)}>
            <h2 className={styles.title}>
              Личный помощник поможет найти квартиру мечты
            </h2>
            <ul className={styles.advantagesList}>
              <AssistantAdvantageItem
                id={0}
                iconName="as-ruble"
                title="Экономия"
                shortTitle="Экономия"
                notice="Личный помощник стоит гораздо дешевле риелтора."
                isActive={activeIndex === 0}
                progressTime={forceVisible ? 0 : PROGRESS_TIMINGS[0]}
                onProgressEnd={this.handleProgressEnd}
                onClick={this.handleAdvantageClick}
              />
              <AssistantAdvantageItem
                id={1}
                iconName="as-search"
                title="Внимание к деталям"
                shortTitle="Внимание"
                notice="Хотите квартиру с белыми стенами, окнами в зеленый двор и чтобы можно было жить с любимым питомцем? Мы позвоним собственнику и уточним все, что вам важно."
                isActive={activeIndex === 1}
                progressTime={forceVisible ? 0 : PROGRESS_TIMINGS[1]}
                onProgressEnd={this.handleProgressEnd}
                onClick={this.handleAdvantageClick}
              />
              <AssistantAdvantageItem
                id={2}
                iconName="as-message"
                title="Удобство общения"
                shortTitle="Общение"
                notice="Личный помощник доступен по телефону, в почте или в любом удобном вам мессенджере."
                isActive={activeIndex === 2}
                progressTime={forceVisible ? 0 : PROGRESS_TIMINGS[2]}
                onProgressEnd={this.handleProgressEnd}
                onClick={this.handleAdvantageClick}
              />
              <AssistantAdvantageItem
                id={3}
                iconName="as-shield"
                title="Безопасность гарантирована"
                shortTitle="Безопасность"
                notice="Проверим документы собственника и дадим заключение о правомерности сделки."
                isActive={activeIndex === 3}
                progressTime={forceVisible ? 0 : PROGRESS_TIMINGS[3]}
                onProgressEnd={this.handleProgressEnd}
                onClick={this.handleAdvantageClick}
              />
            </ul>
          </div>
          <div
            className={classNames(styles.col, styles.colChat)}
            ref={this.chatContentRef}
          >
            <div className={styles.chatContent}>
              {activeIndex === 0 && (
                <div ref={this.chatPartRefs[0]}>
                  <AssistantChat
                    delay={BLOCK_SHOW_DELAY}
                    forceVisible={forceVisible}
                    rows={[
                      <AssistantChatAnimatedBlock direction="right">
                        <AssistantChatRow
                          forceVisible
                          avatarSrc={clientAvatar}
                          messages={[
                            { text: 'Здравствуйте!' },
                            {
                              text:
                                'Наткнулась на ваш сайт. Подскажите, сколько стоят ваши услуги?',
                            },
                            {
                              text: 'Вы что-то типа риелторов?',
                            },
                          ]}
                          direction="right"
                          theme="green"
                        />
                      </AssistantChatAnimatedBlock>,
                      <AssistantChatAnimatedBlock direction="left">
                        <AssistantChatRow
                          forceVisible
                          avatarSrc={managerAvatar}
                          messages={[
                            { text: 'Добрый день!' },
                            {
                              text: 'Нет, мы не риелторы :)',
                            },
                            {
                              text:
                                'Мы помогаем быстро и эффективно находить жилье и у нас нет комиссий.',
                            },
                            {
                              text:
                                'Цена фиксированная и не зависит от <br>стоимости квартиры. Есть несколько <br>тарифов на выбор от 2 900 рублей.',
                            },
                          ]}
                          direction="left"
                          theme="white"
                        />
                      </AssistantChatAnimatedBlock>,
                      forceVisible ? null : (
                        <div ref={this.typingMessageRefs[0]}>
                          <AssistantChatRow
                            avatarSrc={clientAvatar}
                            messages={[{ text: '' }]}
                            direction="right"
                            theme="green"
                            typingTime={-1}
                          />
                        </div>
                      ),
                    ]}
                  />
                </div>
              )}
              {activeIndex === 1 && (
                <div ref={this.chatPartRefs[1]}>
                  <AssistantChat
                    delay={BLOCK_SHOW_DELAY}
                    forceVisible={forceVisible}
                    rows={[
                      <AssistantChatAnimatedBlock direction="right">
                        <AssistantChatRow
                          forceVisible
                          avatarSrc={clientAvatar}
                          messages={[
                            {
                              text: 'То есть вы подбираете жилье?',
                            },
                          ]}
                          direction="right"
                          theme="green"
                        />
                        <AssistantChatRow
                          forceVisible
                          avatarSrc={managerAvatar}
                          messages={[
                            {
                              text:
                                'Да. Вы расскажете, какую квартиру ищете и что для вас важно. Мы все учтем и каждый день будем присылать персональную подборку объявлений.',
                            },
                          ]}
                          direction="left"
                          theme="white"
                        />
                      </AssistantChatAnimatedBlock>,
                      <AssistantChatAnimatedBlock direction="left">
                        <AssistantChatRow
                          forceVisible
                          avatarSrc={clientAvatar}
                          messages={[
                            {
                              text: 'Прямо любые пожелания учтете?)',
                            },
                          ]}
                          direction="right"
                          theme="green"
                        />
                        <AssistantChatRow
                          forceVisible
                          avatarSrc={managerAvatar}
                          messages={[
                            {
                              text: 'Даже самые необычные :)',
                            },
                            {
                              text:
                                'Например, вы хотите исключительно синие стены и собираетесь жить с сенбернаром.',
                            },
                          ]}
                          direction="left"
                          theme="white"
                        />
                      </AssistantChatAnimatedBlock>,
                      forceVisible ? null : (
                        <div ref={this.typingMessageRefs[1]}>
                          <AssistantChatRow
                            avatarSrc={clientAvatar}
                            messages={[{ text: '' }]}
                            direction="right"
                            theme="green"
                            typingTime={-1}
                          />
                        </div>
                      ),
                    ]}
                  />
                </div>
              )}
              {activeIndex === 2 && (
                <div ref={this.chatPartRefs[2]}>
                  <AssistantChat
                    delay={BLOCK_SHOW_DELAY}
                    forceVisible={forceVisible}
                    rows={[
                      <AssistantChatAnimatedBlock direction="left">
                        <AssistantChatRow
                          forceVisible
                          avatarSrc={managerAvatar}
                          messages={[
                            {
                              text:
                                'Помощник ищет такую квартиру и составляет подборку с комментариями.',
                            },
                            {
                              text: <AssistantSelection />,
                            },
                          ]}
                          direction="left"
                          theme="white"
                        />
                      </AssistantChatAnimatedBlock>,
                      <AssistantChatAnimatedBlock direction="right">
                        <AssistantChatRow
                          forceVisible
                          avatarSrc={clientAvatar}
                          messages={[
                            {
                              text:
                                'А это реальный человек? С ним можно будет связаться?',
                            },
                          ]}
                          direction="right"
                          theme="green"
                        />
                        <AssistantChatRow
                          forceVisible
                          avatarSrc={managerAvatar}
                          messages={[
                            { text: 'Реальный :)' },
                            {
                              text: 'У вас будет свой личный помощник.',
                            },
                            {
                              text:
                                'Общаться можно в мессенджере или по <br>почте, как вам удобнее ',
                            },
                          ]}
                          direction="left"
                          theme="white"
                        />
                      </AssistantChatAnimatedBlock>,
                      forceVisible ? null : (
                        <div ref={this.typingMessageRefs[2]}>
                          <AssistantChatRow
                            avatarSrc={clientAvatar}
                            messages={[{ text: '' }]}
                            direction="right"
                            theme="green"
                            typingTime={-1}
                          />
                        </div>
                      ),
                    ]}
                  />
                </div>
              )}
              {activeIndex === 3 && (
                <div ref={this.chatPartRefs[3]}>
                  <AssistantChat
                    delay={BLOCK_SHOW_DELAY}
                    forceVisible={forceVisible}
                    rows={[
                      <AssistantChatAnimatedBlock direction="right">
                        <AssistantChatRow
                          forceVisible
                          avatarSrc={clientAvatar}
                          messages={[
                            {
                              text:
                                'А гарантии? Или вы просто размещаете объявления?',
                            },
                          ]}
                          direction="right"
                          theme="green"
                        />
                        <AssistantChatRow
                          forceVisible
                          avatarSrc={managerAvatar}
                          messages={[
                            {
                              text:
                                'Каждое объявление от собственника проверяет модератор. Когда вы <br>определитесь с квартирой, мы закажем для нее выписку из Росреестра.',
                            },
                            {
                              text:
                                'Еще можем помочь составить договор <br>аренды и приехать на его подписание.',
                            },
                          ]}
                          direction="left"
                          theme="white"
                        />
                      </AssistantChatAnimatedBlock>,
                      <AssistantChatAnimatedBlock direction="left">
                        <AssistantChatRow
                          forceVisible
                          avatarSrc={clientAvatar}
                          messages={[
                            {
                              text: 'Круто! Если я надумаю, что нужно сделать?',
                            },
                          ]}
                          direction="right"
                          theme="green"
                        />
                        <AssistantChatRow
                          forceVisible
                          avatarSrc={managerAvatar}
                          messages={[
                            {
                              text:
                                'Заполните заявку с вопросами про квартиру мечты. Это займет не больше 5 минут :)',
                            },
                          ]}
                          direction="left"
                          theme="white"
                        />
                      </AssistantChatAnimatedBlock>,
                    ]}
                  />
                </div>
              )}
              {forceVisible && <AboutAssistantButton />}
            </div>
          </div>
        </div>
      </VisibilitySensor>
    );
  }
}

AssistantAdvantages.propTypes = {};

export default AssistantAdvantages;
