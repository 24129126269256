export default function getElementOffset(element) {
  if (!element) {
    return null;
  }

  const rect = element.getBoundingClientRect();

  return {
    top: rect.top + window.pageYOffset - document.documentElement.clientTop,
    left: rect.left + window.pageXOffset - document.documentElement.clientLeft,
    bottom:
      rect.bottom + window.pageYOffset - document.documentElement.clientTop,
  };
}
