/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { defaultMessages } from '../../../../libs/i18n/default';
import { subwaysApi } from '../../api/subways';
import { FILTER_KEYS } from './AdCatalog.constants';
import filterOptions from '../../../common/helpers/ReactSelect/defaultFilterOptions';
import MetroOption from '../../../common/helpers/ReactSelect/metroOptionRenderer';
import FlashNotifierService from '../../services/FlashNotifier/FlashNotifier';
import MetroSchemeModal from '../MetroSchemeModal/MetroSchemeModal';

class AdCatalogFilterBodySubways extends React.PureComponent {
  state = {
    isLoading: true,
    options: [],
  };

  componentDidMount() {
    this.getSubways();
  }

  componentWillUnmount() {
    if (this.requestSubwayCancel) {
      this.requestSubwayCancel();
      this.requestSubwayCancel = null;
    }
  }

  getSortedSelectValue() {
    const { options } = this.state;
    const { subways } = this.props;

    if (subways) {
      return options
        .filter(option => subways.indexOf(option.value) !== -1)
        .map(option => option.value);
    }

    return [];
  }

  getSubways() {
    const {
      intl: { formatMessage },
    } = this.props;

    this.setState({ isLoading: true });

    const [requestPromise, cancel] = subwaysApi.fetchSubways(void 0, {
      withCancel: true,
    });

    this.requestSubwayCancel = cancel;

    requestPromise
      .then(response => {
        this.setState({
          isLoading: false,
          options: response
            .filter(item => item.id !== null)
            .map(({ id, text, colors, count }) => ({
              count,
              colors,
              value: id,
              label: text,
            })),
        });
      })
      .catch(error => {
        console.error(error);

        FlashNotifierService.notifyError(
          formatMessage(
            defaultMessages.jsFlashNotifierErrorHasOccurredRefreshBrowser,
          ),
        );

        this.setState({ isLoading: false });
      });
  }

  handleSelectChange = values => {
    const { updateFilter } = this.props;

    const nextSubways = values.map(item => Number(item.value));

    updateFilter({
      [FILTER_KEYS.SUBWAYS]: nextSubways.length ? nextSubways : null,
    });
  };

  handleClearLinkClick = event => {
    const { deleteGeoAreas } = this.props;

    this.updateFilterSubways([]);

    deleteGeoAreas();

    event.preventDefault();
  };

  handleGeoAreasChoiceRemoveClick = () => {
    const { deleteGeoAreas } = this.props;

    deleteGeoAreas();
  };

  handleMetroSchemeButtonClick = event => {
    const { city, subways } = this.props;

    ReactDOM.render(
      <MetroSchemeModal
        city={city}
        selectedStations={subways ? subways.map(Number) : []}
        updateOffers={this.updateFilterSubways}
      />,
      document.getElementById('modals'),
    );

    event.preventDefault();
  };

  updateFilterSubways = subwayIds => {
    const { updateFilter } = this.props;

    updateFilter({
      [FILTER_KEYS.SUBWAYS]: subwayIds.length ? subwayIds : null,
    });
  };

  // TODO: Поменять текст если нет результатов в селекте при наборе
  render() {
    const { isLoading, options } = this.state;
    const {
      geoAreas,
      shouldShowScheme,
      subways,
      intl: { formatMessage },
    } = this.props;

    return (
      <div className="filter-row">
        <div className="filter-label">
          <span>{formatMessage(defaultMessages.jsCatalogFilterWhere)}</span>
          {((subways && subways.length) || geoAreas) && (
            <a
              className="link link-orange pull-right"
              href="#"
              onClick={this.handleClearLinkClick}
            >
              {formatMessage(defaultMessages.jsCatalogFilterClear)}
            </a>
          )}
        </div>
        <div
          className={classNames('filter-select', 'filter-select-metro', {
            '__with-scheme': shouldShowScheme,
          })}
        >
          <Select
            multi
            arrowRenderer={null}
            clearable={false}
            disabled={isLoading}
            filterOptions={filterOptions}
            isLoading={isLoading}
            noResultsText={formatMessage(
              defaultMessages.jsReactSelectNoResults,
            )}
            options={options}
            optionRenderer={MetroOption}
            placeholder={formatMessage(
              defaultMessages.jsCatalogFilterTypeTheSubwayName,
            )}
            value={this.getSortedSelectValue()}
            onChange={this.handleSelectChange}
          />
          {shouldShowScheme && (
            <div
              className="filter-metro-scheme-button"
              onClick={this.handleMetroSchemeButtonClick}
            >
              <div className="filter-metro-scheme-button-icon" />
              <div className="filter-metro-scheme-button-text">
                {formatMessage(
                  defaultMessages.jsCatalogFilterSelectInTheScheme,
                )}
              </div>
              {subways && subways.length && (
                <div className="filter-metro-scheme-button-count">
                  {subways.length}
                </div>
              )}
            </div>
          )}
        </div>
        {geoAreas && (
          <div className="filter-choice">
            {formatMessage(defaultMessages.jsCatalogFilterAreaOnTheMap)}
            <span
              className="filter-choice-remove"
              onClick={this.handleGeoAreasChoiceRemoveClick}
            />
          </div>
        )}
      </div>
    );
  }
}

AdCatalogFilterBodySubways.propTypes = {
  city: PropTypes.object.isRequired,
  deleteGeoAreas: PropTypes.func.isRequired,
  geoAreas: PropTypes.array,
  intl: intlShape.isRequired,
  shouldShowScheme: PropTypes.bool,
  subways: PropTypes.array,
  updateFilter: PropTypes.func.isRequired,
};

export default AdCatalogFilterBodySubways;
