import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

import { defaultMessages } from '../../../../libs/i18n/default';
import AdNewsletterModal from '../AdNewsletterModal/AdNewsletterModal';
import AdCatalogBanner from './AdCatalogBanner';

const AdCatalogFilterSubscriptionBanner = ({
  intl: { formatMessage },
  email,
  filter,
}) => {
  const handleSubmit = React.useCallback(() => {
    ReactDOM.render(
      <AdNewsletterModal email={email} filter={filter} />,
      document.getElementById('modals'),
    );
  }, []);

  return (
    <AdCatalogBanner
      type="filter"
      imageSrc={require('../../../../images/client/AdCatalog/banner-filter-subscription.svg')}
      imageWidth={220}
      imageHeight={126}
      title={formatMessage(defaultMessages.jsCatalogSubscriptionCardTitle)}
      notice={formatMessage(defaultMessages.jsCatalogSubscriptionCardText)}
      submitText={formatMessage(
        defaultMessages.jsCatalogSubscriptionCardRequest,
      )}
      onSubmit={handleSubmit}
    />
  );
};

AdCatalogFilterSubscriptionBanner.propTypes = {
  email: PropTypes.string,
  filter: PropTypes.object,
  intl: intlShape.isRequired,
};

export default injectIntl(AdCatalogFilterSubscriptionBanner);
