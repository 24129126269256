import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import { FIELD_NAMES } from './constants';
import AdFormValidatable from './AdFormValidatableMixin';
import AdFormInput from './AdFormInput';

class AdFormTitle extends React.PureComponent {
  handleChange = event => {
    const { setAdState } = this.props;

    setAdState({ [FIELD_NAMES.TITLE]: event.currentTarget.value });
  };

  render() {
    const {
      intl: { formatMessage },
      title,
      hasErrors,
      errorMessage,
    } = this.props;

    return (
      <div className="form-row">
        <div className="form-row-label">
          {formatMessage(defaultMessages.jsAdFormTitleTitle)}
        </div>
        <div className="form-row-content">
          <AdFormInput
            value={title}
            placeholder={formatMessage(
              defaultMessages.jsAdFormTitlePlaceholder,
            )}
            error={hasErrors()}
            onChange={this.handleChange}
          />
          {errorMessage()}
        </div>
      </div>
    );
  }
}

AdFormTitle.propTypes = {
  errorMessage: PropTypes.func,
  hasErrors: PropTypes.func,
  intl: intlShape.isRequired,
  setAdState: PropTypes.func,
  title: PropTypes.string,
};

export default AdFormValidatable(AdFormTitle, FIELD_NAMES.TITLE);
