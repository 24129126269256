import validate from 'validate.js';

import * as regExps from '../../constants/regExps';
import {
  FIELD_NAMES,
  RECEIVE_TYPE_VALUES,
  LOCATION_TYPE_VALUES,
} from './constants';

validate.validators.areaType = function(value, options, key, attributes) {
  const locationTypeValue = attributes[FIELD_NAMES.LOCATION_TYPE];

  if (locationTypeValue === LOCATION_TYPE_VALUES.AREA) {
    if (value !== null && value.length > 0) {
      return null;
    } else {
      return 'Обязательное поле';
    }
  } else {
    return null;
  }
};

validate.validators.subwayType = function(value, options, key, attributes) {
  const locationTypeValue = attributes[FIELD_NAMES.LOCATION_TYPE];

  if (locationTypeValue === LOCATION_TYPE_VALUES.SUBWAY) {
    switch (key) {
      case FIELD_NAMES.SUBWAY_IDS:
        return value && value.length > 0 ? null : 'Обязательное поле';

      case FIELD_NAMES.FROM_SUBWAY:
      case FIELD_NAMES.FROM_SUBWAY_TIME:
        return value !== null ? null : 'Обязательное поле';
    }
  } else {
    return null;
  }
};

validate.validators.customLocationType = function(
  value,
  options,
  key,
  attributes,
) {
  const locationTypeValue = attributes[FIELD_NAMES.LOCATION_TYPE];

  if (locationTypeValue === LOCATION_TYPE_VALUES.CUSTOM) {
    if (value !== null && value.length > 0) {
      return null;
    } else {
      return 'Обязательное поле';
    }
  } else {
    return null;
  }
};

validate.validators.residentsOther = function(value, options, key, attributes) {
  const residentsValue = attributes[FIELD_NAMES.RESIDENTS];

  if (residentsValue === 'other') {
    if (value !== null && value.length > 0) {
      return null;
    } else {
      return 'Обязательное поле';
    }
  } else {
    return null;
  }
};

validate.validators.urgencyDate = function(value, options, key, attributes) {
  const urgencyValue = attributes[FIELD_NAMES.URGENCY];

  if (urgencyValue === 'other') {
    if (value !== null && value.length > 0) {
      return null;
    } else {
      return 'Обязательное поле';
    }
  } else {
    return null;
  }
};

export const VALIDATION_RULES = {
  [FIELD_NAMES.ROOM_QUANTITY]: {
    presence: {
      allowEmpty: false,
      message: 'Необходимо выбрать количество комнат',
    },
  },
  [FIELD_NAMES.PRICE]: {
    presence: true,
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
    },
  },
  [FIELD_NAMES.MIN_SPACE]: {
    presence: true,
    numericality: {
      onlyInteger: true,
    },
  },
  [FIELD_NAMES.LOCATION_TYPE]: {
    presence: true,
  },
  [FIELD_NAMES.SUBWAY_IDS]: {
    subwayType: true,
  },
  [FIELD_NAMES.FROM_SUBWAY]: {
    subwayType: true,
  },
  [FIELD_NAMES.FROM_SUBWAY_TIME]: {
    subwayType: true,
  },
  [FIELD_NAMES.GEO_AREAS]: {
    areaType: true,
  },
  [FIELD_NAMES.CUSTOM_LOCATION]: {
    customLocationType: true,
  },
  [FIELD_NAMES.RESIDENTS]: {
    presence: true,
  },
  [FIELD_NAMES.RESIDENTS_OTHER]: {
    residentsOther: true,
  },
  [FIELD_NAMES.URGENCY_DATE]: {
    urgencyDate: true,
  },
  [FIELD_NAMES.WORK]: {
    presence: false,
    // length: { minimum: 1 },
  },
  [FIELD_NAMES.OWNER]: {
    presence: {
      allowEmpty: false,
      message: 'Необходимо выбрать тип собственника',
    },
  },
  [FIELD_NAMES.PRIORITIES]: {
    length: { minimum: 1 },
  },
  [FIELD_NAMES.RECEIVE_TYPE]: {
    presence: true,
  },
  [`${FIELD_NAMES.RECEIVE_VALUE}.${RECEIVE_TYPE_VALUES.WHATSAPP}`]: {
    format: {
      pattern: regExps.PHONE_RU_EXT,
      message: 'Неверный формат телефона',
    },
  },
  [`${FIELD_NAMES.RECEIVE_VALUE}.${RECEIVE_TYPE_VALUES.TELEGRAM}`]: {
    format: {
      pattern: regExps.PHONE_RU_EXT,
      message: 'Неверный формат телефона',
    },
  },
  [`${FIELD_NAMES.RECEIVE_VALUE}.${RECEIVE_TYPE_VALUES.EMAIL}`]: {
    email: {
      message: 'Неверный формат, попробуйте снова',
    },
  },
};
