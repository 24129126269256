import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';

const CLASS_LOADING = '__loading';
const CLASS_ACTIVE = '__active';

const FavoriteButton = React.forwardRef(
  ({ intl: { formatMessage }, isLoading, isFavorited, onClick }, ref) => {
    let text;

    if (isFavorited) {
      text = formatMessage(defaultMessages.jsFavoriteButtonFavorited);
    } else {
      text = formatMessage(defaultMessages.jsFavoriteButtonToFavorites);
    }

    if (isLoading) {
      text = formatMessage(defaultMessages.jsFavoriteButtonWait);
    }

    return (
      <a
        href="#"
        onClick={onClick}
        className={
          'button-favorite' +
          (isFavorited ? ` ${CLASS_ACTIVE}` : '') +
          (isLoading ? ` ${CLASS_LOADING}` : '')
        }
        ref={ref}
      >
        <i className="button-favorite-icon">
          {isLoading && <span className="spinner" />}
        </i>
        {text}
      </a>
    );
  },
);

FavoriteButton.propTypes = {
  intl: intlShape.isRequired,
  isFavorited: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
};

export default FavoriteButton;
