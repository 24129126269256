import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';

import { defaultMessages } from '../../../../libs/i18n/default';
import { FIELD_NAMES } from './constants';
import { KINDS } from '../AdCatalog/AdCatalog.constants';
import AdFormStepLabel from './AdFormStepLabel';
import AdFormValidatable from './AdFormValidatableMixin';
import AdFormRadio from './AdFormRadio';

class AdFormKind extends React.PureComponent {
  handleChange = event => {
    const { setAdState } = this.props;

    setAdState({ [FIELD_NAMES.KIND]: event.currentTarget.value });
  };

  render() {
    const { intl, kind, stepNumber, errorMessage, type } = this.props;
    const { formatMessage } = intl;

    return (
      <div className="form-row">
        <div className="form-row-label">
          <AdFormStepLabel intl={intl} stepNumber={stepNumber} />
          {type === 'rent'
            ? formatMessage(defaultMessages.jsAdFormKindLabel)
            : formatMessage(defaultMessages.jsAdFormKindLabelSale)}
        </div>
        <div className="form-row-content">
          <div className="radio-group radio-group-two-cols radio-group-offerkind">
            <AdFormRadio
              required
              value="room"
              icon="room"
              text={formatMessage(defaultMessages.jsAdFormKindRentRoom)}
              checked={kind === KINDS.ROOM}
              onChange={this.handleChange}
            />
            <AdFormRadio
              required
              value="apartment"
              icon="apartment"
              text={formatMessage(defaultMessages.jsAdFormKindRentApartment)}
              checked={kind === KINDS.APARTMENT}
              onChange={this.handleChange}
            />
          </div>
          {errorMessage()}
        </div>
      </div>
    );
  }
}

AdFormKind.propTypes = {
  errorMessage: PropTypes.func,
  intl: intlShape.isRequired,
  kind: PropTypes.string,
  type: PropTypes.string,
  setAdState: PropTypes.func,
  stepNumber: PropTypes.number,
};

AdFormKind.defaultProps = {
  stepNumber: null,
};

export default AdFormValidatable(AdFormKind, FIELD_NAMES.KIND);
