/* eslint-disable jsx-a11y/label-has-associated-control */
import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import { FILTER_KEYS } from './AdCatalog.constants';

class AdCatalogFilterBodyRouteTime extends React.PureComponent {
  onChange = event => {
    const { updateFilter } = this.props;
    const { checked, value } = event.target;

    if (checked) {
      updateFilter({
        [FILTER_KEYS.ROUTE_TIME]: parseInt(value),
      });
    }
  };

  renderRadio(value) {
    const {
      intl: { formatMessage },
      routeTime,
    } = this.props;

    return (
      <label className="filter-radio">
        <input
          className="filter-radio-control"
          name="route-time"
          type="radio"
          value={value}
          checked={!!(routeTime && routeTime === value)}
          onChange={this.onChange}
        />
        <span className="filter-radio-text">
          <span className="filter-radio-text-inner">
            {value}{' '}
            <span className="filter-radio-text-label">
              {formatMessage(defaultMessages.jsCatalogFilterMinutesShort)}
            </span>
          </span>
        </span>
      </label>
    );
  }

  render() {
    return (
      <div className="filter-row filter-row-margin-top-xs">
        <div className="filter-radio-group">
          {this.renderRadio(5)}
          {this.renderRadio(10)}
          {this.renderRadio(15)}
          {this.renderRadio(20)}
        </div>
      </div>
    );
  }
}

AdCatalogFilterBodyRouteTime.propTypes = {
  intl: intlShape.isRequired,
  routeTime: PropTypes.number,
  updateFilter: PropTypes.func.isRequired,
};

export default AdCatalogFilterBodyRouteTime;
