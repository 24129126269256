import { intlShape } from 'react-intl';
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import TranslationProvider from '../../../common/components/HOC/TranslationProvider';
import Modal from '../Modal/Modal';
import UserSettingsModalForm from './UserSettingsModalForm';

class UserSettingsModal extends React.Component {
  state = {
    isLoading: false,
  };

  handleModalClose = e => {
    this.closeModal();
  };

  toggleLoading = () => {
    this.setState(({ isLoading }) => ({
      isLoading: !isLoading,
    }));
  };

  closeModal = () => {
    ReactDOM.unmountComponentAtNode(document.getElementById('modals'));
  };

  render() {
    const { isLoading } = this.state;
    const { country, intl } = this.props;

    return (
      <Modal
        handleClose={this.handleModalClose}
        containerClass="modal-profile-settings"
      >
        <UserSettingsModalForm
          intl={intl}
          country={country}
          toggleLoading={this.toggleLoading}
          onSuccessSave={this.closeModal}
        />
        {isLoading && (
          <div className="modal-loader">
            <span className="spinner" />
          </div>
        )}
      </Modal>
    );
  }
}

UserSettingsModal.propTypes = {
  country: PropTypes.string,
  intl: intlShape.isRequired,
};

UserSettingsModal.defaultProps = {
  country: window.COUNTRY_CODE || 'ru',
};

export default TranslationProvider(UserSettingsModal);
