import axios from './axios';

const saveComment =  (id, text) => {
  return axios
    .post(`/api/admin/comments/${id}`, {
      comment: {text: text},
    })
    .then(({ data }) => data)
    .catch(({ response: { data: { errors } } }) => Promise.reject(errors));
};

export const commentsApi = {
  saveComment,
};

export default commentsApi;
