/* eslint-disable jsx-a11y/label-has-associated-control */
import { intlShape } from 'react-intl';
import isNil from 'lodash/isNil';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import { FILTER_KEYS } from './AdCatalog.constants';

const ROOM_COUNTS = [1, 2, 3, 4];

class AdCatalogFilterBodyRooms extends React.PureComponent {
  onChange = value => {
    const { roomQuantity, updateFilter } = this.props;

    let nextRoomQuantity = isNil(roomQuantity) ? [] : [].concat(roomQuantity);

    const index = nextRoomQuantity.indexOf(value);

    if (index === -1) {
      nextRoomQuantity.push(value);
    } else {
      nextRoomQuantity.splice(index, 1);
    }

    updateFilter({
      [FILTER_KEYS.ROOM_QUANTITY]: nextRoomQuantity.length
        ? nextRoomQuantity
        : null,
    });
  };

  isChecked(value) {
    const { roomQuantity } = this.props;

    if (!roomQuantity) {
      return false;
    } else if (roomQuantity.indexOf(value) !== -1) {
      return true;
    } else {
      return false;
    }
  }

  renderCheckboxList() {
    return ROOM_COUNTS.map(num => (
      <label key={`RoomQuantity-${num}`} className="filter-checkbox">
        <input
          className="filter-checkbox-control"
          type="checkbox"
          checked={this.isChecked(num)}
          onChange={this.onChange.bind(this, num)}
        />
        <span className="filter-checkbox-text">
          {num === ROOM_COUNTS.length ? `${ROOM_COUNTS.length}+` : num}
        </span>
      </label>
    ));
  }

  render() {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <div className="filter-row">
        <div className="filter-label">
          {formatMessage(defaultMessages.jsCatalogFilterNumberOfRooms)}
        </div>
        <div className="filter-checkbox-group">{this.renderCheckboxList()}</div>
      </div>
    );
  }
}

AdCatalogFilterBodyRooms.propTypes = {
  intl: intlShape.isRequired,
  roomQuantity: PropTypes.array,
  updateFilter: PropTypes.func.isRequired,
};

export default AdCatalogFilterBodyRooms;
