import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const RentSearchApplicationStepCounter = ({ step, stepCount }) => {
  return (
    <div className={styles.stepCounter}>
      шаг {step} из {stepCount}
      <span
        className={styles.stepCounterProgress}
        style={{
          width: `${(step * 100) / stepCount}%`,
        }}
      >
        <span>
          шаг {step} из {stepCount}
        </span>
      </span>
    </div>
  );
};

RentSearchApplicationStepCounter.propTypes = {
  step: PropTypes.number.isRequired,
  stepCount: PropTypes.number.isRequired,
};

export default RentSearchApplicationStepCounter;
