import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

import setRailsContext from '../../../common/utils/setRailsContext';
import TranslationProvider from '../../../common/components/HOC/TranslationProvider';
import OfferMapGoogleMap from './OfferMapGoogleMap';
import OfferMapYandexMap from './OfferMapYandexMap';

const OfferMap = ({ ad, intl, locale, isUkraine }) =>
  isUkraine ? (
    <OfferMapGoogleMap ad={ad} intl={intl} />
  ) : (
    <OfferMapYandexMap ad={ad} intl={intl} locale={locale} />
  );

OfferMap.propTypes = {
  ad: PropTypes.object,
  intl: intlShape.isRequired,
  isUkraine: PropTypes.bool,
  locale: PropTypes.string,
};

export default setRailsContext(TranslationProvider(OfferMap));
