import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';
import SvgCross from '../../../../inline-svgs/client/RentSearchApplication/cross.svg';

const RentSearchApplicationClose = ({ onClick }) => {
  return (
    <button
      className={styles.close}
      type="button"
      aria-label="Закрыть"
      onClick={onClick}
    >
      <SvgCross aria-hidden="true" />
    </button>
  );
};

RentSearchApplicationClose.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default RentSearchApplicationClose;
