import React from 'react';
import PropTypes from 'prop-types';

import MetroSchemeModalSchemeStationCheckbox from './MetroSchemeModalSchemeStationCheckbox';

const MetroSchemeModalSchemeMapStations = ({
  stations,
  selectedStations,
  onStationChange,
}) => (
  <div className="metro-scheme-stations">
    {stations.map(
      ({ id, scheme_data: schemeData, line_ids: lineIds, title }) => {
        if (schemeData === null) {
          return null;
        }

        const {
          size,
          title: schemeDataTitle,
          offset_top: offsetTop,
          offset_left: offsetleft,
          title_align: titleAlign = null,
          title_absolute: titleAbsolute = null,
          title_top: titleTop = null,
          title_right: titleRight = null,
          title_bottom: titleBottom = null,
          title_left: titleLeft = null,
        } = schemeData;

        return (
          <MetroSchemeModalSchemeStationCheckbox
            key={id}
            id={id}
            lines={lineIds}
            title={schemeDataTitle || title}
            offset={{
              top: offsetTop,
              left: offsetleft,
            }}
            size={size || null}
            textAlign={titleAlign}
            textAbsolute={titleAbsolute}
            textTop={titleTop}
            textRight={titleRight}
            textBottom={titleBottom}
            textLeft={titleLeft}
            isChecked={selectedStations.indexOf(id) !== -1}
            onChange={onStationChange}
          />
        );
      },
    )}
  </div>
);

MetroSchemeModalSchemeMapStations.propTypes = {
  selectedStations: PropTypes.array.isRequired,
  stations: PropTypes.array.isRequired,
  onStationChange: PropTypes.func,
};

export default MetroSchemeModalSchemeMapStations;
