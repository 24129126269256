/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'react-tooltip-lite';

import { defaultMessages } from '../../../../libs/i18n/default';
import TranslationProvider from '../../../common/components/HOC/TranslationProvider';
import Dropdown from '../Dropdown/Dropdown';

const MAIN_CLASS = 'control-promo';

class OfferPromoControl extends React.PureComponent {
  state = {
    isOpen: false,
  };

  handleDropdownActivatorClick = () => {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
  };

  handleDropdownOutsideClick = () => {
    this.closeDropdown();
  };

  handleDropdownCloseButtonClick = () => {
    this.closeDropdown();
  };

  closeDropdown() {
    this.setState({ isOpen: false });
  }

  renderIcon = () => {
    return <div className={`${MAIN_CLASS}-icon`} />;
  };

  renderControl() {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <div className={MAIN_CLASS}>
        <Tooltip
          arrow
          arrowSize={7}
          distance={13}
          tagName="span"
          eventOn="onClick"
          eventOff="onMouseOut"
          content={formatMessage(
            defaultMessages.jsControlPromoTooltipAdPromoted,
          )}
        >
          {this.renderIcon()}
        </Tooltip>
      </div>
    );
  }

  renderButton() {
    const { signedIn, isOwned } = this.props;

    if (signedIn && !isOwned) {
      return (
        <div className={MAIN_CLASS} onClick={this.handleDropdownActivatorClick}>
          {this.renderIcon()}
        </div>
      );
    } else {
      return this.renderControl();
    }
  }

  renderDropdown() {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <div>
        <div className="dropdown-popup-service-pic">
          <div className="dropdown-popup-service-icon dropdown-popup-service-icon-rocket" />
        </div>
        <div
          className="dropdown-popup-service-title"
          dangerouslySetInnerHTML={{
            __html: formatMessage(defaultMessages.jsControlPromoDropdownTitle),
          }}
        />
        <ul className="dropdown-popup-service-benefits">
          <li
            className="dropdown-popup-service-benefits-item"
            dangerouslySetInnerHTML={{
              __html: formatMessage(
                defaultMessages.jsControlPromoDropdownMoreViews,
              ),
            }}
          />
          <li
            className="dropdown-popup-service-benefits-item"
            dangerouslySetInnerHTML={{
              __html: formatMessage(
                defaultMessages.jsControlPromoDropdownAlwaysOnTop,
              ),
            }}
          />
          <li
            className="dropdown-popup-service-benefits-item"
            dangerouslySetInnerHTML={{
              __html: formatMessage(
                defaultMessages.jsControlPromoDropdownHighlightedInList,
              ),
            }}
          />
          <li
            className="dropdown-popup-service-benefits-item"
            dangerouslySetInnerHTML={{
              __html: formatMessage(
                defaultMessages.jsControlPromoDropdownRecommendation,
              ),
            }}
          />
        </ul>
        <a
          href="/cabinet/"
          title={formatMessage(
            defaultMessages.jsControlPromoDropdownOrderButton,
          )}
          className="button button-green"
        >
          {formatMessage(defaultMessages.jsControlPromoDropdownOrderButton)}
        </a>
      </div>
    );
  }

  render() {
    const { isOpen } = this.state;
    const { isStatic, isOwned, signedIn } = this.props;

    if (isStatic) {
      return this.renderControl();
    }

    if (signedIn && !isOwned) {
      return (
        <Dropdown
          showButtonClose
          isOpen={isOpen}
          extraClassName="dropdown-popup-service"
          onOutsideClick={this.handleDropdownOutsideClick}
          onButtonCloseClick={this.handleDropdownCloseButtonClick}
          button={this.renderButton()}
        >
          {this.renderDropdown()}
        </Dropdown>
      );
    } else {
      return this.renderButton();
    }
  }
}

OfferPromoControl.propTypes = {
  intl: intlShape.isRequired,
  isOwned: PropTypes.bool,
  isStatic: PropTypes.bool,
  signedIn: PropTypes.bool,
};

export default TranslationProvider(OfferPromoControl);
