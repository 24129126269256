
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import ContactViewsHistoryModal from "./ContactViewsHistoryModal";

class ContactViewsHistoryButton extends React.Component {
  onClick = () => {
    const { id } = this.props;

    ReactDOM.render(
      <ContactViewsHistoryModal id={id} />,
      document.getElementById('modals'),
    );
  };

  render() {
    return (
      <span className="link link-dotted" onClick={this.onClick}>
        Показать Историю просмотра контактов
      </span>
    );
  }
}

ContactViewsHistoryButton.propTypes = {
  id: PropTypes.number.isRequired,
};

export default ContactViewsHistoryButton;
