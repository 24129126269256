import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import debounce from 'lodash/debounce';
import classNames from 'classnames';

import { defaultMessages } from '../../../../libs/i18n/default';
import { FIELD_NAMES } from './constants';
import { geocodeAddress, reverseGeocode } from '../../api/rooms';
import AdFormStepLabel from './AdFormStepLabel';
import AdFormValidatable from './AdFormValidatableMixin';
import AdFormFootnote from './AdFormFootnoteMixin';
import AdFormInput from './AdFormInput';
import styles from './AdForm.scss';

const addressToString = item => (item ? item.address : '');

const Map = ({ mapOptions, lat, lng, onMarkerDragEnd }) => {
  const mapRef = React.useRef();
  const mapCanvasRef = React.useRef();
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setCenter(
        [lat, lng],
        Math.max(15, mapRef.current.getZoom()),
        { duration: 500 },
      );
    }
  }, [lat, lng]);

  React.useEffect(() => {
    function init() {
      mapRef.current = new ymaps.Map(mapCanvasRef.current, {
        zoom: mapOptions.zoom,
        center: lat && lng ? [lat, lng] : [mapOptions.lat, mapOptions.lng],
        controls: ['zoomControl'],
      });

      // mapRef.current.behaviors.disable('scrollZoom');

      const markerLayout = ymaps.templateLayoutFactory.createClass(
        '<div class="ymaps-placemark __stroke">' +
          '<div class="ymaps-placemark-icon"></div>' +
          '</div>',
      );

      const marker = new ymaps.GeoObject(
        {
          geometry: { type: 'Point', coordinates: mapRef.current.getCenter() },
        },
        {
          iconLayout: markerLayout,
          iconShape: {
            type: 'Polygon',
            coordinates: [
              [
                [0, 0],
                [-13, -18],
                [-14, -21],
                [-14, -28],
                [-12, -32],
                [-5, -38],
                [-2, -39],
                [1, -39],
                [4, -38],
                [8, -36],
                [13, -29],
                [14, -26],
                [14, -23],
                [13, -20],
                [8, -11],
                [0, 0],
              ],
            ],
          },
          draggable: true,
        },
      );

      marker.events.add(['dragend'], async function(event) {
        try {
          setIsLoading(true);
          const coordinates = event.get('target').geometry.getCoordinates();
          if (!coordinates) {
            return;
          }

          const addresses = await reverseGeocode({
            lat: coordinates[0],
            lng: coordinates[1],
          });
          onMarkerDragEnd(addresses);
        } catch (error) {
          console.log({ error });
        } finally {
          setIsLoading(false);
        }
      });

      mapRef.current.geoObjects.add(marker);

      // if (DeviceSupports.touch) {
      //   mapRef.current.behaviors.disable('drag');
      // }
    }

    if (typeof window.ymaps === 'undefined') {
      return null;
    }

    ymaps.ready(init);
  }, []);

  React.useEffect(() => {
    if (mapRef.current && lat && lng) {
      const marker = mapRef.current.geoObjects.get(0);
      marker.geometry.setCoordinates([lat, lng]);
    }
  }, [lat, lng]);

  return (
    <div className={styles.map}>
      <div ref={mapCanvasRef} className={styles.mapCanvas} />
      <div
        className={classNames(styles.mapOverlay, {
          [styles['mapOverlay--visible']]: isLoading,
        })}
      >
        <span className={classNames('spinner', styles.mapSpinner)} />
      </div>
    </div>
  );
};

const AdFormAddress = ({
  lat,
  lng,
  intl,
  address,
  cityTitle,
  bigFootnote,
  stepNumber,
  showFootnote,
  hideFootnote,
  hasErrors,
  smallFootnote,
  errorMessage,
  mapOptions,
  setAdState,
}) => {
  const downshiftRef = React.useRef();
  const [suggestions, setSuggestions] = React.useState([]);

  const getSuggestions = debounce(async address => {
    // const place = `${cityTitle}, ${address}`;
    const addressSuggestions = await geocodeAddress(address);
    setSuggestions(addressSuggestions);
  }, 500);

  const handleAddressChange = selection => {
    setAdState({
      [FIELD_NAMES.ADDRESS]: selection.address,
      [FIELD_NAMES.LAT]: selection.lat,
      [FIELD_NAMES.LNG]: selection.lng,
      [FIELD_NAMES.SUBWAY_IDS]: selection.subway_ids,
    });
  };

  const handleAddressKeyUp = async event => {
    const address = event.currentTarget.value;

    if (!address) {
      return null;
    }
    try {
      await getSuggestions(address);
    } catch (error) {
      console.log({ error });
    }
  };

  const handleMarkerDragEnd = addresses => {
    const currentAddress = addresses[0];

    setSuggestions(addresses);

    downshiftRef.current.setState({ inputValue: currentAddress.address });

    setAdState({
      [FIELD_NAMES.ADDRESS]: currentAddress.address,
      [FIELD_NAMES.LAT]: currentAddress.lat,
      [FIELD_NAMES.LNG]: currentAddress.lng,
      [FIELD_NAMES.SUBWAY_IDS]: currentAddress.subway_ids,
    });
  };

  return (
    <div className="form-row">
      <div className="form-row-label">
        <AdFormStepLabel intl={intl} stepNumber={stepNumber} />
        {intl.formatMessage(defaultMessages.jsAdFormAddressLabel)}
      </div>

      <div className="form-row-content">
        {bigFootnote()}

        <Downshift
          ref={downshiftRef}
          initialInputValue={address || ''}
          onChange={handleAddressChange}
          itemToString={addressToString}
        >
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            highlightedIndex,
            selectedItem,
            isOpen,
          }) => {
            return (
              <div className={styles.dropdownContainer}>
                <AdFormInput
                  id="ad_input_address"
                  classMods={['search']}
                  placeholder={intl.formatMessage(
                    defaultMessages.jsAdFormAddressPlaceholder,
                  )}
                  icon="magnifier"
                  error={hasErrors()}
                  onFocus={showFootnote}
                  onBlur={hideFootnote}
                  onKeyUp={handleAddressKeyUp}
                  inputProps={getInputProps()}
                />

                <ul {...getMenuProps()} className={styles.dropdown}>
                  {isOpen &&
                    suggestions.map((item, index) => (
                      <li
                        {...getItemProps({
                          key: `${item.address}${index}`,
                          item,
                          index,
                          className: styles.dropdownItem,
                          style: {
                            backgroundColor:
                              highlightedIndex === index ? '#f2f9fc' : 'white',
                            fontWeight:
                              selectedItem === item ? 'bold' : 'normal',
                          },
                        })}
                      >
                        {item.address}
                      </li>
                    ))}
                </ul>
              </div>
            );
          }}
        </Downshift>

        {smallFootnote()}

        <Map
          mapOptions={mapOptions}
          lat={lat}
          lng={lng}
          onMarkerDragEnd={handleMarkerDragEnd}
        />

        {errorMessage()}
      </div>
    </div>
  );
};

AdFormAddress.propTypes = {
  address: PropTypes.string,
  bigFootnote: PropTypes.func,
  cityTitle: PropTypes.string,
  errorMessage: PropTypes.func,
  hasErrors: PropTypes.func,
  hideFootnote: PropTypes.func,
  intl: intlShape.isRequired,
  isUkraine: PropTypes.bool,
  lat: PropTypes.number,
  lng: PropTypes.number,
  mapOptions: PropTypes.object,
  setAdState: PropTypes.func,
  showFootnote: PropTypes.func,
  smallFootnote: PropTypes.func,
  stepNumber: PropTypes.number,
};

export default AdFormFootnote(
  AdFormValidatable(AdFormAddress, FIELD_NAMES.ADDRESS),
  FIELD_NAMES.ADDRESS,
);
