/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import { MAIN_CLASS, VIEW_MAP, VIEW_PANORAMA } from './OfferMap.constants';
import SvgIcon from '../SvgIcon/SvgIcon';

class OfferMapViewControl extends React.Component {
  onClick = () => {
    const { view, onPanoramaViewToggle, onMapViewToggle } = this.props;

    if (view !== VIEW_PANORAMA) {
      onPanoramaViewToggle();
    }

    if (view !== VIEW_MAP) {
      onMapViewToggle();
    }
  };

  getIcon() {
    const { view } = this.props;

    switch (view) {
      case VIEW_MAP:
        return 'street-view-thin';
      case VIEW_PANORAMA:
        return 'map-thin';
    }
  }

  getTooltipText() {
    const {
      intl: { formatMessage },
      view,
    } = this.props;

    switch (view) {
      case VIEW_MAP:
        return formatMessage(defaultMessages.jsOfferMapViewStreet);
      case VIEW_PANORAMA:
        return formatMessage(defaultMessages.jsOfferMapViewMap);
    }
  }

  render() {
    const { isVisible, view } = this.props;

    return (
      isVisible && (
        <div
          className={classNames(`${MAIN_CLASS}__viewControl`, {
            [`${MAIN_CLASS}__viewControl--panorama`]: view === VIEW_MAP,
            [`${MAIN_CLASS}__viewControl--map`]: view === VIEW_PANORAMA,
          })}
          onClick={this.onClick}
        >
          <SvgIcon icon={this.getIcon()} />
          <div className={`${MAIN_CLASS}__viewControlTooltip`}>
            {this.getTooltipText()}
          </div>
        </div>
      )
    );
  }
}

OfferMapViewControl.propTypes = {
  intl: intlShape.isRequired,
  isVisible: PropTypes.bool,
  view: PropTypes.string,
  onMapViewToggle: PropTypes.func,
  onPanoramaViewToggle: PropTypes.func,
};

export default OfferMapViewControl;
