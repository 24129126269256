import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Switcher.scss';

const Switcher = React.memo(function({
  values,
  onChange,
  isRowMobile = false,
  suffix = null,
  initialActiveValue = null,
  multiple = false,
  itemClassName = null,
  canDisable = false,
  maxValues = values.length,
}) {
  const isInitialMount = React.useRef(true);
  const [activeValue, setActiveValue] = React.useState(initialActiveValue);

  React.useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (activeValue !== initialActiveValue) {
        onChange(activeValue);
      }
    }
  }, [activeValue]);

  React.useEffect(() => {
    if (initialActiveValue !== activeValue) {
      setActiveValue(initialActiveValue);
    }
  }, [initialActiveValue]);

  const handleClick = React.useCallback(value => {
    if (multiple) {
      const index = activeValue
        ? activeValue.findIndex(currentValue => value === currentValue)
        : -1;

      if (activeValue === maxValues && index !== -1) {
        setActiveValue(activeValue);
      }

      setActiveValue(
        index !== -1
          ? [...activeValue.slice(0, index), ...activeValue.slice(index + 1)]
          : [...activeValue, value],
      );
    } else {
      setActiveValue(
        canDisable && activeValue && activeValue === value ? null : value,
      );
    }
  });

  return (
    <div
      className={classNames(styles.switcher, {
        [styles.switcherRowMobile]: isRowMobile,
      })}
    >
      {values.length > 0 &&
        values.map(({ value, title = null }, index) => {
          const isActive = multiple
            ? activeValue.includes(value)
            : activeValue === value;

          return (
            <button
              key={value}
              type="button"
              className={classNames(styles.switcherItem, {
                [itemClassName]: itemClassName !== null,
                [styles.switcherItemActive]: isActive,
              })}
              disabled={
                multiple && activeValue.length === maxValues && !isActive
              }
              onClick={() => handleClick(value)}
            >
              {title || value}
              {suffix ? (
                <span className={styles.switcherItemSuffix}> {suffix}</span>
              ) : null}
            </button>
          );
        })}
    </div>
  );
});

Switcher.propTypes = {
  canDisable: PropTypes.bool,
  initialActiveValue: PropTypes.any,
  isRowMobile: PropTypes.bool,
  itemClassName: PropTypes.string,
  maxValues: PropTypes.number,
  multiple: PropTypes.bool,
  suffix: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
      value: PropTypes.any,
    }),
  ),
  onChange: PropTypes.func,
};

export default Switcher;

