import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import AuthModalVkButton from './AuthModalVkButton';
import AuthModalFbButton from './AuthModalFbButton';

class AuthModalSocials extends React.Component {
  getTitle() {
    const {
      intl: { formatMessage },
      type,
    } = this.props;

    switch (type) {
      case 'login':
        return formatMessage(defaultMessages.jsAuthModalLoginFastSocialAuth);
      case 'register':
        return formatMessage(defaultMessages.jsAuthModalRegisterFastSocialAuth);
      default:
        return '';
    }
  }

  render() {
    const { intl } = this.props;

    return (
      <div className="modal-form-social modal-form-social-bottom">
        <div className="modal-form-text modal-form-text-social">
          {this.getTitle()}
        </div>
        <AuthModalVkButton intl={intl} />
        <AuthModalFbButton intl={intl} />
      </div>
    );
  }
}

AuthModalSocials.propTypes = {
  intl: intlShape.isRequired,
  type: PropTypes.string,
};

export default AuthModalSocials;
