export default function getPolygonSquare(points) {
  const length = points.length;

  let square = 0;

  for (let i = 0; i < length; i++) {
    const nextIndex = i + 1 === length ? 0 : i + 1;

    square +=
      points[i][0] * points[nextIndex][1] - points[i][1] * points[nextIndex][0];
  }

  return Math.abs(square / 2);
}
