import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const UserSettingsModalPhoneGroupButton = React.memo(function({
  isDisabled,
  onClick,
  children,
}) {
  return (
    <button
      className={classNames('button', 'button-green', {
        __disabled: isDisabled,
      })}
      type="button"
      disabled={isDisabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
});

UserSettingsModalPhoneGroupButton.propTypes = {
  children: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default UserSettingsModalPhoneGroupButton;
