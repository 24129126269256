import { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { defaultMessages } from '../../../../libs/i18n/default';

import TranslationProvider from '../../../common/components/HOC/TranslationProvider';
import { PAYMENT_CARDS_REMOVE_PATH } from '../../constants/paths';
import FlashNotifierService from '../../services/FlashNotifier/FlashNotifier';

class PaymentFormLinkedCards extends Component {
  static propTypes = {
    cards: PropTypes.array,
    currentLinkedCardId: PropTypes.number,
    intl: intlShape.isRequired,
    isDeletingCard: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    setCardFormView: PropTypes.func.isRequired,
    setPaymentFormState: PropTypes.func.isRequired,
    toggleLoading: PropTypes.func.isRequired
  }

  componentWillUnmount() {
    if (this.deleteRequest) {
      this.deleteRequest.abort();
      this.deleteRequest = null;
    }
  }

  onLinkedCardChange = (e, id) => {
    if (this.props.isLoading) return;

    this.props.setPaymentFormState({
      currentLinkedCardId: id
    });
  }

  onNewCardLinkClick = (e) => {
    if (this.props.isLoading) return;

    this.props.setCardFormView();
    e.preventDefault();
  }

  onDeleteCardLinkClick = (e, id) => {
    if (this.props.isLoading) return;

    this.deleteCard(id);
    e.preventDefault();
  }

  deleteCard(id) {
    if (this.props.isDeletingCard) return;

    const { formatMessage } = this.props.intl;

    if (confirm(formatMessage(defaultMessages.jsPaymentFormConfirmRemoveCard))) {
      this.props.setPaymentFormState({
        currentLinkedCardId: this.props.currentLinkedCardId === id ? null : this.props.currentLinkedCardId,
        isDeletingCard: true
      });

      this.deleteRequest = $.ajax({
        url: PAYMENT_CARDS_REMOVE_PATH.replace('{{id}}', id),
        method: 'DELETE',
        contentType: 'application/json',
        beforeSend: () => {
          this.props.toggleLoading();
        },
      })

      this.deleteRequest.done(response => {
        this.props.toggleLoading();

        if (response.result === 'success') {
          this.props.setPaymentFormState({
            cards: this.props.cards.filter(card => card.id !== id),
            isDeletingCard: false
          });

          FlashNotifierService.notifySuccess(formatMessage(defaultMessages.jsPaymentFormFlashNotifierCardSuccessfullyDeleted));
        } else {
          FlashNotifierService.notifyError(formatMessage(defaultMessages.jsFlashNotifierErrorHasOccurredTryAgain));
        }
      })

      this.deleteRequest.fail(() => {
        this.props.setPaymentFormState({
          isDeletingCard: false
        });
        this.props.toggleLoading();

        FlashNotifierService.notifyError(formatMessage(defaultMessages.jsFlashNotifierErrorHasOccurredTryAgain));
      })
    } else {

    }
  }

  render() {
    const { cards, isLoggedIn } = this.props;
    const { formatMessage } = this.props.intl;

    if (isLoggedIn && cards.length > 0) {
      return (
        <div className="PaymentForm__linkedCards">
          <div className="PaymentForm__linkedCardsTitle">
            {formatMessage(defaultMessages.jsPaymentFormLinkedCards)}
          </div>
          <ul className="PaymentForm__linkedCardsList">
            {cards.map(card => {
              return (
                <li key={`PaymentLinkedCard-${card.id}`}>
                  <label className="PaymentForm__radio">
                    <input
                      className="PaymentForm__radioControl"
                      type="radio"
                      name="PaymentForm[OtherCards]"
                      checked={this.props.currentLinkedCardId === card.id}
                      disabled={this.props.isLoading}
                      onChange={e => this.onLinkedCardChange(e, card.id)}
                    />
                    <span className="PaymentForm__radioBox" />
                    <span className="PaymentForm__radioText">
                      {card.kind} {(card.number).replace(/(.{4})(?=.)/g, '$1 ')}
                    </span>
                  </label>
                  <a
                    className="PaymentForm__linkedCardsRemove"
                    href="#"
                    title={formatMessage(defaultMessages.jsPaymentFormRemoveCard)}
                    onClick={e => this.onDeleteCardLinkClick(e, card.id)}
                  >
                    {formatMessage(defaultMessages.jsPaymentFormRemoveCard)}
                  </a>
                </li>
              )
            })}
          </ul>
          <div className="PaymentForm__linkedCardsAdd">
            {`${formatMessage(defaultMessages.jsPaymentFormOr)} `}
            <a
              href="#"
              title={formatMessage(defaultMessages.jsPaymentFormAddNewCard)}
              onClick={this.onNewCardLinkClick}
            >
              {formatMessage(defaultMessages.jsPaymentFormAddNewCard)}
            </a>
          </div>
        </div>
      );
    }

    return null;
  }
};

export default TranslationProvider(PaymentFormLinkedCards);
