import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import { FIELD_NAMES } from './constants';
import AdFormStepLabel from './AdFormStepLabel';
import AdFormValidatable from './AdFormValidatableMixin';
import AdFormInput from './AdFormInput';
import AdFormSocialAuthButton from './AdFormSocialAuthButton';

class AdFormAuth extends React.Component {
  componentDidMount() {
    const { validatePresented } = this.props;

    validatePresented();
  }

  handleChange = event => {
    const { setAdState } = this.props;

    setAdState({ [FIELD_NAMES.EMAIL]: event.currentTarget.value });
  };

  footNoteContent() {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <div className="footnote-content">
        {formatMessage(defaultMessages.jsAdFormAuthFootnotesRegister)}{' '}
        <a data-auth-modal-trigger="" href="#">
          {formatMessage(defaultMessages.jsAdFormAuthFootnotesSign)}
        </a>
      </div>
    );
  }

  render() {
    const {
      email,
      intl,
      stepNumber,
      errorMessage,
      hasErrors,
      vkAuthPath,
      fbAuthPath,
    } = this.props;
    const { formatMessage } = intl;

    return (
      <div className="form-row form-row-auth">
        <div className="form-row-label">
          <AdFormStepLabel intl={intl} stepNumber={stepNumber} />
          {formatMessage(defaultMessages.jsAdFormAuthLabel)}
        </div>
        <div className="form-row-content">
          <div className="footnote __active">{this.footNoteContent()}</div>
          <div className="footnote footnote-static margin-bottom-15 hidden-lg">
            {this.footNoteContent()}
          </div>
          <div className="grid">
            <div className="grid-item grid-item-half">
              <AdFormInput
                placeholder="Email"
                value={email}
                error={hasErrors()}
                onChange={this.handleChange}
              />
            </div>
            <div className="grid-item grid-item-half grid-item-auth-socials">
              <span className="form-text">
                {formatMessage(defaultMessages.jsAdFormOr)}
              </span>
              <AdFormSocialAuthButton
                url={vkAuthPath}
                title="Вконтакте"
                classMod="vk"
              />
              <AdFormSocialAuthButton
                url={fbAuthPath}
                title="Facebook"
                classMod="fb"
              />
            </div>
          </div>
          {errorMessage()}
        </div>
      </div>
    );
  }
}

AdFormAuth.propTypes = {
  email: PropTypes.string,
  errorMessage: PropTypes.func,
  fbAuthPath: PropTypes.string,
  hasErrors: PropTypes.func,
  intl: intlShape.isRequired,
  setAdState: PropTypes.func,
  stepNumber: PropTypes.number,
  validatePresented: PropTypes.func,
  vkAuthPath: PropTypes.string,
};

AdFormAuth.defaultProps = {
  vkAuthPath: '/users/auth/vkontakte',
  fbAuthPath: '/users/auth/facebook',
  stepNumber: null,
};

export default AdFormValidatable(AdFormAuth, FIELD_NAMES.EMAIL);
