import { intlShape } from 'react-intl';
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import TranslationProvider from '../../../common/components/HOC/TranslationProvider';
import Modal from '../Modal/Modal';
import MetroSchemeModalScheme from './MetroSchemeModalScheme';

class MetroSchemeModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  handleClose = event => {
    this.close();
  };

  toggleLoading = () => {
    this.setState(({ isLoading }) => ({ isLoading: !isLoading }));
  };

  close = () => {
    ReactDOM.unmountComponentAtNode(document.getElementById('modals'));
  };

  render() {
    const { isLoading } = this.state;
    const { city, intl, selectedStations, updateOffers } = this.props;

    return (
      <Modal handleClose={this.handleClose} containerClass="modal-metro-scheme">
        <MetroSchemeModalScheme
          intl={intl}
          city={city}
          selectedStations={selectedStations}
          toggleLoading={this.toggleLoading}
          updateOffers={updateOffers}
          onClose={this.handleClose}
        />
        {isLoading && (
          <div className="modal-loader">
            <span className="spinner" />
          </div>
        )}
      </Modal>
    );
  }
}

MetroSchemeModal.propTypes = {
  city: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  selectedStations: PropTypes.array.isRequired,
  updateOffers: PropTypes.func.isRequired,
};

export default TranslationProvider(MetroSchemeModal);
