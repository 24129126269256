/* eslint-disable jsx-a11y/label-has-associated-control */
import { intlShape } from 'react-intl';
import isNil from 'lodash/isNil';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import { FILTER_KEYS } from './AdCatalog.constants';

class AdCatalogFilterBodySex extends React.PureComponent {
  onChange = value => {
    const { updateFilter } = this.props;

    updateFilter({
      [FILTER_KEYS.SEX]: !isNil(value) ? value : null,
    });
  };

  renderRadio(value, label) {
    const { sex } = this.props;

    return (
      <label className="filter-radio">
        <input
          className="filter-radio-control"
          type="radio"
          name="filter[sex]"
          checked={sex === value}
          onChange={this.onChange.bind(this, value)}
        />
        <span className="filter-radio-text">{label}</span>
      </label>
    );
  }

  render() {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <div className="filter-row">
        <div className="filter-label">
          {formatMessage(defaultMessages.jsCatalogFilterSex)}
        </div>
        <div className="filter-radio-group filter-radio-group-table">
          {this.renderRadio(
            void 0,
            formatMessage(defaultMessages.jsCatalogFilterSexAny),
          )}
          {this.renderRadio(
            'female',
            formatMessage(defaultMessages.jsCatalogFilterSexFemale),
          )}
          {this.renderRadio(
            'male',
            formatMessage(defaultMessages.jsCatalogFilterSexMale),
          )}
        </div>
      </div>
    );
  }
}

AdCatalogFilterBodySex.propTypes = {
  intl: intlShape.isRequired,
  sex: PropTypes.string,
  updateFilter: PropTypes.func.isRequired,
};

export default AdCatalogFilterBodySex;
