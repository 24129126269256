import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const MetroSchemeModalSchemeMapLineBadges = ({ lines, onLineChange }) => {
  return (
    <div className="metro-scheme-badges">
      {lines.map(({ id, bg_color: bgColor, scheme_data: schemeData = {} }) => {
        if (!schemeData || !schemeData.badges) {
          return null;
        }

        const { badges = [], mod, color, label } = schemeData;

        return badges.map((item, index) => (
          <button
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={classNames('metro-scheme-badge', {
              [`metro-scheme-badge-${mod}`]: mod,
            })}
            type="button"
            style={{
              top: item.offset_top,
              left: item.offset_left,
              backgroundColor: bgColor,
              color: color,
            }}
            onClick={() => {
              onLineChange(id);
            }}
          >
            {label}
          </button>
        ));
      })}
    </div>
  );
};

MetroSchemeModalSchemeMapLineBadges.propTypes = {
  lines: PropTypes.array.isRequired,
  onLineChange: PropTypes.func.isRequired,
};

export default MetroSchemeModalSchemeMapLineBadges;
