/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.scss';

class Checkbox extends React.PureComponent {
  handleChange = () => {
    const { value, onChange } = this.props;

    onChange && onChange(value);
  };

  render() {
    const { className, inputRef, value, title, isChecked } = this.props;

    return (
      <label
        className={classNames(styles.checkbox, {
          [className]: className !== null,
        })}
      >
        <input
          className={styles.input}
          type="checkbox"
          value={value}
          checked={isChecked}
          onChange={this.handleChange}
          ref={inputRef}
        />
        <span
          className={classNames(styles.field, {
            [styles.fieldChecked]: isChecked,
          })}
        >
          {title}
        </span>
      </label>
    );
  }
}

Checkbox.propTypes = {
  className: PropTypes.string,
  inputRef: PropTypes.func,
  isChecked: PropTypes.bool,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  className: null,
  isChecked: false,
};

export default Checkbox;
