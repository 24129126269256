import { intlShape } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { defaultMessages } from '../../../../libs/i18n/default';
import {
  fetchAvailabilityNewsLetters,
  subscribeToNewsLetter,
} from '../../api/adNewsletters';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';
import FlashNotifierService from '../../services/FlashNotifier/FlashNotifier';

class AdNewsLetterForm extends React.Component {
  constructor(props) {
    super(props);

    const { email } = this.props;

    this.state = {
      email,
      tags: [],
      isAvailable: false,
      isExpandedTags: false,
      isLoading: false,
      subwaysCount: 0,
    };
  }

  componentDidMount() {
    const { filter } = this.props;

    fetchAvailabilityNewsLetters({ filter }).then(
      ({ available = false, tags = [] }) => {
        this.setState({
          tags,
          isAvailable: available,
          subwaysCount: tags.filter(({ type }) => type === 'subway').length,
        });
      },
    );
  }

  handleMoreButtonClick = e => {
    this.setState(({ isExpandedTags }) => ({
      isExpandedTags: !isExpandedTags,
    }));
  };

  handleEmailInputKeyDown = e => {
    const { isLoading } = this.state;

    if (e.which === 13 && !isLoading) {
      this.subscribe();
    }
  };

  handleEmailInputChange = e => {
    this.setState({ email: e.target.value });
  };

  subscribe = e => {
    const { email } = this.state;
    const { filter, onSuccessSubscribe } = this.props;

    this.setState({ isLoading: true });

    subscribeToNewsLetter({ email, filter })
      .then(data => {
        this.setState({ isLoading: false });

        onSuccessSubscribe && onSuccessSubscribe();
      })
      .catch(error => {
        this.setState({ isLoading: false });

        FlashNotifierService.notifyError(error);
      });
  };

  renderWizardTags() {
    const { tags, isExpandedTags, subwaysCount } = this.state;

    let nodes = tags.map(tag => {
      return (
        <div
          key={tag.title}
          className={classNames('subscription-wizard-tag', {
            __hide: subwaysCount > 4 && !isExpandedTags,
          })}
        >
          <span className="subscription-wizard-tag-text">{tag.title}</span>
        </div>
      );
    });

    return nodes;
  }

  renderMoreButton() {
    const { isExpandedTags, subwaysCount } = this.state;
    const {
      intl: { formatPlural, formatMessage },
    } = this.props;

    if (subwaysCount > 4) {
      const viewSubwaysCount = subwaysCount - 4;

      let buttonText;

      if (!isExpandedTags) {
        buttonText = formatMessage(
          defaultMessages[
            `jsAdNewsletterModalMoreSubwaysButtonShowCounting${capitalizeFirstLetter(
              formatPlural(viewSubwaysCount),
            )}`
          ],
          { count: viewSubwaysCount },
        );
      } else {
        buttonText = formatMessage(
          defaultMessages[
            `jsAdNewsletterModalMoreSubwaysButtonHideCounting${capitalizeFirstLetter(
              formatPlural(viewSubwaysCount),
            )}`
          ],
          { count: viewSubwaysCount },
        );
      }

      return (
        <button
          className="button button-gray button-outline subscription-wizard-more-button"
          type="button"
          onClick={this.handleMoreButtonClick}
        >
          {buttonText}
        </button>
      );
    } else {
      return null;
    }
  }

  renderInputs() {
    const {
      intl: { formatMessage },
      email,
    } = this.props;

    return (
      <div className="subscription-wizard-form">
        <div className="input subscription-wizard-form-input">
          {email === null ? (
            <input
              className="input-control"
              type="text"
              placeholder={formatMessage(
                defaultMessages.jsAdNewsletterModalEmailPlaceholder,
              )}
              onChange={this.handleEmailInputChange}
              onKeyDown={this.handleEmailInputKeyDown}
            />
          ) : (
            <input
              className="input-control"
              type="text"
              placeholder={formatMessage(
                defaultMessages.jsAdNewsletterModalEmailPlaceholder,
              )}
              defaultValue={email}
              disabled
            />
          )}
        </div>
        <button
          onClick={this.subscribe}
          type="button"
          className="button button-green subscription-wizard-form-button"
        >
          {formatMessage(defaultMessages.jsAdNewsletterModalSubscribeButton)}
        </button>
      </div>
    );
  }

  renderForm() {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <div className="subscription-wizard">
        <div
          className="subscription-wizard-title"
          dangerouslySetInnerHTML={{
            __html: formatMessage(
              defaultMessages.jsAdNewsletterModalNewOffersByEmail,
            ),
          }}
        />
        <div className="subscription-wizard-panel">
          <div className="subscription-wizard-panel-title">
            {formatMessage(defaultMessages.jsAdNewsletterModalYouChoosed)}
          </div>
          <div className="subscription-wizard-tags">
            {this.renderWizardTags()}
            {this.renderMoreButton()}
          </div>
        </div>
        {this.renderInputs()}
        <p className="subscription-wizard-note">
          <span
            dangerouslySetInnerHTML={{
              __html: formatMessage(
                defaultMessages.jsAdNewsletterModalNoteForNewsletter,
              ),
            }}
          />
          <span> </span>
          <a href="/cabinet/notifications">
            {formatMessage(
              defaultMessages.jsAdNewsletterModalNoteForNewsletterLink,
            )}
          </a>
          .
        </p>
      </div>
    );
  }

  renderNotAvailable() {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <div className="subscription-wizard">
        <div className="subscription-wizard-title">
          {formatMessage(defaultMessages.jsAdNewsletterModalSelectMoreFilters)}
        </div>
      </div>
    );
  }

  render() {
    const { isAvailable, isLoading } = this.state;

    return (
      <React.Fragment>
        {isAvailable === true ? this.renderForm() : this.renderNotAvailable()}
        {isLoading && (
          <div className="modal-loader">
            <span className="spinner" />
          </div>
        )}
      </React.Fragment>
    );
  }
}

AdNewsLetterForm.propTypes = {
  email: PropTypes.string,
  filter: PropTypes.object,
  intl: intlShape.isRequired,
  onSuccessSubscribe: PropTypes.func,
};

AdNewsLetterForm.defaultProps = {
  email: null,
};

export default AdNewsLetterForm;
