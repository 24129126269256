import React from 'react';
import PropTypes from 'prop-types';

const AdCatalogHeaderBreadcrumbsItem = ({ href, title }) => {
  return (
    <li className="breadcrumbs-item">
      <a className="breadcrumbs-link" href={href} title={title}>
        {title}
      </a>
    </li>
  );
};

AdCatalogHeaderBreadcrumbsItem.propTypes = {
  href: PropTypes.any,
  title: PropTypes.any,
};

export default AdCatalogHeaderBreadcrumbsItem;
