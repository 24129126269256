import { intlShape } from 'react-intl';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import TranslationProvider from '../../../common/components/HOC/TranslationProvider';
import OfferMetro from '../OfferMetro/OfferMetro';
import SearchCabinetOfferCardFavoriteControl from './SearchCabinetOfferCardFavoriteControl';

class SearchCabinetOfferCard extends React.Component {
  getPrettyRooms() {
    const {
      intl: { formatMessage },
      ad: { rooms },
    } = this.props;

    // TODO: Translation
    switch (rooms) {
      case 1:
        return '1к квартира';
      case 2:
        return '2к квартира';
      case 3:
        return '2к квартира';
      case 4:
        return '4+к квартира';
      case 'K': // Latin letter K
        return formatMessage(defaultMessages.jsAdCardSpaceForRentRoom);
    }
  }

  getStatusText() {
    const {
      ad: { status },
    } = this.props;

    // TODO: Translation
    switch (status) {
      case 'published':
        return 'На сайте';
      case 'archive':
        return 'Уже в архиве';
      default:
        return '';
    }
  }

  renderArea() {
    const {
      intl: { formatMessage },
      ad: { space },
    } = this.props;

    if (space) {
      return (
        <span>
          {space}&nbsp;
          {formatMessage(defaultMessages.jsAdCardMeterShort)}
          <sup>2</sup>
        </span>
      );
    }

    return null;
  }

  renderComments() {
    const {
      ad: { comments },
    } = this.props;

    if (comments.length > 0) {
      return (
        <div className="SearchCabinet__offerCardComments">
          {comments.map(({ id, sender, text }) => {
            return (
              <div
                key={`SearchCabinet__offerCardComment-${id}`}
                className={classNames('SearchCabinet__offerCardComment', {
                  'SearchCabinet__offerCardComment--from': sender === 'from',
                  'SearchCabinet__offerCardComment--to': sender === 'to',
                })}
              >
                <span className="SearchCabinet__offerCardCommentTitle">
                  {sender === 'from'
                    ? 'Комментарий менеджера'
                    : sender === 'to'
                    ? 'Ваш комментарий'
                    : ''}
                  :
                </span>
                {text}
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  }

  renderLink() {
    const {
      ad: { url },
    } = this.props;

    return (
      <a className="SearchCabinet__offerCardLink" href={url}>
        <span className="SearchCabinet__offerCardLinkArea" />
      </a>
    );
  }

  renderMetro() {
    const {
      ad: { subways },
    } = this.props;

    const subwaysLength = subways.length;

    if (subwaysLength) {
      let offerMetroColors = [];
      let offerMetroText = [];

      subways.forEach(({ colors, url, text }, i) => {
        offerMetroColors = offerMetroColors.concat(colors);

        if (url) {
          offerMetroText.push(
            <a key={text} href={url} title={text}>
              {text}
            </a>,
          );
        } else {
          offerMetroText.push(text);
        }

        if (subwaysLength - 1 > i) {
          offerMetroText.push(', ');
        }
      });

      offerMetroColors = offerMetroColors.filter(
        (color, index) => offerMetroColors.indexOf(color) === index,
      );

      return (
        <div className="SearchCabinet__offerCardMetro">
          <OfferMetro colors={offerMetroColors} text={offerMetroText} />
        </div>
      );
    }

    return null;
  }

  renderPrice() {
    const {
      ad: { price },
    } = this.props;

    return (
      <div className="SearchCabinet__offerCardPrice">
        {`${price} `}
        {this.renderPriceCurrencyIcon()}
      </div>
    );
  }

  renderPriceCurrencyIcon() {
    const {
      ad: { priceCurrencyCode },
    } = this.props;

    if (priceCurrencyCode) {
      switch (priceCurrencyCode) {
        case 'usd':
          return '$';
        default:
          return <span className={`currency currency-${priceCurrencyCode}`} />;
      }
    }

    return null;
  }

  renderStatus() {
    const {
      ad: { status },
    } = this.props;

    if (status) {
      return (
        <div
          className={classNames({
            SearchCabinet__offerCardStatus: true,
            'SearchCabinet__offerCardStatus--active': status === 'published',
            'SearchCabinet__offerCardStatus--archive': status === 'archive',
          })}
        >
          {this.getStatusText()}
        </div>
      );
    }

    return null;
  }

  render() {
    const {
      ad: { thumb, isFavorite, id },
    } = this.props;

    return (
      <div className="SearchCabinet__offerCard">
        <div className="SearchCabinet__offerCardMain">
          <div className="SearchCabinet__offerCardBlock">
            <div className="SearchCabinet__offerCardImage">
              <div
                className="SearchCabinet__offerCardImageBg"
                style={{ backgroundImage: `url(${thumb})` }}
              />
              {this.renderStatus()}
            </div>
            <div className="SearchCabinet__offerCardContent">
              <SearchCabinetOfferCardFavoriteControl
                isFavorite={isFavorite}
                offerId={id}
              />
              {this.renderPrice()}
              <div className="SearchCabinet__offerCardInfo">
                <span>{this.getPrettyRooms()}</span>
                {this.renderArea()}
              </div>
              {this.renderMetro()}
            </div>
            {this.renderLink()}
          </div>
          {this.renderComments()}
        </div>
      </div>
    );
  }
}

SearchCabinetOfferCard.propTypes = {
  ad: PropTypes.object,
  intl: intlShape.isRequired,
};

export default TranslationProvider(SearchCabinetOfferCard);
