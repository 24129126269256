// export const AUTH_EMAIL_LOGIN_PATH = '/api/frontend/sessions/email';
// export const AUTH_EMAIL_REGISTER_PATH = '/api/frontend/users/email';
export const AUTH_PHONE_NEW_PATH = '/api/frontend/sessions/phone/new';
export const AUTH_PHONE_POST_PATH = '/api/frontend/sessions/phone';
// export const AUTH_RECOVERY_PATH = '/users/password.json';
// export const AUTH_RESET_PATH = '/users/password.json';
export const AUTH_FB_PATH = '/users/auth/facebook';
export const AUTH_VK_PATH = '/users/auth/vkontakte';

export const CLOUD_PAYMENTS_PAY_WITH_CRYPTO_PATH =
  '/api/frontend/cloud_payments/pay_with_crypto';
export const CLOUD_PAYMENTS_PAY_WITH_TOKEN_PATH =
  '/api/frontend/cloud_payments/pay_with_saved_card';

export const LANDING_SEARCH_ASSISTANT_PATH = '/rent_search_application';
export const LANDING_SALE_SEARCH_ASSISTANT_PATH = '/sale_search_application';

// export const MAIN_FILTERS_PATH = 'api/frontend/main_filter/result_url';

export const PAGE_AGREEMENT_PATH = '/pages/agreement';
export const PAGE_PERSONAL_DATA_PATH = '/pages/152';

export const PAYMENT_CARDS_REMOVE_PATH = '/api/frontend/payment_cards/{{id}}';

// export const USER_SETTINGS_GET_PATH = '/api/frontend/profile';
// export const USER_SETTINGS_SAVE_PATH = '/users.json';
// export const USER_SETTINGS_UPLOAD_AVATAR_PATH = '/profile/upload_avatar';
// export const USER_SETTINGS_PHONE_NEW_PATH = '/api/frontend/profile/phone/new';
// export const USER_SETTINGS_PHONE_POST_PATH =
//   '/api/frontend/profile/phone/confirm';

export const USER_AVATAR_DEFAULT_PATH = '/images/avatar.jpg';
