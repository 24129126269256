/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ButtonClose from '../ButtonClose';
import styles from '../styles.scss';

const StepStart = ({ goToNextStep, close }) => {
  return (
    <React.Fragment>
      <ButtonClose onClick={close} />
      <div className={styles.inner}>
        <div className={styles.startImage}>
          <img
            src={require('../../../../../images/client/RentSearchApplication/start-illustration.svg')}
            alt=""
          />
        </div>
        <div className={classNames(styles.title, styles.startTitle)}>
          Запишитесь на просмотр
        </div>
      </div>
      <div className={classNames(styles.cta, styles.startCta)}>
        <div className={styles.buttonWrapper}>
          <button
            className={styles.button}
            type="button"
            onClick={goToNextStep}
          >
            Записаться
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

StepStart.propTypes = {
  close: PropTypes.func.isRequired,
  goToNextStep: PropTypes.func.isRequired,
};

export default StepStart;
