import React from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';

import { searchAssistanceApi } from '../../api/searchAssistance';
import setRailsContext from '../../../common/utils/setRailsContext';
import SearchCabinetOfferCard from './SearchCabinetOfferCard';

class SearchCabinetOfferCards extends React.Component {
  constructor(props) {
    super(props);

    const { ads, isViewed } = this.props;

    this.state = {
      ads,
      isViewed,
      isLoading: false,
    };
  }

  handleVisibilityChange = isVisible => {
    const { isViewed } = this.state;

    if (!isViewed && isVisible) {
      this.saveViewedStateToServer();
    }
  };

  saveViewedStateToServer() {
    const { isLoading } = this.state;
    const { id } = this.props;

    if (isLoading) {
      return;
    }

    searchAssistanceApi
      .saveViewedState(id)
      .then(() => {
        this.setState({
          isLoading: false,
          isViewed: true,
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { ads } = this.state;

    return (
      <VisibilitySensor
        partialVisibility
        onChange={this.handleVisibilityChange}
      >
        <div className="SearchCabinet__offerCards">
          {ads.length > 0 &&
            ads.map(ad => (
              <div
                key={`SearchCabinetOfferCardsItem-${ad.id}`}
                className="SearchCabinet__offerCardsItem"
              >
                <SearchCabinetOfferCard ad={ad} />
              </div>
            ))}
        </div>
      </VisibilitySensor>
    );
  }
}

SearchCabinetOfferCards.propTypes = {
  ads: PropTypes.array.isRequired,
  id: PropTypes.number.isRequired,
  isViewed: PropTypes.bool,
};

SearchCabinetOfferCards.defaultProps = {
  isViewed: false,
};

export default setRailsContext(SearchCabinetOfferCards);
