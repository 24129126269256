import qs from 'qs';

import axios from './axios';

export const fetchAvailabilityNewsLetters = ({ filter }) => {
  return axios
    .get(
      `/api/frontend/ad_newsletters/availability${qs.stringify(
        { filter },
        { addQueryPrefix: true, arrayFormat: 'brackets' },
      )}`,
    )
    .then(({ data }) => data)
    .catch(({ response: { data: { errors } } }) => Promise.reject(errors));
};

export const subscribeToNewsLetter = ({ email, filter }) => {
  return axios
    .post('/api/frontend/ad_newsletters', { email, filter })
    .then(({ data }) => data)
    .catch(({ response: { data: { errors } } }) => Promise.reject(errors));
};
