import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

import OfferFavorite from '../OfferFavorite/OfferFavorite';
import OfferFavoriteDropdown from '../OfferFavoriteDropdown/OfferFavoriteDropdown';
import Dropdown from '../Dropdown/Dropdown';
import FavoriteButton from './FavoriteButton';

class OfferCardFavoriteControl extends React.PureComponent {
  state = {
    isOpen: false,
    isHovered: false,
  };

  handleDropdownOutsideClick = () => {
    this.setState({ isOpen: false });
  };

  handleMouseEnter = event => {
    if (!DeviceSupports.touch) {
      this.setState({ isHovered: true });
    }
  };

  handleMouseLeave = event => {
    if (!DeviceSupports.touch) {
      this.setState({ isHovered: false });
    }
  };

  handleUnsignedFavoriteClick = () => {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
  };

  render() {
    const { isOpen, isHovered } = this.state;
    const {
      id,
      intl,
      isInitialFavorited,
      isUserLoggedIn,
      onFavorite,
      onUnfavorite,
    } = this.props;

    return (
      <span className="OfferCard__control OfferCard__control--favorite">
        <OfferFavorite
          id={id} // ad.id
          intl={intl}
          isInitialFavorited={isInitialFavorited} // ad.favorite
          onFavorite={onFavorite}
          onUnfavorite={onUnfavorite}
        >
          {({ onFavoriteClick, isLoading, isFavorited }) => {
            if (!isUserLoggedIn) {
              return (
                <Dropdown
                  isOpen={isOpen}
                  extraClassName="dropdown-popup-auth"
                  onOutsideClick={this.handleDropdownOutsideClick}
                  button={
                    <FavoriteButton
                      intl={intl}
                      isLoading={isLoading}
                      isFavorited={isFavorited}
                      isHovered={isHovered}
                      onClick={this.handleUnsignedFavoriteClick}
                    />
                  }
                >
                  <OfferFavoriteDropdown intl={intl} />
                </Dropdown>
              );
            } else {
              return (
                <FavoriteButton
                  intl={intl}
                  isLoading={isLoading}
                  isFavorited={isFavorited}
                  isHovered={isHovered}
                  onMouseEnter={this.handleMouseEnter}
                  onMouseLeave={this.handleMouseLeave}
                  onClick={onFavoriteClick}
                />
              );
            }
          }}
        </OfferFavorite>
      </span>
    );
  }
}

OfferCardFavoriteControl.propTypes = {
  id: PropTypes.any,
  intl: intlShape.isRequired,
  isInitialFavorited: PropTypes.bool,
  isUserLoggedIn: PropTypes.bool,
  onFavorite: PropTypes.func,
  onUnfavorite: PropTypes.func,
};

OfferCardFavoriteControl.defaultProps = {
  isUserLoggedIn: false,
};

export default OfferCardFavoriteControl;
