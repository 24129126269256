import axios, { CancelToken } from './axios';

const uploadProfileAvatar = (params, { withCancel = false }) => {
  let cancel;

  const request = axios
    .post('/profile/upload_avatar', params, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    })
    .then(({ data }) => data)
    .catch(({ response: { data: { errors } } }) => Promise.reject(errors));

  return withCancel ? [request, cancel] : request;
};

const getUserProfileData = ({ withCancel = false }) => {
  let cancel;

  const request = axios
    .get('/api/frontend/profile', {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    })
    .then(({ data }) => data)
    .catch(({ response: { data = null } }) =>
      Promise.reject(data && data.errors ? data.errors : 'Server Error'),
    );

  return withCancel ? [request, cancel] : request;
};

const saveUserProfileData = (params, { withCancel = false } = {}) => {
  let cancel;
  const request = axios
    .put(
      '/users.json',
      { user: params },
      {
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      },
    )
    .then(({ data }) => data)
    .catch(({ response: { data = null } }) =>
      Promise.reject(data && data.errors ? data.errors : 'Server Error'),
    );

  return withCancel ? [request, cancel] : request;
};

const savePhone = (params, { withCancel = false }) => {
  let cancel;

  const request = axios
    .post(
      '/api/frontend/profile/phone/confirm',
      { user: params },
      {
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      },
    )
    .then(({ data }) => data)
    .catch(({ response: { data: { errors } } }) => Promise.reject(errors));

  return withCancel ? [request, cancel] : request;
};

const sendSmsCode = (params, { withCancel = false }) => {
  let cancel;

  const request = axios
    .post(
      '/api/frontend/profile/phone/new',
      { user: params },
      {
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      },
    )
    .then(({ data }) => data)
    .catch(({ response: { data: { errors } } }) => Promise.reject(errors));

  return withCancel ? [request, cancel] : request;
};

export const profileApi = {
  uploadProfileAvatar,
  getUserProfileData,
  saveUserProfileData,
  savePhone,
  sendSmsCode,
};

export default profileApi;
