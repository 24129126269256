/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import AuthModalTab from './AuthModalTab';

class AuthModalTabs extends React.PureComponent {
  render() {
    const {
      intl: { formatMessage },
      active,
      onTabClick,
    } = this.props;

    return (
      <div className="modal-form-tabs">
        <AuthModalTab
          id="phone"
          isActive={active === 'phone'}
          title={formatMessage(defaultMessages.jsAuthModalByPhoneLink)}
          onClick={onTabClick}
        />
        <AuthModalTab
          id="email"
          isActive={active === 'email'}
          title={formatMessage(defaultMessages.jsAuthModalByEmailLink)}
          onClick={onTabClick}
        />
      </div>
    );
  }
}

AuthModalTabs.defaultProps = {
  active: '',
  onTabClick: () => {},
};

AuthModalTabs.propTypes = {
  active: PropTypes.string,
  intl: intlShape.isRequired,
  onTabClick: PropTypes.func,
};

export default AuthModalTabs;
