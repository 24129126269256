import React from 'react';
import PropTypes from 'prop-types';
import isObject from 'lodash/isObject';

const getDisplayName = Component =>
  Component.displayName || Component.name || 'Component';

export default function(EnhancedComponent, attrName) {
  class WrapperComponent extends React.Component {
    static displayName = `AdFormValidatable(${getDisplayName(
      EnhancedComponent,
    )})`;

    validatePresented = () => {
      const { validateAttr } = this.props;

      validateAttr(attrName, { isPresented: true });
    };

    errorMessage = () => {
      const { errors } = this.props;

      return (
        this.hasErrors() && (
          <div className="error-message">{errors[attrName].join('; ')}</div>
        )
      );
    };

    hasErrors = () => {
      const { errors } = this.props;

      return isObject(errors[attrName]);
    };

    render() {
      return (
        <EnhancedComponent
          {...this.props}
          validatePresented={this.validatePresented}
          errorMessage={this.errorMessage}
          hasErrors={this.hasErrors}
        />
      );
    }
  }

  WrapperComponent.propTypes = {
    errors: PropTypes.object,
    validateAttr: PropTypes.func,
  };

  return WrapperComponent;
}
