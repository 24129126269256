/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import SvgIcon from '../SvgIcon/SvgIcon';
import styles from './AssistantAdvantageItem.scss';

const PROGRESS_CIRCLE_SIZE = 52;
const PROGRESS_CIRCLE_RADIUS = PROGRESS_CIRCLE_SIZE / 2;
const CIRCUMFERENCE = 2 * PROGRESS_CIRCLE_RADIUS * Math.PI;
const CIRCUMFERENCE_PERCENT = -(CIRCUMFERENCE / 100);

class AssistantAdvantageItem extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      progress: 0,
    };

    this.progressRAF = null;
  }

  componentDidMount() {
    const { isActive, progressTime } = this.props;

    if (isActive && progressTime > 0) {
      this.countdownProgress(progressTime);
    }
  }

  componentDidUpdate(prevProps) {
    const { isActive, progressTime } = this.props;

    if (!prevProps.isActive && isActive && progressTime > 0) {
      this.countdownProgress(progressTime);
    }
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.progressRAF);
  }

  countdownProgress = ms => {
    const { onProgressEnd } = this.props;

    const startTime = new Date().getTime();

    const onePercent = ms / 100;

    const tickTimeout = () => {
      const diff = new Date().getTime() - startTime;

      if (diff < ms) {
        this.setState({ progress: Math.round(diff / onePercent) });

        this.progressRAF = requestAnimationFrame(tickTimeout);
      }

      if (diff >= ms) {
        this.setState({ progress: 100 });

        onProgressEnd();
      }
    };

    this.progressRAF = requestAnimationFrame(tickTimeout);
  };

  handleIconClick = () => {
    const { id, onClick } = this.props;

    onClick && onClick(id);
  };

  render() {
    const { progress } = this.state;
    const {
      iconName,
      title,
      notice,
      shortTitle,
      isActive,
      progressTime,
    } = this.props;

    return (
      <li className={styles.advantagesListItem}>
        <div
          className={classNames(styles.item, {
            [styles.itemActive]: isActive,
          })}
        >
          <div
            className={styles.progressContainer}
            style={{
              width: `${PROGRESS_CIRCLE_SIZE}px`,
              height: `${PROGRESS_CIRCLE_SIZE}px`,
            }}
            onClick={this.handleIconClick}
          >
            {isActive && progressTime > 0 && (
              <svg
                className={styles.progress}
                height={`${PROGRESS_CIRCLE_SIZE}px`}
                width={`${PROGRESS_CIRCLE_SIZE}px`}
              >
                <circle
                  className={styles.progressCover}
                  r={`${PROGRESS_CIRCLE_RADIUS - 2}px`}
                  cx={`${PROGRESS_CIRCLE_RADIUS}px`}
                  cy={`${PROGRESS_CIRCLE_RADIUS}px`}
                  fill="transparent"
                  strokeWidth="4px"
                  strokeDasharray={CIRCUMFERENCE}
                  strokeDashoffset={CIRCUMFERENCE}
                />
                <circle
                  className={styles.progressBackground}
                  r={`${PROGRESS_CIRCLE_RADIUS - 2}px`}
                  cx={`${PROGRESS_CIRCLE_RADIUS}px`}
                  cy={`${PROGRESS_CIRCLE_RADIUS}px`}
                  fill="transparent"
                  strokeWidth="2px"
                  strokeDasharray={
                    CIRCUMFERENCE - CIRCUMFERENCE_PERCENT * progress
                  }
                  strokeDashoffset={CIRCUMFERENCE}
                />
              </svg>
            )}
            <span className={styles.icon}>
              <SvgIcon icon={iconName} />
            </span>
          </div>
          <div
            className={classNames(styles.description, {
              [styles.descriptionActive]: isActive,
            })}
          >
            <p className={styles.shortTitle}>{shortTitle}</p>
            <div className={styles.descriptionContent}>
              <p className={styles.title}>{title}</p>
              <p className={styles.notice}>{notice}</p>
            </div>
          </div>
        </div>
      </li>
    );
  }
}

AssistantAdvantageItem.propTypes = {
  iconName: PropTypes.string,
  id: PropTypes.number,
  isActive: PropTypes.bool,
  notice: PropTypes.string,
  progressTime: PropTypes.number,
  shortTitle: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  onProgressEnd: PropTypes.func,
};

export default AssistantAdvantageItem;
