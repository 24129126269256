export default {
  isMobile(userAgent) {
    return !!userAgent.match(/Mobile/i);
  },

  isIphone(userAgent) {
    return !!userAgent.match(/iPhone/i);
  },

  isAndroid(userAgent) {
    return !!userAgent.match(/Android/i);
  },
};
