import '@babel/polyfill';

import ReactOnRails from 'react-on-rails';
import React from 'react';
import ReactDOM from 'react-dom';
import smoothscroll from 'smoothscroll-polyfill';

// kick off the polyfill!
smoothscroll.polyfill();

// ==========================================================
// Only client-side
// ==========================================================

// Components
import AdAfterCreateModal from '../bundles/client/components/AdAfterCreateModal/AdAfterCreateModal';
import AdForm from '../bundles/client/components/AdForm/AdForm';
import AdHistoryButton from '../bundles/client/components/AdHistory/AdHistoryButton';
import AdNewsletterModal from '../bundles/client/components/AdNewsletterModal/AdNewsletterModal';
import AuthModal from '../bundles/client/components/AuthModal/AuthModal';
import OwnerAssistantBanner from '../bundles/client/components/OwnerAssistantBanner/OwnerAssistantBanner';
import RentSearchApplication from '../bundles/client/components/RentSearchApplication';
import ReviewRequestApplication from '../bundles/client/components/ReviewRequestApplication';
import SaleSearchApplication from '../bundles/client/components/SaleSearchApplication';

// Controllers
import '../bundles/client/controllers/AppBanner/AppBanner';
import '../bundles/client/controllers/UserSettingsModal/UserSettingsModal';

if (window) {
  window.React = React;
  window.ReactDOM = ReactDOM;
  window.AdAfterCreateModal = AdAfterCreateModal;
  window.AdNewsletterModal = AdNewsletterModal;
  window.AuthModal = AuthModal;
}

// ==========================================================
// Server-side render
// ==========================================================

// Components
import AdCatalog from '../bundles/client/components/AdCatalog/AdCatalog';
import AdContactsButton from '../bundles/client/components/AdContactsButton/AdContactsButton';
import AdViewingButton from '../bundles/client/components/AdViewingButton/AdViewingButton';
import CitySelector from '../bundles/client/components/CitySelector/CitySelector';
import MainFilters from '../bundles/client/components/MainFilters/MainFilters';
import OfferCards from '../bundles/client/components/OfferCards/OfferCards';
import PriceHistoryButton from '../bundles/client/components/PriceHistory/PriceHistoryButton';
import OfferGallery from '../bundles/client/components/OfferGallery/OfferGallery';
import OfferFavoriteButton from '../bundles/client/components/OfferFavoriteButton/OfferFavoriteButton';
import OfferFeaturingForm from '../bundles/client/components/OfferFeaturingForm/OfferFeaturingForm';
import OfferMap from '../bundles/client/components/OfferMap/OfferMap';
import PaymentForm from '../bundles/client/components/PaymentForm/PaymentForm';
import PaymentApplePay from '../bundles/client/components/PaymentApplePay/PaymentApplePay';
import SearchCabinetOfferCards from '../bundles/client/components/SearchCabinet/SearchCabinetOfferCards';
import WorkingTimeAlert from '../bundles/client/components/WorkingTimeAlert/WorkingTimeAlert';
import VerificationRequest from '../bundles/client/components/VerificationRequest';
import OfferHowItWorks from '../bundles/client/components/OfferHowItWorks';
import EditComment from '../bundles/client/components/EditComment/EditComment';
import AssistantAdvantages from '../bundles/client/components/AssistantAdvantages/AssistantAdvantages';
import MediaReviews from '../bundles/client/components/MediaReviews/MediaReviews';
import ContactViewsHistoryButton from '../bundles/client/components/ContactViewsHistory/ContactViewsHistoryButton';

ReactOnRails.register({
  AdCatalog,
  AdContactsButton,
  AdViewingButton,
  AdForm,
  AdHistoryButton,
  CitySelector,
  MainFilters,
  OfferCards,
  OfferGallery,
  OfferFavoriteButton,
  OfferFeaturingForm,
  OfferMap,
  OwnerAssistantBanner,
  PaymentForm,
  PaymentApplePay,
  SearchCabinetOfferCards,
  WorkingTimeAlert,
  VerificationRequest,
  OfferHowItWorks,
  EditComment,
  AssistantAdvantages,
  MediaReviews,
  PriceHistoryButton,
  ContactViewsHistoryButton,
  RentSearchApplication,
  ReviewRequestApplication,
  SaleSearchApplication,
});
