import { intlShape } from 'react-intl';
import { Collapse } from 'react-collapse';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Media from 'react-media';
import Modal from 'react-flex-modal';

import { sendAdFeedback } from '../../api/rooms';
import { defaultMessages } from '../../../../libs/i18n/default';
import AnimateOnMount, {
  ANIMATION_TYPES,
} from '../AnimateOnMount/AnimateOnMount';
import TranslationProvider from '../../../common/components/HOC/TranslationProvider';
import FlashNotifierService from '../../services/FlashNotifier/FlashNotifier';
import styles from './AdContactsRatingModal.scss';

const DISLIKE_COMMENTS = {
  DONT_PICKUP: 'Не берут трубку',
  ACTUALITY: 'Не актуально',
  COMMISION: 'Объявление с комиссией',
};

class AdContactsRatingModal extends React.PureComponent {
  state = {
    isSending: false,
    isDisliked: false,
    isOtherOpened: false,
    comments: '',
  };

  handleSubmit = ({ kind, comment = '' }) => {
    const { isSending } = this.state;
    const { id, intl } = this.props;

    let sendedIds = null;

    if (DeviceSupports.localStorage) {
      sendedIds = JSON.parse(localStorage.getItem('sendedAdContactsRating'));

      if (Array.isArray(sendedIds) && sendedIds.indexOf(id) !== -1) {
        return null;
      }
    }

    if (isSending) {
      return null;
    }

    this.setState({ isSending: true });

    sendAdFeedback(id, { kind, comment: comment })
      .then(data => {
        if (data) {
          FlashNotifierService.notifySuccess(
            intl.formatMessage(defaultMessages.jsFlashNotifierThanksForMark),
          );

          if (DeviceSupports.localStorage) {
            localStorage.setItem(
              'sendedAdContactsRating',
              JSON.stringify(
                sendedIds && Array.isArray(sendedIds)
                  ? sendedIds.concat(id)
                  : [id],
              ),
            );
          }
        }

        this.setState(
          { isSending: false, isDisliked: false },
          this.handleClose,
        );
      })
      .catch(error => {
        console.error(error);

        this.setState({ isSending: false });

        FlashNotifierService.notifyError(
          intl.formatMessage(defaultMessages.jsFlashNotifierSomethingWentWrong),
        );
      });
  };

  sendDislike = (comment = '') => {
    this.handleSubmit({ kind: 'dislike', comment: comment });
  };

  handleLike = () => {
    this.handleSubmit({ kind: 'like' });
  };

  handleDislike = () => {
    this.setState({ isDisliked: true });
  };

  handleDislikeDontPickup = async () => {
    this.sendDislike(DISLIKE_COMMENTS.DONT_PICKUP);
  };

  handleDislikeActuality = async () => {
    this.sendDislike(DISLIKE_COMMENTS.ACTUALITY);
  };

  handleDislikeComission = async () => {
    this.sendDislike(DISLIKE_COMMENTS.COMMISION);
  };

  handleOpenOther = () => {
    this.setState({ isOtherOpened: true });
  };

  handleCommentsChange = event => {
    this.setState({ comments: event.target.value });
  };

  handleSubmitWithComments = () => {
    const { comments } = this.state;

    this.sendDislike(comments);
  };

  handleClose = () => {
    const { isDisliked } = this.state;
    const { onClose } = this.props;

    if (isDisliked) {
      this.sendDislike();
    }

    this.setState({
      isSending: false,
      isDisliked: false,
      isOtherOpened: false,
      comments: '',
    });

    onClose && onClose();
  };

  render() {
    const { isDisliked, isOtherOpened, comments } = this.state;
    const { isOpen } = this.props;

    const content = (
      <React.Fragment>
        <h3 className={styles.title}>Оцените звонок</h3>
        <div className={styles.ratingContainer}>
          <button
            type="button"
            className={classNames(
              styles.buttonRating,
              styles.buttonRatingLike,
              { [styles.buttonRatingDisabled]: isDisliked },
            )}
            onClick={this.handleLike}
          >
            <img
              src={require('../../../../images/client/AdContacts/like.svg')}
              alt="like"
              width="30"
              height="30"
            />
          </button>
          <button
            type="button"
            className={classNames(
              styles.buttonRating,
              styles.buttonRatingDislike,
            )}
            onClick={this.handleDislike}
          >
            <img
              src={require('../../../../images/client/AdContacts/dislike.svg')}
              alt="dislike"
              width="30"
              height="30"
            />
          </button>
        </div>
        <Collapse isOpened={isDisliked}>
          <div className={styles.optionsContainer}>
            <div className={styles.optionWrapper}>
              <button
                type="button"
                className={styles.option}
                onClick={this.handleDislikeDontPickup}
              >
                <span className={styles.optionText}>
                  {DISLIKE_COMMENTS.DONT_PICKUP}
                </span>
              </button>
            </div>
            <div className={styles.optionWrapper}>
              <button
                type="button"
                className={styles.option}
                onClick={this.handleDislikeActuality}
              >
                <span className={styles.optionText}>
                  {DISLIKE_COMMENTS.ACTUALITY}
                </span>
              </button>
            </div>
            <div
              className={classNames(
                styles.optionWrapper,
                styles.optionWrapperFull,
              )}
            >
              <button
                type="button"
                className={styles.option}
                onClick={this.handleDislikeComission}
              >
                <span className={styles.optionText}>
                  {DISLIKE_COMMENTS.COMMISION}
                </span>
              </button>
            </div>
            <div
              className={classNames(
                styles.optionWrapper,
                styles.optionWrapperFull,
                styles.optionWrapperOther,
              )}
            >
              {isOtherOpened ? (
                <React.Fragment>
                  <textarea
                    className={styles.textarea}
                    placeholder="Ваш комментарий"
                    value={comments}
                    onChange={this.handleCommentsChange}
                  />
                  <button
                    type="button"
                    className={styles.buttonSubmit}
                    onClick={this.handleSubmitWithComments}
                    disabled={comments.length === 0}
                  >
                    Отправить
                  </button>
                </React.Fragment>
              ) : (
                <button
                  type="button"
                  className={styles.option}
                  onClick={this.handleOpenOther}
                >
                  <span className={styles.optionText}>Другое</span>
                </button>
              )}
            </div>
          </div>
        </Collapse>
      </React.Fragment>
    );

    return (
      <Media defaultMatches query="(max-width: 767px)">
        {isMobile => {
          return isMobile ? (
            <Modal
              transition
              isOpen={isOpen}
              onClose={this.handleClose}
              modalDialogClass={styles.modalDialog}
            >
              {content}
            </Modal>
          ) : (
            <AnimateOnMount
              isOpen={isOpen}
              component="div"
              animationType={ANIMATION_TYPES.SLIDE}
              transitionAppear
              transitionAppearTimeout={400}
              transitionEnterTimeout={400}
              transitionLeaveTimeout={200}
            >
              <div className={styles.container}>
                <button
                  type="button"
                  className={styles.buttonClose}
                  onClick={this.handleClose}
                />
                {content}
              </div>
            </AnimateOnMount>
          );
        }}
      </Media>
    );
  }
}

AdContactsRatingModal.propTypes = {
  id: PropTypes.number.isRequired,
  intl: intlShape,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

AdContactsRatingModal.defaultProps = {
  isOpen: false,
};

export default TranslationProvider(AdContactsRatingModal);
