import { intlShape } from 'react-intl';
import classNames from 'classnames';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import React from 'react';

import * as masks from '../../constants/masks';
import { defaultMessages } from '../../../../libs/i18n/default';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';

const UserSettingsModalPhoneSmsCodeAction = React.memo(function({
  intl: { formatMessage, formatPlural },
  showError,
  errorMessage,
  smsCode,
  smsResendTimer,
  smsCodeInputRef,
  onFocus,
  onBlur,
  onChange,
  onKeyDown,
  onResendClick,
}) {
  const isHasError = errorMessage !== null;

  return (
    <React.Fragment>
      <div className="settings-item">
        <p
          dangerouslySetInnerHTML={{
            __html: formatMessage(
              defaultMessages.jsSettingsModalSMSCodeSuccessNote,
            ),
          }}
        />
      </div>
      <div className="settings-item">
        <div
          className={classNames('input', {
            __error: showError && isHasError,
            __success: !isHasError,
          })}
        >
          <InputMask
            ref={smsCodeInputRef}
            className="input-control"
            type="text"
            name="code"
            mask={masks.SMS_CODE}
            placeholder={formatMessage(
              defaultMessages.jsSettingsModalSMSCodePlaceholder,
            )}
            value={smsCode}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            onKeyDown={onKeyDown}
          />
          {showError && isHasError && (
            <div className="error-message">{errorMessage}</div>
          )}
        </div>
      </div>
      <div className="settings-item">
        {smsResendTimer > 0 ? (
          <p
            dangerouslySetInnerHTML={{
              __html:
                formatMessage(defaultMessages.jsSettingsModalSMSCodeTimerNote, {
                  time: smsResendTimer,
                }) +
                ' ' +
                formatMessage(
                  defaultMessages[
                    `jsSettingsModalSMSCodeTimerNoteSeconds${capitalizeFirstLetter(
                      formatPlural(smsResendTimer),
                    )}`
                  ],
                ),
            }}
          />
        ) : (
          <p>
            <a
              className="link link-green"
              href="#"
              title={formatMessage(
                defaultMessages.jsSettingsModalResendSMSCodeLink,
              )}
              onClick={onResendClick}
            >
              {formatMessage(defaultMessages.jsSettingsModalResendSMSCodeLink)}
            </a>
          </p>
        )}
      </div>
    </React.Fragment>
  );
});

UserSettingsModalPhoneSmsCodeAction.propTypes = {
  errorMessage: PropTypes.string,
  intl: intlShape.isRequired,
  showError: PropTypes.bool.isRequired,
  smsCode: PropTypes.string.isRequired,
  smsCodeInputRef: PropTypes.func.isRequired,
  smsResendTimer: PropTypes.number.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  onResendClick: PropTypes.func.isRequired,
};

UserSettingsModalPhoneSmsCodeAction.defaultProps = {
  errorMessage: null,
};

export default UserSettingsModalPhoneSmsCodeAction;
