/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FIELD_NAMES, ROOM_QUANTITY_VALUES } from '../constants';
import Switcher from '../Switcher';
import styles from '../styles.scss';

const StepRoomQuantity = ({
  goToNextStep,
  roomQuantity,
  setMainValues,
  errors,
}) => {
  const isValid = !errors[FIELD_NAMES.ROOM_QUANTITY];

  const handleRoomQuantityChange = value => {
    setMainValues({ [FIELD_NAMES.ROOM_QUANTITY]: value });
  };

  const handleSubmitClick = () => {
    if (!isValid) {
      return null;
    }

    goToNextStep();
  };

  return (
    <React.Fragment>
      <div className={classNames(styles.inner, styles.innerTopbarSpace)}>
        <div className={styles.title}>Сколько комнат?</div>
        <div className={styles.checkboxRoomsGroup}>
          <Switcher
            multiple
            values={ROOM_QUANTITY_VALUES}
            initialActiveValue={roomQuantity}
            onChange={handleRoomQuantityChange}
          />
        </div>
      </div>
      <div className={styles.cta}>
        <div className={styles.buttonWrapper}>
          <button
            className={styles.button}
            type="button"
            disabled={!isValid}
            onClick={handleSubmitClick}
          >
            Дальше
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

StepRoomQuantity.propTypes = {
  errors: PropTypes.object.isRequired,
  goToNextStep: PropTypes.func.isRequired,
  roomQuantity: PropTypes.array.isRequired,
  setMainValues: PropTypes.func.isRequired,
};

export default StepRoomQuantity;
