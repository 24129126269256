import axios from './axios';
import { FIELD_NAMES } from '../components/RentSearchApplication/constants';

const createSearchAssistance = values => {
  return axios
    .post('/api/frontend/leads/search_assistance', {
      lead_search_assistance: {
        animals: values[FIELD_NAMES.ANIMALS],
        commission: values[FIELD_NAMES.COMMISSION],
        owner: values[FIELD_NAMES.OWNER],
        price_max: values[FIELD_NAMES.PRICE],
        min_space: values[FIELD_NAMES.MIN_SPACE],
        repairs: values[FIELD_NAMES.REPAIRS],
        rooms_amount: values[FIELD_NAMES.ROOM_QUANTITY],
        additionally: values[FIELD_NAMES.WISHES],
        residents: values[FIELD_NAMES.RESIDENTS],
        residents_other: values[FIELD_NAMES.RESIDENTS_OTHER],
        resident_work: values[FIELD_NAMES.WORK],
        urgency_type: values[FIELD_NAMES.URGENCY],
        urgency_date: values[FIELD_NAMES.URGENCY_DATE],
        priorities: values[FIELD_NAMES.PRIORITIES],
        receive_type: values[FIELD_NAMES.RECEIVE_TYPE],
        receive_values: values[FIELD_NAMES.RECEIVE_VALUE],
        location_type: values[FIELD_NAMES.LOCATION_TYPE],
        subway_ids: values[FIELD_NAMES.SUBWAY_IDS],
        subway_path_mode: values[FIELD_NAMES.FROM_SUBWAY],
        subway_path_duration: values[FIELD_NAMES.FROM_SUBWAY_TIME],
        custom_location: values[FIELD_NAMES.CUSTOM_LOCATION],
        source: values.lead_source || 'web',
        filter: values.filter,
        geo_areas: values[FIELD_NAMES.GEO_AREAS],
      },
    })
    .then(({ data }) => data)
    .catch(({ response: { data: { errors } } }) => Promise.reject(errors));
};

const fovoriteAdVariant = id => {
  return axios
    .post(`/api/frontend/search_assistance/ad_variants/${id}/favorite`)
    .then(({ data }) => data)
    .catch(({ response: { data: { errors } } }) => Promise.reject(errors));
};

const unfovoriteAdVariant = id => {
  return axios
    .post(`/api/frontend/search_assistance/ad_variants/${id}/unfavorite`)
    .then(({ data }) => data)
    .catch(({ response: { data: { errors } } }) => Promise.reject(errors));
};

const saveViewedState = id => {
  return axios
    .post(`/api/frontend/search_assistance/emails/${id}/view`, {
      isViewed: true,
    })
    .then(({ data }) => data)
    .catch(({ response: { data: { errors } } }) => Promise.reject(errors));
};

export const searchAssistanceApi = {
  createSearchAssistance,
  fovoriteAdVariant,
  unfovoriteAdVariant,
  saveViewedState,
};

export default searchAssistanceApi;
