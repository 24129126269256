/* eslint-disable jsx-a11y/label-has-associated-control */
import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'react-tooltip-lite';

import { defaultMessages } from '../../../../libs/i18n/default';
import { FILTER_KEYS } from './AdCatalog.constants';

class AdCatalogFilterBodyVerification extends React.PureComponent {
  onChange = event => {
    const { updateFilter } = this.props;
    const { checked } = event.target;

    if (checked) {
      updateFilter({
        [FILTER_KEYS.VERIFICATION]: true,
        [FILTER_KEYS.REALTOR]: null,
        [FILTER_KEYS.REALTOR_COMISSION]: null,
      });
    } else {
      updateFilter({
        [FILTER_KEYS.VERIFICATION]: false,
        [FILTER_KEYS.REALTOR]: true,
        [FILTER_KEYS.REALTOR_COMISSION]: 100,
      });
    }
  };

  render() {
    const {
      isActive,
      intl: { formatMessage },
    } = this.props;

    return (
      <div className="filter-row filter-row-padding-top">
        <div className="filter-label">
          {formatMessage(defaultMessages.jsCatalogFilterSource)}
        </div>
        <label className="filter-checkbox filter-checkbox-toggler">
          <input
            className="filter-checkbox-control"
            type="checkbox"
            checked={isActive}
            onChange={this.onChange}
          />
          <span className="filter-checkbox-fake" />
          <span className="filter-checkbox-text">
            {formatMessage(defaultMessages.jsCatalogFilterOnlyVerified)}{' '}
            <Tooltip
              arrow
              arrowSize={7}
              distance={11}
              direction="down"
              tagName="span"
              content={
                <span className="catalog-tooltip-inner">
                  {formatMessage(
                    defaultMessages.jsCatalogFilterOnlyVerifiedTooltip,
                  )}
                </span>
              }
            >
              <span className="filter-help">i</span>
            </Tooltip>
          </span>
        </label>
      </div>
    );
  }
}

AdCatalogFilterBodyVerification.propTypes = {
  intl: intlShape.isRequired,
  isActive: PropTypes.bool,
  updateFilter: PropTypes.func.isRequired,
};

AdCatalogFilterBodyVerification.defaultProps = {
  isActive: false,
};

export default AdCatalogFilterBodyVerification;
