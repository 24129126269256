import * as React from 'react';
import classNames from 'classnames';
import Modal from 'react-flex-modal';
import Media from 'react-media';

import styles from './AssistantSelection.scss';

class AssistantSelection extends React.Component {
  state = {
    isHovered: false,
    isModalOpen: false,
  };

  handleOnMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleOnMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  handleClick = () => {
    this.setState(({ isHovered }) => ({ isHovered: !isHovered }));
  };

  handleMobileClick = () => {
    this.setState(({ isModalOpen }) => ({ isModalOpen: !isModalOpen }));
  };

  handleModalClose = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const { isHovered, isModalOpen } = this.state;

    return (
      <span
        className={classNames(styles.container, {
          [styles.hovered]: isHovered,
        })}
      >
        Вот пример подборки:
        <br />
        <Media defaultMatches query="(max-width: 767px)">
          {isMobile =>
            !isMobile ? (
              <button
                className={styles.link}
                onMouseEnter={this.handleOnMouseEnter}
                onMouseLeave={this.handleOnMouseLeave}
                onClick={this.handleClick}
                type="button"
              >
                thelocals.ru/podborka/2049
                <img
                  className={styles.image}
                  src={require('../../../../images/client/AssistantChat/selection.png')}
                  alt=""
                  width={2048}
                  height={2454}
                />
              </button>
            ) : (
              <React.Fragment>
                <button
                  className={styles.link}
                  onClick={this.handleMobileClick}
                  type="button"
                >
                  thelocals.ru/podborka/2049
                </button>
                <Modal
                  transition
                  modalDialogClass={styles.modalDialog}
                  isOpen={isModalOpen}
                  onClose={this.handleModalClose}
                >
                  <div className={styles.mobileImageContainer}>
                    <img
                      className={styles.image}
                      src={require('../../../../images/client/AssistantChat/selection-mobile.png')}
                      alt=""
                      width={828}
                      height={1248}
                    />
                  </div>
                </Modal>
              </React.Fragment>
            )
          }
        </Media>
      </span>
    );
  }
}

export default AssistantSelection;
