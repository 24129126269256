import { intlShape } from 'react-intl';
import React from 'react';

import { defaultMessages } from '../../../../libs/i18n/default';
import { AUTH_VK_PATH } from '../../constants/paths';

const AuthModalVkButton = ({ intl: { formatMessage } }) => (
  <a
    className="button button-vk"
    href={AUTH_VK_PATH}
    title={formatMessage(defaultMessages.jsSocialButtonVkontakte)}
  >
    {formatMessage(defaultMessages.jsSocialButtonVkontakte)}
  </a>
);

AuthModalVkButton.propTypes = {
  intl: intlShape.isRequired,
};

export default AuthModalVkButton;
