import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import SvgIcon from '../SvgIcon/SvgIcon';

class MainFiltersSubmitButton extends React.Component {
  handleClick = event => {
    const { isDisabled, onClick } = this.props;

    if (isDisabled) {
      return null;
    }

    onClick(event);
  };

  render() {
    const {
      intl: { formatMessage },
      isDisabled,
    } = this.props;

    return (
      <button
        className="MainFilters__submit Button Button--green"
        type="button"
        disabled={isDisabled}
        onClick={this.handleClick}
      >
        <SvgIcon icon="magnifier" />
        {formatMessage(defaultMessages.jsMainFiltersSubmitButton)}
      </button>
    );
  }
}

MainFiltersSubmitButton.propTypes = {
  intl: intlShape.isRequired,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default MainFiltersSubmitButton;
