/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';

import { FILTER_KEYS } from './AdCatalog.constants';

class AdCatalogFilterBodyExtraTags extends Component {
  handleChange = value => {
    const { activeIds, updateFilter } = this.props;

    let nextActiveIds = isNil(activeIds) ? [] : [].concat(activeIds);

    const i = nextActiveIds.indexOf(value);

    if (i === -1) {
      nextActiveIds.push(value);
    } else {
      nextActiveIds.splice(i, 1);
    }

    updateFilter({
      [FILTER_KEYS.AMENITIES]: nextActiveIds.length ? nextActiveIds : null,
    });
  };

  isChecked(id) {
    const { activeIds } = this.props;

    return activeIds && activeIds.length > 0 && activeIds.indexOf(id) !== -1;
  }

  render() {
    const { tags } = this.props;

    return (
      <ul className="filter-list">
        {tags.length > 0 &&
          tags.map(({ id, title }) => (
            <li className="filter-list-item" key={`tagItem-${id}`}>
              <label className="filter-checkbox filter-checkbox-normal">
                <input
                  className="filter-checkbox-control"
                  type="checkbox"
                  checked={this.isChecked(id)}
                  onChange={this.handleChange.bind(this, id)}
                />
                <span className="filter-checkbox-fake" />
                <span className="filter-checkbox-text">{title}</span>
              </label>
            </li>
          ))}
      </ul>
    );
  }
}

AdCatalogFilterBodyExtraTags.propTypes = {
  activeIds: PropTypes.array,
  tags: PropTypes.array,
  updateFilter: PropTypes.func.isRequired,
};

AdCatalogFilterBodyExtraTags.defaultProps = {
  activeIds: [],
  tags: [],
};

export default AdCatalogFilterBodyExtraTags;
