import React from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import ButtonBack from './ButtonBack';
import StepCounter from './StepCounter';
import styles from './styles.scss';

const TransitionGroupContainer = ({ children }) => {
  return children || null;
};

const Topbar = React.memo(function({ step, stepCount, goToPrevStep }) {
  return (
    <div className={styles.topbar}>
      <ButtonBack goToPrevStep={goToPrevStep} />
      <ReactCSSTransitionGroup
        transitionName={{
          enter: styles['stepCounter--enter'],
          enterActive: styles['stepCounter--enterActive'],
          leave: styles['stepCounter--leave'],
          leaveActive: styles['stepCounter--leaveActive'],
        }}
        transitionEnterTimeout={400}
        transitionLeaveTimeout={400}
        component={TransitionGroupContainer}
      >
        {step <= stepCount && <StepCounter step={step} stepCount={stepCount} />}
      </ReactCSSTransitionGroup>
    </div>
  );
});

Topbar.propTypes = {
  goToPrevStep: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  stepCount: PropTypes.number.isRequired,
};

export default Topbar;
