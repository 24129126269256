export default {
  get(key) {
    if (typeof document === 'undefined') {
      return;
    }

    const keyEqual = key + '=';
    const cookieArray = document.cookie.split(';');

    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      let cookieLenght = cookie.length;

      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1, cookieLenght);
      }

      if (cookie.indexOf(keyEqual) === 0) {
        return cookie.substring(keyEqual.length, cookieLenght);
      }
    }

    return null;
  },

  set(key, value, days) {
    if (typeof document === 'undefined') {
      return;
    }

    let expires = '';

    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toGMTString();
    }

    document.cookie = key + '=' + value + expires + '; path=/';
  },
};
