import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';

const DRAW_STATE = {
  INACTIVE: 'inactive',
  EDITING: 'editing',
  ADDED: 'added',
};

class AdCatalogYandexMapDrawer extends React.Component {
  constructor(props) {
    super(props);

    const { isDrawn, enableDraw, startDrawInitial } = this.props;

    let initialState = DRAW_STATE.INACTIVE;

    if (isDrawn) {
      initialState = DRAW_STATE.ADDED;
    } else {
      initialState = DRAW_STATE.INACTIVE;
    }

    if (enableDraw && startDrawInitial) {
      initialState = DRAW_STATE.EDITING;
    }

    this.state = {
      // eslint-disable-next-line react/no-unused-state
      isDrawn,
      currentState: initialState,
      mouseDown: false,
      mouseLeft: false,
    };

    this.drawerRef = React.createRef();
  }

  componentDidMount() {
    const { onDrawButtonClick } = this.props;
    const { currentState } = this.state;

    if (currentState === DRAW_STATE.EDITING) {
      onDrawButtonClick && onDrawButtonClick();
    }
  }

  componentDidUpdate(prevProps) {
    const { enableDraw } = this.props;

    if (prevProps.enableDraw && !enableDraw) {
      this.handleCancelButtonClick();
    }

    if (!prevProps.enableDraw && enableDraw) {
      this.handleDrawButtonClick();
    }
  }

  getClassName() {
    const { currentState } = this.state;

    return (
      'catalog-map-drawer' +
      (currentState === DRAW_STATE.EDITING ? ' __editing' : '')
    );
  }

  handleCancelButtonClick = event => {
    const { onCancelButtonClick } = this.props;

    if (event) {
      event.preventDefault();
    }

    this.setState(({ isDrawn }) => ({
      currentState: isDrawn ? DRAW_STATE.ADDED : DRAW_STATE.INACTIVE,
    }));

    onCancelButtonClick && onCancelButtonClick();
  };

  handleClearButtonClick = e => {
    e.preventDefault();

    this.clear();
  };

  handleMouseDown = () => {
    const { currentState } = this.state;
    const { onDrawStart } = this.props;

    if (currentState === DRAW_STATE.EDITING) {
      this.setState({
        mouseDown: true,
        mouseLeft: false,
      });

      onDrawStart();

      if (this.drawerRef.current) {
        this.drawerRef.current.addEventListener('mousemove', this.trackPoints);
      }
    }
  };

  handleMouseUp = () => {
    const { currentState, mouseLeft } = this.state;
    if (currentState === DRAW_STATE.EDITING && !mouseLeft) {
      this.setState({ mouseDown: false });
      this.drawEnd();
    }
  };

  // Баг, если увести за пределы карты без отжатия клавиши мыши и вернуть в пределы карты
  handleMouseLeave = () => {
    const { currentState, mouseDown } = this.state;

    if (currentState === DRAW_STATE.EDITING && mouseDown) {
      this.setState({
        mouseDown: false,
        mouseLeft: true,
      });
      this.drawEnd();
    }
  };

  handleTouchStart = () => {
    const { currentState } = this.state;
    const { onDrawStart } = this.props;

    if (currentState === DRAW_STATE.EDITING) {
      onDrawStart();
    }
  };

  handleTouchMove = e => {
    const { currentState } = this.state;
    const { onDraw } = this.props;

    if (currentState === DRAW_STATE.EDITING) {
      onDraw([e.changedTouches[0].pageX, e.changedTouches[0].pageY]);
      e.preventDefault();
    }
  };

  handleTouchEnd = e => {
    const { currentState } = this.state;

    if (currentState === DRAW_STATE.EDITING) {
      this.drawEnd();
    }
  };

  handleDrawButtonClick = event => {
    const { onDrawButtonClick } = this.props;

    this.setState({ currentState: DRAW_STATE.EDITING });

    if (event) {
      event.preventDefault();
    }

    onDrawButtonClick && onDrawButtonClick();
  };

  trackPoints = e => {
    const { onDraw } = this.props;

    onDraw([e.pageX, e.pageY]);
  };

  drawEnd() {
    const { multipleAreas, onDrawEnd } = this.props;

    if (this.drawerRef.current) {
      this.drawerRef.current.removeEventListener('mousemove', this.trackPoints);
    }

    onDrawEnd(() => {
      this.setState({
        // currentState: DRAW_STATE.ADDED,
        currentState: multipleAreas ? DRAW_STATE.EDITING : DRAW_STATE.ADDED,
        // isDrawn: true, // Если нужна возможность выделения нескольких областей
      });
    });
  }

  clear() {
    const { onDrawClear } = this.props;

    onDrawClear();

    this.setState({
      currentState: DRAW_STATE.INACTIVE,
      // isDrawn: false // Если нужна возможность выделения нескольких областей
    });
  }

  renderDrawButton() {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <a
        key="drawButton"
        href="#"
        className="catalog-map-drawer-button button button-green button-small"
        onClick={this.handleDrawButtonClick}
      >
        {formatMessage(defaultMessages.jsCatalogMapDrawerSelectAreaButton)}
      </a>
    );
  }

  renderCancelButton() {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <a
        key="cancelButton"
        href="#"
        className="catalog-map-drawer-button button button-orange button-small"
        onClick={this.handleCancelButtonClick}
      >
        {formatMessage(defaultMessages.jsCatalogMapDrawerExitButton)}
      </a>
    );
  }

  renderClearButton() {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <a
        key="clearButton"
        href="#"
        className="catalog-map-drawer-button button button-orange button-small"
        onClick={this.handleClearButtonClick}
      >
        {formatMessage(defaultMessages.jsCatalogMapDrawerClearButton)}
      </a>
    );
  }

  renderButtons() {
    const { multipleAreas } = this.props;
    const { currentState } = this.state;

    let nodes = [];

    switch (currentState) {
      case DRAW_STATE.INACTIVE:
        nodes.push(this.renderDrawButton());
        break;
      case DRAW_STATE.EDITING:
        nodes.push(this.renderCancelButton());
        break;
      case DRAW_STATE.ADDED:
        // nodes.push(this.renderDrawButton()); // Если нужна возможность выделения нескольких областей
        !multipleAreas && nodes.push(this.renderClearButton());
        break;
    }

    return <div className="catalog-map-drawer-buttons">{nodes}</div>;
  }

  renderOverlay() {
    return (
      <div
        role="presentation"
        ref={this.drawerRef}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        onMouseLeave={this.handleMouseLeave}
        onTouchStart={this.handleTouchStart}
        onTouchMove={this.handleTouchMove}
        onTouchEnd={this.handleTouchEnd}
        className="catalog-map-drawer-overlay"
      />
    );
  }

  render() {
    const { showButtons } = this.props;

    return (
      <div className={this.getClassName()}>
        {showButtons && this.renderButtons()}
        {this.renderOverlay()}
      </div>
    );
  }
}

AdCatalogYandexMapDrawer.propTypes = {
  enableDraw: PropTypes.bool,
  intl: intlShape.isRequired,
  isDrawn: PropTypes.bool, // Если нужна возможность выделения нескольких областей
  multipleAreas: PropTypes.bool,
  showButtons: PropTypes.bool,
  startDrawInitial: PropTypes.bool,
  onCancelButtonClick: PropTypes.func,
  onDraw: PropTypes.func,
  onDrawButtonClick: PropTypes.func,
  onDrawClear: PropTypes.func,
  onDrawEnd: PropTypes.func,
  onDrawStart: PropTypes.func,
};

AdCatalogYandexMapDrawer.defaultProps = {
  enableDraw: true,
  isDrawn: false,
  multipleAreas: false,
  startDrawInitial: false,
  showButtons: true,
};

export default AdCatalogYandexMapDrawer;
