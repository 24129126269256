/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

const OfferMetro = ({ text, colors }) => {
  return (
    text && (
      <span className="metro">
        <span className="metro__label">
          {colors && colors.length > 0 ? (
            colors.map((color, index) => {
              return (
                <span
                  key={`${index}-color${color}`}
                  className="metro__icon"
                  style={{ backgroundColor: color }}
                />
              );
            })
          ) : (
            <span className="metro__sign" />
          )}
        </span>
        <span className="metro__text">{text}</span>
      </span>
    )
  );
};

OfferMetro.propTypes = {
  colors: PropTypes.array,
  text: PropTypes.node,
};

export default OfferMetro;
