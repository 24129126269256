export default function isElementContains(element, other) {
  var node = other;

  do {
    if (element === node) {
      return true;
    }

    if (node) {
      node = node.parentNode;
    }
  } while (node);

  return false;
}
