/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FIELD_NAMES, PRIORITIY_VALUES } from '../constants';
import Switcher from '../Switcher';
import styles from '../styles.scss';

function StepPriorities({ priorities, errors, goToNextStep, setMainValues }) {
  const isValid = !errors[FIELD_NAMES.PRIORITIES];

  const handlePrioritiesChange = value => {
    if (value.length < 2) {
      setMainValues({
        [FIELD_NAMES.PRIORITIES]: value,
      });
    }
  };

  const handleSubmitClick = () => {
    if (!isValid) {
      return null;
    }

    goToNextStep();
  };

  return (
    <React.Fragment>
      <div className={classNames(styles.inner, styles.innerTopbarSpace)}>
        <div className={styles.title}>Что для вас самое важное?</div>
        <div className={styles.formItem}>
          <div className={styles.formLabel}>
            Выберите не более двух вариантов
          </div>
          <Switcher
            multiple
            maxValues={2}
            values={PRIORITIY_VALUES}
            initialActiveValue={priorities}
            onChange={handlePrioritiesChange}
          />
        </div>
      </div>
      <div className={styles.cta}>
        <div className={styles.buttonWrapper}>
          <button
            className={classNames(styles.button)}
            type="button"
            disabled={!isValid}
            onClick={handleSubmitClick}
          >
            Дальше
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

StepPriorities.propTypes = {
  errors: PropTypes.object.isRequired,
  goToNextStep: PropTypes.func.isRequired,
  priorities: PropTypes.array.isRequired,
  setMainValues: PropTypes.func.isRequired,
};

export default StepPriorities;
