import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import isObject from 'lodash/isObject';

import { defaultMessages } from '../../../../libs/i18n/default';
import { FIELD_NAMES } from './constants';
import { REPAIR_TYPES, PARKING_TYPES } from '../AdCatalog/AdCatalog.constants';
import AdFormRadio from './AdFormRadio';
import AdFormInputCounter from './AdFormInputCounter';
import AdFormCustomCheckbox from './AdFormCustomCheckbox';

class AdSaleFormExtraOptions extends React.PureComponent {
  state = {
    attrNames: [
      FIELD_NAMES.BALCONY_COUNT,
      FIELD_NAMES.LOGGIA_COUNT,
      FIELD_NAMES.WINDOWS_POSITION_OUTSIDE,
      FIELD_NAMES.WINDOWS_POSITION_COURTYARD,
      FIELD_NAMES.COMBINED_BATHROOM_COUNT,
      FIELD_NAMES.SEPARATE_BATHROOM_COUNT,
      FIELD_NAMES.REPAIR_TYPE,
      FIELD_NAMES.PASSENGER_ELEVATOR_COUNT,
      FIELD_NAMES.SERVICE_ELEVATOR_COUNT,
      FIELD_NAMES.ENTRANCE_RAMP,
      FIELD_NAMES.ENTRANCE_GARBAGE_CHUTE,
      FIELD_NAMES.PARKING_TYPE,
    ],
  };

  componentDidMount() {
    this.validatePresented();
  }

  handleBalconyCount = (event, val) => {
    const { setAdState } = this.props;

    setAdState({ [FIELD_NAMES.BALCONY_COUNT]: val });
  };

  handleLoggiaCount = (event, val) => {
    const { setAdState } = this.props;

    setAdState({ [FIELD_NAMES.LOGGIA_COUNT]: val });
  };

  handleCourtyardWindows = (event, val) => {
    const { setAdState } = this.props;

    setAdState({ [FIELD_NAMES.WINDOWS_POSITION_COURTYARD]: val });
  };

  handleOutsideWindows = (event, val) => {
    const { setAdState } = this.props;

    setAdState({ [FIELD_NAMES.WINDOWS_POSITION_OUTSIDE]: val });
  };

  handleSeparateBathroomCount = (event, val) => {
    const { setAdState } = this.props;

    setAdState({ [FIELD_NAMES.SEPARATE_BATHROOM_COUNT]: val });
  };

  handleCombineBathroomCount = (event, val) => {
    const { setAdState } = this.props;

    setAdState({ [FIELD_NAMES.COMBINED_BATHROOM_COUNT]: val });
  };

  handleChangeRepairType = event => {
    const { setAdState } = this.props;

    setAdState({ [FIELD_NAMES.REPAIR_TYPE]: event.currentTarget.value });
  };

  handlePassengerElevatorCount = (event, val) => {
    const { setAdState } = this.props;

    setAdState({ [FIELD_NAMES.PASSENGER_ELEVATOR_COUNT]: val });
  };

  handleServiceElevatorCount = (event, val) => {
    const { setAdState } = this.props;

    setAdState({ [FIELD_NAMES.SERVICE_ELEVATOR_COUNT]: val });
  };

  handleEntranceRamp = (event, val) => {
    const { setAdState } = this.props;

    setAdState({ [FIELD_NAMES.ENTRANCE_RAMP]: val });
  };

  handleEntranceGarbageChute = (event, val) => {
    const { setAdState } = this.props;

    setAdState({ [FIELD_NAMES.ENTRANCE_GARBAGE_CHUTE]: val });
  };

  handleChangeParkingType = event => {
    const { setAdState } = this.props;

    setAdState({ [FIELD_NAMES.PARKING_TYPE]: event.currentTarget.value });
  };

  validatePresented() {
    const { attrNames } = this.state;
    const { validateAttr } = this.props;

    attrNames.forEach(attr => {
      validateAttr(attr, { isPresented: true });
    });
  }

  errorMessage() {
    const {
      intl: { formatMessage },
    } = this.props;

    const hasError =
      this.hasErrors(FIELD_NAMES.BALCONY_COUNT) ||
      this.hasErrors(FIELD_NAMES.LOGGIA_COUNT) ||
      this.hasErrors(FIELD_NAMES.WINDOWS_POSITION_OUTSIDE) ||
      this.hasErrors(FIELD_NAMES.WINDOWS_POSITION_COURTYARD) ||
      this.hasErrors(FIELD_NAMES.COMBINED_BATHROOM_COUNT) ||
      this.hasErrors(FIELD_NAMES.SEPARATE_BATHROOM_COUNT) ||
      this.hasErrors(FIELD_NAMES.REPAIR_TYPE) ||
      this.hasErrors(FIELD_NAMES.PASSENGER_ELEVATOR_COUNT) ||
      this.hasErrors(FIELD_NAMES.SERVICE_ELEVATOR_COUNT) ||
      this.hasErrors(FIELD_NAMES.ENTRANCE_RAMP) ||
      this.hasErrors(FIELD_NAMES.ENTRANCE_GARBAGE_CHUTE) ||
      this.hasErrors(FIELD_NAMES.PARKING_TYPE);

    return (
      hasError && (
        <div className="error-message">
          {formatMessage(defaultMessages.jsAdFormErrorsMustBeNumeric)}
          // Todo: add default messages
        </div>
      )
    );
  }

  hasErrors(attr) {
    const { errors } = this.props;

    return isObject(errors[attr]);
  }

  render() {
    const {
      balconyCount,
      loggiaCount,
      windowsPositionOutside,
      windowsPositionCourtyard,
      combinedВathroomCount,
      separateВathroomCount,
      repairType,
      passengerElevatorCount,
      serviceElevatorCount,
      entranceRamp,
      entranceGarbageChute,
      parkingType,
      intl: { formatMessage },
    } = this.props;

    return (
      <div className="form-row">
        <div className="form-row-label">
          {formatMessage(defaultMessages.jsAdFormExtLabel)}
        </div>

        <div className="grid form-row-content grid-extra-options-block-row">
          <div className="item-option-living grid-item grid-item-option">
            <label
              className="control-label"
              htmlFor="ad_balcony"
              id="ad_input_balcony"
            >
              {formatMessage(defaultMessages.jsAdFormExtraOptionBalcony)}
            </label>
            <div
              className="grid-item grid-item-option"
              style={{ marginRight: '20px' }}
            >
              <AdFormInputCounter
                value={balconyCount}
                label={formatMessage(
                  defaultMessages.jsAdFormExtraOptionBalconyCount,
                )}
                error={this.hasErrors(FIELD_NAMES.BALCONY_COUNT)}
                onChange={this.handleBalconyCount}
              />
            </div>
            <div className="grid-item grid-item-option">
              <AdFormInputCounter
                value={loggiaCount}
                label={formatMessage(
                  defaultMessages.jsAdFormExtraOptionLoggiaCount,
                )}
                error={this.hasErrors(FIELD_NAMES.LOGGIA_COUNT)}
                onChange={this.handleLoggiaCount}
              />
            </div>
          </div>
          <div className="item-option-living">
            <div className="grid-item grid-item-option">
              <label
                className="control-label"
                htmlFor="ad_windows_position"
                id="ad_input_windows_position"
              >
                {formatMessage(
                  defaultMessages.jsAdFormExtraOptionWindowsPosition,
                )}
              </label>
              <div className="flex">
                <AdFormCustomCheckbox
                  value="outside"
                  text="На улицу"
                  checked={windowsPositionOutside}
                  onChange={this.handleOutsideWindows}
                ></AdFormCustomCheckbox>
                <AdFormCustomCheckbox
                  value="courtyard"
                  text="Во двор"
                  checked={windowsPositionCourtyard}
                  onChange={this.handleCourtyardWindows}
                ></AdFormCustomCheckbox>
              </div>
            </div>
          </div>
          <div className="item-option-living grid-item grid-item-option">
            <label
              className="control-label"
              htmlFor="ad_bathroom"
              id="ad_input_bathroom"
            >
              {formatMessage(defaultMessages.jsAdFormExtraOptionBathroom)}
            </label>
            <div
              className="grid-item grid-item-option"
              style={{ marginRight: '20px' }}
            >
              <AdFormInputCounter
                value={separateВathroomCount}
                label={formatMessage(
                  defaultMessages.jsAdFormExtraOptionSeparateBathroomCount,
                )}
                error={this.hasErrors(FIELD_NAMES.SEPARATE_BATHROOM_COUNT)}
                onChange={this.handleSeparateBathroomCount}
              />
            </div>
            <div className="grid-item grid-item-option">
              <AdFormInputCounter
                value={combinedВathroomCount}
                label={formatMessage(
                  defaultMessages.jsAdFormExtraOptionCombineBathroomCount,
                )}
                error={this.hasErrors(FIELD_NAMES.COMBINED_BATHROOM_COUNT)}
                onChange={this.handleCombineBathroomCount}
              />
            </div>
          </div>
          <div className="grid-item grid-item-option">
            <label
              className="control-label"
              htmlFor="ad_repair_type"
              id="ad_input_repair_type"
            >
              {formatMessage(defaultMessages.jsAdFormExtraOptionRepairType)}
            </label>

            <div
              className="radio-group grid-item-option-building-type-radio-block"
              id="ad_input_repair_type"
            >
              <AdFormRadio
                value="none"
                text={formatMessage(
                  defaultMessages.jsAdFormExtraOptionRepairTypesNone,
                )}
                checked={repairType === REPAIR_TYPES.NONE}
                onChange={this.handleChangeRepairType}
              />
              <AdFormRadio
                value="cosmetic"
                text={formatMessage(
                  defaultMessages.jsAdFormExtraOptionRepairTypesCosmetic,
                )}
                checked={repairType === REPAIR_TYPES.COSMETIC}
                onChange={this.handleChangeRepairType}
              />
              <AdFormRadio
                value="euro"
                text={formatMessage(
                  defaultMessages.jsAdFormExtraOptionRepairTypesEuro,
                )}
                checked={repairType === REPAIR_TYPES.EURO}
                onChange={this.handleChangeRepairType}
              />
              <AdFormRadio
                value="design"
                text={formatMessage(
                  defaultMessages.jsAdFormExtraOptionRepairTypesDesign,
                )}
                checked={repairType === REPAIR_TYPES.DESIGN}
                onChange={this.handleChangeRepairType}
              />
            </div>
          </div>
          <div className="item-option-living grid-item grid-item-option">
            <label
              className="control-label"
              htmlFor="ad_elevator"
              id="ad_input_elevator"
            >
              {formatMessage(defaultMessages.jsAdFormExtraOptionElevator)}
            </label>
            <div
              className="grid-item grid-item-option"
              style={{ marginRight: '20px' }}
            >
              <AdFormInputCounter
                value={passengerElevatorCount}
                label={formatMessage(
                  defaultMessages.jsAdFormExtraOptionPassengerElevatorCount,
                )}
                error={this.hasErrors(FIELD_NAMES.PASSENGER_ELEVATOR_COUNT)}
                onChange={this.handlePassengerElevatorCount}
              />
            </div>
            <div className="grid-item grid-item-option">
              <AdFormInputCounter
                value={serviceElevatorCount}
                label={formatMessage(
                  defaultMessages.jsAdFormExtraOptionServiceElevatorCount,
                )}
                error={this.hasErrors(FIELD_NAMES.SERVICE_ELEVATOR_COUNT)}
                onChange={this.handleServiceElevatorCount}
              />
            </div>
          </div>
          <div className="item-option-living">
            <div className="grid-item grid-item-option">
              <label
                className="control-label"
                htmlFor="ad_entrance"
                id="ad_input_entrance"
              >
                {formatMessage(defaultMessages.jsAdFormExtraOptionEntrance)}
              </label>
              <div className="flex">
                <AdFormCustomCheckbox
                  value="ramp"
                  text="Пандус"
                  checked={entranceRamp}
                  onChange={this.handleEntranceRamp}
                ></AdFormCustomCheckbox>
                <AdFormCustomCheckbox
                  value="garbage-chute"
                  text="Мусоропровод"
                  checked={entranceGarbageChute}
                  onChange={this.handleEntranceGarbageChute}
                ></AdFormCustomCheckbox>
              </div>
            </div>
          </div>
          <div className="grid-item grid-item-option">
            <label
              className="control-label"
              htmlFor="ad_parking_type"
              id="ad_input_parking_type"
            >
              {formatMessage(defaultMessages.jsAdFormExtraOptionParkingType)}
            </label>

            <div
              className="radio-group grid-item-option-building-type-radio-block"
              id="ad_input_parking_type"
            >
              <AdFormRadio
                value="ground"
                text={formatMessage(
                  defaultMessages.jsAdFormExtraOptionParkingTypesGround,
                )}
                checked={parkingType === PARKING_TYPES.GROUND}
                onChange={this.handleChangeParkingType}
              />
              <AdFormRadio
                value="multi_level"
                text={formatMessage(
                  defaultMessages.jsAdFormExtraOptionParkingTypesMultiLevel,
                )}
                checked={parkingType === PARKING_TYPES.MULTI_LEVEL}
                onChange={this.handleChangeParkingType}
              />
              <AdFormRadio
                value="underground"
                text={formatMessage(
                  defaultMessages.jsAdFormExtraOptionParkingTypesUnderground,
                )}
                checked={parkingType === PARKING_TYPES.UNDERGROUND}
                onChange={this.handleChangeParkingType}
              />
              <AdFormRadio
                value="rooftop"
                text={formatMessage(
                  defaultMessages.jsAdFormExtraOptionParkingTypesRooftop,
                )}
                checked={parkingType === PARKING_TYPES.ROOFTOP}
                onChange={this.handleChangeParkingType}
              />
            </div>
          </div>
          {this.errorMessage()}
        </div>
      </div>
    );
  }
}

AdSaleFormExtraOptions.propTypes = {
  errors: PropTypes.object,
  balconyCount: PropTypes.number,
  loggiaCount: PropTypes.number,
  windowsPositionOutside: PropTypes.bool,
  windowsPositionCourtyard: PropTypes.bool,
  separateВathroomCount: PropTypes.number,
  combinedВathroomCount: PropTypes.number,
  repairType: PropTypes.string,
  passengerElevatorCount: PropTypes.number,
  serviceElevatorCount: PropTypes.number,
  entranceRamp: PropTypes.bool,
  entranceGarbageChute: PropTypes.bool,
  parkingType: PropTypes.string,
  intl: intlShape.isRequired,
  setAdState: PropTypes.func,
  validateAttr: PropTypes.func,
};

export default AdSaleFormExtraOptions;
