/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';

import AnimateOnMount, {
  ANIMATION_TYPES,
} from '../AnimateOnMount/AnimateOnMount';

function AnimateContainer({ children }) {
  return <div className="flash-notifier-container">{children}</div>;
}

AnimateContainer.propTypes = {
  children: PropTypes.any,
};

const LEAVE_TIMEOUT = 200;

class FlashNotifier extends React.PureComponent {
  state = {
    isOpen: true,
  };

  hideTimeout = null;

  componentDidMount() {
    this.setHideTimeout();
  }

  componentWillUnmount() {
    this.clearHideTimeout();
  }

  setHideTimeout = () => {
    const { delay } = this.props;

    this.clearHideTimeout();

    this.hideTimeout = setTimeout(this.closeNotiy, delay - LEAVE_TIMEOUT);
  };

  closeNotiy = () => {
    const { onClose } = this.props;

    this.setState({ isOpen: false }, onClose);
  };

  clearHideTimeout = () => {
    this.hideTimeout && clearTimeout(this.hideTimeout);
  };

  handleClick = () => {
    this.clearHideTimeout();
    this.closeNotiy();
  };

  render() {
    const { isOpen } = this.state;
    const { type, text } = this.props;

    return (
      <AnimateOnMount
        isOpen={isOpen}
        transitionAppear
        component={AnimateContainer}
        animationType={ANIMATION_TYPES.SLIDE}
        transitionAppearTimeout={400}
        transitionEnterTimeout={400}
        transitionLeaveTimeout={LEAVE_TIMEOUT}
      >
        <div
          className={`flash-notifier flash-notifier-${type}`}
          onClick={this.handleClick}
        >
          <div
            className="flash-notifier-inner"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </div>
      </AnimateOnMount>
    );
  }
}

FlashNotifier.propTypes = {
  delay: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

FlashNotifier.defaultProps = {
  onClose: null,
};

export default FlashNotifier;
