/* eslint-disable react/no-string-refs */
import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';

import { recoveryPassword } from '../../api/auth';
import { defaultMessages } from '../../../../libs/i18n/default';
import AuthModalInput from './AuthModalInput';

class AuthModalRecovery extends React.Component {
  state = {
    canSubmit: false,
    isRecovered: false,
  };

  constructor(props) {
    super(props);

    this.setValidationRules();
  }

  onLoginLinkClick = e => {
    const { onChangeForm } = this.props;

    e.preventDefault();

    onChangeForm('loginByEmail', this.getEmailValue());
  };

  onRecoveryLinkClick = e => {
    e.preventDefault();

    this.setState({ isRecovered: false });
  };

  onValid = () => {
    this.setState({ canSubmit: true });
  };

  onInvalid = () => {
    this.setState({ canSubmit: false });
  };

  onSubmit = (model, reset, invalidate) => {
    const { canSubmit } = this.state;
    const { storeEmail, toggleLoading } = this.props;

    if (!canSubmit) {
      return;
    }

    storeEmail(this.getEmailValue());

    toggleLoading();

    recoveryPassword(model)
      .then(data => {
        toggleLoading();

        this.setState({ isRecovered: true });
      })
      .catch(errors => {
        toggleLoading();

        invalidate({
          'user[email]': errors['email'],
        });
      });
  };

  getEmailValue() {
    return this.refs.recoveryForm.getModel().user.email;
  }

  setValidationRules() {
    const {
      intl: { formatMessage },
    } = this.props;

    this.validations = {
      email: {
        isEmail: true,
      },
    };

    this.validationErrors = {
      email: {
        isDefaultRequiredValue: formatMessage(
          defaultMessages.jsAuthModalValidationFieldRequired,
        ),
        isEmail: formatMessage(
          defaultMessages.jsAuthModalValidationFieldWrongFormat,
        ),
      },
    };
  }

  render() {
    const { isRecovered } = this.state;
    const { intl, email } = this.props;
    const { formatMessage } = intl;

    if (!isRecovered) {
      return (
        <Formsy
          ref="recoveryForm"
          className="modal-form"
          acceptCharset="UTF-8"
          method="post"
          onValid={this.onValid}
          onInvalid={this.onInvalid}
          onSubmit={this.onSubmit}
        >
          <div className="modal-form-header">
            <div className="modal-form-title">
              {formatMessage(defaultMessages.jsAuthModalRecoveryTitle)}
            </div>
            <div className="modal-form-text">
              <a
                href="#"
                className="link link-green"
                onClick={this.onLoginLinkClick}
              >
                {formatMessage(defaultMessages.jsAuthModalRecoveryTitleLink)}
              </a>
            </div>
          </div>
          <div className="modal-form-content">
            <div className="modal-form-item">
              <AuthModalInput
                ref="recoveryFormEmail"
                name="user[email]"
                intl={intl}
                placeholder={formatMessage(
                  defaultMessages.jsAuthModalEmailPlaceholder,
                )}
                required
                type="email"
                validations={this.validations.email}
                validationErrors={this.validationErrors.email}
                value={email}
              />
            </div>
            <div className="modal-form-item modal-form-item-actions">
              <div className="modal-form-item-cell">
                <button
                  className="button button-green modal-form-submit"
                  type="submit"
                  formNoValidate
                >
                  {formatMessage(defaultMessages.jsAuthModalSendPasswordButton)}
                </button>
              </div>
            </div>
          </div>
        </Formsy>
      );
    } else {
      return (
        <div className="modal-message">
          <div
            className="modal-title"
            dangerouslySetInnerHTML={{
              __html: formatMessage(
                defaultMessages.jsAuthModalRecoverySuccessTitle,
              ),
            }}
          />
          <div className="modal-text">
            <span
              dangerouslySetInnerHTML={{
                __html: formatMessage(
                  defaultMessages.jsAuthModalRecoverySuccessText,
                ),
              }}
            />
            <span> </span>
            <a
              href="#"
              className="link link-green"
              onClick={this.onRecoveryLinkClick}
            >
              {formatMessage(
                defaultMessages.jsAuthModalRecoverySuccessTextLink,
              )}
            </a>
          </div>
        </div>
      );
    }
  }
}

AuthModalRecovery.propTypes = {
  email: PropTypes.string,
  intl: intlShape.isRequired,
  storeEmail: PropTypes.func.isRequired,
  toggleLoading: PropTypes.func.isRequired,
  onChangeForm: PropTypes.func.isRequired,
};

export default AuthModalRecovery;
