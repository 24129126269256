import { intlShape } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ControlContainer, ControlContainerDivider } from './ControlContainer';
import { ControlButton, BUTTON_TYPES } from './ControlButton';
import TranslationProvider from '../../../../common/components/HOC/TranslationProvider';
import dischargeArray from '../../AdCatalog/utils/dischargeArray';
import getPolygonSquare from '../../AdCatalog/utils/getPolygonSquare';
import AdCatalogYandexMapDrawer from '../../AdCatalog/AdCatalogYandexMapDrawer';
import styles from './styles.scss';

const ZOOM_MIN = 2;
const ZOOM_MAX = 17;

const MAX_DRAWER_POINTS = 40;

class MapAreaDraw extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isEnableDraw: false,
      isDrawedSome: false,
      isNotificationClosed: false,
    };

    this.polygons = [];

    this.map = null;

    this.drawerRef = React.createRef();
    this.mapRef = React.createRef();

    this.modalRef = null;
  }

  componentDidMount() {
    if (window.ymaps !== 'undefined') {
      ymaps.ready(this.initMap);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { geoAreas } = this.props;

    if (!nextProps.geoAreas && geoAreas) {
      this.drawerRef.current.clear();
    }
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;

    if (isOpen !== prevProps.isOpen) {
      this.map.container.fitToViewport();
    }

    if (isOpen && !prevProps.isOpen) {
      this.map.setZoom(11);

      // this.handleDrawStartClick();
    }

    if (!isOpen && prevProps.isOpen) {
      this.handleDrawEndClick();
    }
  }

  componentWillUnmount() {
    this.removeModalPreventTouch();
  }

  initMap = () => {
    const {
      city: { latitude: lat, longitude: lng },
      geoAreas,
    } = this.props;

    if (typeof ymaps === 'undefined' || !ymaps.Map) {
      return null;
    }

    this.map = new ymaps.Map(this.mapRef.current, {
      zoom: 10,
      center: [lat, lng],
      maxZoom: ZOOM_MAX,
      minZoom: ZOOM_MIN,
      controls: [],
    });

    const zoomControl = new ymaps.control.ZoomControl({
      options: {
        position: {
          top: 100,
          left: 27,
        },
      },
    });
    this.map.controls.add(zoomControl);

    if (geoAreas && geoAreas.length) {
      for (let i = 0; i < geoAreas.length; i++) {
        this.addPolygon(geoAreas[i]);
      }
    }
  };

  addPolygon = coords => {
    const drawerPolygon = new ymaps.Polygon(
      [coords],
      {},
      {
        fillColor: '#18a24b',
        fillOpacity: 0.3,
        strokeWidth: 3,
        strokeColor: '#18a24b',
        strokeOpacity: 0.9,
      },
    );

    this.polygons.push(drawerPolygon);
    this.map.geoObjects.add(drawerPolygon);

    return drawerPolygon;
  };

  handleDraw = point => {
    const [x, y] = point;
    const map = this.map;
    const projection = map.options.get('projection');

    this.drawerPolygonPixelCoords.push([x, y]);
    this.drawerPolygonGeoCoords.push(
      projection.fromGlobalPixels(
        map.converter.pageToGlobal([x, y]),
        map.getZoom(),
      ),
    );

    const index = this.drawerPolygonGeoCoords.length - 1;

    this.drawerPolyline.geometry.insert(
      index,
      this.drawerPolygonGeoCoords[index],
    );

    this.drawerPolyline.geometry.remove(index + 1);
  };

  handleDrawStart = () => {
    this.drawerPolygonPixelCoords = []; // координаты в пикселях
    this.drawerPolygonGeoCoords = []; // геокоординаты

    this.map.geoObjects.remove(this.drawerPolyline);

    this.drawerPolyline = new ymaps.Polyline(
      this.drawerPolygonGeoCoords,
      {},
      {
        strokeColor: '#ff4729',
        strokeWidth: 3,
      },
    );

    this.map.geoObjects.add(this.drawerPolyline);

    this.addModalPreventTouch();
  };

  handleDrawEnd = callbackSuccess => {
    const map = this.map;
    const projection = this.map.options.get('projection');

    this.map.geoObjects.remove(this.drawerPolyline);

    if (this.drawerPolygonPixelCoords.length > MAX_DRAWER_POINTS) {
      this.drawerPolygonPixelCoords = dischargeArray(
        this.drawerPolygonPixelCoords,
        MAX_DRAWER_POINTS,
      );
    }

    // Если выделенная область имеет большую площадь, чем 300 пикселей
    // Измеряем в пикселях, т.к., если измерять по геокординатам, то на разных зумах одна
    // и таже выделенная область имеет разную площадь
    if (getPolygonSquare(this.drawerPolygonPixelCoords) > 300) {
      this.drawerPolygonGeoCoords = this.drawerPolygonPixelCoords.map(item => {
        return projection.fromGlobalPixels(
          map.converter.pageToGlobal(item),
          map.getZoom(),
        );
      });

      this.addPolygon(this.drawerPolygonGeoCoords);

      this.setState({ isDrawedSome: true });

      callbackSuccess();
    }

    this.removeModalPreventTouch();
  };

  handlePreventTouch = event => {
    event.preventDefault();
  };

  addModalPreventTouch = () => {
    if (this.drawerRef.current) {
      const modalElement = this.drawerRef.current.drawerRef.current.closest(
        '.Modal',
      );

      if (modalElement) {
        modalElement.addEventListener('touchmove', this.handlePreventTouch);
      }
    }
  };

  removeModalPreventTouch = () => {
    if (this.drawerRef.current) {
      const modalElement = this.drawerRef.current.drawerRef.current.closest(
        '.Modal',
      );

      if (modalElement) {
        modalElement.removeEventListener('touchmove', this.handlePreventTouch);
      }
    }
  };

  handleDrawClear = () => {
    const { onUpdate } = this.props;

    this.polygons.forEach(polygon => {
      this.map.geoObjects.remove(polygon);
    });

    this.polygons = [];

    this.setState({ isDrawedSome: false });

    onUpdate && onUpdate([]);
  };

  handleDrawCancel = () => {
    const { onUpdate } = this.props;

    this.map.geoObjects.remove(this.polygons[this.polygons.length - 1]);

    this.polygons = this.polygons.slice(0, this.polygons.length - 1);

    const newValues = this.polygons.map(polygon => {
      return polygon.geometry.getCoordinates()[0];
    });

    onUpdate && onUpdate(newValues);

    if (this.polygons.length === 0) {
      this.setState({ isDrawedSome: false });
    }
  };

  handleSaveArea = () => {
    const { onUpdate } = this.props;

    const newValues = this.polygons.map(polygon => {
      return polygon.geometry.getCoordinates()[0];
    });

    onUpdate && onUpdate(newValues);

    this.handleClose(newValues);
  };

  handleClose = newValues => {
    const { onClose } = this.props;

    this.map.setZoom(8);

    onClose && onClose(Array.isArray(newValues) ? newValues : null);
  };

  handleDrawStartClick = () => {
    this.setState({ isEnableDraw: true });
  };

  handleDrawEndClick = () => {
    this.setState({ isEnableDraw: false });
  };

  handleNotificationClose = () => {
    this.setState({ isNotificationClosed: true });
  };

  render() {
    const { isEnableDraw, isDrawedSome, isNotificationClosed } = this.state;
    const { intl, geoAreas, isOpen, onChangeButtonClick } = this.props;

    const isHaveSavedDraws = geoAreas && geoAreas.length > 0;
    const isDrawCancelAvailable = isHaveSavedDraws || isDrawedSome;
    const isControlPanelVisible = isOpen;

    return (
      <div
        className={classNames(styles.areaMap, {
          [styles['areaMap--opened']]: isOpen,
          [styles['areaMap--control-visible']]: isControlPanelVisible,
        })}
      >
        {isOpen ? (
          <button
            type="button"
            className={classNames(styles.areaMapClose, 'Modal__close')}
            onClick={this.handleClose}
          >
            <span className="Modal__close-icon" />
          </button>
        ) : (
          <button
            type="button"
            className={styles.changeButton}
            onClick={onChangeButtonClick}
          >
            <span
              className={classNames(
                styles.changeButtonIcon,
                styles.changeButtonIconArea,
              )}
            />
            <span>Изменить</span>
          </button>
        )}
        <div ref={this.mapRef} className={styles.areaMapCanvas} />
        <AdCatalogYandexMapDrawer
          ref={this.drawerRef}
          intl={intl}
          multipleAreas
          startDrawInitial
          showButtons={false}
          enableDraw={isEnableDraw}
          isDrawn={isHaveSavedDraws}
          onDraw={this.handleDraw}
          onDrawStart={this.handleDrawStart}
          onDrawEnd={this.handleDrawEnd}
          onDrawClear={this.handleDrawClear}
        />
        {isOpen && isControlPanelVisible && (
          <ControlContainer>
            {isDrawedSome && (
              <div
                className={classNames(styles.areaMapNotifyContainer, {
                  [styles.areaMapNotifyContainerVisible]: !isNotificationClosed,
                })}
              >
                <div className={styles.areaMapNotify}>
                  <span>Обведите еще области, если нужно</span>
                  <button
                    type="button"
                    className={styles.areaMapNotifyCloseButton}
                    onClick={this.handleNotificationClose}
                  >
                    <span className={styles.areaMapNotifyCloseButtonIcon} />
                  </button>
                </div>
              </div>
            )}
            <ControlButton
              type={BUTTON_TYPES.CLEAR}
              onClick={this.handleDrawClear}
              disabled={!isDrawCancelAvailable}
            />
            <ControlButton
              type={BUTTON_TYPES.CANCEL}
              onClick={this.handleDrawCancel}
              disabled={!isDrawCancelAvailable}
            />
            {isEnableDraw ? (
              <ControlButton
                type={BUTTON_TYPES.DRAG}
                onClick={this.handleDrawEndClick}
              />
            ) : (
              <ControlButton
                type={BUTTON_TYPES.DRAW}
                onClick={this.handleDrawStartClick}
              />
            )}
            <ControlContainerDivider />
            <ControlButton
              type={BUTTON_TYPES.SAVE}
              onClick={this.handleSaveArea}
              disabled={!isHaveSavedDraws && !isDrawedSome}
            />
          </ControlContainer>
        )}
      </div>
    );
  }
}

MapAreaDraw.propTypes = {
  city: PropTypes.object,
  geoAreas: PropTypes.array,
  intl: intlShape.isRequired,
  isOpen: PropTypes.bool,
  onChangeButtonClick: PropTypes.func,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default TranslationProvider(MapAreaDraw);
