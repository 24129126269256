/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';

import { defaultMessages } from '../../../../libs/i18n/default';
import isScrolledIntoView from './utils/isScrolledIntoView';

const AdCatalogGridLoadMore = ({
  intl: { formatMessage },

  ads,
  allAdsAmount,
  isFilterOpen,
  loadMore,
}) => {
  const refreshButtonRef = React.useRef();

  React.useEffect(() => {
    window.addEventListener('scroll', tryLoadMore);

    return () => {
      window.removeEventListener('scroll', tryLoadMore);
    };
  }, []);

  const tryLoadMore = React.useCallback(() => {
    if (ads.length < allAdsAmount) {
      if (
        isScrolledIntoView(refreshButtonRef.current) &&
        !isFilterOpen &&
        !document.documentElement.classList.contains(
          'is-RentSearchApplication-open',
        )
      ) {
        loadMore();
      }
    }
  }, []);

  return (
    <div className="refresh" id="filter_refresh" ref={refreshButtonRef}>
      <a onClick={loadMore}>
        {formatMessage(defaultMessages.jsCatalogLoadMore)}
      </a>
    </div>
  );
};

AdCatalogGridLoadMore.propTypes = {
  ads: PropTypes.any,
  allAdsAmount: PropTypes.any,
  intl: intlShape.isRequired,
  isFilterOpen: PropTypes.any,
  loadMore: PropTypes.any,
};

export default AdCatalogGridLoadMore;
