import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import { FIELD_NAMES } from './constants';
import AdFormValidatable from './AdFormValidatableMixin';
import AdFormRadio from './AdFormRadio';

class AdFormRooms extends React.PureComponent {
  componentDidMount() {
    const { validatePresented } = this.props;

    validatePresented();
  }

  handleChange = event => {
    const { setAdState } = this.props;

    setAdState({
      [FIELD_NAMES.ROOMS_AMOUNT]: Number(event.currentTarget.value),
    });
  };

  render() {
    const {
      intl: { formatMessage },
      roomsAmount,
      errorMessage,
    } = this.props;

    return (
      <div className="form-row">
        <div className="form-row-label">
          {formatMessage(defaultMessages.jsAdFormRoomsLabel)}
        </div>
        <div className="form-row-content">
          <div className="radio-group" id="ad_input_rooms_amount">
            <AdFormRadio
              value="1"
              text="1"
              checked={roomsAmount === 1}
              onChange={this.handleChange}
            />
            <AdFormRadio
              value="2"
              text="2"
              checked={roomsAmount === 2}
              onChange={this.handleChange}
            />
            <AdFormRadio
              value="3"
              text="3"
              checked={roomsAmount === 3}
              onChange={this.handleChange}
            />
            <AdFormRadio
              value="4"
              text="4+"
              checked={roomsAmount === 4}
              onChange={this.handleChange}
            />
          </div>
          {errorMessage()}
        </div>
      </div>
    );
  }
}

AdFormRooms.propTypes = {
  errorMessage: PropTypes.func,
  intl: intlShape.isRequired,
  roomsAmount: PropTypes.number,
  setAdState: PropTypes.func,
  validatePresented: PropTypes.func,
};

export default AdFormValidatable(AdFormRooms, FIELD_NAMES.ROOMS_AMOUNT);
