import React from 'react';
import { injectIntl, intlShape } from 'react-intl';

import { defaultMessages } from '../../../../libs/i18n/default';
import AdCatalogBanner from './AdCatalogBanner';

const AdCatalogWhatsappBanner = ({ intl: { formatMessage } }) => {
  return (
    <AdCatalogBanner
      type="whatsapp"
      imageSrc={require('../../../../images/client/AdCatalog/banner-whatsapp.svg')}
      imageWidth={220}
      imageHeight={124}
      title={formatMessage(defaultMessages.jsCatalogWhatsappHelpCardTitle)}
      notice={formatMessage(defaultMessages.jsCatalogWhatsappHelpCardText)}
      submitText={formatMessage(
        defaultMessages.jsCatalogWhatsappHelpCardRequest,
      )}
      href="https://wa.me/79774563267"
    />
  );
};

AdCatalogWhatsappBanner.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(AdCatalogWhatsappBanner);
