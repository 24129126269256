import React from 'react';
import PropTypes from 'prop-types';

import DropdownPopup from './DropdownPopup';

class Dropdown extends React.PureComponent {
  constructor(props) {
    super(props);

    const { isOpen } = this.props;

    this.state = {
      isOpen,
    };

    this.togglerRef = React.createRef();

    this.container = null;
  }

  componentDidMount() {
    const { isOpen } = this.props;

    if (isOpen) {
      this.openPopup();
    }
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;

    if (!prevProps.isOpen && isOpen) {
      this.openPopup();
    }

    if (prevProps.isOpen && !isOpen) {
      this.closePopup();
    }
  }

  openPopup() {
    this.setState({ isOpen: true });
  }

  closePopup() {
    this.setState({ isOpen: false });
  }

  render() {
    const { isOpen } = this.state;
    const {
      button,
      extraClassName,
      showButtonClose,
      children,
      offset,
      onButtonCloseClick,
      onOutsideClick,
    } = this.props;

    return (
      <React.Fragment>
        {React.cloneElement(button, { ref: this.togglerRef })}
        {isOpen && (
          <DropdownPopup
            offset={offset}
            extraClassName={extraClassName}
            showButtonClose={showButtonClose}
            onButtonCloseClick={onButtonCloseClick}
            toggleButtonRef={this.togglerRef}
            onOutsideClick={onOutsideClick}
          >
            {React.Children.only(children)}
          </DropdownPopup>
        )}
      </React.Fragment>
    );
  }
}

Dropdown.propTypes = {
  button: PropTypes.node.isRequired,
  children: PropTypes.any,
  extraClassName: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  offset: PropTypes.number,
  showButtonClose: PropTypes.bool,
  onButtonCloseClick: PropTypes.func,
  onOutsideClick: PropTypes.func,
};

Dropdown.defaultProps = {
  extraClassName: '',
  showButtonClose: false,
  offset: 12,
};

export default Dropdown;
