/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'react-tooltip-lite';
import filter from 'lodash/filter';

import { defaultMessages } from '../../../../libs/i18n/default';
import { sendRotateImage } from '../../api/images';
import { FIELD_NAMES } from './constants';

class AdFormImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isRotationLoading: false,
    };

    this.parentElementRef = React.createRef();
  }

  removeImage = () => {
    const {
      image: { id: imageId },
      imageIds,
      mainImageId,
      setAdState,
      onRemove,
    } = this.props;

    if (imageIds.length > 1) {
      // TODO: translate
      const willRemove = confirm('Вы действительно хотите удалить фотографию?');

      if (!willRemove) {
        return;
      }

      const updatedImages = filter(imageIds, id => id !== imageId);

      let newMainImageId = mainImageId;

      if (mainImageId === imageId) {
        if (updatedImages.length > 0) {
          newMainImageId = updatedImages[0];
        } else {
          newMainImageId = null;
        }
      }

      setAdState({
        [FIELD_NAMES.IMAGE_IDS]: updatedImages,
        [FIELD_NAMES.MAIN_IMAGE_ID]: newMainImageId,
      });

      onRemove(imageId);
    } else {
      // TODO: translate
      alert('Нельзя удалить последнее изображение');
    }
  };

  rotateImage = () => {
    const { isRotationLoading } = this.state;
    const {
      image: { id: imageId },
      updateImage,
    } = this.props;

    if (isRotationLoading) {
      return;
    }

    this.setState({ isRotationLoading: true });

    sendRotateImage(imageId)
      .then(data => {
        updateImage(data);

        this.setState({ isRotationLoading: false });
      })
      .catch(error => {
        this.setState({ isRotationLoading: false });
      });
  };

  setMainImage = () => {
    const {
      image: { id: imageId },
      setAdState,
    } = this.props;

    setAdState({ [FIELD_NAMES.MAIN_IMAGE_ID]: imageId });
  };

  render() {
    const { isRotationLoading } = this.state;
    const {
      intl: { formatMessage },
      image: { id: imageId, url: imageUrl },
      mainImageId,
      isDisabledSortable,
    } = this.props;

    return (
      <div
        className={classNames('uploadzone-thumb', '__uploaded', {
          __cover: mainImageId === imageId,
          __loading: isRotationLoading,
          '__not-sortable': isDisabledSortable,
        })}
        ref={this.parentElementRef}
      >
        <div className="uploadzone-thumb-image">
          <div className="uploadzone-thumb-image-content uploadzone-thumb-sortable-handle">
            <div
              className="uploadzone-thumb-image-bg"
              style={{ backgroundImage: `url(${imageUrl})` }}
              onClick={this.setMainImage}
            />
          </div>
          <div className="uploadzone-thumb-buttons">
            <Tooltip
              arrow
              arrowSize={7}
              className="uploadzone-thumb-tooltip"
              content="Поменять порядок"
              animation="fade"
            >
              <span className="uploadzone-thumb-button uploadzone-thumb-button-move uploadzone-thumb-sortable-handle" />
            </Tooltip>
            <Tooltip
              arrow
              arrowSize={7}
              className="uploadzone-thumb-tooltip"
              content="Повернуть"
              animation="fade"
            >
              <span
                className={classNames(
                  'uploadzone-thumb-button',
                  'uploadzone-thumb-button-rotate',
                  { __animate: isRotationLoading },
                )}
                onClick={this.rotateImage}
              >
                {isRotationLoading && <span className="spinner" />}
              </span>
            </Tooltip>
            <Tooltip
              arrow
              arrowSize={7}
              className="uploadzone-thumb-tooltip"
              content="Удалить"
              animation="fade"
            >
              <span
                className="uploadzone-thumb-button uploadzone-thumb-button-remove"
                onClick={this.removeImage}
              />
            </Tooltip>
          </div>
        </div>
        {mainImageId === imageId && (
          <div className="uploadzone-thumb-label">
            {formatMessage(defaultMessages.jsAdFormImagesCover)}
          </div>
        )}
      </div>
    );
  }
}

AdFormImage.propTypes = {
  image: PropTypes.object,
  imageIds: PropTypes.array,
  intl: intlShape.isRequired,
  isDisabledSortable: PropTypes.bool,
  mainImageId: PropTypes.number,
  setAdState: PropTypes.func,
  updateImage: PropTypes.func,
  onRemove: PropTypes.func,
};

export default AdFormImage;
