import React from 'react';
import PropTypes from 'prop-types';

class AdCatalogChildLinks extends React.Component {
  renderLinks() {
    const { childLinks } = this.props;

    return childLinks.map(({ id, title, adsCount, href }) => (
      <a key={id} href={href} className="catalog-links-item">
        {title}
        <span> {adsCount}</span>
      </a>
    ));
  }

  render() {
    return <ul className="catalog-links">{this.renderLinks()}</ul>;
  }
}

AdCatalogChildLinks.propTypes = {
  childLinks: PropTypes.array.isRequired,
};

export default AdCatalogChildLinks;
