import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';
import SvgBackArrow from '../../../../inline-svgs/client/RentSearchApplication/back-arrow.svg';

const RentSearchApplicationBack = ({ goToPrevStep }) => {
  return (
    <button
      className={styles.back}
      type="button"
      onClick={goToPrevStep}
      onMouseDown={e => e.preventDefault()}
    >
      <SvgBackArrow aria-hidden="true" />
      Назад
    </button>
  );
};

RentSearchApplicationBack.propTypes = {
  goToPrevStep: PropTypes.func.isRequired,
};

export default RentSearchApplicationBack;
