import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Textarea from 'react-textarea-autosize/dist/react-textarea-autosize';

import { FIELD_NAMES } from '../constants';
import styles from '../styles.scss';

const StepDateAndTime = ({
  dateAndTime,
  errors,
  setMainValues,
  goToNextStep,
}) => {
  const isEmpty = !dateAndTime || dateAndTime.length === 0;

  const handleChange = e => {
    setMainValues({ [FIELD_NAMES.DATE_AND_TIME]: e.target.value });
  };

  const handleSubmitClick = () => {
    goToNextStep();
  };

  return (
    <React.Fragment>
      <div className={classNames(styles.inner, styles.innerTopbarSpace)}>
        <div className={styles.title}>Дата и время просмотра</div>
        <div className={styles.formItem}>
          <Textarea
            className={styles.textarea}
            value={dateAndTime}
            minRows={1}
            placeholder="Когда вы хотите посмотреть квартиру?&#10;Укажите точную дату и время просмотра."
            onChange={handleChange}
          />
        </div>
      </div>
      <div className={styles.cta}>
        <div className={styles.buttonWrapper}>
          <button
            className={styles.button}
            type="button"
            disabled={isEmpty}
            onClick={handleSubmitClick}
          >
            Дальше
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

StepDateAndTime.propTypes = {
  dateAndTime: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  goToNextStep: PropTypes.func.isRequired,
  setMainValues: PropTypes.func.isRequired,
};

export default StepDateAndTime;
