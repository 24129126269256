import { intlShape } from 'react-intl';
import React from 'react';

import { defaultMessages } from '../../../../libs/i18n/default';
import { AUTH_FB_PATH } from '../../constants/paths';

const AuthModalFbButton = ({ intl: { formatMessage } }) => (
  <a
    className="button button-fb"
    href={AUTH_FB_PATH}
    title={formatMessage(defaultMessages.jsSocialButtonFacebook)}
  >
    {formatMessage(defaultMessages.jsSocialButtonFacebook)}
  </a>
);

AuthModalFbButton.propTypes = {
  intl: intlShape.isRequired,
};

export default AuthModalFbButton;
