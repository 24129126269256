import axios from './axios';
import { FIELD_NAMES } from '../components/ReviewRequestApplication/constants';

const createReviewRequest = values => {
  return axios
    .post('/api/frontend/leads/review_request', {
      lead_review_request: {
        ad_id: values['ad_id'],
        animals: values[FIELD_NAMES.ANIMALS],
        rooms_amount: values[FIELD_NAMES.ROOM_QUANTITY],
        residents: values[FIELD_NAMES.RESIDENTS],
        residents_other: values[FIELD_NAMES.RESIDENTS_OTHER],
        resident_work: values[FIELD_NAMES.WORK],
        urgency_type: values[FIELD_NAMES.URGENCY],
        urgency_date: values[FIELD_NAMES.URGENCY_DATE],
        date_and_time: values[FIELD_NAMES.DATE_AND_TIME],
        phone: values[FIELD_NAMES.PHONE_NUMBER],
      },
    })
    .then(({ data }) => data)
    .catch(({ response: { data: { errors } } }) => Promise.reject(errors));
};

export const reviewRequestApi = {
  createReviewRequest,
};

export default reviewRequestApi;
