import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { defaultMessages } from '../../../../libs/i18n/default';
import { yandexKassaApi } from '../../api/yandexKassa';
import TranslationProvider from '../../../common/components/HOC/TranslationProvider';
import FlashNotifierService from '../../services/FlashNotifier/FlashNotifier';

class PaymentApplePay extends React.Component {
  state = {
    isVisible: false,
  };

  componentDidMount(prevProps, prevState) {
    if (window.ApplePaySession) {
      const merchantIdentifier = 'merchant.ru.thelocals';

      ApplePaySession.canMakePaymentsWithActiveCard(merchantIdentifier).then(
        canMakePayments => {
          if (canMakePayments) {
            this.setState({ isVisible: true });
          }
        },
      );
    }
  }

  onClick = () => {
    const {
      payment,
      intl: { formatMessage },
    } = this.props;

    if (!window.ApplePaySession) {
      return null;
    }

    const request = {
      countryCode: 'RU',
      currencyCode: payment.sumCurrencyCode.toUpperCase(),
      supportedNetworks: ['visa', 'masterCard'],
      merchantCapabilities: ['supports3DS'],
      total: { label: 'THELOCALS.RU', amount: `${payment.sum}` },
    };

    const session = new ApplePaySession(3, request);

    session.onvalidatemerchant = function(event) {
      yandexKassaApi
        .applePayMerchantValidation({
          validationUrl: event.validationURL,
        })
        .then(function(data) {
          session.completeMerchantValidation(data.Model);
        });
    };

    session.onpaymentauthorized = function(event) {
      yandexKassaApi
        .applePayPayment({
          paymentId: payment.id,
          token: event.payment.token.paymentData,
        })
        .then(function(data) {
          let status;
          if (data.success) {
            status = ApplePaySession.STATUS_SUCCESS;
            window.location.href = `/payments/${payment.id}/success`;
          } else {
            status = ApplePaySession.STATUS_FAILURE;
            window.location.href = `/payments/${payment.id}/fail?message=${
              data.message
            }`;
          }

          session.completePayment(status);
        });
    };

    session.oncancel = function(event) {
      FlashNotifierService.notifyError(
        formatMessage(defaultMessages.jsFlashNotifierErrorHasOccurredTryAgain),
      );
    };

    session.begin();
  };

  render() {
    const { isVisible } = this.state;

    return (
      isVisible && (
        <button
          className={classNames('button', 'PaymentForm__appleButton')}
          id="apple-pay"
          type="button"
          onClick={this.onClick}
        >
          Pay with
        </button>
      )
    );
  }
}

PaymentApplePay.propTypes = {
  intl: intlShape.isRequired,
  payment: PropTypes.object.isRequired,
};

export default TranslationProvider(PaymentApplePay);
