import axios from './axios';

export const uploadImage = (formData, { onUploadProgress = null }) => {
  return axios
    .post('/api/frontend/images', formData, { onUploadProgress })
    .then(({ data }) => data)
    .catch(({ response: { data: { errors } } }) => Promise.reject(errors));
};

export const sendRotateImage = id => {
  return axios
    .put(`/api/frontend/images/${id}/rotate`)
    .then(({ data }) => data)
    .catch(({ response: { data: { errors } } }) => Promise.reject(errors));
};
