import React from 'react';
import classNames from 'classnames';

import { FilterContext } from './contexts/FilterContext';
import AdCatalogFilterViewTabs from './AdCatalogFilterViewTabs';
import AdCatalogFilterHeader from './AdCatalogFilterHeader';
import AdCatalogFilterBody from './AdCatalogFilterBody';
import AdCatalogFilterFooter from './AdCatalogFilterFooter';

// eslint-disable-next-line react/prefer-stateless-function
class AdCatalogFilter extends React.PureComponent {
  render() {
    return (
      <FilterContext.Consumer>
        {({
          intl,
          view,
          closeFilter,
          isFilterOpen,
          resetFilter,
          onViewChange,
          city,
          cityHasSubways,
          districts,
          filter,
          priceMaxLimit,
          priceMinLimit,
          shouldShowScheme,
          deleteGeoAreas,
          updateFilter,
          amenities,
        }) => (
          <div className={classNames('filter', { __hide: !isFilterOpen })}>
            <AdCatalogFilterViewTabs
              intl={intl}
              view={view}
              onChange={onViewChange}
            />
            <form className="filter-form">
              <div className="filter-main">
                <AdCatalogFilterHeader
                  intl={intl}
                  onCloseClick={closeFilter}
                  onResetClick={resetFilter}
                />
                <AdCatalogFilterBody
                  intl={intl}
                  city={city}
                  cityHasSubways={cityHasSubways}
                  districts={districts}
                  filter={filter}
                  priceMaxLimit={priceMaxLimit}
                  priceMinLimit={priceMinLimit}
                  shouldShowScheme={shouldShowScheme}
                  deleteGeoAreas={deleteGeoAreas}
                  amenities={amenities}
                  updateFilter={updateFilter}
                />
                <AdCatalogFilterFooter intl={intl} onApplyClick={closeFilter} />
              </div>
            </form>
          </div>
        )}
      </FilterContext.Consumer>
    );
  }
}

export default AdCatalogFilter;
