/* eslint-disable react/prefer-stateless-function */
import { addLocaleData, injectIntl, IntlProvider } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import en from 'react-intl/locale-data/en';
import ru from 'react-intl/locale-data/ru';
import uk from 'react-intl/locale-data/uk';

import { defaultLocale } from '../../../../libs/i18n/default';
import { translations } from '../../../../libs/i18n/translations';

addLocaleData([...en, ...ru, ...uk]);

const getLocaleFromWindowI18n = () => {
  if (typeof window !== 'undefined' && window.I18n && window.I18n.locale) {
    return window.I18n.locale;
  }

  return null;
};

const TranslationProvider = EnhancedComponent => {
  const EnhancedComponentWithIntl = injectIntl(EnhancedComponent);

  const WrapperComponent = props => {
    const { railsContext, locale } = props;

    const resultLocale =
      (typeof railsContext !== 'undefined'
        ? railsContext.i18nLocale
        : void 0) ||
      locale ||
      getLocaleFromWindowI18n() ||
      defaultLocale;

    const messages = translations[resultLocale];

    return (
      <IntlProvider
        locale={resultLocale}
        key={resultLocale}
        messages={messages}
      >
        <EnhancedComponentWithIntl {...props} />
      </IntlProvider>
    );
  };

  WrapperComponent.propTypes = {
    locale: PropTypes.string,
    railsContext: PropTypes.shape({
      i18nLocale: PropTypes.string,
    }),
  };

  return WrapperComponent;
};

export default TranslationProvider;
