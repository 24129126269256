import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as paths from '../../constants/paths';
import { defaultMessages } from '../../../../libs/i18n/default';
import setRailsContext from '../../../common/utils/setRailsContext';
import TranslationProvider from '../../../common/components/HOC/TranslationProvider';
import FlashNotifierService from '../../services/FlashNotifier/FlashNotifier';
import { VIEW_CARD_FORM, VIEW_LINKED_CARDS } from './PaymentForm.constants';
import PaymentCard from '../PaymentCard/PaymentCard';
import PaymentFormLinkedCards from './PaymentFormLinkedCards';
import PaymentApplePay from '../PaymentApplePay/PaymentApplePay';

class PaymentForm extends React.Component {
  constructor(props) {
    super(props);

    const { payment } = this.props;

    this.state = {
      cards: payment.cards,
      currentLinkedCardId: null,
      currentView:
        payment.cards.length > 0 ? VIEW_LINKED_CARDS : VIEW_CARD_FORM,
      isCardValid: false,
      isDeletingCard: false,
      isLoading: false,
      isRememberCard: true,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { cards } = this.state;

    if (prevState.cards.length !== cards.length && cards.length === 0) {
      this.setCardFormView();
    }
  }

  onLinkedCardsLinkClick = e => {
    const { isLoading } = this.state;

    if (isLoading) {
      return;
    }

    this.setLinkedCardsView();
    e.preventDefault();
  };

  onInvalid = () => {
    const { isCardValid } = this.state;

    if (isCardValid) {
      this.setState({ isCardValid: false });
    }
  };

  onValid = () => {
    const { isCardValid } = this.state;

    if (!isCardValid) {
      this.setState({ isCardValid: true });
    }
  };

  onSubmitButtonClick = () => {
    this.submitForm();
  };

  setLinkedCardsView = () => {
    this.setState({
      currentView: VIEW_LINKED_CARDS,
      isCardValid: false,
    });
  };

  setCardFormView = () => {
    this.setState({
      currentView: VIEW_CARD_FORM,
    });
  };

  setPaymentFormState = newAttrs => {
    this.setState({ ...newAttrs });
  };

  formPostRequest = (action, fields) => {
    const form = document.createElement('form');

    form.setAttribute('action', action);
    form.setAttribute('method', 'POST');

    Object.keys(fields).map(field => {
      const input = document.createElement('input');

      input.setAttribute('type', 'hidden');
      input.setAttribute('name', field);
      input.setAttribute('value', fields[field]);
      form.appendChild(input);
    });

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  };

  submitForm = () => {
    const {
      isLoading,
      currentView,
      currentLinkedCardId,
      isCardValid,
    } = this.state;

    if (!this.isSubmitDisabled() && !isLoading) {
      if (currentView === VIEW_LINKED_CARDS && currentLinkedCardId) {
        this.payWithToken();
      } else if (currentView === VIEW_CARD_FORM && isCardValid) {
        this.payWithCrypto();
      }
    }
  };

  toggleLoading = () => {
    this.setState(({ isLoading }) => ({
      isLoading: !isLoading,
    }));
  };

  payWithToken() {
    const { currentLinkedCardId } = this.state;
    const {
      payment,
      intl: { formatMessage },
    } = this.props;

    this.payWithTokenRequest = $.ajax({
      url: paths.CLOUD_PAYMENTS_PAY_WITH_TOKEN_PATH,
      method: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({
        id: payment.id,
        card_id: currentLinkedCardId,
      }),
      beforeSend: () => {
        this.setState({ isLoading: true });
      },
    });

    this.payWithTokenRequest.done((data, _status, xhr) => {
      if (data.result === 'success') {
        window.location.href = `/payments/${payment.id}/success`;
      } else {
        window.location.href = `/payments/${payment.id}/fail?message=${
          data.message
        }`;
      }
    });

    this.payWithTokenRequest.fail(() => {
      FlashNotifierService.notifyError(
        formatMessage(defaultMessages.jsFlashNotifierErrorHasOccurredTryAgain),
      );
      this.setState({ isLoading: false });
    });
  }

  payWithCrypto() {
    const { isRememberCard } = this.state;
    const {
      payment,
      intl: { formatMessage },
    } = this.props;
    const result = this.createCryptogram();

    if (result.success) {
      let ajaxData = {
        id: payment.id,
        crypto: result.packet,
      };

      if (payment.isLogged) {
        ajaxData.remember_card = isRememberCard;
      }

      this.payWithCryptoRequest = $.ajax({
        url: paths.CLOUD_PAYMENTS_PAY_WITH_CRYPTO_PATH,
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(ajaxData),
        beforeSend: () => {
          this.setState({ isLoading: true });
        },
      });

      this.payWithCryptoRequest.done((data, _status, xhr) => {
        if (data.result === 'secure3d') {
          this.formPostRequest(data.acs_url, {
            PaReq: data.pa_req,
            MD: data.transaction_id.toString(),
            TermUrl: data.term_url,
          });

          this.setState({ isLoading: false });
        } else if (data.result === 'success') {
          window.location.href = `/payments/${payment.id}/success`;
        } else {
          window.location.href = `/payments/${payment.id}/fail?message=${
            data.message
          }`;
        }
      });

      this.payWithCryptoRequest.fail(() => {
        FlashNotifierService.notifyError(
          formatMessage(
            defaultMessages.jsFlashNotifierErrorHasOccurredTryAgain,
          ),
        );
        this.setState({ isLoading: false });
      });
    } else {
      // result.messages?
      FlashNotifierService.notifyError(
        formatMessage(defaultMessages.jsFlashNotifierErrorHasOccurredTryAgain),
      );
    }
  }

  createCryptogram() {
    const { cardNumber, expDateMonthYear, cvv } = this.state;
    const { payment } = this.props;

    const checkout = new cp.Checkout(payment.cloudPaymentsPublicKey);

    return checkout.createCryptogramPacket({
      cardNumber: cardNumber,
      expDateMonthYear: expDateMonthYear,
      cvv: cvv,
    });
  }

  isSubmitDisabled() {
    const {
      currentView,
      currentLinkedCardId,
      isDeletingCard,
      isCardValid,
    } = this.state;

    return (
      (currentView === VIEW_CARD_FORM && !isCardValid) ||
      (currentView === VIEW_LINKED_CARDS && currentLinkedCardId === null) ||
      (currentView === VIEW_LINKED_CARDS && isDeletingCard)
    );
  }

  renderCardForm() {
    const { currentView, isRememberCard } = this.state;
    const { payment, intl } = this.props;

    if (currentView === VIEW_CARD_FORM) {
      return (
        <div className="PaymentForm__card">
          {this.renderCardFormHeader()}
          <PaymentCard
            intl={intl}
            isRememberSwitchActive={isRememberCard}
            isRememberSwitchVisible={payment.isLogged}
            onInvalid={this.onInvalid}
            onValid={this.onValid}
            setPaymentFormState={this.setPaymentFormState}
            submitForm={this.submitForm}
          />
        </div>
      );
    }
  }

  renderCardFormHeader() {
    const { cards } = this.state;
    const {
      payment,
      intl: { formatMessage },
    } = this.props;

    if (payment.isLogged && cards.length > 0) {
      return (
        <div className="PaymentForm__cardHeader">
          <div className="PaymentForm__cardHeaderLeft">
            <div className="PaymentForm__title">
              {formatMessage(defaultMessages.jsPaymentFormNewCard)}
            </div>
          </div>
          <div className="PaymentForm__cardHeaderRight">
            <a
              className="PaymentForm__backLink"
              href="#"
              title={formatMessage(defaultMessages.jsPaymentFormLinkedCards)}
              onClick={this.onLinkedCardsLinkClick}
            >
              &larr; {formatMessage(defaultMessages.jsPaymentFormLinkedCards)}
            </a>
          </div>
        </div>
      );
    }
  }

  renderCurrencyIcon() {
    const { payment } = this.props;

    switch (payment.sumCurrencyCode) {
      case 'usd':
        return '$';
      default:
        return <i className={`currency currency-${payment.sumCurrencyCode}`} />;
    }
  }

  renderLinkedCards() {
    const {
      currentView,
      cards,
      currentLinkedCardId,
      isDeletingCard,
      isLoading,
    } = this.state;
    const { payment } = this.props;

    if (currentView === VIEW_LINKED_CARDS) {
      return (
        <PaymentFormLinkedCards
          cards={cards}
          currentLinkedCardId={currentLinkedCardId}
          isDeletingCard={isDeletingCard}
          isLoading={isLoading}
          isLoggedIn={payment.isLogged}
          setCardFormView={this.setCardFormView}
          setPaymentFormState={this.setPaymentFormState}
          toggleLoading={this.toggleLoading}
        />
      );
    }

    return null;
  }

  renderCta() {
    const { isLoading } = this.state;
    const {
      intl: { formatMessage },
      payment,
    } = this.props;

    return (
      <div className="PaymentForm__cta">
        <div className="PaymentForm__ctaItem">
          <button
            className={classNames({
              PaymentForm__submitButton: true,
              button: true,
              'PaymentForm__submitButton--disabled': this.isSubmitDisabled(),
              'PaymentForm__submitButton--loading': isLoading,
            })}
            type="button"
            disabled={this.isSubmitDisabled()}
            onClick={this.onSubmitButtonClick}
            data-bind="click: createCryptogram"
          >
            <span className="PaymentForm__submitButtonText">
              {`${formatMessage(defaultMessages.jsPaymentFormSubmitButton)} `}
              {`${payment.sumWithDelimiter} `}
              {this.renderCurrencyIcon()}
            </span>
            <span className="PaymentForm__submitButtonLoader" />
          </button>
          <PaymentApplePay payment={payment} />
        </div>
        <div className="PaymentForm__ctaItem">
          <div className="PaymentForm__securityText">
            {formatMessage(defaultMessages.jsPaymentFormSecurityText)}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="PaymentForm">
        {this.renderCardForm()}
        {this.renderLinkedCards()}
        {this.renderCta()}
      </div>
    );
  }
}

PaymentForm.propTypes = {
  intl: intlShape.isRequired,
  payment: PropTypes.object.isRequired,
};

export default setRailsContext(TranslationProvider(PaymentForm));
