/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import PropTypes from 'prop-types';

import setRailsContext from '../../../common/utils/setRailsContext';

const AdViewingButton = ({ adId }) => {
  const handleClick = () => {
    window.location = `/review_request?ad_id=${adId}`;
  };

  return (
    <React.Fragment>
      <a
        id="ad_contacts_viewing"
        className="button button-large button-viewing button-white button-orange-outline "
        onClick={handleClick}
      >
        Записаться на просмотр
      </a>
    </React.Fragment>
  );
};

AdViewingButton.propTypes = {
  adId: PropTypes.number,
};

export default setRailsContext(AdViewingButton);
