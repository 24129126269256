import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const SvgIcon = React.memo(function({ icon }) {
  return (
    <span
      className={classNames('SvgIcon', `SvgIcon--${icon}`)}
      aria-hidden="true"
    >
      <svg className="SvgIcon__symbol">
        <use xlinkHref={`#icon-${icon}`} />
      </svg>
    </span>
  );
});

SvgIcon.propTypes = {
  icon: PropTypes.string,
};

export default SvgIcon;
