import { Sticky, StickyContainer } from 'react-sticky';
import classNames from 'classnames';
import Media from 'react-media';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import requestAnimationFrame from 'dom-helpers/util/requestAnimationFrame';

import { VIEW_MODES } from './AdCatalog.constants';
import { defaultMessages } from '../../../../libs/i18n/default';
import AdCatalogGoogleMap from './AdCatalogGoogleMap';
import AdCatalogNewYandexMap from './AdCatalogNewYandexMap';
import AdCatalogFilter from './AdCatalogFilter';
import AdCatalogGrid from './AdCatalogGrid';
import AdCatalogSEO from './AdCatalogSEO';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';

const TOP_OFFSET = -20;

class AdCatalogContent extends Component {
  state = {
    mobileTopOffset: TOP_OFFSET,
  };

  constructor(props) {
    super(props);

    this.catalogGridRef = React.createRef();
    this.stickyRef = React.createRef();

    this.lastScrollPosition = null;
    this.currentScrollOffset = null;

    this.scheduledAnimationFrame = false;
  }

  componentDidMount() {
    this.calcStickyTopOffset();

    window.addEventListener('scroll', this.handleWindowScrollRAF);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleWindowScrollRAF);
  }

  // расчет отступа sticky для мобилок
  calcStickyTopOffset = () => {
    if (this.catalogGridRef.current) {
      const gridElement = this.catalogGridRef.current.getParent();

      this.setState({
        mobileTopOffset: -gridElement.offsetTop,
      });
    }
  };

  handleWindowScrollRAF = () => {
    if (this.scheduledAnimationFrame) {
      return;
    }

    this.scheduledAnimationFrame = true;
    requestAnimationFrame(this.handleWindowScroll);
  };

  // рассчет отступа для скрола контента sticky элемента
  handleWindowScroll = event => {
    const { isFilterOpen } = this.props;

    if (isFilterOpen) {
      return false;
    }

    const {
      state: { isSticky, calculatedHeight, distanceFromBottom, style } = {},
      content: stickyElement,
    } = this.stickyRef.current;
    const { scrollY, innerHeight: windowHeight } = window;

    let delta = 0;

    if (this.lastScrollPosition !== null) {
      delta = scrollY - this.lastScrollPosition;
    }

    this.lastScrollPosition = scrollY;

    if (isSticky) {
      const { top = 0 } = style;

      if (!this.currentScrollOffset) {
        this.currentScrollOffset = top;
      }

      if (windowHeight < calculatedHeight) {
        const heightWithoutOffset =
          calculatedHeight + TOP_OFFSET - Math.abs(this.currentScrollOffset);

        if (heightWithoutOffset > windowHeight || delta < 0) {
          this.currentScrollOffset = Math.min(
            this.currentScrollOffset -
              Math.min(delta, heightWithoutOffset - windowHeight),
            0,
          );
        }

        stickyElement.style.top = `${Math.min(
          this.currentScrollOffset,
          distanceFromBottom,
        )}px`;
      }
    }

    this.scheduledAnimationFrame = false;
  };

  handleMediaChange = isMobile => {
    isMobile && this.calcStickyTopOffset();
  };

  renderMap() {
    const {
      intl,
      adsMap,
      filter,
      isUkraine,
      hideLoader,
      mapOptions,
      updateFilter,
      view,
      signedIn,
      shouldUpdateNewMap,
      showLoader,
      onReadyMap,
      onMapLastAjaxSuccessLoadingObjects,
      onMapLastAjaxErrorLoadingObjects,
    } = this.props;

    const isViewModeMap = view === VIEW_MODES.MAP;

    if (isUkraine) {
      return (
        <AdCatalogGoogleMap
          isVisible={isViewModeMap}
          intl={intl}
          ads={adsMap}
          options={mapOptions}
          isUserLoggedIn={signedIn}
          onInit={onReadyMap}
        />
      );
    } else {
      return (
        <AdCatalogNewYandexMap
          isVisible={isViewModeMap}
          intl={intl}
          filter={filter}
          options={mapOptions}
          shouldUpdate={shouldUpdateNewMap}
          isUserLoggedIn={signedIn}
          isUkraine={isUkraine}
          showLoader={showLoader}
          hideLoader={hideLoader}
          updateFilter={updateFilter}
          onReady={onReadyMap}
          onLastAjaxSuccessLoadingObjects={onMapLastAjaxSuccessLoadingObjects}
          onLastAjaxErrorLoadingObjects={onMapLastAjaxErrorLoadingObjects}
        />
      );
    }
  }

  render() {
    const { mobileTopOffset } = this.state;
    const {
      adsAmountFromOwner,
      ads,
      email,
      filter,
      intl,
      allAdsAmount,
      signedIn,
      loadMore,
      isLoading,
      isTheLocalsRuHost,
      queries,
      text,
      view,
      isFilterOpen,
    } = this.props;
    const { formatPlural, formatMessage } = intl;

    const isViewModeList = view === VIEW_MODES.LIST;

    return (
      <div className="catalog-content">
        <div className="catalog-results-count">
          <span>{allAdsAmount}</span>{' '}
          {formatMessage(
            defaultMessages[
              `jsPluralsApartments${capitalizeFirstLetter(
                formatPlural(allAdsAmount),
              )}`
            ],
          )}
        </div>
        <div className="container-fluid">
          <StickyContainer className="catalog-grid" ref={this.catalogGridRef}>
            <div className="catalog-grid-col catalog-grid-col-aside">
              <Media
                query="(max-width: 992px)"
                onChange={this.handleMediaChange}
              >
                {isMobile =>
                  isFilterOpen ? (
                    <AdCatalogFilter />
                  ) : (
                    <Sticky
                      ref={this.stickyRef}
                      topOffset={isMobile ? mobileTopOffset : TOP_OFFSET}
                    >
                      {({ isSticky, style }) => (
                        <div
                          className={classNames('sticky-filter', {
                            'sticky-filter--active': isSticky,
                          })}
                          style={isViewModeList ? style : null}
                        >
                          <AdCatalogFilter />
                        </div>
                      )}
                    </Sticky>
                  )
                }
              </Media>
            </div>
            {isViewModeList && (
              <AdCatalogGrid
                intl={intl}
                adsAmountFromOwner={adsAmountFromOwner}
                ads={ads}
                allAdsAmount={allAdsAmount}
                email={email}
                filter={filter}
                view={view}
                signedIn={signedIn}
                isFilterOpen={isFilterOpen}
                isTheLocalsRuHost={isTheLocalsRuHost}
                loadMore={loadMore}
              />
            )}
          </StickyContainer>
          {isViewModeList && (queries || text) && (
            <AdCatalogSEO queries={queries} text={text} />
          )}
        </div>
        {this.renderMap()}
        {isLoading && <div className="catalog-loader __full" />}
      </div>
    );
  }
}

AdCatalogContent.propTypes = {
  ads: PropTypes.any,
  adsAmountFromOwner: PropTypes.number,
  adsMap: PropTypes.any,
  allAdsAmount: PropTypes.any,
  email: PropTypes.any,
  filter: PropTypes.any,
  hideLoader: PropTypes.any,
  intl: PropTypes.any,
  isFilterOpen: PropTypes.any,
  isLoading: PropTypes.bool.isRequired,
  isTheLocalsRuHost: PropTypes.any,
  isUkraine: PropTypes.any,
  loadMore: PropTypes.any,
  mapOptions: PropTypes.any,
  queries: PropTypes.any,
  shouldUpdateNewMap: PropTypes.any,
  showLoader: PropTypes.any,
  signedIn: PropTypes.any,
  text: PropTypes.any,
  updateFilter: PropTypes.any,
  view: PropTypes.any,
  onMapLastAjaxErrorLoadingObjects: PropTypes.any,
  onMapLastAjaxSuccessLoadingObjects: PropTypes.any,
  onReadyMap: PropTypes.any,
};

export default AdCatalogContent;
