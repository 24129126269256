/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar';

import { fetchAdByIdWithCancelation } from '../../api/rooms';
import { defaultMessages } from '../../../../libs/i18n/default';
import FlashNotifierService from '../../services/FlashNotifier/FlashNotifier';
import OfferCard from '../OfferCard/OfferCard';

class AdCatalogGoogleMapClusterPopup extends React.Component {
  constructor(props) {
    super(props);

    const { ads = [], isLoaded = false } = this.props;

    this.state = {
      ads,
      isLoaded,
    };

    this.scrollBar = null;

    this.contentRef = React.createRef();
  }

  componentDidMount() {
    const { isLoaded } = this.state;

    if (!isLoaded) {
      this.getData();
    } else {
      this.initCustomScrollbar();
    }
  }

  componentDidUpdate() {
    const { isLoaded } = this.state;

    if (isLoaded) {
      this.initCustomScrollbar();
    }
  }

  componentWillUnmount() {
    if (this.requestsCancel) {
      this.requestsCancel.forEach(cancel => {
        cancel();
      });

      this.requestsCancel = null;
    }
  }

  onOfferFavorite = id => {
    const { ads } = this.state;
    const { onOfferFavorite } = this.props;

    let adsWithFavourite = ads.map(ad => {
      if (ad.id === id) {
        ad.favorite = true;
      }

      return ad;
    });

    this.setState({ ads: adsWithFavourite });

    if (typeof onOfferFavorite !== 'undefined') {
      onOfferFavorite(id);
    }
  };

  onOfferUnfavorite = id => {
    const { ads } = this.state;
    const { onOfferUnfavorite } = this.props;

    let adsWithFavourite = ads.map(ad => {
      if (ad.id === id) {
        ad.favorite = false;
      }

      return ad;
    });

    this.setState({ ads: adsWithFavourite });

    if (typeof onOfferUnfavorite !== 'undefined') {
      onOfferUnfavorite(id);
    }
  };

  getData() {
    const {
      intl: { formatMessage },
      unloadedAdIds,
    } = this.props;

    this.requestsCancel = [];
    this.numberRequests = unloadedAdIds.length;
    this.requestCounter = 0;
    this.requestCounterWithErrors = 0;

    unloadedAdIds.forEach(id => {
      const [requestPromise, cancel] = fetchAdByIdWithCancelation(id);

      requestPromise
        .then(response => {
          const { ads } = this.state;
          const { onAjaxSuccess } = this.props;

          let nextState = {
            ads: [].concat(ads, response),
          };

          this.requestCounter++;

          if (this.numberRequests === this.requestCounter) {
            nextState.isLoaded = true;
          }

          this.setState(nextState);

          if (onAjaxSuccess) {
            onAjaxSuccess(response);
          }

          nextState = null;
        })
        .catch(() => {
          this.requestCounter++;
          this.requestCounterWithErrors++;

          if (this.requestCounterWithErrors === this.requestCounter) {
            FlashNotifierService.notifyError(
              formatMessage(
                defaultMessages.jsFlashNotifierErrorHasOccurredTryAgain,
              ),
            );
            return;
          }

          if (this.numberRequests === this.requestCounter) {
            this.setState({ isLoaded: true });

            if (this.requestCounterWithErrors) {
              FlashNotifierService.notifyError(
                formatMessage(
                  defaultMessages.jsFlashNotifierCouldNotLoadAllAdsTryAgain,
                ),
              );
            }
          }
        });

      this.requestsCancel.push(cancel);
    });
  }

  getPrettyRooms(rooms) {
    const {
      intl: { formatMessage },
    } = this.props;

    switch (rooms) {
      case 1:
        return formatMessage(
          defaultMessages.jsAdCardSpaceForRentOnMapOneRoomApartments,
        );
      case 2:
        return formatMessage(
          defaultMessages.jsAdCardSpaceForRentOnMapTwoRoomApartments,
        );
      case 3:
        return formatMessage(
          defaultMessages.jsAdCardSpaceForRentOnMapThreeRoomApartments,
        );
      case 'K':
        return formatMessage(defaultMessages.jsAdCardSpaceForRentOnMapRoom);
      default:
        return formatMessage(
          defaultMessages.jsAdCardSpaceForRentOnMapMultiRoomApartments,
        );
    }
  }

  initCustomScrollbar() {
    setTimeout(() => {
      if (!DeviceSupports.touch && !this.scrollBar) {
        this.scrollBar = new SimpleBar(this.contentRef.current);
      }
    }, 0);
  }

  renderContent() {
    const { ads, isLoaded } = this.state;
    const {
      intl: { locale },
      isUserLoggedIn,
    } = this.props;

    if (!isLoaded) {
      return (
        <div className="map-popup-loader">
          <div className="spinner" />
        </div>
      );
    } else {
      return (
        <div ref={this.contentRef} className="map-popup-content">
          {ads.map(ad => {
            return (
              <OfferCard
                isHorizontal
                key={ad.id}
                ad={ad}
                isUserLoggedIn={isUserLoggedIn}
                onFavorite={this.onOfferFavorite}
                onUnfavorite={this.onOfferUnfavorite}
                locale={locale}
              />
            );
          })}
        </div>
      );
    }
  }

  render() {
    const { ads } = this.state;
    const {
      intl: { formatMessage },
      placement,
      onClose,
    } = this.props;

    return (
      <div className={'map-popup map-popup-cluster map-popup-' + placement}>
        <div className="map-popup-close" onClick={onClose}>
          <div className="map-popup-close-icon" />
        </div>
        <div className="map-popup-arrow" />
        <div className="map-popup-header">
          {ads.length} {formatMessage(defaultMessages.jsCatalogSortingAds)}
        </div>
        {this.renderContent()}
      </div>
    );
  }
}

AdCatalogGoogleMapClusterPopup.propTypes = {
  ads: PropTypes.array,
  intl: intlShape.isRequired,
  isLoaded: PropTypes.bool,
  isUserLoggedIn: PropTypes.bool,
  placement: PropTypes.string,
  unloadedAdIds: PropTypes.array,
  onAjaxSuccess: PropTypes.func,
  onClose: PropTypes.func,
  onOfferFavorite: PropTypes.func,
  onOfferUnfavorite: PropTypes.func,
};

export default AdCatalogGoogleMapClusterPopup;
