/* eslint-disable react/no-multi-comp */
import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

import setRailsContext from '../../../common/utils/setRailsContext';
import TranslationProvider from '../../../common/components/HOC/TranslationProvider';
import OfferFavorite from '../OfferFavorite/OfferFavorite';
import OfferFavoriteDropdown from '../OfferFavoriteDropdown/OfferFavoriteDropdown';
import Dropdown from '../Dropdown/Dropdown';
import FavoriteButton from './FavoriteButton';

class OfferFavoriteButton extends React.PureComponent {
  state = {
    isOpen: false,
  };

  handleDropdownOutsideClick = () => {
    this.setState({ isOpen: false });
  };

  handleUnsignedFavoriteClick = () => {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
  };

  render() {
    const { isOpen } = this.state;
    const {
      ad: { id, favorite },
      intl,
      signedIn,
    } = this.props;

    return (
      <OfferFavorite
        id={id} // ad.id
        intl={intl}
        isInitialFavorited={favorite} // ad.favorite
      >
        {({ onFavoriteClick, isLoading, isFavorited }) => {
          if (!signedIn) {
            return (
              <Dropdown
                isOpen={isOpen}
                extraClassName="dropdown-popup-auth"
                onOutsideClick={this.handleDropdownOutsideClick}
                button={
                  <FavoriteButton
                    intl={intl}
                    isLoading={isLoading}
                    isFavorited={isFavorited}
                    onClick={this.handleUnsignedFavoriteClick}
                  />
                }
              >
                <OfferFavoriteDropdown intl={intl} />
              </Dropdown>
            );
          } else {
            return (
              <FavoriteButton
                intl={intl}
                isLoading={isLoading}
                isFavorited={isFavorited}
                onClick={onFavoriteClick}
              />
            );
          }
        }}
      </OfferFavorite>
    );
  }
}

OfferFavoriteButton.propTypes = {
  ad: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  signedIn: PropTypes.bool,
};

export default setRailsContext(TranslationProvider(OfferFavoriteButton));
