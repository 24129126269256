import axios, { CancelToken } from './axios';

export const resetPassword = params => {
  return axios
    .put('/users/password.json', params)
    .then(({ data }) => data)
    .catch(({ response: { data: { errors, error } } }) =>
      Promise.reject(errors, error),
    );
};

export const recoveryPassword = params => {
  return axios
    .post('/users/password.json', params)
    .then(({ data }) => data)
    .catch(({ response: { data: { errors } } }) => Promise.reject(errors));
};

export const loginByEmail = params => {
  return axios
    .post('/api/frontend/sessions/email', params)
    .then(({ data }) => data)
    .catch(({ response: { data: { errors } } }) => Promise.reject(errors));
};

export const registerByEmail = params => {
  return axios
    .post('/api/frontend/users/email', params)
    .then(({ data }) => data)
    .catch(({ response: { data: { errors } } }) => Promise.reject(errors));
};

export const loginByPhone = (phone, recaptcha) => {
  let cancel;

  return [
    axios
      .post(
        '/api/frontend/sessions/phone/new',
        { user: { phone, recaptcha } },
        {
          cancelToken: new CancelToken(function executor(c) {
            cancel = c;
          }),
        },
      )
      .then(({ data }) => data)
      .catch(({ response: { data: { errors } } }) => Promise.reject(errors)),
    cancel,
  ];
};

export const confirmLoginByPhone = ({ phone, code }) => {
  let cancel;

  return [
    axios
      .post(
        '/api/frontend/sessions/phone',
        { user: { phone, code } },
        {
          cancelToken: new CancelToken(function executor(c) {
            cancel = c;
          }),
        },
      )
      .then(({ data }) => data)
      .catch(({ response: { data: { errors } } }) => Promise.reject(errors)),
    cancel,
  ];
};
