import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-flex-modal';

import { commentsApi } from '../../api/comments';
import FlashNotifierService from '../../services/FlashNotifier/FlashNotifier';
import styles from './EditComment.scss';

class EditComment extends React.Component {
  constructor(props) {
    super(props);

    const { text = '' } = this.props;

    this.state = {
      text,
      isModalOpen: false,
    };
  }

  handleValueChange = event => {
    this.setState({ text: event.target.value });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  handleSubmitForm = event => {
    const { text } = this.state;
    const { id } = this.props;

    event.preventDefault();

    commentsApi
      .saveComment( id, text )
      .then(() => {
        this.setState({ isModalOpen: false });

        FlashNotifierService.notifySuccess('Успешно');

        window.location.reload(true);
      })
      .catch(() => {
        FlashNotifierService.notifyError('Произошла ошибка');
      });
  };

  render() {
    const { isModalOpen, text } = this.state;

    return (
      <React.Fragment>
        <button
          className={classNames(
            'Button',
            'Button--info',
          )}
          type="button"
          onClick={this.openModal}
        >
          Редактировать
        </button>
        <Modal transition isOpen={isModalOpen} onClose={this.closeModal}>
          <form onSubmit={this.handleSubmitForm}>
            <h5 className={styles.modalTitle}>Редактировать комментарий</h5>
            <textarea
              className={styles.modalTextarea}
              placeholder="Комментарий"
              value={text}
              onChange={this.handleValueChange}
            />
            <div className={styles.modalCta}>
              <button
                className={classNames(
                  styles.actionButton,
                  'Button',
                  'Button--green',
                )}
                type="submit"
                disabled={text.length === 0}
              >
                Сохранить
              </button>
            </div>
          </form>
        </Modal>
      </React.Fragment>
    );
  }
}

EditComment.propTypes = {
  id: PropTypes.number.isRequired,
  text: PropTypes.string,
};

EditComment.defaultProps = {
  text: '',
};

export default EditComment;
