/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import { FIELD_NAMES, AMENITY_KINDS } from './constants';
import AdFormCheckbox from './AdFormCheckbox';

class AdFormExtraOptions extends React.Component {
  constructor(props) {
    super(props);

    const { amenityIds } = this.props;

    this.state = {
      extended: amenityIds.length > 0,
    };
  }

  toggleExtended = () => {
    this.setState(({ extended }) => ({
      extended: !extended,
    }));
  };

  handleAmenityChange = event => {
    const { amenityIds, setAdState } = this.props;

    const value = parseInt(event.currentTarget.value);

    if (amenityIds.indexOf(value) === -1) {
      amenityIds.push(value);
    } else {
      amenityIds.splice(amenityIds.indexOf(value), 1);
    }

    setAdState({ [FIELD_NAMES.AMENITY_IDS]: amenityIds });
  };

  renderSubjectsCheckboxes() {
    const { amenityIds, amenities } = this.props;

    if (amenities.length === 0) {
      return null;
    }

    return amenities.map(
      ({
        id,
        title,
        kind,
        active_image: activeImage,
        disabled_image: inactiveImage,
      }) => {
        return (
          kind === AMENITY_KINDS.SUBJECT && (
            <AdFormCheckbox
              customIcon
              key={`subject_${id}`}
              classMods={['flat']}
              value={id.toString()}
              text={title}
              checked={amenityIds.indexOf(id) !== -1}
              onChange={this.handleAmenityChange}
            >
              <span className="checkbox-icon">
                <img
                  className="checkbox-icon-img checkbox-icon-img-default"
                  src={inactiveImage.url}
                />
                <img
                  className="checkbox-icon-img checkbox-icon-img-active"
                  src={activeImage.url}
                />
              </span>
            </AdFormCheckbox>
          )
        );
      },
    );
  }

  renderTagsCheckboxes() {
    const { amenityIds, amenities } = this.props;

    if (amenities.length === 0) {
      return null;
    }

    return amenities.map(
      ({
        id,
        title,
        kind,
        disabled_image: inactiveImage,
        active_image: activeImage,
      }) => {
        return (
          kind === AMENITY_KINDS.TAG && (
            <AdFormCheckbox
              key={`tag_${id}`}
              classMods={['flat']}
              value={id.toString()}
              onChange={this.handleAmenityChange}
              customIcon
              text={title}
              checked={amenityIds.indexOf(id) !== -1}
            >
              <span className="checkbox-icon">
                <img
                  className="checkbox-icon-img checkbox-icon-img-default"
                  src={inactiveImage.url}
                />
                <img
                  className="checkbox-icon-img checkbox-icon-img-active"
                  src={activeImage.url}
                />
              </span>
            </AdFormCheckbox>
          )
        );
      },
    );
  }

  renderExtended() {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <div className="form-extendable-body">
        <div className="form-row">
          <div className="form-row-label">
            {formatMessage(defaultMessages.jsAdFormExtAmenities)}
          </div>
          <div className="form-row-content">
            <div className="checkbox-group checkbox-group-four-cols checkbox-group-features">
              {this.renderSubjectsCheckboxes()}
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-row-label">
            {formatMessage(defaultMessages.jsAdFormExtFeatures)}
          </div>
          <div className="form-row-content">
            <div className="checkbox-group checkbox-group-four-cols checkbox-group-features">
              {this.renderTagsCheckboxes()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { extended } = this.state;
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <div className={classNames('form-extendable', { __extended: extended })}>
        <div className="form-extendable-header">
          <div className="form-row">
            <div className="form-row-content">
              <a className="pseudo-link" onClick={this.toggleExtended}>
                {formatMessage(defaultMessages.jsAdFormExtLabel)}
                <i className="caret" />
              </a>
            </div>
          </div>
        </div>
        {this.renderExtended()}
      </div>
    );
  }
}

AdFormExtraOptions.propTypes = {
  amenities: PropTypes.array,
  amenityIds: PropTypes.array,
  intl: intlShape.isRequired,
  setAdState: PropTypes.func,
};

AdFormExtraOptions.defaultProps = {
  amenities: [],
  amenityIds: [],
};

export default injectIntl(AdFormExtraOptions);
