/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';

import { defaultMessages } from '../../../../libs/i18n/default';

const AdCatalogFilterFooter = ({ onApplyClick, intl: { formatMessage } }) => {
  return (
    <div className="filter-footer">
      <a className="filter-apply-button" onClick={onApplyClick}>
        {formatMessage(defaultMessages.jsCatalogFilterApply)}
      </a>
    </div>
  );
};

AdCatalogFilterFooter.propTypes = {
  intl: intlShape.isRequired,
  onApplyClick: PropTypes.func.isRequired,
};

export default AdCatalogFilterFooter;
