import { intlShape } from 'react-intl';
import { propTypes, withFormsy } from 'formsy-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import InputMask from 'react-input-mask';

import * as masks from '../../constants/masks';

class AuthModalInput extends React.Component {
  state = {
    isWasBlurred: false,
  };

  getMask() {
    const { type } = this.props;

    switch (type) {
      case 'tel':
        return this.getPhoneMask();
      case 'code':
        return masks.SMS_CODE;
      default:
        return null;
    }
  }

  getPhoneMask() {
    const { country } = this.props;

    switch (country) {
      case 'ua':
        return masks.PHONE_UA;
      default:
        // ru
        return masks.PHONE_RU;
    }
  }

  handleBlur = e => {
    this.setState({ isWasBlurred: true });
  };

  handleChange = e => {
    const { isValid, onValid, onInvalid, setValue } = this.props;

    // Даём время InputMask обновить значение инпута
    setTimeout(() => {
      if (isValid()) {
        if (typeof onValid !== 'undefined') {
          onValid();
        }
      } else {
        if (typeof onInvalid !== 'undefined') {
          onInvalid();
        }
      }
    }, 0);

    setValue(e.currentTarget.value);
  };

  renderInput() {
    const {
      type,
      autoFocus,
      name,
      getValue,
      placeholder,
      required,
    } = this.props;

    const inputProps = {
      autoFocus: autoFocus,
      className: 'input-control',
      name: name,
      mask: this.getMask(),
      placeholder: placeholder,
      required: required,
      type: type === 'code' ? 'text' : type,
      value: getValue() || '',
      onBlur: this.handleBlur,
      onChange: this.handleChange,
    };

    switch (type) {
      case 'tel':
      case 'code':
        return <InputMask {...inputProps} />;
      default:
        return <input {...inputProps} />;
    }
  }

  render() {
    const { isWasBlurred } = this.state;
    const { isFormSubmitted, isValid, getErrorMessage } = this.props;

    const hasError =
      (isFormSubmitted() && !isValid()) || (isWasBlurred && !isValid());

    return (
      <div
        className={classNames('input', {
          __error: hasError,
          __success: !hasError && isValid(),
        })}
      >
        {this.renderInput()}
        {hasError && <div className="error-message">{getErrorMessage()}</div>}
      </div>
    );
  }
}

AuthModalInput.propTypes = {
  intl: intlShape.isRequired,
  type: PropTypes.string,
  ...propTypes,
};

AuthModalInput.defaultProps = {
  type: 'text',
};

export default withFormsy(AuthModalInput);
