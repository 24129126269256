/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'react-tooltip-lite';

import { KINDS } from '../AdCatalog/AdCatalog.constants';

class MainFiltersCheckboxRooms extends React.Component {
  handleChange = event => {
    const { isDisabled, roomQuantity, updateState } = this.props;

    if (isDisabled) {
      return null;
    }

    const value = parseInt(event.target.value);

    let nextRoomQuantity = [...roomQuantity];

    const indexOfCurrent = nextRoomQuantity.indexOf(value);

    if (indexOfCurrent === -1) {
      nextRoomQuantity.push(value);
    } else {
      nextRoomQuantity.splice(indexOfCurrent, 1);
    }

    updateState({
      kind: nextRoomQuantity.length ? KINDS.APARTMENT : '',
      roomQuantity: nextRoomQuantity,
    });
  };

  render() {
    const { tooltip, isChecked, isDisabled, value, text } = this.props;

    return (
      <label className="MainFilters__checkbox MainFilters__checkbox--roomQuantity">
        <input
          className="MainFilters__checkboxControl"
          checked={isChecked}
          disabled={isDisabled}
          type="checkbox"
          value={value}
          onChange={this.handleChange}
        />
        {tooltip === null ? (
          <span className="MainFilters__checkboxContent">{text}</span>
        ) : (
          <Tooltip
            arrow
            arrowSize={7}
            distance={12}
            direction="up"
            eventOn="onClick"
            eventOff="onMouseOut"
            content={tooltip}
            tagName="span"
            className="MainFilters__checkboxContent"
            tipContentClassName=""
          >
            {text}
          </Tooltip>
        )}
      </label>
    );
  }
}

MainFiltersCheckboxRooms.propTypes = {
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  roomQuantity: PropTypes.array.isRequired,
  text: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  updateState: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};

MainFiltersCheckboxRooms.defaultProps = {
  tooltip: null,
};

export default MainFiltersCheckboxRooms;
