/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
import { intlShape } from 'react-intl';
import { PhotoSwipe } from 'react-photoswipe';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import { MAIN_CLASS, MAX_VISIBLE_ITEMS_NUMBER } from './OfferGallery.constants';
import setRailsContext from '../../../common/utils/setRailsContext';
import TranslationProvider from '../../../common/components/HOC/TranslationProvider';
import OfferGalleryImage from './OfferGalleryImage';

const PHOTO_SWIPE_OPTIONS = {
  showHideOpacity: true,
  barsSize: {
    top: 0,
    bottom: 'auto',
  },
  clickToCloseNonZoomable: false,
  captionEl: false,
  fullscreenEl: false,
  zoomEl: false,
  shareEl: false,
  history: false,
  maxSpreadZoom: 1,
  getDoubleTapZoom: (isMouseClick, item) => item.initialZoomLevel,
  pinchToClose: false,
};

class OfferGallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.touchstartTime = 0;
    this.touchstartX = 0;
    this.touchstartY = 0;
    this.touchendTime = 0;
    this.touchendX = 0;
    this.touchendY = 0;
  }

  componentDidMount() {
    this.photoSwipe.pswpElement.addEventListener(
      'click',
      this.onPhotoSwipeClick,
      false,
    );
  }

  componentWillUnmount() {
    this.photoSwipe.pswpElement.removeEventListener(
      'click',
      this.onPhotoSwipeClick,
      false,
    );
  }

  onAnchorClick = e => {
    this.openPhotoSwipe();
    e.preventDefault();
  };

  onAnchorTouchStart = e => {
    this.touchstartTime = new Date().getTime();
    this.touchstartX = e.changedTouches[0].screenX;
    this.touchstartY = e.changedTouches[0].screenY;
  };

  onAnchorTouchEnd = e => {
    this.touchendTime = new Date().getTime();
    this.touchendX = e.changedTouches[0].screenX;
    this.touchendY = e.changedTouches[0].screenY;

    if (
      Math.abs(this.touchendX - this.touchstartX) /
        (this.touchendTime - this.touchstartTime) >
        0.3 &&
      Math.abs(this.touchendY - this.touchstartY) < 80
    ) {
      this.openPhotoSwipe();
    }
  };

  onPhotoSwipeClick = e => {
    const { photoSwipe } = this.photoSwipe;

    if (photoSwipe.framework.hasClass(e.target, 'pswp__img')) {
      photoSwipe.items.length > 1 ? photoSwipe.next() : photoSwipe.close();
    }
  };

  onPhotoSwipeClose = () => {
    this.closePhotoSwipe();
  };

  getImagesLength() {
    const { images } = this.props;

    return images.thumb.length;
  }

  openPhotoSwipe() {
    this.setState({ isOpen: true });
  }

  closePhotoSwipe() {
    this.setState({ isOpen: false });
  }

  renderItems() {
    const { images } = this.props;

    return images.thumb.map((image, i) => {
      return (
        <div key={`${MAIN_CLASS}__item-${i}`} className={`${MAIN_CLASS}__item`}>
          <OfferGalleryImage
            key={`${MAIN_CLASS}__image-${i}`}
            src={image.src}
            alt={image.alt}
          />
        </div>
      );
    });
  }

  renderImageCounter() {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <div className={`${MAIN_CLASS}__counter`}>
        {this.getImagesLength()}{' '}
        {formatMessage(defaultMessages.jsOfferGalleryPhoto)}
      </div>
    );
  }

  renderMoreItems() {
    const {
      intl: { formatMessage },
    } = this.props;
    const length = this.getImagesLength();

    return (
      length > MAX_VISIBLE_ITEMS_NUMBER && (
        <div className={`${MAIN_CLASS}__more`}>
          <div className={`${MAIN_CLASS}__moreText`}>
            +{length - MAX_VISIBLE_ITEMS_NUMBER}{' '}
            {formatMessage(defaultMessages.jsOfferGalleryPhoto)}
          </div>
        </div>
      )
    );
  }

  render() {
    const { isOpen } = this.state;
    const { images } = this.props;
    const length = this.getImagesLength();

    return (
      <div>
        <div
          className={
            MAIN_CLASS +
            ` ${MAIN_CLASS}--style-grid-${
              length < MAX_VISIBLE_ITEMS_NUMBER
                ? length
                : MAX_VISIBLE_ITEMS_NUMBER
            }` +
            `
            ${images.blur_images == true ? 'OfferGallery__disabled blur' : null }
            `
          }
        >
          {this.renderItems()}
          {this.renderImageCounter()}
          {this.renderMoreItems()}
          <div
            className={`${MAIN_CLASS}__click`}
            onClick={this.onAnchorClick}
            onTouchStart={this.onAnchorTouchStart}
            onTouchEnd={this.onAnchorTouchEnd}
          />
        </div>
        <PhotoSwipe
          ref={photoSwipe => (this.photoSwipe = photoSwipe)}
          isOpen={isOpen}
          items={images.source}
          options={PHOTO_SWIPE_OPTIONS}
          onClose={this.onPhotoSwipeClose}
        />
      </div>
    );
  }
}

OfferGallery.propTypes = {
  images: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

export default setRailsContext(TranslationProvider(OfferGallery));
