import React from 'react';
import ReactDOM from 'react-dom';

import * as cookieKeys from '../../constants/cookieKeys';
import { profileApi } from '../../api/profile';
import Cookies from '../../../common/utils/Cookies';
import DeviceDetect from '../../../common/utils/DeviceDetect';
import AppBanner from '../../components/AppBanner/AppBanner';

export default (() => {
  if (typeof document === 'undefined') {
    return;
  }

  const userAgent = navigator.userAgent;
  const isIphone = DeviceDetect.isIphone(userAgent);
  const isAndroidOnMobile =
    DeviceDetect.isAndroid(userAgent) && DeviceDetect.isMobile(userAgent);

  const getAppBannerContainer = () => {
    const container = document.createElement('div');

    container.setAttribute('data-app-banner-container', '');
    document.body.appendChild(container);

    return container;
  };

  const getUserDevice = () => {
    if (isIphone) {
      return 'iPhone';
    }

    if (isAndroidOnMobile) {
      return 'mobileAndroid';
    }
  };

  const handleHideAppBanner = () => {
    profileApi
      .saveUserProfileData({ hide_app_banner: true })
      .catch(error => {});
    Cookies.set(cookieKeys.APP_BANNER_HIDDEN, true, 30);
  };

  const onDOMReady = () => {
    if (
      (isIphone || isAndroidOnMobile) &&
      !Cookies.get(cookieKeys.APP_BANNER_HIDDEN) &&
      window.showAppBanner
    ) {
      const container = getAppBannerContainer();

      ReactDOM.render(
        <AppBanner
          device={getUserDevice()}
          onClose={() => {
            handleHideAppBanner();
            ReactDOM.unmountComponentAtNode(container);
          }}
        />,
        container,
      );
    }
  };

  document.addEventListener('DOMContentLoaded', onDOMReady);
})();
