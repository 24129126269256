/* eslint-disable jsx-a11y/label-has-associated-control */
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { defaultMessages } from '../../../../libs/i18n/default';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';

class PricingPlans extends React.Component {
  componentDidMount() {
    $('[data-toggle="popover"]').popover();
  }

  onChange = e => {
    const { onChange } = this.props;

    onChange(parseInt(e.currentTarget.value));
  };

  getPlanBuyText(adFeaturingPeriod, adFeaturingPrice) {
    const {
      intl: { formatMessage, formatPlural },
      defaultCurrency,
    } = this.props;

    const featurePlanPeriod = `${adFeaturingPeriod}&nbsp;${formatMessage(
      defaultMessages[
        `jsPluralsDays${capitalizeFirstLetter(formatPlural(adFeaturingPeriod))}`
      ],
    )}`;

    const featurePlanPrice = `${adFeaturingPrice}&nbsp;${formatMessage(
      defaultMessages[`jsCurrencies${capitalizeFirstLetter(defaultCurrency)}`],
    )}`;

    return `${formatMessage(
      defaultMessages.jsAdFormPlanBuy,
    )} ${featurePlanPeriod} ${formatMessage(
      defaultMessages.jsAdFormPlanFor,
    )} <b>${featurePlanPrice}</b>`;
  }

  renderPlan(plan) {
    const { currentPlanId } = this.props;

    if (plan.deal_type === 'sale' && plan.kind === 'rocket') {
      return (
        <div className="PricingPlan">
          <div className="PricingPlan__inner">
            <div className="PricingPlan__header">
              <div
                className={`PricingPlan__headerIcon PricingPlan__headerIcon--${plan.kind}`}
              />
              <h3
                className="PricingPlan__headerTitle"
                dangerouslySetInnerHTML={{ __html: plan.title }}
              />
            </div>
            <div className="PricingPlan__content">
              <ul className="PricingPlan__featureList">
                <li className="PricingPlan__featureListItem">
                  Экспертная оценка и анализ рынка
                </li>
                <li className="PricingPlan__featureListItem">
                  ТОП продвижение объявления
                </li>
                <li className="PricingPlan__featureListItem">
                  Организуем показы
                </li>
                <li className="PricingPlan__featureListItem">
                  Согласуем все детали
                </li>
                <li className="PricingPlan__featureListItem">
                  Сопроводим в МФЦ и банке
                </li>
                <li className="PricingPlan__featureListItem">
                  Консультация по налоговым вопросам
                </li>
                <li className="PricingPlan__featureListItem">
                  Подготовим договор
                </li>
                <li className="PricingPlan__featureListItem">
                  Полное сопровождеине сделки
                </li>
              </ul>
            </div>
            <div className="PricingPlan__footer">
              <label className="radio radio-flat2 radio-pricing-plan">
                <input
                  className="radio-control"
                  name="ad[feature_plan_id]"
                  type="radio"
                  value={plan.id}
                  onChange={this.onChange}
                  checked={currentPlanId === plan.id}
                />
                <span className="radio-fake" />
                <span className="radio-content">
                  <span className="radio-text">
                    Купить услугу за <b>{plan.price} руб</b>
                  </span>
                </span>
              </label>
              <i>
                Услуга действует без ограничения по времени, до продажи недвижимости
              </i>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        className={classNames('PricingPlan', {
          'PricingPlan--top': plan.kind === 'bike',
        })}
      >
        <div className="PricingPlan__inner">
          <div className="PricingPlan__header">
            <div
              className={`PricingPlan__headerIcon PricingPlan__headerIcon--${plan.kind}`}
            />
            <h3
              className="PricingPlan__headerTitle"
              dangerouslySetInnerHTML={{ __html: plan.title }}
            />
          </div>
          <div className="PricingPlan__content">
            <ul className="PricingPlan__featureList">
              <li className="PricingPlan__featureListItem">
                Выделено в&nbsp;списке
                <span
                  className="PricingPlan__hint PricingPlan__hint--after"
                  data-container="body"
                  data-content="<div class='pricing-plan-popover'><div class='pricing-plan-popover-content'><h2 class='pricing-plan-popover-title'>Выделено в списке</h2><p>Ваше объявление будет выделено в&amp;nbsp;списке объектов специальным знаком.</p></div><div class='pricing-plan-popover-image pricing-plan-popover-image-highlight'></div></div>"
                  data-html="true"
                  data-placement="bottom"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-original-title=""
                  title=""
                />
              </li>
              <li className="PricingPlan__featureListItem">
                Наверху в&nbsp;результатах поиска
              </li>
              <li className="PricingPlan__featureListItem">
                Видно на&nbsp;главной странице
              </li>
              <li
                className={classNames('PricingPlan__featureListItem', {
                  'PricingPlan__featureListItem--disabled':
                    plan.kind === 'shoes',
                })}
              >
                Реклама в&nbsp;социальных сетях
                <span
                  className="PricingPlan__hint PricingPlan__hint--after"
                  data-container="body"
                  data-content="<div class='pricing-plan-popover'><div class='pricing-plan-popover-content'><p>Разместим вашу квартиру в&amp;nbsp;наших группах Facebook и&amp;nbsp;Vkontakte с&amp;nbsp;аудиторией более 200 тысяч человек</p></div></div>"
                  data-html="true"
                  data-placement="bottom"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-original-title=""
                  title=""
                />
              </li>
              <li
                className={classNames('PricingPlan__featureListItem', {
                  'PricingPlan__featureListItem--disabled':
                    plan.kind === 'shoes',
                })}
              >
                Модерация за&nbsp;1&nbsp;час
                <span
                  className="PricingPlan__hint PricingPlan__hint--after"
                  data-container="body"
                  data-content="<div class='pricing-plan-popover'><div class='pricing-plan-popover-content'><p>В выходные дни от 10 до 20 часов</p></div></div>"
                  data-html="true"
                  data-placement="bottom"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-original-title=""
                  title=""
                />
              </li>
              {/* <li
                className={classNames('PricingPlan__featureListItem', {
                  'PricingPlan__featureListItem--disabled':
                    plan.kind === 'shoes' || plan.kind === 'bike',
                })}
              >
                Размещение на&nbsp;15&nbsp;площадках
                <span
                  className="PricingPlan__hint PricingPlan__hint--after"
                  data-container="body"
                  data-content="<div class='pricing-plan-popover'><div class='pricing-plan-popover-content'><p>Разместим ваше объявление в&nbsp;премиум блоке ЦИАН, а&nbsp;так&nbsp;же на&nbsp;Авито, Домофонде, Юле и&nbsp;30-ти партнерских площадках</p></div></div>"
                  data-html="true"
                  data-placement="bottom"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-original-title=""
                  title=""
                />
              </li> */}
              <li
                className={classNames('PricingPlan__featureListItem', {
                  'PricingPlan__featureListItem--disabled':
                    plan.kind === 'shoes' || plan.kind === 'bike',
                })}
              >
                Аналитика
                {plan.kind === 'rocket' && (
                  <span className="PricingPlan__bonus PricingPlan__bonus--after PricingPlan__bonus--before">
                    Бонус
                  </span>
                )}
                <span
                  className="PricingPlan__hint PricingPlan__hint--after"
                  data-container="body"
                  data-content="<div class='pricing-plan-popover'><div class='pricing-plan-popover-content'><p>Ежедневный отчет на&amp;nbsp;почту о&amp;nbsp;количестве просмотров вашего объявления по&amp;nbsp;каждой площадке и&amp;nbsp;рекомендации по&amp;nbsp;улучшению видимости объявления</p></div></div>"
                  data-html="true"
                  data-placement="bottom"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-original-title=""
                  title=""
                />
              </li>
            </ul>
          </div>
          <div className="PricingPlan__footer">
            <label className="radio radio-flat2 radio-pricing-plan">
              <input
                className="radio-control"
                name="ad[feature_plan_id]"
                type="radio"
                value={plan.id}
                onChange={this.onChange}
                checked={currentPlanId === plan.id}
              />
              <span className="radio-fake" />
              <span className="radio-content">
                <span
                  className="radio-text"
                  dangerouslySetInnerHTML={{
                    __html: this.getPlanBuyText(plan.restriction, plan.price),
                  }}
                />
              </span>
            </label>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { plans, currentPlanId } = this.props;
    const filteredPlans = plans.filter(
      plan =>
        plan.kind === 'shoes' || plan.kind === 'bike' || plan.kind === 'rocket',
    );

    return (
      <section className="PricingPlans">
        <header className="PricingPlans__header">
          <div className="PricingPlans__headerTitle">Выберите тариф</div>
        </header>
        <div className="PricingPlans__content">
          <div className="PricingPlans__list PricingPlans__list--scroll">
            {filteredPlans.length > 0 &&
              filteredPlans.map(plan => (
                <div className="PricingPlans__item" key={plan.id}>
                  {this.renderPlan(plan)}
                </div>
              ))}
          </div>
        </div>
        <footer className="PricingPlans__footer">
          <label className="radio radio-flat2 radio-pricing-plan">
            <input
              className="radio-control"
              name="ad[pricing-plan]"
              type="radio"
              value={0}
              checked={currentPlanId === 0}
              onChange={this.onChange}
            />
            <span className="radio-fake" />
            <span className="radio-content">
              <span className="radio-text">
                Или оставить объявление
                <br /> обычным <span className="text-muted">Бесплатно</span>
              </span>
            </span>
          </label>
        </footer>
      </section>
    );
  }
}

PricingPlans.propTypes = {
  currentPlanId: PropTypes.number,
  defaultCurrency: PropTypes.string,
  intl: intlShape.isRequired,
  plans: PropTypes.array,
  onChange: PropTypes.func,
};

export default PricingPlans;
