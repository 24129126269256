/* eslint-disable jsx-a11y/label-has-associated-control */
import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import { KINDS, FILTER_KEYS } from './AdCatalog.constants';

class AdCatalogFilterBodyKind extends React.PureComponent {
  onChange = event => {
    const { updateFilter } = this.props;

    updateFilter({
      [FILTER_KEYS.KIND]: event.target.value,
    });
  };

  renderRadio(value, label) {
    const { kind } = this.props;

    return (
      <label className="filter-radio">
        <input
          className="filter-radio-control"
          type="radio"
          name="type"
          value={value}
          checked={value === kind}
          onChange={this.onChange}
        />
        <span className="filter-radio-text">{label}</span>
      </label>
    );
  }

  render() {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <div className="filter-row">
        <div className="filter-radio-group">
          {this.renderRadio(
            KINDS.APARTMENT,
            formatMessage(defaultMessages.jsCatalogFilterApartments),
          )}
          {this.renderRadio(
            KINDS.ROOM,
            formatMessage(defaultMessages.jsCatalogFilterRooms),
          )}
        </div>
      </div>
    );
  }
}

AdCatalogFilterBodyKind.propTypes = {
  intl: intlShape.isRequired,
  kind: PropTypes.string,
  updateFilter: PropTypes.func.isRequired,
};

export default AdCatalogFilterBodyKind;
