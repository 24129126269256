/* eslint-disable jsx-a11y/label-has-associated-control */
import { intlShape } from 'react-intl';
import Nouislider from 'nouislider-react';
import React from 'react';
import PropTypes from 'prop-types';
import CleaveInput from 'cleave.js/react';

import { defaultMessages } from '../../../../libs/i18n/default';
import { FILTER_KEYS } from './AdCatalog.constants';

const DEFAULT_SLIDER_STEP = 500;

const PRICE_INPUT_OPTIONS = {
  numeral: true,
  numeralThousandsGroupStyle: 'thousand',
  delimiter: ' ',
};

class AdCatalogFilterBodyPrices extends React.PureComponent {
  constructor(props) {
    super(props);

    const { priceMaxLimit, priceMinLimit, priceMin, priceMax } = this.props;

    const currentMin = priceMin || priceMinLimit;
    const currentMax = priceMax || priceMaxLimit;

    this.state = {
      currentMin,
      currentMax,
      sliderMin: currentMin,
      sliderMax: currentMax,
      range: this.getRangeLimits(),
      cleaveInputMin: null,
      cleaveInputMax: null,
    };

    this.updatePriceTimout = null;
  }

  componentDidUpdate(prevProps) {
    const { priceMaxLimit, priceMinLimit, priceMin, priceMax } = this.props;

    if (
      prevProps.priceMaxLimit !== priceMaxLimit ||
      prevProps.priceMinLimit !== priceMinLimit
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ range: this.setRangeLimits() });
    }

    if (prevProps.priceMin !== priceMin && priceMin !== null) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ sliderMin: priceMin, currentMin: priceMin });
    }
    if (prevProps.priceMax !== priceMax && priceMax !== null) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ sliderMax: priceMax, currentMax: priceMax });
    }
  }

  getRangeLimits() {
    const { priceMinLimit, priceMaxLimit } = this.props;

    let range = {
      min: priceMinLimit,
      max: priceMaxLimit,
    };

    if (priceMaxLimit > 80000) {
      range = {
        min: [priceMinLimit],
        '75%': [80000, 5000],
        max: [priceMaxLimit],
      };
    }

    if (priceMaxLimit > 300000) {
      range = {
        min: [priceMinLimit],
        '70%': [80000, 5000],
        '85%': [300000, 50000],
        max: [priceMaxLimit],
      };
    }

    if (priceMaxLimit > 1000000) {
      range = {
        min: [priceMinLimit],
        '65%': [80000, 5000],
        '88%': [300000, 50000],
        '95%': [1000000, 500000],
        max: [priceMaxLimit],
      };
    }

    return range;
  }

  handlePriceMinInputInit = input => {
    this.setState({ cleaveInputMin: input });
  };

  handlePriceMaxInputInit = input => {
    this.setState({ cleaveInputMax: input });
  };

  handlePriceMinChange = event => {
    const { currentMin, currentMax, cleaveInputMin } = this.state;
    const { priceMinLimit, priceMaxLimit } = this.props;

    let value = event.target.rawValue;

    if (value === currentMin) {
      return false;
    }

    if (value < priceMinLimit) {
      value = priceMinLimit;
    }

    if (value > priceMaxLimit) {
      value = priceMaxLimit;
    }

    if (value > currentMax && value <= priceMaxLimit) {
      value = currentMax;
    }

    this.setState({ currentMin: value });

    if (this.updatePriceTimout) {
      clearTimeout(this.updatePriceTimout);
    }

    this.updatePriceTimout = setTimeout(() => {
      this.setState({ sliderMin: value });
    }, 500);

    // cleave js не обновляет значение инпута если оно === начальному значению
    if (currentMin === value) {
      cleaveInputMin.setRawValue(value);
    }
  };

  handlePriceMaxChange = event => {
    const { currentMin, currentMax, cleaveInputMax } = this.state;
    const { priceMinLimit, priceMaxLimit } = this.props;

    let value = Number(event.target.rawValue);

    if (value === currentMax) {
      return false;
    }

    if (value < priceMinLimit) {
      value = priceMinLimit;
    }

    if (value > priceMaxLimit) {
      value = priceMaxLimit;
    }

    if (value < currentMin && value >= priceMinLimit) {
      value = currentMin;
    }

    this.setState({ currentMax: value });

    if (this.updatePriceTimout) {
      clearTimeout(this.updatePriceTimout);
    }

    this.updatePriceTimout = setTimeout(() => {
      this.setState({ sliderMax: value });
    }, 500);

    // cleave js не обновляет значение инпута если оно === начальному значению
    if (currentMax === value) {
      cleaveInputMax.setRawValue(value);
    }
  };

  handleSliderUpdate = values => {
    this.setState({
      currentMin: Math.round(values[0]),
      currentMax: Math.round(values[1]),
    });
  };

  handleSliderSet = values => {
    const { updateFilter, priceMin, priceMax } = this.props;

    const nextMin = Math.round(values[0]);
    const nextMax = Math.round(values[1]);

    if (priceMin === nextMin && priceMax === nextMax) {
      return null;
    }

    updateFilter({
      [FILTER_KEYS.PRICE_MIN]: nextMin,
      [FILTER_KEYS.PRICE_MAX]: nextMax,
    });
  };

  render() {
    const { currentMin, currentMax, sliderMin, sliderMax, range } = this.state;
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <div className="filter-row">
        <div className="filter-label">
          {formatMessage(defaultMessages.jsCatalogFilterPrice)}
        </div>
        <div className="filter-range-slider">
          <Nouislider
            id="filter_slider"
            key="filter_slider"
            className="filter-range-slider-ui"
            connect
            range={range}
            start={[sliderMin, sliderMax]}
            step={DEFAULT_SLIDER_STEP}
            onSlide={this.handleSliderUpdate}
            onSet={this.handleSliderSet}
          />
          <label className="filter-range-slider-min">
            {formatMessage(defaultMessages.jsCatalogFilterFrom)}
            <CleaveInput
              className="filter-range-slider-input filter-input filter-input-sm"
              value={currentMin}
              onChange={this.handlePriceMinChange}
              onInit={this.handlePriceMinInputInit}
              options={PRICE_INPUT_OPTIONS}
            />
          </label>
          <label className="filter-range-slider-max">
            {formatMessage(defaultMessages.jsCatalogFilterTo)}
            <CleaveInput
              className="filter-range-slider-input filter-input filter-input-sm"
              value={currentMax}
              onChange={this.handlePriceMaxChange}
              onInit={this.handlePriceMaxInputInit}
              options={PRICE_INPUT_OPTIONS}
            />
          </label>
        </div>
      </div>
    );
  }
}

AdCatalogFilterBodyPrices.propTypes = {
  intl: intlShape.isRequired,
  priceMax: PropTypes.number,
  priceMaxLimit: PropTypes.number.isRequired,
  priceMin: PropTypes.number,
  priceMinLimit: PropTypes.number.isRequired,
  updateFilter: PropTypes.func.isRequired,
};

export default AdCatalogFilterBodyPrices;
