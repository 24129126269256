// TODO: убрать от findDomNode
/* eslint-disable react/no-find-dom-node */
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Formsy, { addValidationRule } from 'formsy-react';

import * as paths from '../../constants/paths';
import * as regExps from '../../constants/regExps';
import { defaultMessages } from '../../../../libs/i18n/default';
import { loginByPhone, confirmLoginByPhone } from '../../api/auth';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';
import FlashNotifierService from '../../services/FlashNotifier/FlashNotifier';
import AuthModalSocials from './AuthModalSocials';
import AuthModalInput from './AuthModalInput';
import AuthModalTabs from './AuthModalTabs';
import ReCAPTCHA from 'react-google-recaptcha';

const getTimeDiff = (start, end) => end - start;

// TODO: Нужно заводить storage, откуда брать текущий country
// Тогда можно будет объединить в одно правило
addValidationRule('isRuPhone', (values, value) => {
  return regExps.PHONE_RU.test(value);
});

addValidationRule('isUaPhone', (values, value) => {
  return regExps.PHONE_UA.test(value);
});

addValidationRule('isSmsCode', (values, value) => {
  return regExps.SMS_CODE.test(value);
});

class AuthModalRegisterByPhone extends React.Component {
  state = {
    canSubmit: true,
    isPhoneValid: false,
    isRegistered: false,
    isSmsCodeSend: false,
    isRecaptchaCheck: false,
    smsResendTimer: 0,
  };

  constructor(props) {
    super(props);
    this.setValidationRules();
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    if (this.countdownId) {
      clearTimeout(this.countdownId);
    }

    if (typeof this.requestGettingSmsCodeCancel === 'function') {
      this.requestGettingSmsCodeCancel();
      this.requestGettingSmsCodeCancel = null;
    }

    if (this.requestSendingToServer) {
      this.requestSendingToServer.abort();
      this.requestSendingToServer = null;
    }

    this._isMounted = false;
  }

  getPhoneValue() {
    return this.registerForm ? this.registerForm.getModel().user['phone'] : '';
  }

  getSmsCodeValue() {
    return this.registerForm ? this.registerForm.getModel().user['code'] : '';
  }

  getSmsCode() {
    const {
      intl: { formatMessage },
      toggleLoading,
    } = this.props;

    const phoneNumber = this.getPhoneValue();
    const { recapthaValue } = this.state;

    if (phoneNumber !== '') {
      toggleLoading();

      const [request, cancel] = loginByPhone(phoneNumber, recapthaValue);

      this.requestGettingSmsCodeCancel = cancel;

      request
        .then(response => {
          toggleLoading();

          this.setState({ isSmsCodeSend: true });

          this.countdown(60);

          this.registerFormSmsCode.setValue('');

          ReactDOM.findDOMNode(this.registerFormSmsCode)
            .querySelector('input')
            .focus();
        })
        .catch(errors => {
          toggleLoading();

          if (errors) {
            FlashNotifierService.notifyError(
              formatMessage(
                defaultMessages.jsFlashNotifierErrorHasOccurredCheckConnection,
              ),
            );
          } else {
            this.setState({ isSmsCodeSend: true });
          }
        });
    }
  }

  setValidationRules() {
    const {
      intl: { formatMessage },
      country,
    } = this.props;

    const fieldRequiredMessage = formatMessage(
      defaultMessages.jsAuthModalValidationFieldRequired,
    );
    const fieldWrongFormatMessage = formatMessage(
      defaultMessages.jsAuthModalValidationFieldWrongFormat,
    );

    this.validations = {
      smsCode: {
        isSmsCode: true,
      },
    };

    this.validationErrors = {
      phone: {
        isDefaultRequiredValue: fieldRequiredMessage,
      },
      smsCode: {
        isDefaultRequiredValue: fieldRequiredMessage,
        isSmsCode: fieldWrongFormatMessage,
      },
    };

    switch (country) {
      case 'ua':
        this.validations.phone = {
          isUaPhone: true,
        };
        this.validationErrors.phone.isUaPhone = fieldWrongFormatMessage;
        break;
      default:
        // ru
        this.validations.phone = {
          isRuPhone: true,
        };
        this.validationErrors.phone.isRuPhone = fieldWrongFormatMessage;
    }
  }

  handleLoginByEmailLinkClick = e => {
    const { onChangeForm } = this.props;

    e.preventDefault();

    onChangeForm('loginByEmail');
  };

  handleTabsClick = tab => {
    const { onChangeForm } = this.props;

    switch (tab) {
      case 'email':
        onChangeForm('registerByEmail');
        break;
      case 'phone':
        onChangeForm('registerByPhone');
    }
  };

  handlePhoneValid = () => {
    if (!this._isMounted) {
      return;
    }

    this.setState({ isPhoneValid: true });
  };

  handlePhoneInvalid = () => {
    if (!this._isMounted) {
      return;
    }

    this.setState({ isPhoneValid: false });
  };

  handleCodeValid = () => {
    this.sendToServer();
  };

  handleValid = () => {
    this.enableSubmit();
  };

  handleInvalid = () => {
    this.disableSubmit();
  };

  handleRecaptcha = value => {
    console.log('sada');
    if (value) {
      this.setState({ isRecaptchaCheck: true });
      this.setState({ recapthaValue: value });
    }
  };

  handleSubmit = () => {
    const { isPhoneValid, smsResendTimer } = this.state;

    if (
      (isPhoneValid || this.isPhoneValidOmittingExternalErrors()) &&
      smsResendTimer === 0
    ) {
      this.getSmsCode();
    }
  };

  disableSubmit = () => {
    this.setState({ canSubmit: false });
  };

  enableSubmit = () => {
    this.setState({ canSubmit: true });
  };

  sendToServer = () => {
    const { canSubmit } = this.state;
    const {
      intl: { formatMessage },
      toggleLoading,
      redirectPath,
    } = this.props;
    const model = this.registerForm.getModel();

    if (
      canSubmit ||
      (this.isPhoneValidOmittingExternalErrors() &&
        this.isSmsCodeValidOmittingExternalErrors())
    ) {
      toggleLoading();

      const [request, cancel] = confirmLoginByPhone({
        phone: model.user.phone,
        code: model.user.code.replace(/\s/g, ''),
      });

      this.requestSendingToServerCancel = cancel;

      request
        .then(response => {
          let redirectLocation =
            redirectPath || response.redirect_location || window.location.href;

          toggleLoading();

          if (response['new_user']) {
            this.setState({ isRegistered: true });

            // setTimeout чтобы успеть прочитать сообщение об успешной регистрации
            setTimeout(() => {
              window.location.href = redirectLocation;
            }, 1000);
          } else {
            if (window.location.href === redirectLocation) {
              window.location.reload(true);
            } else {
              window.location.href = redirectLocation;
            }
          }
        })
        .catch(errors => {
          toggleLoading();

          if (errors) {
            if (errors.phone || errors.code) {
              let fieldErrors = {};

              if (errors.phone) {
                fieldErrors['user[phone]'] = errors.phone;
              }

              if (errors.code) {
                fieldErrors['user[code]'] = errors.code;
              }

              this.registerForm.updateInputsWithError(fieldErrors);

              // Т.к. инпуты валидируются только в том случае, если хотя бы раз теряли фокус,
              // мы вручную имитируем потерю фокуса, чтобы сразу вывести ошибку.
              const registerFormSmsCodeInput = ReactDOM.findDOMNode(
                this.registerFormSmsCode,
              ).querySelector('input');

              registerFormSmsCodeInput.blur();
              registerFormSmsCodeInput.focus();
            } else {
              FlashNotifierService.notifyError(
                formatMessage(
                  defaultMessages.jsFlashNotifierErrorHasOccurredCheckConnection,
                ),
              );
            }
          }

          this.enableSubmit();
        });
    }
  };

  countdown(seconds) {
    const startTime = new Date().getTime();
    const endTime = startTime + seconds * 1000;

    const tick = () => {
      this.countdownId = setTimeout(() => {
        const diff = Math.round(
          getTimeDiff(new Date().getTime(), endTime) / 1000,
        );

        if (diff >= 0) {
          this.setState({ smsResendTimer: diff });
        }

        if (diff > 0) {
          tick();
        }
      }, 1000);
    };

    this.setState({
      smsResendTimer: Math.round(getTimeDiff(startTime, endTime) / 1000),
    });
    tick();
  }

  isPhoneValidOmittingExternalErrors() {
    return !this.registerFormPhone.state.validationError.length;
  }

  isSmsCodeValidOmittingExternalErrors() {
    return !this.registerFormSmsCode.state.validationError.length;
  }

  renderSmsCodeItem() {
    const { intl } = this.props;
    const { formatMessage } = intl;

    return (
      <div className="modal-form-item">
        <AuthModalInput
          ref={registerFormSmsCode =>
            (this.registerFormSmsCode = registerFormSmsCode)
          }
          name="user[code]"
          intl={intl}
          placeholder={formatMessage(
            defaultMessages.jsAuthModalSMSCodePlaceholder,
          )}
          required
          type="code"
          validations={this.validations.smsCode}
          validationErrors={this.validationErrors.smsCode}
          value=""
          onValid={this.handleCodeValid}
        />
      </div>
    );
  }

  renderSmsCodeSuccessNote() {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <div className="modal-form-item">
        <div
          className="modal-form-text"
          dangerouslySetInnerHTML={{
            __html: formatMessage(
              defaultMessages.jsAuthModalSMSCodeSuccessNote,
            ),
          }}
        />
      </div>
    );
  }

  renderSmsCodeTimerNote() {
    const { smsResendTimer } = this.state;
    const {
      intl: { formatMessage, formatPlural },
    } = this.props;

    return (
      <div
        className="modal-form-text modal-form-text-gray modal-form-text-under-button"
        dangerouslySetInnerHTML={{
          __html:
            formatMessage(defaultMessages.jsAuthModalSMSCodeTimerNote, {
              time: smsResendTimer,
            }) +
            ' ' +
            formatMessage(
              defaultMessages[
                'jsAuthModalSMSCodeTimerNoteSeconds' +
                  capitalizeFirstLetter(formatPlural(smsResendTimer))
              ],
            ),
        }}
      />
    );
  }

  renderSubmit() {
    const {
      isPhoneValid,
      isSmsCodeSend,
      smsResendTimer,
      isRecaptchaCheck,
    } = this.state;
    const {
      intl: { formatMessage },
    } = this.props;

    const isDisabled =
      !isPhoneValid ||
      (isSmsCodeSend && smsResendTimer > 0) ||
      !isRecaptchaCheck;

    return (
      <button
        className={classNames('button', 'button-green', 'modal-form-submit', {
          ' __disabled': isDisabled,
        })}
        type="submit"
        formNoValidate
        disabled={isDisabled}
      >
        {formatMessage(defaultMessages.jsAuthModalGetSMSCodeButton)}
      </button>
    );
  }

  render() {
    const { isRegistered, isSmsCodeSend, smsResendTimer } = this.state;
    const { intl, country } = this.props;
    const { formatMessage } = intl;
    const recaptchaRef = React.createRef();

    if (!isRegistered) {
      return (
        <Formsy
          ref={registerForm => (this.registerForm = registerForm)}
          className="modal-form"
          acceptCharset="UTF-8"
          method="post"
          onValid={this.handleValid}
          onInvalid={this.handleInvalid}
          onSubmit={this.handleSubmit}
        >
          <div className="modal-form-header">
            <div className="modal-form-title">
              {formatMessage(defaultMessages.jsAuthModalRegisterTitle)}
            </div>
            <div className="modal-form-text">
              <span>
                {formatMessage(defaultMessages.jsAuthModalRegisterTitleText)}{' '}
              </span>
              <a
                href="#"
                className="link link-green"
                onClick={this.handleLoginByEmailLinkClick}
              >
                {formatMessage(defaultMessages.jsAuthModalRegisterTitleLink)}
              </a>
            </div>
          </div>
          <div className="modal-form-content">
            <div className="modal-form-item">
              <AuthModalTabs
                intl={intl}
                active="phone"
                onTabClick={this.handleTabsClick}
              />
            </div>
            <div className="modal-form-item">
              <AuthModalInput
                ref={registerFormPhone =>
                  (this.registerFormPhone = registerFormPhone)
                }
                country={country}
                name="user[phone]"
                intl={intl}
                placeholder={formatMessage(
                  defaultMessages.jsAuthModalPhonePlaceholder,
                )}
                required
                type="tel"
                validations={this.validations.phone}
                validationErrors={this.validationErrors.phone}
                value=""
                onValid={this.handlePhoneValid}
                onInvalid={this.handlePhoneInvalid}
              />
            </div>
            {isSmsCodeSend && this.renderSmsCodeItem()}
            {isSmsCodeSend && this.renderSmsCodeSuccessNote()}
            <ReCAPTCHA
              // use vite to import env variable
              sitekey="6LdxY1ApAAAAAJYgOxqDLSL16pKp-MV8nNA5YhZy"
              ref={recaptchaRef}
              onChange={this.handleRecaptcha}
            />
            <div className="modal-form-item modal-form-item-actions">
              <div className="modal-form-item-cell">
                {this.renderSubmit()}
                {isSmsCodeSend &&
                  smsResendTimer > 0 &&
                  this.renderSmsCodeTimerNote()}
              </div>
              <div className="modal-form-item-cell">
                <div className="modal-form-text modal-form-text-terms">
                  <span>
                    {formatMessage(defaultMessages.jsAuthModalAcceptTermsOfUse)}{' '}
                  </span>
                  <a
                    href={paths.PAGE_AGREEMENT_PATH}
                    className="link"
                    title={formatMessage(
                      defaultMessages.jsAuthModalAcceptTermsOfUseLink,
                    )}
                  >
                    <b>
                      {formatMessage(
                        defaultMessages.jsAuthModalAcceptTermsOfUseLink,
                      )}
                    </b>
                  </a>
                  <span>
                    {' '}
                    {formatMessage(
                      defaultMessages.jsAuthModalAcceptTermsOfUseAndAgree,
                    )}{' '}
                  </span>
                  <a
                    href={paths.PAGE_PERSONAL_DATA_PATH}
                    className="link"
                    title={formatMessage(
                      defaultMessages.jsAuthModalAcceptTermsOfUsePersonalDataLink,
                    )}
                  >
                    <b>
                      {formatMessage(
                        defaultMessages.jsAuthModalAcceptTermsOfUsePersonalDataLink,
                      )}
                    </b>
                  </a>
                </div>
              </div>
            </div>
            {/*<AuthModalSocials intl={intl} type="register" />*/}
          </div>
        </Formsy>
      );
    } else {
      return (
        <div className="modal-message modal-message-success">
          <div
            className="modal-title"
            dangerouslySetInnerHTML={{
              __html: formatMessage(
                defaultMessages.jsAuthModalRegisterSuccessTitle,
              ),
            }}
          />
          <div
            className="modal-text"
            dangerouslySetInnerHTML={{
              __html: formatMessage(
                defaultMessages.jsAuthModalRegisterSuccessText,
              ),
            }}
          />
        </div>
      );
    }
  }
}

AuthModalRegisterByPhone.propTypes = {
  country: PropTypes.string,
  intl: intlShape.isRequired,
  redirectPath: PropTypes.string,
  toggleLoading: PropTypes.func.isRequired,
  onChangeForm: PropTypes.func.isRequired,
};

export default AuthModalRegisterByPhone;
