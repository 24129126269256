import anime from 'animejs';
import classNames from 'classnames';
import React from 'react';

import styles from './AboutAssistantButton.scss';

class AboutAssistantButton extends React.PureComponent {
  constructor(props) {
    super(props);

    this.containerRef = React.createRef();
    this.buttonRef = React.createRef();
  }

  componentDidMount() {
    if (this.containerRef.current) {
      anime({
        targets: this.containerRef.current,
        // height: [0, this.buttonRef.current.offsetHeight + 50],
        opacity: [0, 1],
        duration: 300,
        easing: 'easeInOutSine',
      });
    }
  }

  render() {
    return (
      <div className={styles.aboutButtonContainer} ref={this.containerRef}>
        <a
          href="/plans"
          className={classNames('button', styles.buttonGreen)}
          ref={this.buttonRef}
        >
          Рассказать про квартиру мечты
        </a>
      </div>
    );
  }
}

export default AboutAssistantButton;
