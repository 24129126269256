/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import Modal from 'react-flex-modal';

import { servicesApi } from '../../api/services';
import { CheckboxList, Checkbox } from '../Checkbox';
import FlashNotifierService from '../../services/FlashNotifier/FlashNotifier';
import styles from './styles.scss';

const OPTIONS = [
  {
    value: 'Актуальность объявления',
    title: 'Актуальность объявления',
  },
  {
    value: 'Дата ближайшего просмотра',
    title: 'Дата ближайшего просмотра',
  },
  {
    value: 'Можно ли жить с питомцем',
    title: 'Можно ли жить с питомцем',
  },
];

class VerificationRequest extends React.Component {
  state = {
    comments: '',
    activeOptions: [],
    isModalOpen: false,
    isOtherClicked: false,
    commentsMinimized: false,
  };

  constructor(props) {
    super(props);

    this.firstCheckboxRef = React.createRef();
  }

  componentDidMount() {
    if (!DeviceSupports.touch) {
      this.firstCheckboxRef.current && this.firstCheckboxRef.current.focus();
    }
  }

  handleCommentsChange = event => {
    this.setState({ comments: event.target.value });
  };

  handleCommentsBlur = event => {
    event.preventDefault();

    this.setState({ commentsMinimized: true });
  };

  handleMinimizedClick = () => {
    this.setState({ commentsMinimized: false });
  };

  closeModal = () => {
    this.setState({
      comments: '',
      activeOptions: [],
      isModalOpen: false,
      isOtherClicked: false,
    });
  };

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  handleOptionChange = values => {
    this.setState({ activeOptions: values });
  };

  handleOtherClick = () => {
    this.setState({ isOtherClicked: true });
  };

  handleCheckMobileClick = () => {
    this.setState({ isOtherClicked: false });
  };

  submitForm = event => {
    const { comments, activeOptions } = this.state;
    const { id } = this.props;

    event.preventDefault();

    servicesApi
      .adModerationRequest({
        id,
        comments:
          (activeOptions.length > 0 ? activeOptions.join('. ') + '. ' : '') +
          comments,
      })
      .then(data => {
        this.closeModal();

        FlashNotifierService.notifyBlack('Отправлено помощнику на проверку');

        window.location.reload(true);
      })
      .catch(() => {
        FlashNotifierService.notifyError('Произошла ошибка');
      });
  };

  handleSubmitClick = event => {
    event.preventDefault();
  };

  render() {
    const {
      isModalOpen,
      isOtherClicked,
      comments,
      activeOptions,
      commentsMinimized,
    } = this.state;

    const isCommentsFilled = comments.length > 0;
    const isActiveOptionsEmpty = activeOptions.length === 0;

    return (
      <React.Fragment>
        <button
          className="Offer__detailsNoteButton button"
          type="button"
          onClick={this.openModal}
        >
          Запросить проверку
        </button>
        <Modal
          transition
          modalClass={styles.modal}
          isOpen={isModalOpen}
          onClose={this.closeModal}
        >
          <Media query="(max-width: 767px)" onChange={this.handleMediaChange}>
            {isMobile =>
              isMobile && isOtherClicked ? (
                <React.Fragment>
                  <textarea
                    className={styles.modalTextareaMobile}
                    placeholder="Напишите, что еще вы хотите узнать"
                    value={comments}
                    onChange={this.handleCommentsChange}
                  />
                  <button
                    className={styles.checkMobileButton}
                    type="button"
                    disabled={!isCommentsFilled && isActiveOptionsEmpty}
                    onClick={this.handleCheckMobileClick}
                  >
                    Проверить
                  </button>
                </React.Fragment>
              ) : (
                <form onSubmit={this.submitForm}>
                  <h5 className={styles.modalTitle}>
                    Отметьте, что&nbsp;вы&nbsp;хотите&nbsp;узнать
                  </h5>
                  <div className={styles.checkboxContainer}>
                    <CheckboxList
                      values={OPTIONS}
                      activeValues={activeOptions}
                      onChange={this.handleOptionChange}
                    />
                    {isMobile && isCommentsFilled && (
                      <Checkbox
                        className={styles.otherButtonMobile}
                        title={comments}
                        value={comments}
                        isChecked
                        onChange={this.handleOtherClick}
                      />
                    )}
                    {!isMobile && (
                      <textarea
                        className={classNames(styles.modalTextarea, {
                          [styles.hidden]: !isOtherClicked || commentsMinimized,
                        })}
                        placeholder="Напишите, что еще вы хотите узнать"
                        value={comments}
                        onBlur={this.handleCommentsBlur}
                        onChange={this.handleCommentsChange}
                      />
                    )}
                    {!isMobile && commentsMinimized && (
                      <Checkbox
                        className={styles.otherButtonMinimized}
                        title={isCommentsFilled ? comments : 'Другое'}
                        value={isCommentsFilled ? comments : 'Другое'}
                        isChecked={isCommentsFilled}
                        onChange={this.handleMinimizedClick}
                      />
                    )}
                    {((!isMobile && !commentsMinimized && !isOtherClicked) ||
                      (isMobile && !isCommentsFilled)) && (
                      <Checkbox
                        title="Другое"
                        value="Другое"
                        onChange={this.handleOtherClick}
                      />
                    )}
                  </div>
                  <div className={styles.modalCta}>
                    <button
                      className={classNames(
                        styles.actionButton,
                        'Button',
                        'Button--green',
                      )}
                      type="submit"
                      onMouseDown={this.handleSubmitClick}
                      disabled={!isCommentsFilled && isActiveOptionsEmpty}
                    >
                      Отправить на проверку
                    </button>
                  </div>
                  <p className={styles.modalNotice}>
                    Отметьте, что вы хотите узнать. В будни помощник отвечает в
                    течение 1-2 часов. В&nbsp;выходные и праздники он отдыхает,
                    но ответит сразу же, как начнет работать.
                  </p>
                </form>
              )
            }
          </Media>
        </Modal>
      </React.Fragment>
    );
  }
}

VerificationRequest.propTypes = {
  id: PropTypes.number.isRequired,
};

export default VerificationRequest;
