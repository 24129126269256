/* eslint-disable jsx-a11y/alt-text */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '../Button';
import styles from './styles.scss';

const AdCatalogBanner = ({
  type,
  imageSrc,
  imageWidth,
  imageHeight,
  title,
  notice,
  submitText,
  href,
  onSubmit,
}) => {
  return (
    <div
      className={classNames(styles.container, {
        [styles[`container-${type}`]]: true,
      })}
    >
      <img
        className={styles.image}
        src={imageSrc}
        width={imageWidth}
        height={imageHeight}
      />

      <div className={styles.content}>
        <div className={styles.titleContainer}>
          <p
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </div>

        <p
          className={styles.notice}
          dangerouslySetInnerHTML={{ __html: notice }}
        />

        <div className={styles.buttonContainer}>
          {submitText ? (
            <Button className={styles.button} href={href} onClick={onSubmit}>
              {submitText}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

AdCatalogBanner.propTypes = {
  href: PropTypes.string,
  imageHeight: PropTypes.number,
  imageSrc: PropTypes.string,
  imageWidth: PropTypes.number,
  notice: PropTypes.string,
  submitText: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf(['filter', 'whatsapp', 'access', 'search']),
  onSubmit: PropTypes.func,
};

export default AdCatalogBanner;
