/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal from 'react-flex-modal';
import Select from 'react-select';

import { defaultMessages } from '../../../../../libs/i18n/default';
import TranslationProvider from '../../../../common/components/HOC/TranslationProvider';
import MetroSchemeModalScheme from '../../MetroSchemeModal/MetroSchemeModalScheme';
import filterOptions from '../../../../common/helpers/ReactSelect/defaultFilterOptions';
import MetroOption from '../../../../common/helpers/ReactSelect/metroOptionRenderer';
import styles from '../styles.scss';

const SubwaySelect = ({
  intl,
  city,
  subways,
  isSubwaysLoading,
  subwayIds,
  onChange,
  onRemove,
}) => {
  const [isMapLoading, setIsMapLoading] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleMetroSchemeButtonClick = React.useCallback(() => {
    setIsModalOpen(true);
  });

  const handleCloseModal = React.useCallback(() => {
    setIsModalOpen(false);
  });

  const toggleMapLoading = React.useCallback(() => {
    setIsMapLoading(isLoading => !isLoading);
  });

  const getSortedSelectValue = () => {
    if (subways) {
      return subways
        .filter(option => subwayIds.indexOf(option.value) !== -1)
        .map(option => option.value);
    }

    return [];
  };

  return (
    <React.Fragment>
      <div className={styles.geoSubwaySelectContainer}>
        <Select
          multi
          arrowRenderer={null}
          clearable={false}
          disabled={isSubwaysLoading}
          filterOptions={filterOptions}
          isLoading={isSubwaysLoading}
          noResultsText={intl.formatMessage(
            defaultMessages.jsReactSelectNoResults,
          )}
          options={subways}
          optionRenderer={MetroOption}
          placeholder={intl.formatMessage(
            defaultMessages.jsCatalogFilterTypeTheSubwayName,
          )}
          value={getSortedSelectValue()}
          onChange={onChange}
        />
        <div
          className="filter-metro-scheme-button"
          onClick={handleMetroSchemeButtonClick}
        >
          <div className="filter-metro-scheme-button-icon" />
          <div className="filter-metro-scheme-button-text">
            {intl.formatMessage(
              defaultMessages.jsCatalogFilterSelectInTheScheme,
            )}
          </div>
          {subwayIds && subwayIds.length && (
            <div className="filter-metro-scheme-button-count">
              {subwayIds.length}
            </div>
          )}
        </div>
      </div>
      <Modal
        transition
        isOpen={isModalOpen}
        modalClass={styles.areaMapModal}
        modalDialogClass={styles.subwayMapModalDialog}
        onClose={handleCloseModal}
      >
        <div className={classNames(styles.areaMap, styles.subwayMap)}>
          <MetroSchemeModalScheme
            city={city}
            selectedStations={subwayIds}
            toggleLoading={toggleMapLoading}
            updateOffers={onChange}
            onClose={handleCloseModal}
          />
          {isMapLoading && (
            <div className={styles.subwayMapSpinnerContainer}>
              <span className="spinner" />
            </div>
          )}
        </div>
      </Modal>
    </React.Fragment>
  );
};

SubwaySelect.propTypes = {
  city: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  isSubwaysLoading: PropTypes.bool.isRequired,
  subwayIds: PropTypes.array.isRequired,
  subways: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default TranslationProvider(SubwaySelect);
