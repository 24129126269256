import React from 'react';

import * as cookieKeys from '../../constants/cookieKeys';
import { LANDING_SEARCH_ASSISTANT_PATH } from '../../constants/paths';
import Cookies from '../../../common/utils/Cookies';
import SvgIcon from '../SvgIcon/SvgIcon';
import styles from './WorkingTimeAlerts.scss';

class WorkingTimeAlert extends React.Component {
  state = {
    isVisible: false,
  };

  componentDidMount() {
    if (
      !Cookies.get(cookieKeys.BROKEN_IMAGES_HIDDEN) &&
      window.showGlobalAlert
    ) {
      this.setState({ isVisible: true });
    }
  }

  handleCloseClick = () => {
    this.close();
  };

  close() {
    this.setState({ isVisible: false });

    Cookies.set(cookieKeys.BROKEN_IMAGES_HIDDEN, true, 90);
  }

  render() {
    const { isVisible } = this.state;

    return (
      isVisible && (
        <div className={styles.alert}>
          <a className={styles.link} href={LANDING_SEARCH_ASSISTANT_PATH}>
            <div className={styles.inner}>
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    'Личные помощники работают в праздники. <b><a href="/plans">Оставить заявку</a></b>',
                }}
              />
            </div>
          </a>
          <button
            className={styles.buttonClose}
            type="button"
            onClick={this.handleCloseClick}
          >
            <SvgIcon icon="cross" />
          </button>
        </div>
      )
    );
  }
}

export default WorkingTimeAlert;
