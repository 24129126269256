import axios from './axios';

const applePayMerchantValidation = ({ validationUrl }) => {
  return axios
    .post('/api/frontend/yandex_kassa/apple_pay/session', { validationUrl })
    .then(({ data }) => data)
    .catch(({ response: { data: { errors } } }) => Promise.reject(errors));
};

const applePayPayment = ({ paymentId, token }) => {
  return axios
    .post(`/api/frontend/yandex_kassa/apple_pay/payment/${paymentId}/pay`, {
      token,
    })
    .then(({ data }) => data)
    .catch(({ response: { data: { errors } } }) => Promise.reject(errors));
};

export const yandexKassaApi = {
  applePayMerchantValidation,
  applePayPayment,
};

export default yandexKassaApi;
