import React from 'react';
import PropTypes from 'prop-types';

class AdCatalogSEO extends React.PureComponent {
  renderQueries() {
    const { queries } = this.props;

    const len = queries.length;

    const nodes = queries.map((query, index) => {
      return (
        <span key={query}>
          <b>{query}</b>
          {index < len - 1 ? ', ' : null}
        </span>
      );
    });

    return (
      <div className="catalog-seo-grid-col catalog-seo-grid-col-left">
        <div className="catalog-seo-grid-col-inner">
          <p>Страницу находят по запросам: {nodes}</p>
        </div>
      </div>
    );
  }

  render() {
    const { text, queries } = this.props;

    return (
      <div className="catalog-seo">
        <div className="catalog-seo-grid">
          {queries && queries.length > 0 && this.renderQueries()}
          {text && (
            <div className="catalog-seo-grid-col catalog-seo-grid-col-right">
              <div className="catalog-seo-grid-col-inner">
                <p>{text}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

AdCatalogSEO.propTypes = {
  queries: PropTypes.array,
  text: PropTypes.string,
};

export default AdCatalogSEO;
