import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

import { defaultMessages } from '../../../../libs/i18n/default';
import setRailsContext from '../../../common/utils/setRailsContext';
import TranslationProvider from '../../../common/components/HOC/TranslationProvider';
import Modal from '../Modal/Modal';

const SUPPORT_EMAIL = 'hello@thelocals.ru';
const SUPPORT_PHONE = '+7 (800) 333-13-68';
const SUPPORT_PHONE_TEL = '+78003331368';

const emailLink = `<a class="link link-green" href="mailto:${SUPPORT_EMAIL}" title="Связаться с нами по почте">${SUPPORT_EMAIL}</a>`;

const phoneLink = `<a class="link link-green" href="tel:${SUPPORT_PHONE_TEL}" title="Связаться с нами по телефону">${SUPPORT_PHONE}</a>`;

class AdAfterCreateModal extends React.PureComponent {
  handleClose = e => {
    ReactDOM.unmountComponentAtNode(document.getElementById('modals'));
  };

  answerPeriod() {
    const { featured } = this.props;

    if (featured) {
      return 'суток';
    } else {
      return 'часа';
    }
  }

  render() {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <Modal handleClose={this.handleClose} containerClass="modal-info-create">
        <div className="modal-message modal-message-success">
          <div
            className="modal-title"
            dangerouslySetInnerHTML={{
              __html: formatMessage(defaultMessages.jsAfterCreateModalReady),
            }}
          />
          <div
            className="modal-text"
            dangerouslySetInnerHTML={{
              __html: formatMessage(
                defaultMessages.jsAfterCreateModalSentForModeration,
              ),
            }}
          />
          <ul className="modal-list">
            <li
              dangerouslySetInnerHTML={{
                __html: formatMessage(
                  defaultMessages.jsAfterCreateModalTimeContact,
                ),
              }}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: formatMessage(
                  defaultMessages.jsAfterCreateModalAdChanging,
                ),
              }}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: formatMessage(
                  defaultMessages.jsAfterCreateModalPublicationNotice,
                ),
              }}
            />
          </ul>
          <div
            className="modal-text"
            dangerouslySetInnerHTML={{
              __html: formatMessage(
                defaultMessages.jsAfterCreateModalContactUs,
                { email: emailLink, phone: phoneLink },
              ),
            }}
          />
        </div>
      </Modal>
    );
  }
}

AdAfterCreateModal.propTypes = {
  featured: PropTypes.string,
  intl: intlShape.isRequired,
};

export default setRailsContext(TranslationProvider(AdAfterCreateModal));
