import axios from './axios';

export const fetchAdHistory = id => {
  return axios
    .get(`/api/admin/ads/${id}/logs`)
    .then(({ data }) => data)
    .catch(({ response: { data: { errors } } }) => Promise.reject(errors));
};

export const fetchPriceHistory = id => {
  return axios
    .get(`/api/admin/ads/${id}/price_history`)
    .then(({ data }) => data)
    .catch(({ response: { data: { errors } } }) => Promise.reject(errors));
};

export const fetchContactViewsHistory = id => {
  return axios
      .get(`/api/admin/ads/${id}/contact_views`)
      .then(({ data }) => data)
      .catch(({ response: { data: { errors } } }) => Promise.reject(errors));
};
