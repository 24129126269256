/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Nouislider from 'nouislider-react';
import debounce from 'lodash/debounce';

import { FIELD_NAMES, OWNER_VALUES } from '../constants';
import Switcher from '../Switcher';
import CustomInput from '../CustomInput';
import formatPrice from '../../../utils/formatPrice';
import styles from '../styles.scss';

const INPUT_OPTIONS = {
  numeral: true,
  numeralThousandsGroupStyle: 'thousand',
  delimiter: ' ',
};

const DEFAULT_SLIDER_STEP = 1;
const COMMISSION_MIN = 0;
const COMMISSION_MAX = 100;
const RANGE = {
  min: [COMMISSION_MIN],
  max: [COMMISSION_MAX],
};

function StepOwner({ owner, commission, errors, setMainValues, goToNextStep }) {
  const debouncedInputChange = React.useRef(null);
  const [commissionValue, setCommissionValue] = React.useState(commission);
  const [sliderCommission, setSliderCommission] = React.useState(commission);

  const handleOwnerChange = value => {
    setMainValues({ [FIELD_NAMES.OWNER]: value });
  };

  const isValid = !errors[FIELD_NAMES.OWNER];

  const handleInputChange = React.useCallback(
    rawValue => {
      if (debouncedInputChange && debouncedInputChange.current) {
        debouncedInputChange.current.cancel();
      }

      debouncedInputChange.current = debounce(() => {
        setSliderCommission(sliderPrice =>
          rawValue !== sliderPrice ? rawValue : sliderPrice,
        );

        setMainValues({ [FIELD_NAMES.PRICE]: rawValue });
      }, 500);

      return debouncedInputChange.current();
    },
    [commissionValue],
  );

  const handleSliderUpdate = React.useCallback(
    (render, handle, value, un, percent) => {
      setCommissionValue(parseInt(value[0], 10));
    },
  );

  const handleSliderSet = React.useCallback(
    (render, handle, value, un, percent) => {
      setMainValues({ [FIELD_NAMES.COMMISSION]: parseInt(value[0], 10) });
    },
  );

  const handleSubmitClick = () => {
    if (!isValid) {
      return null;
    }

    goToNextStep();
  };

  return (
    <React.Fragment>
      <div className={classNames(styles.inner, styles.innerTopbarSpace)}>
        <div className={classNames(styles.title, styles['title--mb25'])}>
          Рассмотрю объявления
        </div>
        <div className={classNames(styles.formItem, styles['formItem--mb30'])}>
          <Switcher
            values={OWNER_VALUES}
            initialActiveValue={owner}
            onChange={handleOwnerChange}
          />
        </div>
        <div
          className={classNames(styles.formPartTransition, {
            [styles.hidden]: owner !== 'agent',
          })}
        >
          <div className={styles.formLabel}>Максимальная комиссия</div>
          <div
            className={classNames(
              styles.rangeSlider,
              styles.rangeSliderCommission,
            )}
          >
            <CustomInput
              value={commissionValue}
              min={COMMISSION_MIN}
              max={COMMISSION_MAX}
              suffix="%"
              isValid={isValid}
              options={INPUT_OPTIONS}
              formatFunction={formatPrice}
              onChange={handleInputChange}
            />
            <Nouislider
              id="commission-range-slider"
              key="commission-range-slider"
              className={styles.rangeSlider}
              range={RANGE}
              start={sliderCommission}
              connect={[true, false]}
              step={DEFAULT_SLIDER_STEP}
              onSlide={handleSliderUpdate}
              onSet={handleSliderSet}
            />
          </div>
        </div>
      </div>
      <div className={styles.cta}>
        <div className={styles.buttonWrapper}>
          <button
            className={styles.button}
            type="button"
            disabled={!isValid}
            onClick={handleSubmitClick}
          >
            Дальше
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

StepOwner.propTypes = {
  commission: PropTypes.number.isRequired,
  errors: PropTypes.object.isRequired,
  goToNextStep: PropTypes.func.isRequired,
  owner: PropTypes.string.isRequired,
  setMainValues: PropTypes.func.isRequired,
};

export default StepOwner;
