import { intlShape } from 'react-intl';
import React from 'react';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { defaultMessages } from '../../../../libs/i18n/default';
import { FILTER_KEYS } from './AdCatalog.constants';

class AdCatalogFilterBodyRouteType extends React.PureComponent {
  onChange = selectedOption => {
    const { routeTime, updateFilter } = this.props;

    let nextRoute = {
      [FILTER_KEYS.ROUTE_TYPE]: parseInt(selectedOption.value),
    };

    if (
      !isNil(nextRoute[FILTER_KEYS.ROUTE_TYPE]) &&
      nextRoute[FILTER_KEYS.ROUTE_TYPE] === 0
    ) {
      if (!isNil(routeTime)) {
        nextRoute[FILTER_KEYS.ROUTE_TIME] = null;
      }

      nextRoute[FILTER_KEYS.ROUTE_TYPE] = null;
    }

    updateFilter(nextRoute);
  };

  render() {
    const {
      routeType = '0',
      intl: { formatMessage },
    } = this.props;

    return (
      <div className="filter-row">
        <div className="filter-label">
          {formatMessage(defaultMessages.jsCatalogFilterDistanceFromSubway)}
        </div>
        <Select
          value={routeType}
          options={[
            {
              value: '0',
              label: formatMessage(
                defaultMessages.jsCatalogFilterRouteTypeNoMatter,
              ),
            },
            {
              value: '1',
              label: formatMessage(
                defaultMessages.jsCatalogFilterRouteTypeOnFoot,
              ),
            },
            {
              value: '2',
              label: formatMessage(
                defaultMessages.jsCatalogFilterRouteTypeTransport,
              ),
            },
          ]}
          clearable={false}
          searchable={false}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

AdCatalogFilterBodyRouteType.propTypes = {
  intl: intlShape.isRequired,
  routeTime: PropTypes.number,
  routeType: PropTypes.number,
  updateFilter: PropTypes.func.isRequired,
};

export default AdCatalogFilterBodyRouteType;
