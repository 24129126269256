import { intlShape } from 'react-intl';
import React from 'react';

import { defaultMessages } from '../../../../libs/i18n/default';
import toCamelCase from '../../utils/toCamelCase';

const getDisplayName = Component =>
  Component.displayName || Component.name || 'Component';

const NOT_ACTIVE_ELEMENT_SELECTOR =
  '.footnote:not(.footnote-static):not(.__active)';

export default function(EnhancedComponent, attrName) {
  return class extends React.Component {
    static displayName = `AdFormFootnote(${getDisplayName(EnhancedComponent)})`;

    static propTypes = {
      intl: intlShape.isRequired,
    };

    constructor(props) {
      super(props);

      this.state = {
        attrName,
        footnote: false,
      };
    }

    showFootnote = () => {
      this.setState({ footnote: true });
      // hotfix
      const notActiveElementList = document.querySelectorAll(
        NOT_ACTIVE_ELEMENT_SELECTOR,
      );

      if (notActiveElementList) {
        notActiveElementList.forEach(element => element.classList.add('__out'));
      }
    };

    hideFootnote = () => {
      this.setState({ footnote: false });
      // hotfix
      const notActiveElementList = document.querySelectorAll(
        NOT_ACTIVE_ELEMENT_SELECTOR,
      );

      if (notActiveElementList) {
        notActiveElementList.forEach(element =>
          element.classList.remove('__out'),
        );
      }
    };

    bigFootnote = () => {
      const { footnote } = this.state;

      if (footnote) {
        return (
          <div className="footnote __active">{this.footNoteContent()}</div>
        );
      } else {
        return null;
      }
    };

    smallFootnote = () => {
      const { footnote } = this.state;

      if (footnote) {
        return (
          <div className="footnote footnote-static hidden-lg">
            {this.footNoteContent()}
          </div>
        );
      } else {
        return null;
      }
    };

    footNoteContent() {
      const { attrName } = this.state;
      const {
        intl: { formatMessage },
      } = this.props;

      return (
        <div
          className="footnote-content"
          dangerouslySetInnerHTML={{
            __html: formatMessage(
              defaultMessages[`jsAdForm${toCamelCase(attrName)}Footnote`],
            ),
          }}
        />
      );
    }

    render() {
      return (
        <EnhancedComponent
          {...this.props}
          showFootnote={this.showFootnote}
          hideFootnote={this.hideFootnote}
          bigFootnote={this.bigFootnote}
          smallFootnote={this.smallFootnote}
        />
      );
    }
  };
}
