/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import { defaultMessages } from '../../../../libs/i18n/default';
import { DEAL_TYPES } from '../AdCatalog/AdCatalog.constants';
import AuthModal from '../AuthModal/AuthModal';
import setRailsContext from '../../../common/utils/setRailsContext';
import TranslationProvider from '../../../common/components/HOC/TranslationProvider';
import AdContactsModal from '../AdContactsModal/AdContactsModal';
import AdContactsRatingModal from '../AdContactsRatingModal/AdContactsRatingModal';
import {
  LANDING_SEARCH_ASSISTANT_PATH,
  LANDING_SALE_SEARCH_ASSISTANT_PATH,
} from '../../constants/paths';

class AdContactsButton extends React.Component {
  state = {
    isContactsOpen: false,
    isContactsRatingOpen: false,
  };

  handleClick = () => {
    const { id, kind, dealType } = this.props;

    document.cookie = `visitedAdId=${id}`;
    this.openModal(id, kind, dealType);
  };

  handleContactsClose = () => {
    const { id } = this.props;

    let isContactsRatingOpen = true;

    if (DeviceSupports.localStorage) {
      const sendedIds = JSON.parse(
        localStorage.getItem('sendedAdContactsRating'),
      );

      if (Array.isArray(sendedIds) && sendedIds.indexOf(id) !== -1) {
        isContactsRatingOpen = false;
      }
    }

    this.setState({
      isContactsOpen: false,
      isContactsRatingOpen: isContactsRatingOpen,
    });
  };

  handleContactsRatingClose = () => {
    this.setState({ isContactsRatingOpen: false });
  };

  openModal = (id, kind, dialType) => {
    let error;
    try {
      ga('send', 'event', 'apartment', 'open_contacts');
    } catch (_error) {
      error = _error;
    }

    try {
      localStorage.setItem('offerId' + id + '_isViewed', true);
    } catch (_error) {
      error = _error;
    }

    switch (kind) {
      case 'register':
        let authModal = React.createElement(AuthModal, {
          form: 'registerByEmail',
          redirectPath: window.location.href,
        });
        ReactDOM.render(authModal, document.getElementById('modals'));
        break;
      case 'plans':
        if (dialType === DEAL_TYPES.RENT || dialType === DEAL_TYPES.DAILY_RENT) {
          window.location = LANDING_SEARCH_ASSISTANT_PATH;
        } else {
          window.location = LANDING_SALE_SEARCH_ASSISTANT_PATH;
        }
        break;
      case 'assistance':
        break;
      case 'contacts':
        this.setState({ isContactsOpen: true });
        break;
    }
  };

  buttonText() {
    const {
      dealType,
      from_owner: fromOwner,
      intl: { formatMessage },
    } = this.props;

    if (dealType === DEAL_TYPES.RENT || dealType === DEAL_TYPES.DAILY_RENT) {
      if (fromOwner) {
        return formatMessage(defaultMessages.jsContactsButtonOwnerPhoneNumber);
      } else {
        return formatMessage(defaultMessages.jsContactsButtonShowPhoneNumber);
      }
    } else if (dealType === DEAL_TYPES.SALE) {
      return 'Телефон';
    }
  }

  render() {
    const { isContactsRatingOpen, isContactsOpen } = this.state;
    const { id } = this.props;

    return (
      <React.Fragment>
        <a
          id="ad_contacts_button"
          className="button button-large button-contacts button-orange"
          onClick={this.handleClick}
        >
          {this.buttonText()}
        </a>
        <AdContactsModal
          id={id}
          isOpen={isContactsOpen}
          onClose={this.handleContactsClose}
        />
        <AdContactsRatingModal
          id={id}
          isOpen={isContactsRatingOpen}
          onClose={this.handleContactsRatingClose}
        />
      </React.Fragment>
    );
  }
}

AdContactsButton.propTypes = {
  dealType: PropTypes.string,
  from_owner: PropTypes.bool,
  id: PropTypes.number,
  intl: intlShape.isRequired,
  kind: PropTypes.string,
};

export default setRailsContext(TranslationProvider(AdContactsButton));
