import React from 'react';
import PropTypes from 'prop-types';
import DynamicNumber from 'react-dynamic-number';

import AdFormInputHOC from './AdFormInputMixin';

const AdFormInputNumeric = React.memo(function({
  iconComponent = null,
  name = null,
  value = null,
  placeholder = null,
  fraction = 0,
  integer = null,
  wrapperClass,
  onChange,
}) {
  return (
    <div className={wrapperClass('input')}>
      {iconComponent}
      <DynamicNumber
        negative={false}
        className="input-control"
        name={name}
        onChange={onChange}
        value={value === null ? '' : value}
        placeholder={placeholder}
        fraction={fraction}
        integer={integer}
        thousand=" "
      />
    </div>
  );
});

AdFormInputNumeric.propTypes = {
  fraction: PropTypes.number,
  iconComponent: PropTypes.node,
  integer: PropTypes.any,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  wrapperClass: PropTypes.func,
  onChange: PropTypes.func,
};

export default AdFormInputHOC(AdFormInputNumeric);
