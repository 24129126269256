import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import AdCatalogHeaderBreadcrumbsItem from './AdCatalogHeaderBreadcrumbsItem';

class AdCatalogHeaderBreadcrumbs extends React.Component {
  renderBreadcrumbs() {
    const {
      intl: { locale, formatMessage },
      breadcrumbs,
    } = this.props;

    let length = breadcrumbs.length;

    if (locale === 'ru' && length > 0) {
      return breadcrumbs.map(({ id, title, href }, i) => {
        return (
          <AdCatalogHeaderBreadcrumbsItem
            key={id}
            title={title}
            href={length === i + 1 ? null : href}
          />
        );
      });
    } else {
      return (
        <AdCatalogHeaderBreadcrumbsItem
          title={formatMessage(defaultMessages.jsCatalogBreadcrumbsSearch)}
        />
      );
    }
  }

  render() {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <div className="breadcrumbs">
        <ul className="breadcrumbs-list">
          <AdCatalogHeaderBreadcrumbsItem
            title={formatMessage(defaultMessages.jsCatalogBreadcrumbsHome)}
            href="/"
          />
          {this.renderBreadcrumbs()}
        </ul>
      </div>
    );
  }
}

AdCatalogHeaderBreadcrumbs.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  intl: intlShape.isRequired,
};

export default AdCatalogHeaderBreadcrumbs;
