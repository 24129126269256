/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

const AdFormRadio = React.memo(function({
  icon,
  id = null,
  name = null,
  onChange,
  required,
  checked,
  value,
  text,
}) {
  let iconNode = null;

  if (icon) {
    iconNode = <i className={`radio-icon radio-icon-${icon}`} />;
  }

  return (
    <label className="radio radio-flat">
      <input
        id={id}
        className="radio-control"
        type="radio"
        name={name}
        required={required}
        checked={checked}
        value={value}
        onChange={onChange}
      />
      <span className="radio-content">
        {iconNode}
        <span className="radio-text">{text}</span>
      </span>
    </label>
  );
});

AdFormRadio.propTypes = {
  checked: PropTypes.bool,
  icon: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  text: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default AdFormRadio;
