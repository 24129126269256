import React from 'react';
import PropTypes from 'prop-types';

import setRailsContext from '../../../common/utils/setRailsContext';
import Cards from '../Cards/Cards';
import OfferCard from '../OfferCard/OfferCard';

class OfferCards extends React.Component {
  constructor(props) {
    super(props);

    const { ads } = this.props;

    this.state = {
      ads,
    };
  }

  // TODO: Проверить
  onUnfavorite = id => {
    // Для избранного в кабинете пользователся
    // TODO: Перебрать
    const { ads } = this.state;
    const { shouldReloadAfterOnUnfavorite } = this.props;

    if (shouldReloadAfterOnUnfavorite) {
      const nextAds = ads.filter(ad => ad.id !== id);

      this.setState({ ads: nextAds });

      if (nextAds === 0) {
        window.location.reload(true);
      }
    }
  };

  render() {
    const { ads } = this.state;
    const {
      isUserLoggedIn,
      isStatic,
      isCompactStatic,
      showViewedLabels,
      railsContext: { i18nLocale },
    } = this.props;

    return (
      <Cards>
        {ads.length > 0 &&
          ads.map(ad => {
            return (
              <div key={ad.id} className="Cards__item">
                <OfferCard
                  key={ad.id}
                  ad={ad}
                  isUserLoggedIn={isUserLoggedIn}
                  isStatic={isStatic}
                  isCompactStatic={isCompactStatic}
                  showViewedLabel={showViewedLabels}
                  onUnfavorite={this.onUnfavorite}
                  locale={i18nLocale}
                />
              </div>
            );
          })}
      </Cards>
    );
  }
}

OfferCards.propTypes = {
  ads: PropTypes.array.isRequired,
  isCompactStatic: PropTypes.bool,
  isStatic: PropTypes.bool,
  isUserLoggedIn: PropTypes.bool,
  railsContext: PropTypes.object,
  shouldReloadAfterOnUnfavorite: PropTypes.bool,
  showViewedLabels: PropTypes.bool,
};

OfferCards.defaultProps = {
  isCompactStatic: false,
  isStatic: false,
  shouldReloadAfterOnUnfavorite: false,
};

export default setRailsContext(OfferCards);
