/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import styles from './Tab.scss';

class Tab extends React.Component {
  handleClick = () => {
    const { label, onClick } = this.props;

    onClick(label);
  };

  render() {
    const { activeTab, label, className } = this.props;

    return (
      <li
        className={classNames(styles.item, {
          [className]: className !== null,
          [styles.itemActive]: activeTab === label,
        })}
        onClick={this.handleClick}
      >
        {label}
      </li>
    );
  }
}

Tab.propTypes = {
  activeTab: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

Tab.defaultProps = {
  className: null,
};

export default Tab;
