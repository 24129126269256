import React from 'react';
import PropTypes from 'prop-types';

import MapAreaDraw from '../MapAreaDraw/MapAreaDraw';

const ChooseGeoArea = ({ city, geoAreas, onClose, onChange }) => {
  const [isOpenAreaMap, setIsOpenAreaMap] = React.useState(
    geoAreas.length === 0,
  );

  React.useEffect(() => {
    document.documentElement.classList.toggle('areaMapOpened', isOpenAreaMap);
  }, [isOpenAreaMap]);

  const handleAreaButtonClick = () => {
    setIsOpenAreaMap(true);
  };

  const handleClose = newValues => {
    setIsOpenAreaMap(false);

    onClose && onClose(newValues);
  };

  return (
    <MapAreaDraw
      isOpen={isOpenAreaMap}
      city={city}
      geoAreas={geoAreas}
      onUpdate={onChange}
      onChangeButtonClick={handleAreaButtonClick}
      onClose={handleClose}
    />
  );
};

ChooseGeoArea.propTypes = {
  city: PropTypes.object.isRequired,
  geoAreas: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

export default ChooseGeoArea;
