import React from 'react';
import PropTypes from 'prop-types';

import MetroSchemeModalSchemeMapSVG from './MetroSchemeModalSchemeMapSVG';
import MetroSchemeModalSchemeMapStations from './MetroSchemeModalSchemeMapStations';
import MetroSchemeModalSchemeMapLineBadges from './MetroSchemeModalSchemeMapLineBadges';

const MetroSchemeModalSchemeMap = ({
  city,
  stations,
  lines,
  selectedStations,
  onStationChange,
  onLineChange,
}) => (
  <div className="metro-scheme-map">
    <MetroSchemeModalSchemeMapSVG city={city} />
    <MetroSchemeModalSchemeMapStations
      stations={stations}
      selectedStations={selectedStations}
      onStationChange={onStationChange}
    />
    <MetroSchemeModalSchemeMapLineBadges
      lines={lines}
      onLineChange={onLineChange}
    />
  </div>
);

MetroSchemeModalSchemeMap.propTypes = {
  city: PropTypes.object.isRequired,
  lines: PropTypes.array.isRequired,
  selectedStations: PropTypes.array.isRequired,
  stations: PropTypes.array.isRequired,
  onLineChange: PropTypes.func.isRequired,
  onStationChange: PropTypes.func.isRequired,
};

export default MetroSchemeModalSchemeMap;
