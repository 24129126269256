import React from 'react';
import ReactDOM from 'react-dom';

import UserSettingsModal from '../../components/UserSettingsModal/UserSettingsModal';

export default (() => {
  if (typeof document === 'undefined') {
    return;
  }

  const onDOMReady = () => {
    document.addEventListener('click', (e) => {
      let target = e.target;

      do {
        if (target.getAttribute('data-settings-modal-trigger') !== null) {
          e.preventDefault();

          ReactDOM.render(
            <UserSettingsModal />,
            document.getElementById('modals')
          );

          return;
        }

        target = target.parentNode;
      } while (target !== document && target !== null)
    });
  }

  document.addEventListener('DOMContentLoaded', onDOMReady);
})();