import React from 'react';
import PropTypes from 'prop-types';
import CleaveInput from 'cleave.js/react';
import classNames from 'classnames';

import styles from './CustomInput.scss';

function CustomInput({
  value,
  min,
  max,
  options = {},
  prefix = null,
  suffix = null,
  formatFunction = null,
  isValid = false,
  onChange = null,
}) {
  const initialValue = React.useRef(false);
  const inputRef = React.useRef(null);
  const [formattedValue, setFormattedValue] = React.useState(0);

  React.useEffect(() => {
    initialValue.current = value;
  }, []);

  React.useEffect(() => {
    setFormattedValue(formatFunction ? formatFunction(value) : `${value}`);
  }, [value]);

  const handleInputInit = React.useCallback(input => {
    inputRef.current = input;
  });

  const handleInputChange = React.useCallback(event => {
    let rawValue = parseInt(event.target.rawValue, 10) || min;
    let formattedValue = event.target.value;

    if (rawValue <= min) {
      rawValue = min;
      formattedValue = formatFunction
        ? formatFunction(rawValue)
        : `${rawValue}`;

      if (inputRef && inputRef.current) {
        inputRef.current.onInput(formattedValue, true);
      }
    }

    if (rawValue >= max) {
      rawValue = max;
      formattedValue = formatFunction
        ? formatFunction(rawValue)
        : `${rawValue}`;

      if (inputRef && inputRef.current) {
        inputRef.current.onInput(formattedValue, true);
      }
    }

    setFormattedValue(formattedValue);

    onChange && onChange(rawValue, formattedValue);
  });

  const withPrefix = prefix !== null;

  return (
    <div className={styles.inputContainer}>
      {withPrefix !== null && (
        <span className={styles.inputPrefix}>{prefix}</span>
      )}
      <CleaveInput
        className={classNames(styles.input, {
          [styles.inputWithPrefix]: withPrefix,
          [styles.inputValid]: isValid,
          [styles.inputTouched]: initialValue.current !== value,
        })}
        value={value}
        onInit={handleInputInit}
        onChange={handleInputChange}
        options={options}
      />
      <div className={styles.focusedContainer} />
      {suffix !== null && (
        <div
          className={classNames(styles.input, styles.inputPlaceholder, {
            [styles.inputWithPrefix]: withPrefix,
            [styles.inputTouched]: initialValue.current !== value,
          })}
        >
          <span className={styles.inputPlaceholderValue}>{formattedValue}</span>{' '}
          <span>{suffix}</span>
        </div>
      )}
    </div>
  );
}

CustomInput.propTypes = {
  formatFunction: PropTypes.func,
  isValid: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  options: PropTypes.object,
  prefix: PropTypes.string,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
};

export default CustomInput;

