import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';

import { defaultMessages } from '../../../../libs/i18n/default';
import { SUBWAYS_ID_ANY } from './MainFilters.constants';
import SvgIcon from '../SvgIcon/SvgIcon';

class MainFiltersRegion extends React.Component {
  handleChange = event => {
    const { cityId, updateState } = this.props;

    let nextState = {
      cityId: parseInt(event.target.value),
    };

    if (cityId !== nextState.cityId) {
      nextState.subwayId = SUBWAYS_ID_ANY;
    }

    updateState(nextState);
  };

  renderSelectControl() {
    const { cities, cityId } = this.props;

    return (
      <select
        className="MainFilters__regionSelectControl"
        value={cityId}
        onChange={this.handleChange}
      >
        {cities.map(city => {
          return (
            <option key={`City-${city.id}`} value={city.id}>
              {city.name}
            </option>
          );
        })}
      </select>
    );
  }

  render() {
    const {
      intl: { formatMessage },
      cities,
      cityId,
    } = this.props;

    const currentCity = find(cities, ({ id }) => cityId === id);

    return (
      <div className="MainFilters__region">
        <span className="MainFilters__regionBefore">
          {formatMessage(defaultMessages.jsMainFiltersIn)}&nbsp;
        </span>
        <div className="MainFilters__regionSelect">
          <div className="MainFilters__regionSelectValue">
            {currentCity.name}
          </div>
          <SvgIcon icon="location-arrow" />
          {this.renderSelectControl()}
        </div>
      </div>
    );
  }
}

MainFiltersRegion.propTypes = {
  cities: PropTypes.array.isRequired,
  cityId: PropTypes.number.isRequired,
  intl: intlShape.isRequired,
  updateState: PropTypes.func.isRequired,
};

export default MainFiltersRegion;
