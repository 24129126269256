import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import isObject from 'lodash/isObject';

import { defaultMessages } from '../../../../libs/i18n/default';
import { FIELD_NAMES } from './constants';
import AdFormInputNumeric from './AdFormInputNumeric';

class AdFormOptions extends React.PureComponent {
  state = {
    attrNames: [
      FIELD_NAMES.SPACE,
      FIELD_NAMES.FLOOR,
      FIELD_NAMES.FLOORS_AMOUNT,
    ],
  };

  componentDidMount() {
    this.validatePresented();
  }

  handleAreaChange = (event, val) => {
    const { setAdState } = this.props;

    setAdState({ [FIELD_NAMES.SPACE]: val });
  };

  handleFloorChange = (event, val) => {
    const { setAdState } = this.props;

    setAdState({ [FIELD_NAMES.FLOOR]: val });
  };

  handleFloorsChange = (event, val) => {
    const { setAdState } = this.props;

    setAdState({ [FIELD_NAMES.FLOORS_AMOUNT]: val });
  };

  validatePresented() {
    const { attrNames } = this.state;
    const { validateAttr } = this.props;

    attrNames.forEach(attr => {
      validateAttr(attr, { isPresented: true });
    });
  }

  errorMessage() {
    const {
      intl: { formatMessage },
    } = this.props;

    const hasError =
      this.hasErrors(FIELD_NAMES.SPACE) ||
      this.hasErrors(FIELD_NAMES.FLOOR) ||
      this.hasErrors(FIELD_NAMES.FLOORS_AMOUNT);

    return (
      hasError && (
        <div className="error-message">
          {formatMessage(defaultMessages.jsAdFormErrorsMustBeNumeric)}
        </div>
      )
    );
  }

  hasErrors(attr) {
    const { errors } = this.props;

    return isObject(errors[attr]);
  }

  render() {
    const {
      space,
      floor,
      floorsAmount,
      intl: { formatMessage },
    } = this.props;

    return (
      <div className="form-row form-row-options">
        <div className="form-row-label">
          {formatMessage(defaultMessages.jsAdFormOptionsLabel)}
        </div>
        <div className="form-row-content">
          <div className="grid">
            <div className="grid-item grid-item-option grid-item-option-space">
              <label
                className="control-label"
                htmlFor="ad_space"
                id="ad_input_space"
              >
                {formatMessage(defaultMessages.jsAdFormOptionsSpace)}
              </label>
              <AdFormInputNumeric
                classMods={['option', 'inline']}
                fraction={1}
                integer={3}
                value={space}
                error={this.hasErrors(FIELD_NAMES.SPACE)}
                onChange={this.handleAreaChange}
              />
              <span
                className="form-text"
                dangerouslySetInnerHTML={{
                  __html: formatMessage(defaultMessages.jsAdFormOptionsSq),
                }}
              />
            </div>
            <div className="grid-item grid-item-option grid-item-option-floor">
              <label
                className="control-label"
                htmlFor="ad_floor"
                id="ad_input_floor"
              >
                {formatMessage(defaultMessages.jsAdFormOptionsFloor)}
              </label>
              <AdFormInputNumeric
                id="ad_floor"
                integer={3}
                classMods={['option', 'inline']}
                value={floor}
                error={this.hasErrors(FIELD_NAMES.FLOOR)}
                onChange={this.handleFloorChange}
              />
              <span className="form-text">/</span>
            </div>
            <div className="grid-item grid-item-option grid-item-option-floors">
              <label
                className="control-label"
                htmlFor="ad_floors_amount"
                id="ad_input_floors_amount"
              >
                {formatMessage(defaultMessages.jsAdFormOptionsFloorsAmount)}
              </label>
              <AdFormInputNumeric
                id="ad_floors_amount"
                integer={3}
                classMods={['option', 'inline']}
                value={floorsAmount}
                error={this.hasErrors(FIELD_NAMES.FLOORS_AMOUNT)}
                onChange={this.handleFloorsChange}
              />
            </div>
          </div>
          {this.errorMessage()}
        </div>
      </div>
    );
  }
}

AdFormOptions.propTypes = {
  errors: PropTypes.object,
  floor: PropTypes.number,
  floorsAmount: PropTypes.number,
  intl: intlShape.isRequired,
  setAdState: PropTypes.func,
  space: PropTypes.number,
  validateAttr: PropTypes.func,
};

export default AdFormOptions;
