import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import { FIELD_NAMES } from './constants';
import AdFormFootnote from './AdFormFootnoteMixin';
import AdFormValidatable from './AdFormValidatableMixin';
import AdFormTextarea from './AdFormTextarea';

class AdFormDescription extends React.PureComponent {
  constructor(props) {
    super(props);

    this.descriptionInputRef = React.createRef();
  }

  handleChange = event => {
    const { setAdState } = this.props;

    setAdState({ [FIELD_NAMES.DESCRIPTION]: event.currentTarget.value });
  };

  render() {
    const {
      intl: { formatMessage },
      description,
      bigFootnote,
      hasErrors,
      smallFootnote,
      errorMessage,
    } = this.props;

    return (
      <div className="form-row">
        <div className="form-row-label">
          {formatMessage(defaultMessages.jsAdFormDescriptionLabel)}
        </div>
        <div className="form-row-content">
          {bigFootnote()}
          <AdFormTextarea
            ref={this.descriptionInputRef}
            value={description}
            placeholder={formatMessage(
              defaultMessages.jsAdFormDescriptionPlaceholder,
            )}
            error={hasErrors()}
            onFocus={this.showFootnote}
            onBlur={this.hideFootnote}
            onChange={this.handleChange}
          />
          {smallFootnote()}
          {errorMessage()}
        </div>
      </div>
    );
  }
}

AdFormDescription.propTypes = {
  bigFootnote: PropTypes.func,
  description: PropTypes.string,
  errorMessage: PropTypes.func,
  hasErrors: PropTypes.func,
  intl: intlShape.isRequired,
  setAdState: PropTypes.func,
  smallFootnote: PropTypes.func,
};

export default AdFormFootnote(
  AdFormValidatable(AdFormDescription, FIELD_NAMES.DESCRIPTION),
  FIELD_NAMES.DESCRIPTION,
);
