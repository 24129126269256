/* eslint-disable react/no-string-refs */
import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';

import { resetPassword } from '../../api/auth';
import { defaultMessages } from '../../../../libs/i18n/default';
import AuthModalInput from './AuthModalInput';

class AuthModalReset extends React.Component {
  constructor(props) {
    super(props);
    this.setValidationRules();
  }

  state = {
    canSubmit: false,
    isReset: false,
    errors: '',
  };

  onLoginLinkClick = e => {
    const { onChangeForm } = this.props;

    e.preventDefault();

    onChangeForm('loginByEmail');
  };

  onValid = () => {
    this.setState({ canSubmit: true });
  };

  onInvalid = () => {
    this.setState({ canSubmit: false });
  };

  onSubmit = (model, reset, invalidate) => {
    const { canSubmit } = this.state;
    const {
      intl: { formatMessage },
      toggleLoading,
      resetToken,
    } = this.props;

    if (!canSubmit) {
      return;
    }

    let nextModel = Object.assign({}, model);

    if (!nextModel.user) {
      nextModel.user = {};
    }

    nextModel.user['reset_password_token'] = resetToken;

    toggleLoading();

    resetPassword(nextModel)
      .then(data => {
        toggleLoading();

        this.setState({ isReset: true });

        // setTimeout чтобы успеть прочитать сообщение об успешной регистрации
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      })
      .catch((errors, error) => {
        let errorMessage = formatMessage(
          defaultMessages.jsFlashNotifierSomethingWentWrong,
        );

        if (error) {
          errorMessage = error;
        } else if (errors && errors['reset_password_token']) {
          errorMessage = formatMessage(
            defaultMessages.jsFlashNotifierSessionIsOutdated,
          );
        }

        toggleLoading();

        this.setState({ errors: errorMessage });
      });
  };

  getPasswordValue() {
    return this.refs.resetFormPassword.getModel().user.password;
  }

  getPasswordConfirmationValue() {
    return this.refs.resetFormPasswordConfirmation.getModel().user
      .password_confirmation;
  }

  setValidationRules() {
    const {
      intl: { formatMessage },
    } = this.props;

    this.validations = {
      passwordConfirmation: {
        equalsField: 'user[password]',
      },
    };

    this.validationErrors = {
      password: {
        isDefaultRequiredValue: formatMessage(
          defaultMessages.jsAuthModalValidationFieldRequired,
        ),
      },
      passwordConfirmation: {
        isDefaultRequiredValue: formatMessage(
          defaultMessages.jsAuthModalValidationFieldRequired,
        ),
        equalsField: formatMessage(
          defaultMessages.jsAuthModalValidationPasswordEqualTo,
        ),
      },
    };
  }

  render() {
    const { isReset, errors } = this.state;
    const { intl, resetToken } = this.props;
    const { formatMessage } = intl;

    if (!isReset) {
      return (
        <Formsy
          ref="resetForm"
          className="modal-form"
          acceptCharset="UTF-8"
          method="post"
          onValid={this.onValid}
          onInvalid={this.onInvalid}
          onSubmit={this.onSubmit}
        >
          <input
            ref="resetFormToken"
            type="hidden"
            name="user[reset_password_token]"
            value={resetToken}
          />
          <div className="modal-form-header">
            <div className="modal-form-title">
              {formatMessage(defaultMessages.jsAuthModalResetTitle)}
            </div>
            <div className="modal-form-text">
              <a
                href="#"
                className="link link-green"
                onClick={this.onLoginLinkClick}
              >
                {formatMessage(defaultMessages.jsAuthModalResetTitleLink)}
              </a>
            </div>
          </div>
          <div className="modal-form-content">
            <div className="modal-form-item">
              <AuthModalInput
                intl={intl}
                ref="resetFormPassword"
                name="user[password]"
                placeholder={formatMessage(
                  defaultMessages.jsAuthModalNewPasswordPlaceholder,
                )}
                required
                type="password"
                validationErrors={this.validationErrors.password}
                value=""
              />
            </div>
            <div className="modal-form-item">
              <AuthModalInput
                intl={intl}
                ref="resetFormPasswordConfirmation"
                name="user[password_confirmation]"
                placeholder={formatMessage(
                  defaultMessages.jsAuthModalConfirmPasswordPlaceholder,
                )}
                required
                type="password"
                validations={this.validations.passwordConfirmation}
                validationErrors={this.validationErrors.passwordConfirmation}
                value=""
              />
            </div>
            {errors !== '' && (
              <div className="modal-form-item">
                <div className="error-message">{errors}</div>
              </div>
            )}
            <div className="modal-form-item modal-form-item-actions">
              <div className="modal-form-item-cell">
                <button
                  className="button button-green modal-form-submit"
                  type="submit"
                  formNoValidate
                >
                  {formatMessage(
                    defaultMessages.jsAuthModalChangePasswordButton,
                  )}
                </button>
              </div>
            </div>
          </div>
        </Formsy>
      );
    } else {
      return (
        <div className="modal-message modal-message-success">
          <div
            className="modal-title"
            dangerouslySetInnerHTML={{
              __html: formatMessage(
                defaultMessages.jsAuthModalResetSuccessTitle,
              ),
            }}
          />
          <div
            className="modal-text"
            dangerouslySetInnerHTML={{
              __html: formatMessage(
                defaultMessages.jsAuthModalResetSuccessText,
              ),
            }}
          />
        </div>
      );
    }
  }
}

AuthModalReset.propTypes = {
  intl: intlShape.isRequired,
  resetToken: PropTypes.string.isRequired,
  toggleLoading: PropTypes.func.isRequired,
  onChangeForm: PropTypes.func.isRequired,
};

export default AuthModalReset;
