/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import { FROM_SUBWAY_VALUES, FROM_SUBWAY_TIME_VALUES } from '../constants';
import Switcher from '../Switcher';
import getElementOffset from '../../../utils/getElementOffset';
import SubwaySelect from './SubwaySelect';
import styles from '../styles.scss';

const ChooseGeoSubway = React.memo(
  ({
    city,
    subways,
    isSubwaysLoading,
    subwayIds,
    fromSubway,
    subwayTime,
    onSubwayChange,
    onSubwayRemove,
    onFromSubwayChange,
    onSubwayTimeChange,
  }) => {
    const fromSubwayRef = React.useRef();
    const subwayTimeRef = React.useRef();
    const [isFromSubwayShowed, setIsFromSubwayShowed] = React.useState(false);
    const [isSubwayTimeShowed, setIsSubwayTimeShowed] = React.useState(false);

    const handleSubwayIdsChange = React.useCallback(values => {
      onSubwayChange(values);

      if (isFromSubwayShowed) {
        return null;
      }

      setIsFromSubwayShowed(true);

      setTimeout(scrollToFromSubway, 400);
    }, []);

    const handleFromSubwayChange = React.useCallback(value => {
      onFromSubwayChange(value);

      if (isSubwayTimeShowed) {
        return null;
      }

      setIsSubwayTimeShowed(true);

      setTimeout(scrollToFromSubwayTime, 400);
    }, []);

    const scrollToFromSubway = React.useCallback(() => {
      if (fromSubwayRef.current) {
        const fromSubwayTop = getElementOffset(fromSubwayRef.current).top;

        if (window.scrollY < fromSubwayTop) {
          window.scrollTo({
            top: fromSubwayTop,
            behavior: 'smooth',
          });
        }
      }
    }, []);

    const scrollToFromSubwayTime = React.useCallback(() => {
      if (subwayTimeRef.current) {
        const subwayTimeBottom = getElementOffset(subwayTimeRef.current).bottom;

        if (window.scrollY < subwayTimeBottom) {
          window.scrollTo({
            top: subwayTimeBottom,
            behavior: 'smooth',
          });
        }
      }
    }, []);

    return (
      <React.Fragment>
        <div className={styles.formItemBig}>
          <p className={styles.formLabel}>Метро</p>
          <SubwaySelect
            city={city}
            isSubwaysLoading={isSubwaysLoading}
            subways={subways}
            subwayIds={subwayIds}
            onChange={handleSubwayIdsChange}
            onRemove={onSubwayRemove}
          />
        </div>
        <div
          ref={fromSubwayRef}
          className={classNames(styles.formItem, styles.formItemBig, {
            [styles.hidden]: subwayIds.length === 0,
          })}
        >
          <p className={styles.formLabel}>Как будете добираться до метро</p>
          <Switcher
            values={FROM_SUBWAY_VALUES}
            initialActiveValue={fromSubway}
            onChange={handleFromSubwayChange}
          />
        </div>
        <div
          ref={subwayTimeRef}
          className={classNames({
            [styles.hidden]: !fromSubway,
          })}
        >
          <p className={styles.formLabel}>Время в пути</p>
          <Switcher
            isRowMobile
            itemClassName={styles.switcherItemFixed}
            initialActiveValue={subwayTime}
            values={FROM_SUBWAY_TIME_VALUES}
            suffix="мин."
            onChange={onSubwayTimeChange}
          />
        </div>
      </React.Fragment>
    );
  },
);

ChooseGeoSubway.propTypes = {
  city: PropTypes.object.isRequired,
  fromSubway: PropTypes.string,
  isSubwaysLoading: PropTypes.bool.isRequired,
  subwayIds: PropTypes.array.isRequired,
  subwayTime: PropTypes.number,
  subways: PropTypes.array.isRequired,
  onFromSubwayChange: PropTypes.func.isRequired,
  onSubwayChange: PropTypes.func.isRequired,
  onSubwayRemove: PropTypes.func.isRequired,
  onSubwayTimeChange: PropTypes.func.isRequired,
};

export default ChooseGeoSubway;
