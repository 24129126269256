import { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { defaultMessages } from '../../../../libs/i18n/default';
import classNames from 'classnames';

import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';

class PaymentCardErrorIcon extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired
  }

  render() {
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id={`TooltipPaymentCard-${this.props.id}`}>{this.props.tooltip}</Tooltip>
        }
      >
        <span className="PaymentCard__errorIcon" />
      </OverlayTrigger>
    );
  }
};

export default PaymentCardErrorIcon;