import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

import { FIELD_NAMES } from './constants';
import PricingPlans from '../PricingPlans/PricingPlans';

class AdFormPlans extends React.PureComponent {
  handlePlanChange = id => {
    const { setAdState } = this.props;

    setAdState({ [FIELD_NAMES.FEATURE_PLAN_ID]: id });
  };

  render() {
    const {
      plans,
      currentPlanId,
      defaultCurrency,
      intl,
      dealType,
    } = this.props;

    const filteredPlans = plans.filter(plan => plan.deal_type === dealType);

    return (
      plans.length > 0 && (
        <div className="form-row form-row-pt30">
          <PricingPlans
            intl={intl}
            plans={filteredPlans}
            currentPlanId={currentPlanId}
            defaultCurrency={defaultCurrency}
            onChange={this.handlePlanChange}
            dealType={dealType}
          />
        </div>
      )
    );
  }
}

AdFormPlans.propTypes = {
  currentPlanId: PropTypes.number,
  dealType: PropTypes.string,
  defaultCurrency: PropTypes.string,
  intl: intlShape.isRequired,
  plans: PropTypes.array,
  setAdState: PropTypes.func,
};

AdFormPlans.defaultProps = {
  currentPlanId: 0,
};

export default AdFormPlans;
