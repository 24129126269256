/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { defaultMessages } from '../../../../libs/i18n/default';
import { subwaysApi } from '../../api/subways';
import { FILTER_KEYS } from './AdCatalog.constants';
import filterOptions from '../../../common/helpers/ReactSelect/defaultFilterOptions';
import FlashNotifierService from '../../services/FlashNotifier/FlashNotifier';

class AdCatalogFilterBodyDistricts extends React.PureComponent {
  handleSelectChange = values => {
    const { updateFilter } = this.props;

    const nextDistricts = values.map(item => Number(item.value));

    updateFilter({
      [FILTER_KEYS.DISTRICTS]: nextDistricts.length ? nextDistricts : null,
    });
  };

  handleClearLinkClick = event => {
    this.updateFilterDistricts([]);

    event.preventDefault();
  };

  updateFilterDistricts = districtIds => {
    const { updateFilter } = this.props;

    updateFilter({
      [FILTER_KEYS.DISTRICTS]: districtIds.length ? districtIds : null,
    });
  };

  // TODO: Поменять текст если нет результатов в селекте при наборе
  render() {
    const {
      districts,
      selected,
      intl: { formatMessage },
    } = this.props;

    return (
      <div className="filter-row">
        <div className="filter-label">
          <span>{formatMessage(defaultMessages.jsCatalogFilterWhere)}</span>
          {(selected && selected.length) && (
            <a
              className="link link-orange pull-right"
              href="#"
              onClick={this.handleClearLinkClick}
            >
              {formatMessage(defaultMessages.jsCatalogFilterClear)}
            </a>
          )}
        </div>
        <div
          className={classNames('filter-select', 'filter-select-metro')}
        >
          <Select
            multi
            arrowRenderer={null}
            clearable={false}
            filterOptions={filterOptions}
            options={districts}
            placeholder={formatMessage(
              defaultMessages.jsCatalogFilterTypeTheDistrictName,
            )}
            value={selected}
            onChange={this.handleSelectChange}
          />
        </div>
      </div>
    );
  }
}

AdCatalogFilterBodyDistricts.propTypes = {
  city: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  selected: PropTypes.array,
  districts: PropTypes.array,
  updateFilter: PropTypes.func.isRequired,
};

export default AdCatalogFilterBodyDistricts;
