import anime from 'animejs';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import AssistantChatMessage from './AssistantChatMessage';
import styles from './AssistantChatRow.scss';

class AssistantChatRow extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      typedMessages: [],
    };

    this.rowRef = React.createRef();
  }

  componentDidMount() {
    const { forceVisible } = this.props;

    if (forceVisible) {
      this.showAllMessages();
    } else {
      this.showNextMessage();
      this.animateRow();
    }
  }

  animateRow = () => {
    anime({
      targets: this.rowRef.current,
      opacity: [0, 1],
      duration: 300,
      easing: 'easeInOutSine',
      complete: () => {
        if (this.rowRef.current) {
          this.rowRef.current.style.height = 'auto';
        }
      },
    });
  };

  showNextMessage = () => {
    const { typedMessages } = this.state;
    const { onProgressEnd, messages } = this.props;

    if (typedMessages.length === messages.length) {
      onProgressEnd && onProgressEnd();

      return null;
    }

    this.setState({
      typedMessages: [...typedMessages, messages[typedMessages.length]],
    });
  };

  showAllMessages = () => {
    const { messages, onProgressEnd } = this.props;

    this.setState({ typedMessages: messages }, () => {
      onProgressEnd && onProgressEnd();
    });
  };

  render() {
    const { typedMessages } = this.state;
    const {
      avatarSrc,
      direction,
      theme,
      forceVisible,
      typingTime,
    } = this.props;

    return (
      <div
        className={classNames(styles.row, styles[`row--${direction}`])}
        ref={this.rowRef}
      >
        <div className={styles.colMessage}>
          {typedMessages.length > 0 &&
            typedMessages.map(({ text, showTime = 0 }) => (
              <AssistantChatMessage
                key={text}
                forceVisible={forceVisible}
                message={text}
                theme={theme}
                typingTime={typingTime}
                direction={direction}
                showTime={showTime}
                onShowEnd={this.showNextMessage}
              />
            ))}
        </div>
        <div className={styles.colAvatar}>
          <img src={avatarSrc} alt="" width="47" height="47" />
        </div>
      </div>
    );
  }
}

AssistantChatRow.propTypes = {
  avatarSrc: PropTypes.string,
  direction: PropTypes.oneOf(['left', 'right']),
  forceVisible: PropTypes.bool,
  messages: PropTypes.any,
  theme: PropTypes.oneOf(['green', 'white']),
  typingTime: PropTypes.number,
  onProgressEnd: PropTypes.func,
};

AssistantChatRow.defaultProps = {
  direction: 'right',
  forceVisible: false,
  messages: [],
  theme: 'green',
};

export default AssistantChatRow;
