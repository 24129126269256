import PropTypes from 'prop-types';
import React from 'react';

const MetroOption = ({ count, colors, label }) => {
  return (
    <span className="Select-option-metro">
      <span className="Select-option-metro-count">{count}</span>
      {colors.length > 0 && (
        <span className="Select-option-metro-label">
          {colors.map(color => {
            return (
              <span
                key={'color-' + color}
                className="Select-option-metro-icon"
                style={{ backgroundColor: color }}
              />
            );
          })}
        </span>
      )}
      <span className="Select-option-metro-text">{label}</span>
    </span>
  );
};

MetroOption.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
  count: PropTypes.number,
  label: PropTypes.string.isRequired,
};

export default MetroOption;
