/* eslint-disable jsx-a11y/label-has-associated-control */
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

class MetroSchemeModalSchemeStationCheckbox extends React.Component {
  handleChange = event => {
    const { onChange, id, lines } = this.props;

    onChange({ id, lines });
  };

  render() {
    const {
      offset,
      size,
      textAlign,
      lines,
      id,
      isChecked,
      textAbsolute,
      textTop,
      textRight,
      textBottom,
      textLeft,
      title,
    } = this.props;

    if (!lines || !offset.top || !offset.left) {
      return <span />;
    }

    let styles = {
      top: offset.top,
      left: offset.left,
    };

    if (textAlign) {
      styles.textAlign = textAlign;
    }

    const textStyles = textAbsolute
      ? {
          position: 'absolute',
          top: textTop,
          right: textRight,
          bottom: textBottom,
          left: textLeft,
        }
      : null;

    return (
      <label
        className={classNames('metro-scheme-station', {
          [`__${size}`]: size !== null,
        })}
        style={styles}
      >
        <input
          className="metro-scheme-station-control"
          type="checkbox"
          value={id}
          checked={isChecked}
          onChange={this.handleChange}
        />
        <span className="metro-scheme-station-text" style={textStyles}>
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </span>
      </label>
    );
  }
}

MetroSchemeModalSchemeStationCheckbox.propTypes = {
  id: PropTypes.number.isRequired,
  isChecked: PropTypes.bool.isRequired,
  lines: PropTypes.array.isRequired,
  offset: PropTypes.object.isRequired,
  size: PropTypes.string,
  textAbsolute: PropTypes.bool,
  textAlign: PropTypes.string,
  textBottom: PropTypes.number,
  textLeft: PropTypes.number,
  textRight: PropTypes.number,
  textTop: PropTypes.number,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

MetroSchemeModalSchemeStationCheckbox.defaultProps = {
  size: null,
  textTop: null,
  textRight: null,
  textBottom: null,
  textLeft: null,
};

export default MetroSchemeModalSchemeStationCheckbox;
