/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { defaultMessages } from '../../../../libs/i18n/default';
import { FILTER_KEYS, FILTER_AMENITY_KINDS } from './AdCatalog.constants';
import AdCatalogFilterBodyExtraSpaceFloor from './AdCatalogFilterBodyExtraSpaceFloor';
import AdCatalogFilterBodyExtraSubjects from './AdCatalogFilterBodyExtraSubjects';
import AdCatalogFilterBodyExtraTags from './AdCatalogFilterBodyExtraTags';

class AdCatalogFilterBodyExtra extends React.PureComponent {
  state = {
    isOpen: false,
  };

  handleToggleClick = () => {
    this.setState(({ isOpen }) => ({
      isOpen: !isOpen,
    }));
  };

  handleUpdateDelayedFilter = (newOptions = {}) => {
    const { updateFilter, updateDelay } = this.props;

    updateFilter(newOptions, null, updateDelay);
  };

  render() {
    const { isOpen } = this.state;
    const { intl, amenities, filter, updateFilter } = this.props;
    const { formatMessage } = intl;

    const { tags, subjects } = amenities.reduce(
      (result, amenity) => {
        const kindKey =
          amenity.kind === FILTER_AMENITY_KINDS.TAG ? 'tags' : 'subjects';

        result[kindKey].push(amenity);

        return result;
      },
      { tags: [], subjects: [] },
    );

    return (
      <div className="filter-row">
        <div className={classNames('filter-extra', { __open: isOpen })}>
          <div
            className="filter-extra-trigger"
            onClick={this.handleToggleClick}
          >
            {formatMessage(defaultMessages.jsCatalogFilterAdditionally)}
          </div>
          {isOpen && (
            <div className="filter-extra-body">
              <AdCatalogFilterBodyExtraSpaceFloor
                intl={intl}
                spaceMin={(filter[FILTER_KEYS.SPACE_MIN] || '').toString()}
                spaceMax={(filter[FILTER_KEYS.SPACE_MAX] || '').toString()}
                floorMin={(filter[FILTER_KEYS.FLOOR_MIN] || '').toString()}
                floorMax={(filter[FILTER_KEYS.FLOOR_MAX] || '').toString()}
                updateFilter={this.handleUpdateDelayedFilter}
              />
              <div className="filter-row">
                <div className="filter-label">
                  {formatMessage(defaultMessages.jsCatalogFilterFacilities)}
                </div>
                <AdCatalogFilterBodyExtraSubjects
                  subjects={subjects}
                  activeIds={filter[FILTER_KEYS.AMENITIES]}
                  updateFilter={updateFilter}
                />
                <div className="filter-label">
                  {formatMessage(defaultMessages.jsCatalogFilterFeatures)}
                </div>
                <AdCatalogFilterBodyExtraTags
                  tags={tags}
                  activeIds={filter[FILTER_KEYS.AMENITIES]}
                  updateFilter={updateFilter}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

AdCatalogFilterBodyExtra.propTypes = {
  amenities: PropTypes.array,
  filter: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  updateDelay: PropTypes.number,
  updateFilter: PropTypes.func.isRequired,
};

export default AdCatalogFilterBodyExtra;
