import PropTypes from 'prop-types';
import React from 'react';

class AssistantChat extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showedRows: [],
    };
  }

  componentDidMount() {
    const { forceVisible } = this.props;

    if (forceVisible) {
      this.showAllRows();
    } else {
      this.showNexRow();
    }
  }

  componentDidUpdate(prevProps) {
    const { forceVisible } = this.props;

    if (!prevProps.forceVisible && forceVisible) {
      this.showAllRows();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.showNextRowTimeout);
  }

  showNexRow = () => {
    const { showedRows } = this.state;
    const { rows, delay } = this.props;

    if (showedRows.length >= rows.length) {
      return null;
    }

    let nextRowElement = rows[showedRows.length];

    if (nextRowElement) {
      nextRowElement = React.cloneElement(nextRowElement, {
        key: `chatRow--${showedRows.length}`,
        onProgressEnd: this.showNexRow,
        index: showedRows.length,
      });
    }

    this.showNextRowTimeout = setTimeout(() => {
      this.setState({ showedRows: [...showedRows, nextRowElement] });
    }, delay);
  };

  showAllRows = () => {
    const { rows } = this.props;

    this.setState({
      showedRows: rows.map((element, index) => {
        return element
          ? React.cloneElement(element, {
              // eslint-disable-next-line react/no-array-index-key
              key: `chatRow--${index}`,
              index: index,
            })
          : null;
      }),
    });
  };

  render() {
    const { showedRows } = this.state;

    return showedRows;
  }
}

AssistantChat.propTypes = {
  delay: PropTypes.number,
  forceVisible: PropTypes.bool,
  rows: PropTypes.arrayOf(PropTypes.element).isRequired,
};

AssistantChat.defaultProps = {
  delay: 0,
  forceVisible: false,
};

export default AssistantChat;
