import React from 'react';
import ReactDOM from 'react-dom';
import validate from 'validate.js';
import InputElement from 'react-input-mask';

class OwnerAssistantBanner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      state: 'new',
      phone: ''
    };
  }

  render() {
    if (this.state.state != 'closed') {
      let classes = 'assistant-banner assistant-banner-owner';

      if (this.state.state == 'phone' || this.state.state == 'success') classes += ' __form';

      return(
        <div className={classes}>
          <div className="assistant-banner-close" onClick={this.closeBanner}/>
          <div className="assistant-banner-inner">
            <div className="assistant-banner-heading">
              <p className="assistant-banner-title">Расскажем как<br/> правильно</p>
            </div>
            <div className="assistant-banner-features">
              <div className="assistant-banner-features-inner">
                <ul className="assistant-banner-features-list">
                  <li className="assistant-banner-features-item">Проверить арендатора</li>
                  <li className="assistant-banner-features-item">Заключить договор</li>
                  <li className="assistant-banner-features-item">Застраховать имущество</li>
                </ul>
              </div>
            </div>
            <div
              ref="form"
              className="assistant-banner-form"
            >
              {this.renderBannerForm()}
            </div>
            <div className="assistant-banner-image"></div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  renderBannerForm() {
    if (this.state.state == 'phone') {
      return this.renderPhoneForm();
    }

    if (this.state.state == 'success') {
      return (
        <span className="assistant-banner-form-message">Спасибо, мы свяжемся с вами <br/>в ближайшее время</span>
      )
    }

     if (this.state.state == 'new') {
      return (
        <button
          className="assistant-banner-form-button assistant-banner-form-button-request button button-white"
          onClick={this.showPhone}
        >
          Мне интересно!
        </button>
      )
    }
  }

  renderPhoneForm(){
    return (
      <div className="assistant-banner-form-group assistant-banner-form-group-phone">
        <label
          className="assistant-banner-form-label"
          htmlFor="assistant-banner-form-input-phone"
        >
          Ваш телефон, расскажем подробнее
        </label>
        <InputElement
          id="assistant-banner-form-input-phone"
          className="assistant-banner-form-input assistant-banner-form-input-phone"
          mask="+7 999 999-99-99"
          placeholder="+7 ___ ___-__-__"
          onChange={this.phoneChange}
        />
        <button
          className="assistant-banner-form-button assistant-banner-form-button-submit button button-white"
          onClick={this.sendPhoneForm}
        >
          ОК
        </button>
      </div>
    )
  }

  showPhone = () => {
    this.setState({state: 'phone'});
  }

  phoneChange = (e) => {
    this.setState({'phone': e.target.value});
  }

  getValidationRules() {
    return {
      phone: {
        format: {
          pattern: /\+7 [0-9]{3} [0-9]{3}-[0-9]{2}-[0-9]{2}/,
          message: 'Неверный формат телефона'
        }
      }
    };
  }

  sendPhoneForm = () => {
    let error = validate({phone: this.state.phone}, this.getValidationRules());

    if (typeof error === 'object') {
      let $form = $(ReactDOM.findDOMNode(this.refs.form));
      let animationEnd = 'webkitAnimationEnd oanimationend msAnimationEnd animationend';

      $form.addClass('__shake __animated').one(animationEnd, function(){
        $form.removeClass('__shake __animated')
      });

      return;
    };

    $.ajax({
      url: '/api/frontend/assistant',
      method: 'post',
      dataType: "json",
      data: {phone: this.state.phone, kind: 'owner'},
      success: (data, textStatus, jqXHR) => {
        this.setState({state:'success'});
      },
      error: (jqXHR, textStatus, errorThrown ) => {

      }
    });
  }

  closeBanner = () => {
    this.setState({state: 'closed'});
    $.ajax({
      url: '/api/frontend/assistant/close',
      method: 'get',
      data: {kind: 'owner'},
      dataType: "json"
    });
  }
};

export default OwnerAssistantBanner;