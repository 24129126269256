import React from 'react';

import styles from './MediaReviews.scss';
import Tabs from '../Tabs/Tabs';

class MediaReviews extends React.Component {
  state = {};

  render() {
    return (
      <Tabs tabContentClass={styles.tabContent}>
        <div label="The Village">
          <p className={styles.imageContainer}>
            <img
              src={require('../../../../images/client/MediaReviews/village.svg')}
              alt="The Village"
              width="134"
              height="47"
            />
          </p>
          <p className={styles.reviewText}>
            «На сайте есть премодерация, все объявления проверяются, об этом
            говорится на главной странице. Здесь нет подозрительно низких или
            зашкаливающе высоких цен.»
          </p>
        </div>
        <div label="Forbes">
          <p className={styles.imageContainer}>
            <img
              src={require('../../../../images/client/MediaReviews/forbes.svg')}
              alt="Forbes"
              width="140"
              height="36"
            />
          </p>
          <p className={styles.reviewText}>
            «Локалс переломили рынок локальной аренды недвижимости.»
          </p>
        </div>
        <div label="Радио Mediametrics">
          <p className={styles.imageContainer}>
            <img
              src={require('../../../../images/client/MediaReviews/mediametrics.svg')}
              alt="Радио Mediametrics"
              width="63"
              height="58"
            />
          </p>
          <p className={styles.reviewText}>
            «Проверка квартиры для обычного человека – это время и нервы. Но
            Локалс знает где проверять, как проверять и какие вопросы задавать.
            Это похоже на детективное агентство.»
          </p>
        </div>
        <div label="habr">
          <p className={styles.imageContainer}>
            <img
              src={require('../../../../images/client/MediaReviews/habr.svg')}
              alt="habr"
              width="119"
              height="41"
            />
          </p>
          <p className={styles.reviewText}>
            «Фидбэк от пользователей по-прежнему является основным инструментом
            формирования планов развития.»
          </p>
        </div>
        <div label="Радио «Маяк»">
          <p className={styles.imageContainer}>
            <img
              src={require('../../../../images/client/MediaReviews/mayak.svg')}
              alt="Радио «Маяк»"
              width="117"
              height="47"
            />
          </p>
          <p className={styles.reviewText}>
            «Локалс начал работать для народа, средний срок сдачи квартиры 7-10
            дней.»
          </p>
        </div>
      </Tabs>
    );
  }
}

export default MediaReviews;
