import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import TranslationProvider from '../../../common/components/HOC/TranslationProvider';
import Modal from '../Modal/Modal';
import AuthModalLoginByEmail from './AuthModalLoginByEmail';
import AuthModalLoginByPhone from './AuthModalLoginByPhone';
import AuthModalRegisterByEmail from './AuthModalRegisterByEmail';
import AuthModalRegisterByPhone from './AuthModalRegisterByPhone';
import AuthModalRecovery from './AuthModalRecovery';
import AuthModalReset from './AuthModalReset';

class AuthModal extends React.Component {
  constructor(props) {
    super(props);

    const { country, form = 'loginByPhone' } = this.props;

    this.state = {
      country: window.COUNTRY_CODE || country,
      currentForm: form,
      email: '',
      isLoading: false,
    };
  }

  onModalClose = e => {
    ReactDOM.unmountComponentAtNode(document.getElementById('modals'));
  };

  handleChangeForm = (form, newEmail) => {
    this.setState(({ email }) => ({
      currentForm: form,
      email: newEmail || email,
    }));
  };

  toggleLoading = () => {
    this.setState(({ isLoading }) => ({ isLoading: !isLoading }));
  };

  storeEmail = email => {
    this.setState({ email });
  };

  renderContent() {
    const { currentForm, email, country } = this.state;
    const { intl, redirectPath, resetToken } = this.props;

    switch (currentForm) {
      case 'loginByEmail':
        return (
          <AuthModalLoginByEmail
            intl={intl}
            email={email}
            redirectPath={redirectPath}
            toggleLoading={this.toggleLoading}
            onChangeForm={this.handleChangeForm}
          />
        );
      case 'loginByPhone':
        return (
          <AuthModalLoginByPhone
            intl={intl}
            country={country}
            redirectPath={redirectPath}
            toggleLoading={this.toggleLoading}
            onChangeForm={this.handleChangeForm}
          />
        );
      case 'registerByEmail':
        return (
          <AuthModalRegisterByEmail
            intl={intl}
            email={email}
            redirectPath={redirectPath}
            toggleLoading={this.toggleLoading}
            onChangeForm={this.handleChangeForm}
          />
        );
      case 'registerByPhone':
        return (
          <AuthModalRegisterByPhone
            intl={intl}
            country={country}
            redirectPath={redirectPath}
            toggleLoading={this.toggleLoading}
            onChangeForm={this.handleChangeForm}
          />
        );
      case 'recovery':
        return (
          <AuthModalRecovery
            intl={intl}
            email={email}
            storeEmail={this.storeEmail}
            toggleLoading={this.toggleLoading}
            onChangeForm={this.handleChangeForm}
          />
        );
      case 'reset':
        return (
          <AuthModalReset
            intl={intl}
            resetToken={resetToken}
            toggleLoading={this.toggleLoading}
            onChangeForm={this.handleChangeForm}
          />
        );
    }
  }

  render() {
    const { isLoading } = this.state;

    return (
      <Modal handleClose={this.onModalClose} containerClass="modal-auth">
        {this.renderContent()}
        {isLoading && (
          <div className="modal-loader">
            <span className="spinner" />
          </div>
        )}
      </Modal>
    );
  }
}

AuthModal.propTypes = {
  country: PropTypes.string,
  form: PropTypes.string,
  intl: intlShape.isRequired,
  redirectPath: PropTypes.string,
  resetToken: PropTypes.string,
};

AuthModal.defaultProps = {
  country: 'ru',
};

export default TranslationProvider(AuthModal);
