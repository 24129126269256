import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';

import { FilterContext } from './contexts/FilterContext';
import { Button } from '../Button';
import AdCatalogGridLoadMore from './AdCatalogGridLoadMore';
import Cards from '../Cards/Cards';
import OfferCard from '../OfferCard/OfferCard';
import AdCatalogSearchAssistantBanner from '../AdCatalogBanner/AdCatalogSearchAssistantBanner';
import AdCatalogClosedAccessBanner from '../AdCatalogBanner/AdCatalogClosedAccessBanner';
import AdCatalogFilterSubscriptionBanner from '../AdCatalogBanner/AdCatalogFilterSubscriptionBanner';
import AdCatalogWhatsappBanner from '../AdCatalogBanner/AdCatalogWhatsappBanner';
import styles from './AdCatalogGrid.scss';

const AdCatalogGrid = ({
  intl,
  ads,
  loadMore,
  allAdsAmount,
  adsAmountFromOwner,
  signedIn,
  isFilterOpen,
  filter,
  email,
}) => {
  const { resetFilter } = React.useContext(FilterContext);

  const renderSearchAssistantBanner = () => {
    return (
      <div key="SearchAssistantCardColumn" className="Cards__item">
        <AdCatalogSearchAssistantBanner />
      </div>
    );
  };
  const renderFilterSubscriptionBanner = (index = 0) => {
    return (
      <div
        key={`FilterSubscriptionCardColumn-${index}`}
        className="Cards__item"
      >
        <AdCatalogFilterSubscriptionBanner email={email} filter={filter} />
      </div>
    );
  };

  const renderWhatsappBanner = (index = 0) => {
    return (
      <div key={`WhatsappHelpCardColumn-${index}`} className="Cards__item">
        <AdCatalogWhatsappBanner />
      </div>
    );
  };

  const renderClosedAccessBanner = (index = 0) => {
    return (
      <div key={`ClosedAccessCardColumn-${index}`} className="Cards__item">
        <AdCatalogClosedAccessBanner />
      </div>
    );
  };

  const getBannerPlaceIndexes = () => {
    let bannerPlaceIndexes = [0, 16, 29];

    if (ads.length < 29) {
      if (ads.length < 6) {
        if (ads.length < 3) {
          bannerPlaceIndexes = [0, null, null];
        } else {
          bannerPlaceIndexes = [0, ads.length - 1, null];
        }
      } else {
        bannerPlaceIndexes = [0, Math.floor(ads.length / 2), ads.length - 1];
      }
    }

    return bannerPlaceIndexes;
  };

  const renderList = () => {
    const ADS_REPEAT_BANNERS = 45;
    let nodes = [];
    let banners = [];

    if (adsAmountFromOwner) {
      banners = [
        // renderWhatsappBanner,
        renderClosedAccessBanner,
        renderFilterSubscriptionBanner,
      ];
    } else {
      banners = [
        // renderWhatsappBanner,
        renderFilterSubscriptionBanner,
        // renderWhatsappBanner,
      ];
    }

    const bannerPlaceIndexes = getBannerPlaceIndexes();

    ads.forEach((ad, index) => {
      banners.forEach((renderFunc, i) => {
        if (index % ADS_REPEAT_BANNERS === bannerPlaceIndexes[i]) {
          nodes.push(renderFunc(index));
        }
      });

      nodes.push(
        <div key={`${index}-${ad.id}`} className="Cards__item">
          <OfferCard
            ad={ad}
            showViewedLabel
            isUserLoggedIn={signedIn}
            locale={intl.locale}
          />
        </div>,
      );
    });

    if (ads.length === allAdsAmount) {
      nodes.push(renderSearchAssistantBanner());
    }

    return nodes;
  };

  return (
    <div className="catalog-grid-col catalog-grid-col-main">
      <div className="catalog-list">
        {ads && ads.length > 0 ? (
          <Cards>
            {renderList()}

            {ads.length < allAdsAmount && (
              <AdCatalogGridLoadMore
                intl={intl}
                ads={ads}
                allAdsAmount={allAdsAmount}
                loadMore={loadMore}
                isFilterOpen={isFilterOpen}
              />
            )}
          </Cards>
        ) : (
          <div className={styles.emptyState}>
            <img
              alt="Список пуст"
              src={require('../../../../images/client/AdCatalog/search-empty.svg')}
              width={129}
              height={112}
            />

            <p className={styles.emptyStateTitle}>
              По вашему запросу ничего не нашлось
            </p>

            <p className={styles.emptyStateNotice}>
              Укажите требования к квартире и поручите поиск личному помощнику,
              подборки по квартирам будут приходить каждый день, или{' '}
              <button
                type="button"
                className={styles.clearFilterButton}
                onClick={resetFilter}
              >
                сбросьте фильтр
              </button>
            </p>

            <Button className={styles.assistantButton} href="/plans">
              Оставить заявку
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

AdCatalogGrid.propTypes = {
  ads: PropTypes.any,
  adsAmountFromOwner: PropTypes.number,
  allAdsAmount: PropTypes.any,
  email: PropTypes.any,
  filter: PropTypes.shape({
    deal_type: PropTypes.any,
    kind: PropTypes.any,
    rooms_amount: PropTypes.any,
  }),
  intl: intlShape.isRequired,
  isFilterOpen: PropTypes.any,
  loadMore: PropTypes.any,
  signedIn: PropTypes.any,
};

export default AdCatalogGrid;
