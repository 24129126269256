import { injectIntl, intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import { FIELD_NAMES } from './constants';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';
import AdFormValidatable from './AdFormValidatableMixin';
import AdFormInputNumeric from './AdFormInputNumeric';

class AdFormPrice extends React.PureComponent {
  componentDidMount() {
    const { validatePresented } = this.props;

    validatePresented();
  }

  handleChange = (event, val) => {
    const { setAdState } = this.props;

    setAdState({ [FIELD_NAMES.PRICE_VALUE]: val });
  };

  render() {
    const {
      intl: { formatMessage },
      priceValue,
      priceCurrency,
      hasErrors,
      errorMessage,
    } = this.props;

    return (
      <div className="form-row">
        <div className="form-row-label" id="ad_input_price_value">
          {formatMessage(defaultMessages.jsAdFormPriceLabel)}
        </div>
        <div className="form-row-content">
          <AdFormInputNumeric
            integer={9}
            classMods={['price', 'inline']}
            value={priceValue}
            error={hasErrors()}
            onChange={this.handleChange}
          />
          <span className="form-text">
            {formatMessage(
              defaultMessages[
                `jsCurrencies${capitalizeFirstLetter(priceCurrency)}`
              ],
            )}
          </span>
          {errorMessage()}
        </div>
      </div>
    );
  }
}

AdFormPrice.propTypes = {
  errorMessage: PropTypes.func,
  hasErrors: PropTypes.func,
  intl: intlShape.isRequired,
  priceCurrency: PropTypes.string,
  priceValue: PropTypes.number,
  setAdState: PropTypes.func,
  validatePresented: PropTypes.func,
};

export default injectIntl(
  AdFormValidatable(AdFormPrice, FIELD_NAMES.PRICE_VALUE),
);
