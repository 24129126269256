import React from 'react';
import PropTypes from 'prop-types';

import { MAIN_CLASS } from './OfferGallery.constants';

class OfferGalleryImage extends React.Component {
  state = {
    isLoaded: false,
  };

  componentDidMount() {
    this.load();
  }

  getMainClasses() {
    const { isLoaded } = this.state;

    return (
      `${MAIN_CLASS}__image` +
      (!isLoaded ? ` ${MAIN_CLASS}__image--loading` : '')
    );
  }

  load() {
    const { src } = this.props;

    let img = new Image();

    img.onload = () => {
      this.setState({ isLoaded: true });
    };

    img.onerror = () => {
      this.setState({ isLoaded: true });
    };

    img.src = src;
  }

  render() {
    const { src, alt } = this.props;

    return (
      <div className={this.getMainClasses()}>
        <img className={`${MAIN_CLASS}__imageImg`} src={src} alt={alt} />
        <div
          className={`${MAIN_CLASS}__imageBg`}
          style={{ backgroundImage: `url(${src})` }}
        />
      </div>
    );
  }
}

OfferGalleryImage.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string.isRequired,
};

export default OfferGalleryImage;
