import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import { SEX_TYPES } from '../AdCatalog/AdCatalog.constants';
import { FIELD_NAMES } from './constants';
import AdFormValidatable from './AdFormValidatableMixin';
import AdFormRadio from './AdFormRadio';

class AdFormSex extends React.PureComponent {
  componentDidMount() {
    const { validatePresented } = this.props;

    validatePresented();
  }

  handleChange = event => {
    const { setAdState } = this.props;

    setAdState({ [FIELD_NAMES.SEX]: event.currentTarget.value });
  };

  render() {
    const {
      intl: { formatMessage },
      sex,
      errorMessage,
    } = this.props;

    return (
      <div className="form-row">
        <div className="form-row-label">
          {formatMessage(defaultMessages.jsAdFormSexLabel)}
        </div>
        <div className="form-row-content">
          <div className="radio-group radio-group-table" id="ad_input_sex">
            <AdFormRadio
              value="any"
              onChange={this.handleChange}
              text={formatMessage(defaultMessages.jsAdFormSexAny)}
              checked={sex === SEX_TYPES.ANY}
            />
            <AdFormRadio
              value="female"
              onChange={this.handleChange}
              text={formatMessage(defaultMessages.jsAdFormSexFemale)}
              checked={sex === SEX_TYPES.FEMALE}
            />
            <AdFormRadio
              value="male"
              onChange={this.handleChange}
              text={formatMessage(defaultMessages.jsAdFormSexMale)}
              checked={sex === SEX_TYPES.MALE}
            />
          </div>
          {errorMessage()}
        </div>
      </div>
    );
  }
}

AdFormSex.propTypes = {
  errorMessage: PropTypes.func,
  intl: intlShape.isRequired,
  setAdState: PropTypes.func,
  sex: PropTypes.string,
  validatePresented: PropTypes.func,
};

export default AdFormValidatable(AdFormSex, FIELD_NAMES.SEX);
