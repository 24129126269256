/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import * as urls from '../../constants/urls';
import { defaultMessages } from '../../../../libs/i18n/default';
import getStyleProp from '../../utils/getStyleProp';
import getWindowScrollTop from '../../utils/getWindowScrollTop';
import TranslationProvider from '../../../common/components/HOC/TranslationProvider';
import SvgIcon from '../SvgIcon/SvgIcon';

function getImageSrc(bgImage) {
  return /(?:url\(['"]?)(.*?)(?:['"]?\))/.exec(bgImage)[1];
}

const MAIN_CLASS = 'AppBanner';

class AppBanner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDeferredAnimTimerStopped: false,
      isImagesLoaded: false,
      isVisible: false,
    };

    this.imageRef = React.createRef();
  }

  componentDidMount() {
    // Отложенная анимация баннера, если картинки уже закешированы.
    // Иначе, в общем потоке у анимации будет низкий fps
    setTimeout(() => {
      this.setState(({ isImagesLoaded, isVisible }) => ({
        isDeferredAnimTimerStopped: true,
        isVisible: isImagesLoaded ? true : isVisible,
      }));
    }, 500);

    this.preloadImages()
      .then(() => {
        this.setState(({ isVisible, isDeferredAnimTimerStopped }) => ({
          isImagesLoaded: true,
          isVisible: isDeferredAnimTimerStopped ? true : isVisible,
        }));
      })
      .catch(() => {
        this.setState(({ isVisible, isDeferredAnimTimerStopped }) => ({
          isImagesLoaded: true,
          isVisible: isDeferredAnimTimerStopped ? true : isVisible,
        }));
      });

    window.addEventListener('scroll', this.handleWindowScroll);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isVisible } = this.state;

    return isVisible !== nextState.isVisible;
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleWindowScroll);
  }

  getAppUrl() {
    const { device } = this.props;

    switch (device) {
      case 'iPhone':
        return urls.MOBILE_APP_APPLE_STORE;
      case 'mobileAndroid':
        return urls.MOBILE_APP_GOOGLE_PLAY;
      default:
        return '#';
    }
  }

  handleCloseClick = () => {
    this.close();
  };

  handleWindowScroll = () => {
    const { isImagesLoaded, isDeferredAnimTimerStopped } = this.state;

    // 200 - посчитал оптимальным выбором
    // Если прокрутили страницу больше этого значения - баннер прячется
    if (
      getWindowScrollTop() > 200 &&
      isImagesLoaded &&
      isDeferredAnimTimerStopped
    ) {
      this.setState({ isVisible: false });
    } else {
      this.setState({ isVisible: true });
    }
  };

  close() {
    const { onClose } = this.props;

    this.setState({ isVisible: false });

    setTimeout(() => {
      if (typeof onClose === 'function') {
        onClose();
      }
    }, 1200); // Время CSS анимации при закрытии
  }

  preloadImages() {
    return new Promise((resolve, reject) => {
      let img = new Image();

      img.onload = function() {
        if (typeof onSuccess === 'function') {
          resolve();

          img = null;
        }
      };

      img.onerror = function(error) {
        if (typeof onError === 'function') {
          reject(error);

          img = null;
        }
      };

      img.src = getImageSrc(
        getStyleProp(this.imageRef.current, 'background-image'),
      );
    });
  }

  render() {
    const { isVisible } = this.state;
    const {
      intl: { formatMessage },
      device,
    } = this.props;

    if (device === 'mobileAndroid') {
      return(<div></div>);
    } else {
      return (
        <div
          className={classNames(MAIN_CLASS, {
            [`${MAIN_CLASS}--visible`]: isVisible,
            [`${MAIN_CLASS}--iphoneX`]: device === 'iPhone',
            [`${MAIN_CLASS}--galaxyS8`]: device === 'mobileAndroid',
          })}
        >
          <div className={`${MAIN_CLASS}__close`} onClick={this.handleCloseClick}>
            <SvgIcon icon="cross" />
          </div>
          <div className={`${MAIN_CLASS}__bg`} />
          <div className={`${MAIN_CLASS}__inner`}>
            <div className={`${MAIN_CLASS}__device`}>
              <div ref={this.imageRef} className={`${MAIN_CLASS}__deviceImage`} />
            </div>
            <div className={`${MAIN_CLASS}__content`}>
              <div
                className={`${MAIN_CLASS}__title`}
                dangerouslySetInnerHTML={{
                  __html: formatMessage(defaultMessages.jsAppBannerTitle),
                }}
              />
              <div className={`${MAIN_CLASS}__actions`}>
                <a
                  className={`${MAIN_CLASS}__button`}
                  href={this.getAppUrl()}
                  title={formatMessage(defaultMessages.jsAppBannerButton)}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {formatMessage(defaultMessages.jsAppBannerButton)}
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

AppBanner.propTypes = {
  device: PropTypes.string,
  intl: intlShape.isRequired,
  onClose: PropTypes.func,
};

export default TranslationProvider(AppBanner);
