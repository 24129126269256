import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import { DEAL_TYPES } from '../AdCatalog/AdCatalog.constants';
import { FIELD_NAMES } from './constants';
import AdFormValidatable from './AdFormValidatableMixin';
import AdFormRadio from './AdFormRadio';

class AdFormType extends React.PureComponent {
  componentDidMount() {
    const { validatePresented } = this.props;

    validatePresented();
  }

  handleChange = event => {
    const { setAdState } = this.props;

    setAdState({ [FIELD_NAMES.TYPE]: event.currentTarget.value });
  };

  render() {
    const {
      intl: { formatMessage },
      type,
      errorMessage,
    } = this.props;

    return (
      <div className="form-row">
        <div className="form-row-label">
          {formatMessage(defaultMessages.jsAdFormKindType)}
        </div>
        <div className="form-row-content">
          <div className="radio-group radio-group-table" id="ad_input_type">
            <AdFormRadio
              value="rent"
              text={formatMessage(defaultMessages.jsCatalogFilterRent)}
              checked={type === DEAL_TYPES.RENT}
              onChange={this.handleChange}
            />
            <AdFormRadio
              value="daily_rent"
              text={formatMessage(defaultMessages.jsCatalogFilterDailyRent)}
              checked={type === DEAL_TYPES.DAILY_RENT}
              onChange={this.handleChange}
            />
            <AdFormRadio
              value="sale"
              text={formatMessage(defaultMessages.jsCatalogFilterSale)}
              checked={type === DEAL_TYPES.SALE}
              onChange={this.handleChange}
            />
          </div>
          {errorMessage()}
        </div>
      </div>
    );
  }
}

AdFormType.propTypes = {
  errorMessage: PropTypes.func,
  intl: intlShape.isRequired,
  type: PropTypes.string,
  setAdState: PropTypes.func,
  validatePresented: PropTypes.func,
};

export default AdFormValidatable(AdFormType, FIELD_NAMES.TYPE);
