/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Checkbox from './Checkbox';
import styles from './styles.scss';

class CheckboxList extends React.PureComponent {
  handleChange = value => {
    const { activeValues, onChange } = this.props;
    const index = activeValues.indexOf(value);

    onChange &&
      onChange(
        index > -1
          ? [...activeValues.slice(0, index), ...activeValues.slice(index + 1)]
          : [...activeValues, value],
      );
  };

  render() {
    const { listClassName, itemClassName, values, activeValues } = this.props;

    return (
      <div
        className={classNames(styles.list, {
          [listClassName]: listClassName !== null,
        })}
      >
        {values.map(({ title, value }, index) => (
          <Checkbox
            key={`checkbox-${index}`}
            className={itemClassName}
            title={title}
            value={value}
            isChecked={activeValues.indexOf(value) > -1}
            onChange={this.handleChange}
          />
        ))}
      </div>
    );
  }
}

CheckboxList.propTypes = {
  activeValues: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ),
  itemClassName: PropTypes.string,
  listClassName: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  onChange: PropTypes.func,
};

CheckboxList.defaultProps = {
  itemClassName: null,
  listClassName: null,
};

export default CheckboxList;
