import React from 'react';
import PropTypes from 'prop-types';
import Textarea from 'react-textarea-autosize/dist/react-textarea-autosize';

import AdFormTextareaHOC from './AdFormInputMixin';

const AdFormTextarea = React.memo(function({
  id = null,
  name = null,
  placeholder = null,
  value,
  wrapperClass,
  onChange,
  onFocus,
  onBlur,
}) {
  return (
    <div className={wrapperClass('textarea')}>
      <Textarea
        id={id}
        className="textarea-control"
        name={name}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value === null ? '' : value}
        placeholder={placeholder}
      />
    </div>
  );
});

AdFormTextarea.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  wrapperClass: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
};

export default AdFormTextareaHOC(AdFormTextarea);
