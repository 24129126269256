import { intlShape } from 'react-intl';
import axios from 'axios';
import classNames from 'classnames';
import each from 'lodash/each';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

import { fetchYandexMapAds, fetchAdById } from '../../api/rooms';
import { FILTER_KEYS } from './AdCatalog.constants';
import { defaultMessages } from '../../../../libs/i18n/default';
import dischargeArray from './utils/dischargeArray';
import getPolygonSquare from './utils/getPolygonSquare';
import FlashNotifierService from '../../services/FlashNotifier/FlashNotifier';
import AdCatalogYandexMapDrawer from './AdCatalogYandexMapDrawer';
import OfferCard from '../OfferCard/OfferCard';
import YmapPlacemarkWrapper from './mapHelpers/YmapPlacemarkWrapper';
import isDOMElement from './utils/isDOMElement';
import getScreenWidth from './utils/getScreenWidth';

const UPDATE_DELAY = 300;

const TILE_SIZE = 256;
const ZOOM_MIN = 2;
const ZOOM_MAX = 17;
const ZOOM_REFRACTION = 14;

const isNumber = n => !isNaN(parseFloat(n)) && isFinite(n);

const MARKER_SHAPE = {
  type: 'Circle',
  coordinates: [0, 0],
  radius: 12,
};

const MAX_DRAWER_POINTS = 40;

class AdCatalogYandexMap extends React.Component {
  constructor(props) {
    super(props);

    const {
      options: { zoom },
    } = this.props;

    this.polygons = [];
    this.viewedTiles = [];
    this.cachedTiles = [];
    this.cachedAdsInGeoObjects = {};
    this.prevZoom = zoom;
    this.currentZoom = zoom;
    this.isObjectsLoading = false;

    this.map = null;
    this.objectManager = null;
    this.zoomSegment = zoom >= ZOOM_REFRACTION ? ZOOM_REFRACTION : 2;

    this.drawerRef = React.createRef();
    this.mapRef = React.createRef();
  }

  componentDidMount() {
    const { isUkraine } = this.props;

    if (!isUkraine && typeof window.ymaps !== 'undefined') {
      ymaps.ready(this.initMap);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      filter: { geo_areas: geoAreas },
    } = this.props;

    if (this.map && nextProps.isActive) {
      this.map.container.fitToViewport();
    }

    if (!nextProps.filter.geo_areas && geoAreas) {
      this.drawerRef.current.clear();
    }

    if (nextProps.shouldUpdate && nextProps.isVisible && this.map) {
      this.currentZoom = Math.round(this.map.getZoom());
      this.clearCachedTiles();
      this.clearViewedTiles();
      this.clearObjectManager();
      this.setViewedTiles();
      this.getObjectsByViewedTiles();
    }
  }

  componentWillUnmount() {
    if (this.objectManager) {
      this.removeObjectManagerEvents();
    }
  }

  getVisibleTiles = () => {
    const [lowerLeft, upperRight] = this.map.getBounds();
    const [
      globalLowerLeftX,
      globalLowerLeftY,
    ] = ymaps.projection.wgs84Mercator.toGlobalPixels(
      lowerLeft,
      this.currentZoom,
    );
    const [
      globalUpperRightX,
      globalUpperRightY,
    ] = ymaps.projection.wgs84Mercator.toGlobalPixels(
      upperRight,
      this.currentZoom,
    );
    const tileMinX = Math.floor(globalLowerLeftX / TILE_SIZE);
    const tileMinY = Math.floor(globalLowerLeftY / TILE_SIZE);
    const tileMaxX = Math.floor(globalUpperRightX / TILE_SIZE);
    const tileMaxY = Math.floor(globalUpperRightY / TILE_SIZE);

    let tiles = [];

    for (let y = tileMinY; y >= tileMaxY; y--) {
      for (let x = tileMinX; x <= tileMaxX; x++) {
        tiles.push(x + '_' + y);
      }
    }

    return tiles;
  };

  getObjectsByTile = tile => {
    const { filter } = this.props;

    if (!this.requestsTimersForGetObjectsByTiles) {
      this.requestsTimersForGetObjectsByTiles = {};
    }

    if (!this.requestsForGetObjectsByTiles) {
      this.requestsForGetObjectsByTiles = {};
    }

    if (this.requestsTimersForGetObjectsByTiles[tile]) {
      clearTimeout(this.requestsTimersForGetObjectsByTiles[tile]);

      delete this.requestsTimersForGetObjectsByTiles[tile];
    }

    if (this.requestsForGetObjectsByTiles[tile]) {
      this.requestsForGetObjectsByTiles[tile]();

      delete this.requestsForGetObjectsByTiles[tile];
    }

    this.requestsTimersForGetObjectsByTiles[tile] = setTimeout(() => {
      const [x, y] = tile.split('_');

      const [requestPromise, cancel] = fetchYandexMapAds({
        filter,
        x,
        y,
        z: this.currentZoom,
        source: 'yandex',
      });

      this.requestsForGetObjectsByTiles[tile] = cancel;

      requestPromise
        .then(({ clusters, points }) => {
          this.handleGetObjectByTileSuccess({ clusters, points });

          this.cachedTiles.push(tile);
        })
        .catch(this.handleGetObjectByTileFailure);
    }, UPDATE_DELAY);
  };

  // eslint-disable-next-line react/sort-comp
  handleGetObjectByTileSuccess = newObjects => {
    this.indexRequestsForGetObjectsByTiles++;

    this.addObjectsToMap(newObjects);

    if (
      this.indexRequestsForGetObjectsByTiles ===
      this.countRequestsForGetObjectsByTiles
    ) {
      const { onLastAjaxSuccessLoadingObjects } = this.props;

      this.isObjectsLoading = false;
      onLastAjaxSuccessLoadingObjects();
    }
  };

  handleGetObjectByTileFailure = error => {
    this.indexRequestsForGetObjectsByTiles++;

    if (
      this.indexRequestsForGetObjectsByTiles ===
      this.countRequestsForGetObjectsByTiles
    ) {
      const { onLastAjaxErrorLoadingObjects } = this.props;

      this.isObjectsLoading = false;
      onLastAjaxErrorLoadingObjects(axios.isCancel(error));
    }
  };

  getObjectsByViewedTiles = () => {
    const { showLoader } = this.props;

    if (this.requestsForGetObjectsByTiles) {
      this.clearRequestsForGetObjectsByTiles();
    }

    const newTiles = this.viewedTiles.filter(
      viewedTile => this.cachedTiles.indexOf(viewedTile) === -1,
    );

    this.indexRequestsForGetObjectsByTiles = 0;
    this.countRequestsForGetObjectsByTiles = newTiles.length;

    if (this.countRequestsForGetObjectsByTiles > 0) {
      if (!this.isObjectsLoading) {
        this.isObjectsLoading = true;

        showLoader();
      }

      each(newTiles, this.getObjectsByTile);
    }
  };

  getMarkerViewedStateFromStorage = id => {
    if (DeviceSupports.localStorage) {
      return !!localStorage.getItem('offerId' + id + '_isViewed');
    }

    return false;
  };

  getMarkerTooltipText({ isViewed = false, status }) {
    const {
      intl: { formatMessage },
    } = this.props;

    let tooltip;

    if (isViewed) {
      tooltip = formatMessage(defaultMessages.jsCatalogMapPlacemarkHintViewed);
    } else {
      tooltip = formatMessage(
        defaultMessages.jsCatalogMapPlacemarkHintCurrentOffer,
      );
    }

    if (status === 'unverified') {
      tooltip = 'не проверено'; // TODO: translate
    }

    return tooltip;
  }

  getRoomsTextByCount = rooms => {
    const {
      intl: { formatMessage },
    } = this.props;

    let text = '';

    if (isNumber(rooms)) {
      text = rooms >= 4 ? '4+' : rooms;
    } else {
      text = formatMessage(defaultMessages.jsAdCardSpaceForRentRoom);
    }

    return text;
  };

  setViewedTiles() {
    const visibleTiles = this.getVisibleTiles();

    each(visibleTiles, visibleTile => {
      if (this.viewedTiles.indexOf(visibleTile) === -1) {
        this.viewedTiles.push(visibleTile);
      }
    });
  }

  setClusterPopupReady(cluster) {
    cluster.properties.isLoaded = true;
    this.objectManager.clusters.balloon.setData(cluster);
  }

  clearRequestsForGetObjectsByTiles() {
    for (let key in this.requestsForGetObjectsByTiles) {
      if (this.requestsForGetObjectsByTiles[key]) {
        this.requestsForGetObjectsByTiles[key]();

        delete this.requestsForGetObjectsByTiles[key];
      }
    }

    this.requestsForGetObjectsByTiles = void 0;
  }

  initMap = () => {
    const {
      intl,
      isVisible,
      options: { zoom, lat, lng },
      filter: { geo_areas: geoAreas },
    } = this.props;

    if (typeof ymaps === 'undefined' || !ymaps.Map) {
      return null;
    }

    this.map = new ymaps.Map(this.mapRef.current, {
      zoom: zoom,
      center: [lat, lng],
      maxZoom: ZOOM_MAX,
      minZoom: ZOOM_MIN,
      controls: [],
    });

    this.map.controls.add('zoomControl', {
      float: 'none',
      position: { left: 5, top: 5 },
    });

    this.placemarkWrapper = new YmapPlacemarkWrapper({
      intl,
      ymaps,
      mapInstance: this.map,
      renderOfferCardToContainer: this.renderOfferCardToContainer,
      destroyOfferCardFromContainer: this.destroyOfferCardFromContainer,
    });

    this.initObjectManager();

    if (geoAreas && geoAreas.length) {
      for (let i = 0; i < geoAreas.length; i++) {
        this.addPolygon(geoAreas[i]);
      }
    }

    this.map.events.add('click', e => {
      this.map.balloon.close();
    });

    this.map.events.add('boundschange', e => {
      this.currentZoom = Math.round(this.map.getZoom());

      if (this.prevZoom !== this.currentZoom) {
        this.prevZoom = this.currentZoom;
        this.clearCachedTiles();
        this.clearViewedTiles();
        this.clearObjectManager();
      }

      this.setViewedTiles();
      this.getObjectsByViewedTiles();
    });

    if (isVisible) {
      this.setViewedTiles();
      this.getObjectsByViewedTiles();
    }
  };

  addObjectManagerEvents() {
    this.objectManager.objects.events.add('click', this.handleMarkerClick);
    this.objectManager.objects.events.add(
      'balloonopen',
      this.handleMarkerPopupOpen,
    );

    this.objectManager.clusters.events.add('click', this.handleClusterClick);
    this.objectManager.clusters.events.add(
      'balloonopen',
      this.handleClusterPopupOpen,
    );
  }

  removeObjectManagerEvents() {
    this.objectManager.objects.events.remove('click', this.handleMarkerClick);
    this.objectManager.objects.events.remove(
      'balloonopen',
      this.handleMarkerPopupOpen,
    );

    this.objectManager.clusters.events.remove('click', this.handleClusterClick);
    this.objectManager.clusters.events.remove(
      'balloonopen',
      this.handleClusterPopupOpen,
    );
  }

  clearCachedTiles() {
    this.cachedTiles = [];
  }

  clearViewedTiles() {
    this.viewedTiles = [];
  }

  clearObjectManager() {
    this.objectManager.removeAll();
  }

  handleFavorite = id => {
    this.cachedAdsInGeoObjects[id].favorite = true;
  };

  handleUnfavorite = id => {
    this.cachedAdsInGeoObjects[id].favorite = false;
  };

  renderOfferCardToContainer = ({ id, container = null, props = {} }) => {
    if (!isDOMElement(container) || !this.cachedAdsInGeoObjects[id]) {
      return;
    }

    const {
      intl: { locale },
      isUserLoggedIn,
    } = this.props;

    ReactDOM.render(
      <OfferCard
        ad={this.cachedAdsInGeoObjects[id]}
        locale={locale}
        isUserLoggedIn={isUserLoggedIn}
        onFavorite={this.handleFavorite}
        onUnfavorite={this.handleUnfavorite}
        {...props}
      />,
      container,
    );
  };

  destroyOfferCardFromContainer = container => {
    if (!isDOMElement(container)) {
      return;
    }

    ReactDOM.unmountComponentAtNode(container);
  };

  initObjectManager() {
    this.objectManager = new ymaps.ObjectManager({
      gridSize: 128,
      zoomMargin: 105,
      geoObjectIconLayout: this.placemarkWrapper.getMarkerTemplate(),
      geoObjectIconShape: MARKER_SHAPE,
      geoObjectOpenBalloonOnClick: false,
      geoObjectBalloonAutoPan: false,
      geoObjectBalloonLayout: this.placemarkWrapper.getMarkerPopupLayout(),
      geoObjectBalloonContentLayout: this.placemarkWrapper.getMarkerPopupContentLayout(),
      geoObjectBalloonPanelMaxMapArea: 0,
      clusterOpenBalloonOnClick: false,
      clusterDisableClickZoom: true,
      clusterIconLayout: 'default#pieChart',
      clusterIconPieChartRadius: 19,
      clusterIconPieChartCoreRadius: 14,
      clusterIconPieChartStrokeWidth: 0,
      clusterBalloonAutoPan: false,
      clusterBalloonLayout: this.placemarkWrapper.getClusterPopupLayout(),
      clusterBalloonContentLayout: this.placemarkWrapper.getClusterPopupContentLayout(),
      clusterBalloonAutoPanMargin: [34, getScreenWidth() > 370 ? 34 : 0], // TODO: Временное решение на подумать
      clusterBalloonPanelMaxMapArea: 0,
    });

    this.map.geoObjects.add(this.objectManager);
    this.addObjectManagerEvents();
  }

  addObjectsToMap({ points = [], clusters = [] } = {}) {
    let features = [];

    for (let i = 0, length = points.length; i < length; i++) {
      const {
        id,
        lat,
        lng,
        price,
        rooms,
        status,
        price_currency_code: priceCurrencyCode,
      } = points[i];

      if (lat && lng) {
        const isViewed = this.getMarkerViewedStateFromStorage(id);

        let markerObject = this.objectManager.objects.getById(id);

        if (markerObject) {
          this.objectManager.remove(markerObject);
        }

        features.push({
          type: 'Feature',
          id,
          geometry: {
            type: 'Point',
            coordinates: [lat, lng],
          },
          properties: {
            price,
            priceCurrencyCode,
            isLoaded: !!this.cachedAdsInGeoObjects[id],
            rooms: this.getRoomsTextByCount(rooms),
            hintContent: this.getMarkerTooltipText({
              isViewed,
              status,
            }),
            moderated: status === 'verified',
            viewed: isViewed,
          },
        });
      }
    }

    for (let i = 0, length = clusters.length; i < length; i++) {
      const { lat, lng, unverified, id, points, verified } = clusters[i];

      if (lat && lng) {
        let pieChartData = [];

        if (verified) {
          pieChartData.push({ weight: verified, color: '#2daa4a' });
        }

        if (unverified) {
          pieChartData.push({ weight: unverified, color: '#fcd02c' });
        }

        const clusterObject = this.objectManager.objects.getById(id);

        if (clusterObject) {
          this.objectManager.remove(clusterObject);
        }

        features.push({
          type: 'Cluster',
          id,
          features: points,
          geometry: {
            type: 'Point',
            coordinates: [lat, lng],
          },
          properties: {
            data: pieChartData,
            iconContent: verified + unverified,
          },
        });
      }
    }

    this.objectManager.objects.add({
      type: 'FeatureCollection',
      features,
    });
  }

  addPolygon(coords) {
    const drawerPolygon = new ymaps.Polygon(
      [coords],
      {},
      {
        fillColor: '#18a24b',
        fillOpacity: 0.3,
        strokeWidth: 3,
        strokeColor: '#18a24b',
        strokeOpacity: 0.9,
      },
    );

    this.polygons.push(drawerPolygon);
    this.map.geoObjects.add(drawerPolygon);

    return drawerPolygon;
  }

  handleMarkerClick = e => {
    if (this.isObjectsLoading) {
      return;
    }

    this.objectManager.objects.balloon.open(e.get('objectId'));
  };

  handleMarkerPopupOpen = e => {
    const objectId = e.get('objectId'); // objectId === ad.id
    const object = this.objectManager.objects.getById(objectId);

    if (!this.cachedAdsInGeoObjects[objectId]) {
      fetchAdById(objectId).then(ad => {
        this.cachedAdsInGeoObjects[objectId] = ad;

        // Для встроенного шаблонизатора ymaps
        object.properties.isLoaded = true;
        object.properties.viewed = true;

        if (DeviceSupports.localStorage) {
          localStorage.setItem('offerId' + objectId + '_isViewed', true);
        }

        this.objectManager.objects.balloon.setData(object);
      });
    }
  };

  handleClusterClick = e => {
    if (this.isObjectsLoading) {
      return;
    }

    const clusterId = e.get('objectId');
    const cluster = this.objectManager.clusters.getById(clusterId);

    if (
      (cluster && cluster.features && cluster.features.length > 0) ||
      this.map.getZoom() === this.map.zoomRange.getCurrent()[1]
    ) {
      this.objectManager.clusters.balloon.open(clusterId);
    } else {
      this.map.setCenter(cluster.geometry.coordinates, this.map.getZoom() + 1);
    }
  };

  handleClusterPopupOpen = e => {
    const {
      intl: { formatMessage },
    } = this.props;

    const clusterId = e.get('objectId');
    const cluster = this.objectManager.clusters.getById(clusterId);

    cluster.properties.loadedIds = [];

    const numberRequests = cluster.properties.geoObjects.length;
    let numberSuccessfulRequest = 0;
    let numberFailedRequest = 0;

    const isRequestLast = () => {
      return numberFailedRequest + numberSuccessfulRequest === numberRequests;
    };

    each(cluster.properties.geoObjects, ({ id }) => {
      if (!this.cachedAdsInGeoObjects[id]) {
        fetchAdById(id)
          .then(ad => {
            numberSuccessfulRequest++;

            this.cachedAdsInGeoObjects[id] = ad;

            cluster.properties.loadedIds.push(id);
            cluster.properties.numberLoaded = numberSuccessfulRequest;

            if (isRequestLast()) {
              this.setClusterPopupReady(cluster);

              if (numberFailedRequest > 0) {
                FlashNotifierService.notifyError(
                  formatMessage(
                    defaultMessages.jsFlashNotifierCouldNotLoadAllAdsTryAgain,
                  ),
                );
              }
            }
          })
          .catch(() => {
            numberFailedRequest++;

            if (numberFailedRequest === numberRequests) {
              FlashNotifierService.notifyError(
                formatMessage(
                  defaultMessages.jsFlashNotifierErrorHasOccurredTryAgain,
                ),
              );
              return;
            }

            // Если последний запрос с ошибкой (возможно есть ещё неудачные запросы)
            if (isRequestLast()) {
              FlashNotifierService.notifyError(
                formatMessage(
                  defaultMessages.jsFlashNotifierCouldNotLoadAllAdsTryAgain,
                ),
              );
              this.setClusterPopupReady(cluster);
            }
          });
      } else {
        numberSuccessfulRequest++;

        cluster.properties.loadedIds.push(id);
        cluster.properties.numberLoaded = numberSuccessfulRequest;

        if (isRequestLast()) {
          this.setClusterPopupReady(cluster);
        }
      }
    });
  };

  handleDraw = point => {
    const [x, y] = point;
    const map = this.map;
    const projection = map.options.get('projection');

    this.drawerPolygonPixelCoords.push([x, y]);
    this.drawerPolygonGeoCoords.push(
      projection.fromGlobalPixels(
        map.converter.pageToGlobal([x, y]),
        map.getZoom(),
      ),
    );

    const index = this.drawerPolygonGeoCoords.length - 1;

    this.drawerPolyline.geometry.insert(
      index,
      this.drawerPolygonGeoCoords[index],
    );
    this.drawerPolyline.geometry.remove(index + 1);
  };

  handleDrawStart = () => {
    this.drawerPolygonPixelCoords = []; // координаты в пикселях
    this.drawerPolygonGeoCoords = []; // геокоординаты

    this.map.geoObjects.remove(this.drawerPolyline);

    this.drawerPolyline = new ymaps.Polyline(
      this.drawerPolygonGeoCoords,
      {},
      {
        strokeColor: '#ff4729',
        strokeWidth: 3,
      },
    );

    this.map.geoObjects.add(this.drawerPolyline);
  };

  handleDrawEnd = callbackSuccess => {
    const { filter, updateFilter } = this.props;
    const map = this.map;
    const projection = this.map.options.get('projection');

    this.isObjectsLoading = true;

    this.map.geoObjects.remove(this.drawerPolyline);

    if (this.drawerPolygonPixelCoords.length > MAX_DRAWER_POINTS) {
      this.drawerPolygonPixelCoords = dischargeArray(
        this.drawerPolygonPixelCoords,
        MAX_DRAWER_POINTS,
      );
    }

    // Если выделенная область имеет большую площадь, чем 300 пикселей
    // Измеряем в пикселях, т.к., если измерять по геокординатам, то на разных зумах одна
    // и таже выделенная область имеет разную площадь
    if (getPolygonSquare(this.drawerPolygonPixelCoords) > 300) {
      this.drawerPolygonGeoCoords = this.drawerPolygonPixelCoords.map(item => {
        return projection.fromGlobalPixels(
          map.converter.pageToGlobal(item),
          map.getZoom(),
        );
      });

      const drawerPolygon = this.addPolygon(this.drawerPolygonGeoCoords);

      updateFilter(
        { ...filter, geo_areas: [drawerPolygon.geometry.getCoordinates()[0]] },
        () => {
          this.isObjectsLoading = false;
        },
      );

      callbackSuccess();
    }
  };

  handleDrawClear = () => {
    const { filter, updateFilter } = this.props;

    this.isObjectsLoading = true;

    this.polygons.forEach(polygon => {
      this.map.geoObjects.remove(polygon);
    });

    this.polygons = [];

    let newFilter = Object.assign({}, filter);

    delete newFilter[FILTER_KEYS.GEO_AREAS];

    updateFilter(newFilter, () => {
      this.isObjectsLoading = false;
    });
  };

  render() {
    const {
      intl,
      isVisible,
      filter: { geo_areas: geoAreas },
    } = this.props;

    return (
      <div
        className={classNames('catalog-map', {
          'catalog-map--hidden': !isVisible,
        })}
      >
        <div ref={this.mapRef} className="catalog-map-canvas" />
        <AdCatalogYandexMapDrawer
          ref={this.drawerRef}
          isDrawn={!!geoAreas}
          onDraw={this.handleDraw}
          onDrawStart={this.handleDrawStart}
          onDrawEnd={this.handleDrawEnd}
          onDrawClear={this.handleDrawClear}
          intl={intl}
        />
      </div>
    );
  }
}

AdCatalogYandexMap.propTypes = {
  filter: PropTypes.object,
  intl: intlShape.isRequired,
  isActive: PropTypes.bool,
  isUkraine: PropTypes.bool,
  isUserLoggedIn: PropTypes.bool,
  isVisible: PropTypes.bool,
  options: PropTypes.object,
  shouldUpdate: PropTypes.bool.isRequired,
  showLoader: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
  onLastAjaxErrorLoadingObjects: PropTypes.func.isRequired,
  onLastAjaxSuccessLoadingObjects: PropTypes.func.isRequired,
};

export default AdCatalogYandexMap;
