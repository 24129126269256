import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { subwaysApi } from '../../api/subways';
import { defaultMessages } from '../../../../libs/i18n/default';
import { FIELD_NAMES } from './constants';
import filterOptions from '../../../common/helpers/ReactSelect/defaultFilterOptions';
import FlashNotifierService from '../../services/FlashNotifier/FlashNotifier';

class AdFormSubways extends React.PureComponent {
  state = {
    subways: [],
  };

  componentDidMount() {
    this.fetchSubways();
  }

  handleChange = value => {
    const { setAdState } = this.props;

    const subwayIds = value.map(item => Number(item.value));

    setAdState({
      [FIELD_NAMES.SUBWAY_IDS]: subwayIds.length > 0 ? subwayIds : [''],
    });
  };

  optionRenderer = option => {
    let icons = null;

    if (option.colors.length) {
      icons = option.colors.map(color => (
        <span
          key={`color-${color}`}
          className="Select-option-metro-icon"
          style={{ backgroundColor: color }}
        />
      ));

      icons = <span className="Select-option-metro-label">{icons}</span>;
    }

    return (
      <span className="Select-option-metro">
        <span className="Select-option-metro-count">{option.count}</span>
        {icons}
        <span className="Select-option-metro-text">{option.label}</span>
      </span>
    );
  };

  fetchSubways() {
    const {
      intl: { formatMessage },
    } = this.props;

    subwaysApi
      .fetchSubways()
      .then(data => {
        const subways = data.filter(item => item.id !== null);

        this.setState({ subways });
      })
      .catch(() => {
        FlashNotifierService.notifyError(
          formatMessage(
            defaultMessages.jsFlashNotifierErrorHasOccurredRefreshBrowser,
          ),
        );
      });
  }

  render() {
    const {
      subwayIds,
      intl: { formatMessage },
    } = this.props;
    const { subways } = this.state;

    const options = subways.map(({ id, text, colors, count }) => ({
      count,
      colors,
      value: id,
      label: text,
    }));

    return (
      <div className="form-row">
        <div className="form-row-label">
          {formatMessage(defaultMessages.jsAdFormSubwaysLabel)}
        </div>
        <div className="form-row-content">
          <Select
            multi
            name="ad[subway_ids][]"
            noResultsText={formatMessage(
              defaultMessages.jsReactSelectNoResults,
            )}
            value={subwayIds}
            options={options}
            placeholder=""
            filterOptions={filterOptions}
            optionRenderer={this.optionRenderer}
            onChange={this.handleChange}
          />
        </div>
      </div>
    );
  }
}

AdFormSubways.propTypes = {
  intl: intlShape.isRequired,
  setAdState: PropTypes.func,
  subwayIds: PropTypes.array,
};

export default AdFormSubways;
