/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import React from 'react';

const getDisplayName = Component =>
  Component.displayName || Component.name || 'Component';

export default function(EnhancedComponent) {
  class WrapperComponent extends React.PureComponent {
    static displayName = `AdFormInput(${getDisplayName(EnhancedComponent)})`;

    renderIcon = () => {
      const { icon, id } = this.props;

      if (icon) {
        return (
          <label className={`input-icon input-icon-${icon}`} htmlFor={id} />
        );
      }
    };

    wrapperClass = wrapperClass => {
      const inputName = wrapperClass;
      const { classMods, error, value } = this.props;

      if (classMods) {
        classMods.forEach(mod => {
          wrapperClass += ` ${inputName}-${mod}`;
        });
      }

      if (error) {
        wrapperClass += ' __error';
      }

      if (!error && value) {
        wrapperClass += ' __success';
      }

      return wrapperClass;
    };

    render() {
      const { icon, id } = this.props;

      return (
        <EnhancedComponent
          {...this.props}
          renderIcon={this.renderIcon}
          iconComponent={
            icon !== null && (
              <label className={`input-icon input-icon-${icon}`} htmlFor={id} />
            )
          }
          wrapperClass={this.wrapperClass}
        />
      );
    }
  }

  WrapperComponent.propTypes = {
    classMods: PropTypes.array,
    error: PropTypes.bool,
    icon: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  WrapperComponent.defaultProps = {
    icon: null,
    id: null,
  };

  return WrapperComponent;
}
