/* eslint-disable jsx-a11y/label-has-associated-control */
import Nouislider from 'nouislider-react';
import PropTypes from 'prop-types';
import React from 'react';

import { FILTER_KEYS } from './AdCatalog.constants';

const formatIntegerPercent = value => `${Number(value)}%`;

const SLIDER = {
  STEP: 10,
  RANGE: { min: 0, max: 100 },
  TOOLTIPS: { to: formatIntegerPercent },
  CONNECT: [true, false],
};

class AdCatalogFilterBodyRealtor extends React.PureComponent {
  handleChange = event => {
    const { updateFilter } = this.props;
    const {
      target: { checked },
    } = event;

    updateFilter({
      [FILTER_KEYS.REALTOR]: checked,
      [FILTER_KEYS.REALTOR_COMISSION]: checked ? 100 : null,
    });
  };

  handleSliderSet = values => {
    const { comission, updateFilter } = this.props;
    const nextValue = parseInt(values[0]);

    if (comission === nextValue) {
      return 0;
    }

    updateFilter({
      [FILTER_KEYS.REALTOR_COMISSION]: nextValue,
    });
  };

  render() {
    const { isActive, comission } = this.props;

    return (
      <React.Fragment>
        <div className="filter-row filter-row-padding-top">
          <div className="filter-label">Объявления с комиссией</div>
          <label className="filter-checkbox filter-checkbox-toggler">
            <input
              className="filter-checkbox-control"
              type="checkbox"
              checked={isActive}
              onChange={this.handleChange}
            />
            <span className="filter-checkbox-fake" />
            <span className="filter-checkbox-text">Показывать </span>
          </label>
        </div>
        {isActive && (
          <div className="filter-row">
            <div className="filter-label">Комиссия до</div>
            <div className="filter-range-slider filter-range-slider-comission">
              <Nouislider
                className="filter-range-slider-ui-comission"
                start={comission}
                connect={SLIDER.CONNECT}
                step={SLIDER.STEP}
                range={SLIDER.RANGE}
                tooltips={[SLIDER.TOOLTIPS]}
                onSet={this.handleSliderSet}
              />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

AdCatalogFilterBodyRealtor.propTypes = {
  comission: PropTypes.number,
  // intl: intlShape.isRequired,
  isActive: PropTypes.bool,
  updateFilter: PropTypes.func.isRequired,
};

AdCatalogFilterBodyRealtor.defaultProps = {
  comission: 100,
  isActive: true,
};

export default AdCatalogFilterBodyRealtor;
