/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';

import { fetchAdByIdWithCancelation } from '../../api/rooms';
import OfferCard from '../OfferCard/OfferCard';

class AdCatalogGoogleMapMarkerPopup extends React.Component {
  constructor(props) {
    super(props);

    const { ad = {}, isLoaded = false } = this.props;

    this.state = {
      ad,
      isLoaded,
    };
  }

  componentDidMount() {
    const { isLoaded } = this.state;

    if (!isLoaded) {
      this.getData();
    }
  }

  componentWillUnmount() {
    if (this.requestCancel) {
      this.requestCancel();
      this.requestCancel = null;
    }
  }

  getData() {
    const { id, onAjaxSuccess } = this.props;

    const [requestPromise, cancel] = fetchAdByIdWithCancelation(id);

    this.requestCancel = cancel;

    requestPromise.then(response => {
      this.setState({
        ad: response,
        isLoaded: true,
      });

      if (onAjaxSuccess) {
        onAjaxSuccess(response);
      }
    });
  }

  handleOfferFavorite = id => {
    const { ad } = this.state;
    const { onOfferFavorite } = this.props;

    let copyAd = Object.assign({}, ad);

    copyAd.favorite = true;

    if (ad) {
      this.setState({ ad: copyAd });
    }

    if (typeof onOfferFavorite !== 'undefined') {
      onOfferFavorite(id);
    }
  };

  handleOfferUnfavorite = id => {
    const { onOfferUnfavorite } = this.props;
    const { ad } = this.state;

    let copyAd = Object.assign({}, ad);
    copyAd.favorite = false;

    if (ad) {
      this.setState({ ad: copyAd });
    }

    if (typeof onOfferUnfavorite !== 'undefined') {
      onOfferUnfavorite(id);
    }
  };

  renderContent() {
    const { isLoaded, ad } = this.state;
    const {
      isUserLoggedIn,
      intl: { locale },
    } = this.props;

    if (!isLoaded) {
      return (
        <div className="map-popup-loader">
          <div className="spinner" />
        </div>
      );
    } else {
      return (
        <OfferCard
          isVertical
          ad={ad}
          isUserLoggedIn={isUserLoggedIn}
          onFavorite={this.handleOfferFavorite}
          onUnfavorite={this.handleOfferUnfavorite}
          locale={locale}
        />
      );
    }
  }

  render() {
    const { placement, onClose } = this.props;

    return (
      <div className={`map-popup map-popup-marker map-popup-${placement}`}>
        <div className="map-popup-close" onClick={onClose}>
          <div className="map-popup-close-icon" />
        </div>
        <div className="map-popup-arrow" />
        <div className="map-popup-content">{this.renderContent()}</div>
      </div>
    );
  }
}

AdCatalogGoogleMapMarkerPopup.propTypes = {
  ad: PropTypes.object,
  id: PropTypes.number,
  intl: intlShape.isRequired,
  isLoaded: PropTypes.bool,
  isUserLoggedIn: PropTypes.bool,
  placement: PropTypes.string,
  onAjaxSuccess: PropTypes.func,
  onClose: PropTypes.func,
  onOfferFavorite: PropTypes.func,
  onOfferUnfavorite: PropTypes.func,
};

export default AdCatalogGoogleMapMarkerPopup;
