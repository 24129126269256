import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-flex-modal';
import classNames from 'classnames';

import { fetchAdContacts } from '../../api/rooms';
import styles from './AdContactsModal.scss';

class AdContactsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contacts: null,
      isLoading: true,
      phone: '',
    };
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;
    const { contacts } = this.state;

    if (contacts === null && isOpen && !prevProps.isOpen) {
      const { id } = this.props;

      fetchAdContacts(id).then(data => {
        this.setState({
          phone: data.phone,
          contacts: data.user_contacts,
          realtorName: data.realtor_name,
          isLoading: false,
          // fromRealtor: data.from_realtor,
          // realtorImage: data.realtor_image,
          // showUnverifiedNotice: data.show_unverified_notice,
        });
      });
    }
  }

  render() {
    const { isLoading, contacts, phone, realtorName } = this.state;
    const { isOpen, onClose } = this.props;

    return (
      <Modal
        transition
        isOpen={isOpen}
        onClose={onClose}
        modalDialogClass={styles.modalDialog}
      >
        {isLoading ? (
          <div className={styles.spinnerContainer}>
            <span className={classNames('spinner', styles.spinner)} />
          </div>
        ) : (
          <div className={styles.content}>
            <h3 className={styles.title}>Телефон</h3>
            <p className={styles.phone}>{phone}</p>
            {realtorName || contacts ? (
              <p className={styles.notice}>
                {realtorName ? realtorName + '. ' : ''}
                {contacts}
              </p>
            ) : null}
            {phone ? (
              <a href={`tel:${phone}`} className={styles.callButton}>
                Позвонить
              </a>
            ) : null}
            <p className={styles.warning}>
              Друзья, обратите внимание. Никогда, ни при каких обстоятельствах{' '}
              <strong>
                не передавайте аванс за квартиру до тех пор, пока вы не посетите
                её лично и не увидите документы
              </strong>{' '}
              , подтверждающие право собственности.
            </p>
          </div>
        )}
      </Modal>
    );
  }
}

AdContactsModal.propTypes = {
  id: PropTypes.number,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

AdContactsModal.defaultProps = {
  isOpen: false,
};

export default AdContactsModal;
