import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Button.scss';

export const Button = ({ className, children, href, onClick }) => {
  return href ? (
    <a href={href} className={classNames(styles.button, className)}>
      {children}
    </a>
  ) : (
    <button
      className={classNames(styles.button, className)}
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
};
