import React from 'react';
import PropTypes from 'prop-types';

const MetroSchemeModalSchemeMapSVG = ({ city: { name } }) => (
  <div className={'metro-scheme-map-image __' + name} />
);

MetroSchemeModalSchemeMapSVG.propTypes = {
  city: PropTypes.shape({
    name: PropTypes.string,
  }),
};

export default MetroSchemeModalSchemeMapSVG;
