import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const AdFormSocialAuthButton = React.memo(function({ classMod, url, title }) {
  return (
    <a
      className={classNames('button', { ['button-' + classMod]: classMod })}
      href={url}
      title={title}
    >
      {title}
    </a>
  );
});

AdFormSocialAuthButton.propTypes = {
  classMod: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
};

export default AdFormSocialAuthButton;
