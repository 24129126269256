/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, no-undefined */
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';

import { defaultMessages } from '../../../../libs/i18n/default';
import AdCatalogChildLinks from './AdCatalogLinks';
import AdCatalogHeaderBreadcrumbs from './AdCatalogHeaderBreadcrumbs';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';

const DEFAULT_CLASSNAME = 'sorting-item';

class AdCatalogHeader extends React.Component {
  setOrdering = (name, e) => {
    const { filter, updateFilter } = this.props;

    updateFilter({ ...filter, order: name });
  };

  setSortClassNames(name) {
    const {
      filter: { order },
    } = this.props;

    return order === name ? DEFAULT_CLASSNAME + ' __active' : DEFAULT_CLASSNAME;
  }

  render() {
    const { allAdsAmount, breadcrumbs, intl, h1, childLinks } = this.props;
    const { formatPlural, formatMessage } = intl;

    return (
      <div className="catalog-header">
        <div className="container-fluid">
          <AdCatalogHeaderBreadcrumbs breadcrumbs={breadcrumbs} intl={intl} />
          <div className="sorting">
            <div className="sorting-title">
              <span>{allAdsAmount}</span>{' '}
              {formatMessage(
                defaultMessages[
                  `jsPluralsApartmentsSorted${capitalizeFirstLetter(
                    formatPlural(allAdsAmount),
                  )}`
                ],
              )}
            </div>
            <div
              className={this.setSortClassNames(undefined)}
              onClick={this.setOrdering.bind(this, undefined)}
            >
              {formatMessage(defaultMessages.jsCatalogSortingByDate)}
            </div>
            <div
              className={this.setSortClassNames('price')}
              onClick={this.setOrdering.bind(this, 'price')}
            >
              {formatMessage(defaultMessages.jsCatalogSortingByPrice)}
            </div>
            <div
              className={this.setSortClassNames('space')}
              onClick={this.setOrdering.bind(this, 'space')}
            >
              {formatMessage(defaultMessages.jsCatalogSortingByArea)}
            </div>
          </div>
          <h1 className="catalog-title">{h1}</h1>
          <AdCatalogChildLinks childLinks={childLinks} />
        </div>
      </div>
    );
  }
}

AdCatalogHeader.propTypes = {
  allAdsAmount: PropTypes.any,
  breadcrumbs: PropTypes.any,
  childLinks: PropTypes.any,
  filter: PropTypes.any,
  h1: PropTypes.any,
  intl: intlShape.isRequired,
  updateFilter: PropTypes.func,
};

export default AdCatalogHeader;
