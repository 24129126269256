import qs from 'qs';

import axios from './axios';

export const getUrlByFilter = params => {
  return axios
    .get(
      `api/frontend/main_filter/result_url${qs.stringify(params, {
        addQueryPrefix: true,
        arrayFormat: 'brackets',
      })}`,
    )
    .then(({ data }) => data)
    .catch(({ response: { data: { errors } } }) => Promise.reject(errors));
};

export const mainFilterApi = {
  getUrlByFilter,
};

export default mainFilterApi;
