import { intlShape } from 'react-intl';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import { FILTER_KEYS } from './AdCatalog.constants';

class AdCatalogFilterBodyExtraSpaceFloor extends Component {
  onSpaceMinChange = e => {
    this.updateFilter(FILTER_KEYS.SPACE_MIN, e.target.value);
  };

  onSpaceMaxChange = e => {
    this.updateFilter(FILTER_KEYS.SPACE_MAX, e.target.value);
  };

  onFloorMinChange = e => {
    this.updateFilter(FILTER_KEYS.FLOOR_MIN, e.target.value);
  };

  onFloorMaxChange = e => {
    this.updateFilter(FILTER_KEYS.FLOOR_MAX, e.target.value);
  };

  updateFilter(key, value) {
    const { updateFilter } = this.props;

    updateFilter({
      [key]: value !== '' ? value : null,
    });
  }

  render() {
    const { intl, floorMin, floorMax, spaceMin, spaceMax } = this.props;
    const { formatMessage } = intl;

    return (
      <div className="filter-row">
        <div className="filter-grid">
          <div className="filter-grid-cell filter-grid-cell-50p">
            <div
              className="filter-label"
              dangerouslySetInnerHTML={{
                __html: formatMessage(defaultMessages.jsCatalogFilterSpace),
              }}
            />
            <div className="filter-grid">
              <div className="filter-grid-cell filter-grid-cell-50p">
                <input
                  className="filter-input filter-input-sm text-center"
                  type="text"
                  value={spaceMin}
                  onChange={this.onSpaceMinChange}
                />
              </div>
              <div className="filter-grid-cell filter-grid-cell-gap filter-grid-cell-gap-dash">
                -
              </div>
              <div className="filter-grid-cell filter-grid-cell-50p">
                <input
                  className="filter-input filter-input-sm text-center"
                  type="text"
                  value={spaceMax}
                  onChange={this.onSpaceMaxChange}
                />
              </div>
            </div>
          </div>
          <div className="filter-grid-cell filter-grid-cell-gap filter-grid-cell-gap-34">
            &nbsp;
          </div>
          <div className="filter-grid-cell filter-grid-cell-50p">
            <div className="filter-label">
              {formatMessage(defaultMessages.jsCatalogFilterFloor)}
            </div>
            <div className="filter-grid">
              <div className="filter-grid-cell filter-grid-cell-50p">
                <input
                  className="filter-input filter-input-sm text-center"
                  type="text"
                  value={floorMin}
                  onChange={this.onFloorMinChange}
                />
              </div>
              <div className="filter-grid-cell filter-grid-cell-gap filter-grid-cell-gap-dash">
                -
              </div>
              <div className="filter-grid-cell filter-grid-cell-50p">
                <input
                  className="filter-input filter-input-sm text-center"
                  type="text"
                  value={floorMax}
                  onChange={this.onFloorMaxChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AdCatalogFilterBodyExtraSpaceFloor.propTypes = {
  floorMax: PropTypes.string,
  floorMin: PropTypes.string,
  intl: intlShape.isRequired,
  spaceMax: PropTypes.string,
  spaceMin: PropTypes.string,
  updateFilter: PropTypes.func.isRequired,
};

export default AdCatalogFilterBodyExtraSpaceFloor;
