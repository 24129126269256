import React from 'react';
import ReactDOM from 'react-dom';

import FlashNotifier from '../../components/FlashNotifier/FlashNotifier';

class FlashNotifierService {
  TIMER_DELAY = 5000;
  timer = null;

  getContainer = () => {
    let container = document.querySelector('[data-flash-notifier-container]');

    if (container == null) {
      container = document.createElement('div');
      container.setAttribute('data-flash-notifier-container', '');
      document.body.appendChild(container);
    }

    return container;
  };

  notify(type, text, delay) {
    const delayNotify = delay || this.TIMER_DELAY;

    this.render(type, text, delayNotify);

    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.destroy();
    }, delayNotify);
  }

  notifyError(text, delay) {
    this.notify('error', text, delay);
  }

  notifySuccess(text, delay) {
    this.notify('success', text, delay);
  }

  notifyBlack(text, delay) {
    this.notify('black', text, delay);
  }

  handleCloseClick = () => {
    this.destroy();
  };

  render(type, text, delay) {
    this.destroy();

    ReactDOM.render(
      <FlashNotifier
        delay={delay}
        type={type}
        text={text}
        onClose={this.handleClose}
      />,
      this.getContainer(),
    );
  }

  destroy() {
    ReactDOM.unmountComponentAtNode(this.getContainer());
  }
}

const FlashNotifierServiceInstance = new FlashNotifierService();

export default FlashNotifierServiceInstance;
