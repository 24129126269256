import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Nouislider from 'nouislider-react';
import debounce from 'lodash/debounce';

import { FIELD_NAMES } from '../constants';
import CustomInput from '../../RentSearchApplication/CustomInput';
import formatPrice from '../../../utils/formatPrice';
import styles from '../styles.scss';

const INPUT_OPTIONS = {
  numeral: true,
  numeralThousandsGroupStyle: 'thousand',
  delimiter: ' ',
};
const DEFAULT_SLIDER_STEP = 1;
const SPACE_MIN = 0;
const SPACE_MAX = 300;
const RANGE = {
  min: [SPACE_MIN],
  max: [SPACE_MAX],
};

function StepMinSpace({ minSpace, errors, setMainValues, goToNextStep }) {
  const debouncedInputChange = React.useRef(null);
  const [spaceValue, setSpaceValue] = React.useState(minSpace);
  const [sliderSpace, setSliderSpace] = React.useState(minSpace);

  const isValid = !errors[FIELD_NAMES.MIN_SPACE];
  const isEmpty = minSpace === 0;

  const handleInputChange = React.useCallback(
    rawValue => {
      if (debouncedInputChange && debouncedInputChange.current) {
        debouncedInputChange.current.cancel();
      }

      debouncedInputChange.current = debounce(() => {
        setSliderSpace(sliderPrice =>
          rawValue !== sliderPrice ? rawValue : sliderPrice,
        );

        setMainValues({ [FIELD_NAMES.MIN_SPACE]: rawValue });
      }, 500);

      return debouncedInputChange.current();
    },
    [sliderSpace],
  );

  const handleSliderUpdate = React.useCallback(
    (render, handle, value, un, percent) => {
      setSpaceValue(parseInt(value[0], 10));
    },
  );

  const handleSliderSet = React.useCallback(
    (render, handle, value, un, percent) => {
      setMainValues({ [FIELD_NAMES.MIN_SPACE]: parseInt(value[0], 10) });
    },
  );

  const handleSubmit = () => {
    if (!isValid) {
      return null;
    }

    goToNextStep();
  };

  return (
    <React.Fragment>
      <div className={classNames(styles.inner, styles.innerTopbarSpace)}>
        <div className={classNames(styles.title, styles['title--mb25'])}>
          Минимальная площадь
        </div>
        <div className={styles.formItem}>
          <CustomInput
            value={spaceValue}
            min={SPACE_MIN}
            max={SPACE_MAX}
            prefix="от"
            suffix={
              <span>
                м<sup>2</sup>
              </span>
            }
            isValid={isValid}
            options={INPUT_OPTIONS}
            formatFunction={formatPrice}
            onChange={handleInputChange}
          />
          <Nouislider
            id="price-range-slider"
            key="price-range-slider"
            className={styles.rangeSlider}
            range={RANGE}
            start={sliderSpace}
            connect={[true, false]}
            step={DEFAULT_SLIDER_STEP}
            onSlide={handleSliderUpdate}
            onSet={handleSliderSet}
          />
        </div>
      </div>
      <div className={styles.cta}>
        <div className={styles.buttonWrapper}>
          {isEmpty ? (
            <button
              className={classNames(styles.button, styles.buttonGrey)}
              type="button"
              onClick={handleSubmit}
            >
              Мне не важно
            </button>
          ) : (
            <button
              className={styles.button}
              type="button"
              disabled={!isValid}
              onClick={handleSubmit}
            >
              Дальше
            </button>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

StepMinSpace.propTypes = {
  errors: PropTypes.object.isRequired,
  goToNextStep: PropTypes.func.isRequired,
  minSpace: PropTypes.number.isRequired,
  setMainValues: PropTypes.func.isRequired,
};

export default StepMinSpace;
