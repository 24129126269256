import React from 'react';
import PropTypes from 'prop-types';

import AdFormInputHOC from './AdFormInputMixin';

const AdFormInputCounter = React.memo(function({
  value = null,
  label,
  onChange,
}) {
  const buttonStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '44px',
    height: '44px',
    border: 'none',
    backgroundColor: '#24A14F',
    color: 'white',
    cursor: 'pointer',
    fontSize: '20px',
  };

  return (
    <div
      className="flex"
      style={{
        height: '44px',
        gap: '10px',
        alignItems: 'center',
        border: '1px solid #C6C6C6',
        backgroundColor: 'white',
        paddingLeft: '10px',
      }}
    >
      <span>{label}</span>
      <div className="flex" style={{ minHeight: '44px', alignItems: 'center' }}>
        <button
          type="button"
          style={{
            ...buttonStyles,
            ...(value <= 0 ? { opacity: '0.3', cursor: 'default' } : {}),
          }}
          onClick={event => onChange(event, value - 1)}
          disabled={value <= 0}
        >
          -
        </button>
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0 7px',
            fontSize: '20px',
            width: '44px',
          }}
        >
          {value || 0}
        </span>
        <button
          type="button"
          style={buttonStyles}
          onClick={event => onChange(event, (value || 0) + 1)}
        >
          +
        </button>
      </div>
    </div>
  );
});

AdFormInputCounter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export default AdFormInputHOC(AdFormInputCounter);
