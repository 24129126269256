/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

import { fetchAdHistory } from '../../api/ads';
import Modal from '../Modal/Modal';

class AdHistoryModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      history: {
        head: [],
        body: [],
      },
    };
  }

  componentDidMount() {
    const { id } = this.props;

    fetchAdHistory(id).then(data => {
      this.setState({ history: data });
    });
  }

  onClose = () => {
    ReactDOM.unmountComponentAtNode(document.getElementById('modals'));
  };

  renderTableHead() {
    const { history } = this.state;

    return history.head.map((cell, index) => <th key={index}>{cell}</th>);
  }

  renderTableBody() {
    const { history } = this.state;

    return history.body.map((row, rowIndex) => (
      <tr key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} dangerouslySetInnerHTML={{ __html: cell }} />
        ))}
      </tr>
    ));
  }

  render() {
    return (
      <Modal containerClass="modal-history" handleClose={this.onClose}>
        <table className="modal-history-table">
          <thead>
            <tr>{this.renderTableHead()}</tr>
          </thead>
          <tbody>{this.renderTableBody()}</tbody>
        </table>
      </Modal>
    );
  }
}

AdHistoryModal.propTypes = {
  id: PropTypes.number,
};

export default AdHistoryModal;
