/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';

const AdCatalogFilterHeader = ({
  intl: { formatMessage },
  onCloseClick,
  onResetClick,
}) => {
  return (
    <div className="filter-header">
      <a
        className="filter-action-button filter-reset-button"
        onClick={onResetClick}
      >
        <span>{formatMessage(defaultMessages.jsCatalogFilterReset)}</span>
      </a>
      <div className="filter-close" onClick={onCloseClick}>
        <i className="filter-close-icon" />
      </div>
      <div className="filter-title">
        {formatMessage(defaultMessages.jsCatalogFilterTitle)}
      </div>
    </div>
  );
};

AdCatalogFilterHeader.propTypes = {
  intl: intlShape.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onResetClick: PropTypes.func.isRequired,
};

export default AdCatalogFilterHeader;
