/* eslint-disable react/no-find-dom-node */

import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-flex-modal';

import styles from './styles.scss';

const PopUpInfo = ({ isOpen = false, onClose, title, message }) => {
  return (
    <Modal
      transition
      isOpen={isOpen}
      onClose={onClose}
      modalDialogClass={styles.modalDialog}
    >
      <div className="modal-message modal-message-success modalPopUpInfoBody">
        <div className="modal-title">{title}</div>
        <div className="modal-text">{message}</div>
      </div>
    </Modal>
  );
};

PopUpInfo.propTypes = {
  isOpen: PropTypes.bool,
  message: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func,
};

export default PopUpInfo;
