import axios, { CancelToken } from './axios';

const fetchSubways = (params = {}, { withCancel = false } = {}) => {
  let cancel;

  const request = axios
    .get('/api/frontend/subways/select2', {
      params,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    })
    .then(({ data } = {}) => data)
    .catch(({ response: { data: { errors } } }) => Promise.reject(errors));

  return withCancel ? [request, cancel] : request;
};

const fetchSubwaysMap = ({ withCancel = false } = {}) => {
  let cancel;

  const request = axios
    .get('/api/frontend/subways/map', {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    })
    .then(({ data }) => data)
    .catch(({ response: { data: { errors } } }) => Promise.reject(errors));

  return withCancel ? [request, cancel] : request;
};

const fetchClosestSubways = ({ lat, lng }) => {
  return axios
    .get('/api/frontend/subways/geocoded', { params: { lat, lng } })
    .then(({ data }) => data)
    .catch(({ response: { data: { errors } } }) => Promise.reject(errors));
};

export const subwaysApi = {
  fetchSubways,
  fetchSubwaysMap,
  fetchClosestSubways,
};

export default subwaysApi;
