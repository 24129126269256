/* eslint-disable jsx-a11y/label-has-associated-control */
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

class MetroSchemeModalSchemeLineCheckbox extends React.Component {
  handleChange = event => {
    const { id, onChange } = this.props;

    onChange(id);
  };

  render() {
    const {
      id,
      isActive,
      title,
      label,
      color,
      bgColor,
      bgColorActive,
      colorActive,
      classModificator,
    } = this.props;

    let badgeStyles = {
      backgroundColor: bgColor,
      color: color,
    };

    let textStyles = {};

    if (isActive) {
      textStyles = {
        borderColor: bgColorActive,
        backgroundColor: bgColorActive,
        color: colorActive,
      };
    }

    return (
      <label
        className={classNames('metro-scheme-checkbox', {
          [`metro-scheme-checkbox-${classModificator}`]:
            classModificator !== null,
        })}
      >
        <input
          type="checkbox"
          value={id}
          className="metro-scheme-checkbox-control"
          checked={isActive}
          onChange={this.handleChange}
        />
        <span style={badgeStyles} className="metro-scheme-checkbox-badge">
          {label}
        </span>
        <span style={textStyles} className="metro-scheme-checkbox-text">
          {title}
        </span>
      </label>
    );
  }
}

MetroSchemeModalSchemeLineCheckbox.propTypes = {
  bgColor: PropTypes.string,
  bgColorActive: PropTypes.string,
  classModificator: PropTypes.string,
  color: PropTypes.string,
  colorActive: PropTypes.string,
  id: PropTypes.number,
  isActive: PropTypes.bool,
  label: PropTypes.string,
  title: PropTypes.string,
  onChange: PropTypes.func,
};

MetroSchemeModalSchemeLineCheckbox.defaultProps = {
  classModificator: null,
};

export default MetroSchemeModalSchemeLineCheckbox;
