import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../styles.scss';

const StepInfoBeforeWatching = ({ sendForm }) => {
  const handleSubmitClick = () => {
    sendForm();
  };

  return (
    <React.Fragment>
      <div
        className={classNames(
          styles.inner,
          styles.innerTopbarSpace,
          styles.textBigInfoTitle,
        )}
      >
        <div className={styles.title}>Информация перед просмотром</div>
      </div>

      <div className={styles.textBigInfo}>
        <p className={styles.buttonGeoSwitchTitle}>
          1. Мы свяжемся с вами в ближайшее время и подтвердим дату и время
          просмотра квартиры, а также уточним наличие и размер комиссии.
        </p>
        <p className={styles.buttonGeoSwitchTitle}>
          2. На просмотре вас будет сопровождать наш агент, он проверит
          документы на собственность и безопасность сделки.
        </p>
        <p className={styles.buttonGeoSwitchTitle}>
          3. Юрист подготовит договор найма или проверит, если у собственника
          будет свой договор.
        </p>
        <p className={styles.buttonGeoSwitchTitle}>
          4. На протяжении всего срока аренды по этой квартире, вам будет
          доступна услуга "Локалс-консьерж", с помощью которой вы можете
          заказывать разные бытовые услуги, такие как клининг, вызов мастера и
          другие.
        </p>
      </div>

      <div className={styles.cta}>
        <div className={styles.buttonWrapper}>
          <button
            className={styles.button}
            type="button"
            onClick={handleSubmitClick}
          >
            Записаться
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

StepInfoBeforeWatching.propTypes = {
  sendForm: PropTypes.func.isRequired,
};

export default StepInfoBeforeWatching;
