/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import validate from 'validate.js';
import debounce from 'lodash/debounce';
import classNames from 'classnames';

import {
  FIELD_NAMES,
  FIELD_NAMES_TRANSLATION,
  STEPS_REQUIRED_FIELDS,
  STEPS_COUNT,
  RECEIVE_TYPE_VALUES,
  LOCATION_TYPE_VALUES,
} from './constants';
import { VALIDATION_RULES } from './validation';
import { KINDS } from '../AdCatalog/AdCatalog.constants';
import { reviewRequestApi } from '../../api/reviewRequest';
import isClient from '../../../common/utils/isClient';
import Topbar from './Topbar';
import StepContainer from './StepContainer';
import StepStart from './Steps/StepStart';
import StepResidents from './Steps/StepResidents';
import StepUrgency from './Steps/StepUrgency';
import styles from './styles.scss';
import StepAnimals from './Steps/StepAnimals';
import StepDateAndTime from './Steps/StepDateAndTime';
import StepYourPhoneNumber from './Steps/StepYourPhoneNumber';
import StepInfoBeforeWatching from './Steps/StepInfoBeforeWatching';
import PopUpInfo from './PopUpInfo';

const TransitionGroupContainer = ({ children }) => {
  return children || null;
};

const formatError = error => {
  if (typeof error !== 'string') {
    return null;
  }

  const splited = error.split(/[\[\]]/);

  if (splited && splited.length > 2) {
    return `"${FIELD_NAMES_TRANSLATION[splited[1]] || splited[1]}" ${
      splited[2]
    }`;
  }

  return null;
};

class ReviewRequestApplication extends React.Component {
  constructor(props) {
    super(props);

    const { leadSource } = this.props;

    const {
      animals = [],
      commission = 50,
      owner = 'homeowner',
      price = 50000,
      minSpace = 50,
      repairs = [],
      roomQuantity = [],
      user,
    } = this.props;

    this.state = {
      step: 0,
      values: {
        ad_id: 0,
        [FIELD_NAMES.ANIMALS]: animals,
        [FIELD_NAMES.COMMISSION]: commission,
        [FIELD_NAMES.OWNER]: owner,
        [FIELD_NAMES.PRICE]: price,
        [FIELD_NAMES.MIN_SPACE]: minSpace,
        [FIELD_NAMES.REPAIRS]: repairs,
        [FIELD_NAMES.ROOM_QUANTITY]: roomQuantity,
        [FIELD_NAMES.WISHES]: '',
        [FIELD_NAMES.RESIDENTS]: '',
        [FIELD_NAMES.RESIDENTS_OTHER]: '',
        [FIELD_NAMES.WORK]: '',
        [FIELD_NAMES.URGENCY]: '',
        [FIELD_NAMES.URGENCY_DATE]: '',
        [FIELD_NAMES.PRIORITIES]: [],
        [FIELD_NAMES.RECEIVE_VALUE]: {
          [RECEIVE_TYPE_VALUES.WHATSAPP]: user && user.phone ? user.phone : '',
          [RECEIVE_TYPE_VALUES.TELEGRAM]: user && user.phone ? user.phone : '',
          [RECEIVE_TYPE_VALUES.EMAIL]: user && user.email ? user.email : '',
        },
        [FIELD_NAMES.LOCATION_TYPE]: null,
        [FIELD_NAMES.SUBWAY_IDS]: [],
        [FIELD_NAMES.FROM_SUBWAY]: null,
        [FIELD_NAMES.FROM_SUBWAY_TIME]: null,
        [FIELD_NAMES.GEO_AREAS]: [],
        [FIELD_NAMES.CUSTOM_LOCATION]: '',
        [FIELD_NAMES.DATE_AND_TIME]: '',
        [FIELD_NAMES.PHONE_NUMBER]: '',
        lead_source: leadSource,
        filter: {},
      },
      errors: {},
      isSubmitting: false,
      isOpenInfo: false,
    };

    if (isClient()) {
      const body = document.querySelector('body');
      const containerSelectorName = 'data-search-application-sidebar-root';

      window.showAppBanner = false;

      this.container = document.querySelector(`[${containerSelectorName}]`);

      if (!this.container) {
        this.container = document.createElement('div');
        this.container.setAttribute(containerSelectorName, '');
        body.insertBefore(this.container, body.firstChild);
      }
    }

    this.rootRef = React.createRef();
  }

  componentDidMount() {
    if (isClient()) {
      this.reloadFormData();

      const searchParams = new URLSearchParams(window.location.search);
      // Извлекаем данные
      const adIdParams = searchParams.get('ad_id');
      this.setMainValues({
        ad_id: adIdParams,
      });

      document.documentElement.classList.add('is-ReviewRequestApplication-open');
      this.rootRef.current.focus();

      this.validateAll();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (isClient()) {
      const { values } = this.state;

      if (
        values[FIELD_NAMES.SUBWAY_IDS].length === 0 &&
        DeviceSupports.localStorage &&
        prevState.isSubwaysLoadin
      ) {
        const lastFilter = localStorage.getItem('lastFilter');

        if (lastFilter !== null) {
          const { subway_ids: subwayIds = [] } = JSON.parse(lastFilter);

          this.setMainValues({
            [FIELD_NAMES.SUBWAY_IDS]: subwayIds,
            filter: JSON.parse(lastFilter),
          });
        }
      }
    }
  }

  setMainValues = newValues => {
    const { values } = this.state;

    this.setState({ values: { ...values, ...newValues } }, () => {
      this.validateAll();
    });
  };

  close = () => {
    if (isClient()) {
      window.history.back();
    }
  };

  goToNextStep = () => {
    const { step } = this.state;

    if (this.isAnimating) {
      return;
    }

    this.isAnimating = true;

    this.setState({ step: step + 1 }, () => {
      debounce(() => {
        this.isAnimating = false;
        window.scrollTo({ top: 0 });
      }, 400)(); // 400 - время анимации шагов
    });
  };

  goToPrevStep = () => {
    const { step } = this.state;

    if (step <= 0) {
      return false;
    }

    if (this.isAnimating) {
      return;
    }

    this.isAnimating = true;

    this.setState({ step: step - 1 }, () => {
      debounce(() => {
        this.isAnimating = false;
      }, 400)(); // 400 - время анимации шагов
    });
  };

  reloadFormData = () => {
    const { values } = this.state;

    if (DeviceSupports.localStorage) {
      const lastFilter = localStorage.getItem('lastFilter');

      if (lastFilter !== null) {
        const {
          kind,
          rooms_amount: roomsAmount = [],
          price_max: priceMax,
          subway_ids: subwayIds = [],
          client_fee: clientFee,
          has_client_fee: hasClientFee,
        } = JSON.parse(lastFilter);

        let newValues = {
          [FIELD_NAMES.ROOM_QUANTITY]: kind === KINDS.ROOM ? [0] : roomsAmount,
          [FIELD_NAMES.PRICE]: priceMax || values[FIELD_NAMES.PRICE],
          [FIELD_NAMES.COMMISSION]: clientFee || values[FIELD_NAMES.COMMISSION],
          [FIELD_NAMES.OWNER]:
            hasClientFee === true ? 'agent' : values[FIELD_NAMES.OWNER],
          [FIELD_NAMES.SUBWAY_IDS]: subwayIds,
        };

        if (
          Array.isArray(newValues[FIELD_NAMES.SUBWAY_IDS]) &&
          newValues[FIELD_NAMES.SUBWAY_IDS].length > 0
        ) {
          newValues[FIELD_NAMES.LOCATION_TYPE] = LOCATION_TYPE_VALUES.SUBWAY;
        }

        this.setMainValues(newValues);
      }
    }
  };

  postForm = (path, params, method) => {
    method = method || 'post';

    var form = document.createElement('form');
    form.setAttribute('method', method);
    form.setAttribute('action', path);

    for (var key in params) {
      if (params.hasOwnProperty(key)) {
        var hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', key);
        hiddenField.setAttribute('value', params[key]);

        form.appendChild(hiddenField);
      }
    }

    document.body.appendChild(form);
    form.submit();
  };

  sendForm = () => {
    const { plan_id: planId } = this.props;
    const { isSubmitting, values } = this.state;

    if (isSubmitting) {
      return;
    }

    this.setState({ formError: null, isSubmitting: true });

    reviewRequestApi
      .createReviewRequest(values)
      .then(data => {
        if (DeviceSupports.localStorage) {
          localStorage.setItem('SA_submitted', true);
        }

        this.setState({ isSubmitting: false, isOpenInfo: true });

        setTimeout(() => {
          if (planId) {
            this.postForm(`/plans/${planId}/buy`);
          } else {
            window.location = '/plans';
          }
        }, 1000);
      })
      .catch(error => {
        let fieldNameErrors = [];

        if (Array.isArray(error)) {
          error.forEach(msg => {
            const formattedMsg = formatError(msg);

            if (formattedMsg) {
              fieldNameErrors.push(formattedMsg);
            }
          });
        } else {
          const formattedMsg = formatError(error);

          if (formattedMsg) {
            fieldNameErrors.push(formattedMsg);
          }
        }

        this.setState({
          isSubmitting: false,
          formError: fieldNameErrors.join(', '),
        });
      });
  };

  validateAll = () => {
    const { values } = this.state;

    this.setState({
      errors: validate(values, VALIDATION_RULES, { fullMessages: false }) || {},
    });
  };

  tryGoNextStep = () => {
    const { step, errors } = this.state;

    const requirementFields = STEPS_REQUIRED_FIELDS[step];

    if (requirementFields.length > 0) {
      const hasError = requirementFields.some(
        fieldName => errors[fieldName] && errors[fieldName].length > 0,
      );

      if (hasError) {
        return false;
      }
    }

    if (step === 6) {
      this.sendForm();
    } else {
      this.goToNextStep();
    }
  };

  handleFormKeyUp = ({ target, key, ctrlKey, metaKey }) => {
    switch (key) {
      case 'Enter':
        if (target.tagName === 'TEXTAREA' && !(ctrlKey || metaKey)) {
          return false;
        }

        return this.tryGoNextStep();
      default:
        return false;
    }
  };

  onHandleCloseModalInfo = () => {
    this.setState({ isOpenInfo: false });
  };

  renderSteps() {
    const { step, errors, values } = this.state;

    switch (step) {
      case 0:
        return (
          <StepStart
            key="StepStart"
            step={step}
            close={this.close}
            goToNextStep={this.tryGoNextStep}
          />
        );
      case 1:
        return (
          <StepAnimals
            key="StepAnimals"
            errors={errors}
            animals={values[FIELD_NAMES.ANIMALS]}
            goToNextStep={this.tryGoNextStep}
            setMainValues={this.setMainValues}
          />
        );
      case 2:
        return (
          <StepResidents
            key="StepResidents"
            errors={errors}
            residents={values[FIELD_NAMES.RESIDENTS]}
            work={values[FIELD_NAMES.WORK]}
            residentsOther={values[FIELD_NAMES.RESIDENTS_OTHER]}
            goToNextStep={this.tryGoNextStep}
            setMainValues={this.setMainValues}
          />
        );
      case 3:
        return (
          <StepUrgency
            key="StepUrgency"
            errors={errors}
            urgency={values[FIELD_NAMES.URGENCY]}
            urgencyDate={values[FIELD_NAMES.URGENCY_DATE]}
            goToNextStep={this.tryGoNextStep}
            setMainValues={this.setMainValues}
          />
        );
      case 4:
        return (
          <StepDateAndTime
            key="StepDateAndTime"
            errors={errors}
            dateAndTime={values[FIELD_NAMES.DATE_AND_TIME]}
            goToNextStep={this.tryGoNextStep}
            setMainValues={this.setMainValues}
          />
        );
      case 5:
        return (
          <StepYourPhoneNumber
            key="StepYourPhoneNumber"
            errors={errors}
            phoneNumber={values[FIELD_NAMES.PHONE_NUMBER]}
            goToNextStep={this.tryGoNextStep}
            setMainValues={this.setMainValues}
          />
        );
      case 6:
        return (
          <StepInfoBeforeWatching
            key="StepInfoBeforeWatching"
            sendForm={this.sendForm}
          />
        );
    }
  }

  render() {
    const { formError, step } = this.state;

    return (
      <div
        className={styles.root}
        ref={this.rootRef}
        tabIndex="0"
        onKeyUp={this.handleFormKeyUp}
      >
        <div className={styles.contentContainer}>
          <ReactCSSTransitionGroup
            transitionName={{
              enter: styles['topbar--enter'],
              enterActive: styles['topbar--enterActive'],
              leave: styles['topbar--leave'],
              leaveActive: styles['topbar--leaveActive'],
            }}
            transitionEnterTimeout={500}
            transitionLeaveTimeout={300}
            component={TransitionGroupContainer}
          >
            {step !== 0 && (
              <Topbar
                key="Toolbar"
                step={step}
                stepCount={STEPS_COUNT}
                goToPrevStep={this.goToPrevStep}
              />
            )}
          </ReactCSSTransitionGroup>
          <div className={styles.paneContainer}>
            <ReactCSSTransitionGroup
              transitionName={{
                enter: styles['pane--enter'],
                enterActive: styles['pane--enterActive'],
                leave: styles['pane--leave'],
                leaveActive: styles['pane--leaveActive'],
              }}
              transitionEnterTimeout={500}
              transitionLeaveTimeout={200}
              component={TransitionGroupContainer}
            >
              <StepContainer key={step}>{this.renderSteps()}</StepContainer>
              <PopUpInfo
                isOpen={this.state.isOpenInfo}
                onClose={this.onHandleCloseModalInfo}
                title="Спасибо!&nbsp;Мы скоро с вами свяжемся"
              />
            </ReactCSSTransitionGroup>
            {formError !== null ? (
              <div className={classNames(styles.cta, styles.formError)}>
                {formError}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

ReviewRequestApplication.propTypes = {
  animals: PropTypes.array,
  city: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    name: PropTypes.string,
  }),
  commission: PropTypes.number,
  leadSource: PropTypes.string,
  // filter: PropTypes.any,
  // maxPrice: PropTypes.number,
  // minPrice: PropTypes.number,
  minSpace: PropTypes.number,
  owner: PropTypes.string,
  plan_id: PropTypes.string,
  price: PropTypes.number,
  repairs: PropTypes.array,
  roomQuantity: PropTypes.array,
  user: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
};

export default ReviewRequestApplication;
