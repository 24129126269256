/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';

import { FILTER_KEYS } from './AdCatalog.constants';

class AdCatalogFilterBodyExtraSubjects extends Component {
  handleChange = value => {
    const { activeIds, updateFilter } = this.props;

    let nextActiveIds = isNil(activeIds) ? [] : [].concat(activeIds);

    const index = nextActiveIds.indexOf(value);

    if (index === -1) {
      nextActiveIds.push(value);
    } else {
      nextActiveIds.splice(index, 1);
    }

    updateFilter({
      [FILTER_KEYS.AMENITIES]: nextActiveIds.length ? nextActiveIds : null,
    });
  };

  isChecked = id => {
    const { activeIds } = this.props;

    return activeIds && activeIds.length > 0 && activeIds.indexOf(id) !== -1;
  };

  render() {
    const { subjects } = this.props;

    return (
      <ul className="filter-list">
        {subjects.length > 0 &&
          subjects.map(({ id, title }) => (
            <li key={`subjectItem-${id}`} className="filter-list-item">
              <label className="filter-checkbox filter-checkbox-normal">
                <input
                  className="filter-checkbox-control"
                  type="checkbox"
                  checked={this.isChecked(id)}
                  onChange={this.handleChange.bind(this, id)}
                />
                <span className="filter-checkbox-fake" />
                <span className="filter-checkbox-text">{title}</span>
              </label>
            </li>
          ))}
      </ul>
    );
  }
}

AdCatalogFilterBodyExtraSubjects.propTypes = {
  activeIds: PropTypes.array,
  subjects: PropTypes.array,
  updateFilter: PropTypes.func.isRequired,
};

export default AdCatalogFilterBodyExtraSubjects;
