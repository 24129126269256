import React from 'react';

import styles from './AssistantChatTypingDots.scss';

// eslint-disable-next-line react/prefer-stateless-function
class AssistantChatTypingDots extends React.PureComponent {
  render() {
    return (
      <span className={styles.row}>
        <b className={styles.dot} />
        <b className={styles.dot} />
        <b className={styles.dot} />
      </span>
    );
  }
}

export default AssistantChatTypingDots;
