import React from 'react';
import PropTypes from 'prop-types';

const AdFormSelect = React.memo(function({
  id = null,
  name = null,
  value = null,
  classMods,
  options,
  onChange,
}) {
  let className = 'select';

  if (classMods) {
    classMods.forEach(mod => {
      className += ' select-' + mod;
    });
  }

  return (
    <div className={className}>
      <select
        id={id}
        className="select-control"
        name={name}
        onChange={onChange}
        value={value === null ? '' : value}
      >
        {options.map(({ id, title }) => (
          <option key={id} value={id}>
            {title}
          </option>
        ))}
      </select>
    </div>
  );
});

AdFormSelect.propTypes = {
  classMods: PropTypes.array,
  id: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.number,
  onChange: PropTypes.func,
};

export default AdFormSelect;
