import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import raf from 'raf';

import styles from './LoaderBar.scss';

const easeOutQuint = (t) => {
  return 1+(--t)*t*t*t*t;
};

export default class LoaderBar extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    shouldReset: PropTypes.bool.isRequired
  }

  state = {
    progress: 0,
    isRunning: false
  }

  maxProgress = .98
  progressDuration = 15000
  transitionDuration = 200

  componentDidUpdate(prevProps) {
    if (!prevProps.shouldReset && this.props.shouldReset && this.props.isLoading) {
      this.stop();
      this.setState({progress: 0});
      this.run();
    }

    if (!prevProps.isLoading && this.props.isLoading) {
      this.stop();
      this.run();
    }

    if (prevProps.isLoading && !this.props.isLoading) {
      this.stop();
      this.setState({
        progress: 1
      }, () => {
        setTimeout(() => {
          if (!this.props.isLoading) {
            this.setState({progress: 0});
          }
        }, this.transitionDuration);
      });
    }
  }

  getMainClasses() {
    return classNames({
      [`${styles.loaderBar}`]: true,
      [`${styles['loaderBar--loading']}`]: this.state.progress > 0
    });
  }

  run() {
    this.setState({isRunning: true}, () => {
      const runTime = Date.now();
      const throttleDelay = 200;

      let throttleTime = Date.now();

      const loop = () => {
        const loopRunTime = Date.now();
        const progress = easeOutQuint((loopRunTime - runTime) / this.progressDuration);

        if (progress >= this.maxProgress) {
          this.setState({progress: this.maxProgress});
        } else {
          if (this.props.isLoading) {
            const shouldUpdateState = loopRunTime - throttleTime > throttleDelay;

            if (shouldUpdateState || loopRunTime - runTime < throttleDelay) {
              throttleTime = Date.now();
              this.setState({progress: progress});
            }

            if (this.state.isRunning) {
              this.rafId = raf(loop);
            }
          }
        }
      }

      loop();
    });
  }

  stop() {
    this.setState({isRunning: false});
    if (this.rafId) raf.cancel(this.rafId);
  }

  render() {
    return (
      <div
        className={this.getMainClasses()}
        style={{
          transform: `scaleX(${this.state.progress})`,
          transitionDuration: this.state.progress === 0 ? '0s' : null
        }}
      />
    );
  }
};