import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const StepContainer = ({ children }) => (
  <div className={styles.pane}>{children}</div>
);

StepContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StepContainer;
