/* eslint-disable react/no-string-refs */
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import * as constants from './OfferMap.constants';
import FlashNotifierService from '../../services/FlashNotifier/FlashNotifier';
import OfferMapViewControl from './OfferMapViewControl';

const { MAIN_CLASS } = constants;

class OfferMapYandexMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      view: constants.VIEW_MAP,
      isMapsReady: false,
      isMapInited: false,
      isPanoramaInited: false,
    };

    this.map = null;
    this.marker = null;
  }

  componentDidMount() {
    const { locale } = this.props;

    YandexMapsLoader(
      {
        version: '2.1',
        lang: locale,
      },
      () => {
        if (window && typeof window.ymaps !== 'undefined') {
          ymaps.ready(this.onMapsReady);
        }
      },
    );
  }

  onMapsReady = () => {
    const { view } = this.state;

    this.setState({ isMapsReady: true });

    switch (view) {
      case constants.VIEW_MAP:
        this.initMap();
        break;
      case constants.VIEW_PANORAMA:
        this.initPanorama();
        break;
    }
  };

  onMapViewToggle = () => {
    const { isMapInited } = this.state;

    this.setState({ view: constants.VIEW_MAP }, () => {
      if (!isMapInited) {
        this.initMap();
      } else {
        this.map.container.fitToViewport();
        this.map.setCenter(this.getLatLng());
      }
    });
  };

  onPanoramaViewToggle = () => {
    const { isPanoramaInited } = this.state;

    this.setState({ view: constants.VIEW_PANORAMA }, () => {
      if (!isPanoramaInited) {
        this.initPanorama();
      }
    });
  };

  getLatLng() {
    const {
      ad: { lat, lng },
    } = this.props;

    return [lat, lng];
  }

  getMarkerLayout = () => {
    return ymaps.templateLayoutFactory.createClass(
      '<div class="map-marker map-marker-green-stroke">' +
        '<div class="map-marker-icon">' +
        // '$[properties.rooms]' +
        '</div>' +
        '</div>',
    );
  };

  getMarkerPopupLayout = () => {
    let markerPopupLayout = ymaps.templateLayoutFactory.createClass(
      '<div class="map-popup map-popup-flat">' +
        '<div class="map-popup-arrow"></div>' +
        '$[[options.contentLayout observeSize minWidth=100 maxWidth=280]]' +
        '</div>',
      {
        build: function() {
          this.constructor.superclass.build.call(this);
          this._$element = $('.map-popup', this.getParentElement());
          this.applyElementOffset();
        },
        clear: function() {
          this.constructor.superclass.clear.call(this);
        },
        onSublayoutSizeChange: function() {
          markerPopupLayout.superclass.onSublayoutSizeChange.apply(
            this,
            arguments,
          );
          if (!this._isElement(this._$element)) {
            return;
          }
          this.applyElementOffset();
          this.events.fire('shapechange');
        },
        applyElementOffset: function() {
          this._$element.css({
            left: -(this._$element[0].offsetWidth / 2),
            top: -(
              this._$element[0].offsetHeight +
              this._$element.find('.map-popup-arrow')[0].offsetHeight
            ),
          });
        },
        onCloseClick: function(e) {
          e.preventDefault();
          this.events.fire('userclose');
        },
        getShape: function() {
          var position;
          if (!this._isElement(this._$element)) {
            return markerPopupLayout.superclass.getShape.call(this);
          }
          position = this._$element.position();
          return new ymaps.shape.Rectangle(
            new ymaps.geometry.pixel.Rectangle([
              [position.left, position.top],
              [
                position.left + this._$element[0].offsetWidth,
                position.top +
                  this._$element[0].offsetHeight +
                  this._$element.find('.map-popup-arrow')[0].offsetHeight,
              ],
            ]),
          );
        },
        _isElement: function(element) {
          return element && element[0] && element.find('.map-popup-arrow')[0];
        },
      },
    );

    return markerPopupLayout;
  };

  getMarkerPopupContentLayout = () => {
    return ymaps.templateLayoutFactory.createClass(
      '<div class="map-popup-content">' + '$[properties.address]' + '</div>',
    );
  };

  isMapsPanoramaReady = () => {
    return window && window.ymaps && window.ymaps.panorama;
  };

  isMapsPanoramaSupported = () => {
    return window.ymaps.panorama.isSupported();
  };

  initMap() {
    const {
      ad: { rooms, address },
    } = this.props;

    this.setState({ isMapInited: true }, () => {
      this.map = new ymaps.Map(this.refs.map, {
        zoom: constants.ZOOM,
        center: this.getLatLng(),
        maxZoom: constants.ZOOM_MAX,
        minZoom: constants.ZOOM_MIN,
        controls: [],
      });

      this.map.controls.add('zoomControl', {
        float: 'none',
        position: {
          top: 10,
          right: 10,
        },
      });

      this.map.behaviors.disable('scrollZoom');

      if (DeviceSupports.touch) {
        this.map.behaviors.disable('drag');
      }

      this.addMarker(this.getLatLng(), rooms, address);
    });
  }

  addMarker(latLng, rooms, address) {
    this.marker = new ymaps.Placemark(
      latLng,
      {
        balloonContent: address,
        rooms: rooms >= 4 ? '4+' : rooms,
        address: address,
      },
      {
        balloonShadow: false,
        balloonLayout: this.getMarkerPopupLayout(),
        balloonContentLayout: this.getMarkerPopupContentLayout(),
        balloonPanelMaxMapArea: 0,
        balloonOffset: [0, -44],
        hideIconOnBalloonOpen: false,
        openBalloonOnClick: false,
        iconLayout: this.getMarkerLayout(),
      },
    );

    this.map.geoObjects.add(this.marker);

    this.marker.balloon.open();

    this.map.events.add('sizechange', () => {
      this.marker.balloon.close();
      this.marker.balloon.open();
    });
  }

  initPanorama() {
    const {
      intl: { formatMessage },
    } = this.props;

    this.setState({ isPanoramaInited: true }, () => {
      ymaps.panorama.locate(this.getLatLng()).done(
        panoramas => {
          if (panoramas.length) {
            let player = new ymaps.panorama.Player(
              this.refs.panorama,
              panoramas[0],
              {
                controls: ['panoramaName'],
              },
            );

            player.lookAt(this.getLatLng());
          } else {
            FlashNotifierService.notifyError(
              formatMessage(defaultMessages.jsFlashNotifierThereAreNoPanoramas),
            );
          }
        },
        error => {
          FlashNotifierService.notifyError(error.message);
        },
      );
    });
  }

  renderViewControl() {
    const { isMapsReady, view } = this.state;
    const { intl } = this.props;

    return (
      <OfferMapViewControl
        view={view}
        intl={intl}
        isVisible={
          isMapsReady &&
          this.isMapsPanoramaReady() &&
          this.isMapsPanoramaSupported()
        }
        onMapViewToggle={this.onMapViewToggle}
        onPanoramaViewToggle={this.onPanoramaViewToggle}
      />
    );
  }

  renderMap() {
    const { isMapInited } = this.state;

    if (isMapInited) {
      return <div ref="map" className={`${MAIN_CLASS}__map`} />;
    }

    return null;
  }

  renderPanorama() {
    const { isPanoramaInited } = this.state;

    if (isPanoramaInited) {
      return <div ref="panorama" className={`${MAIN_CLASS}__panorama`} />;
    }

    return null;
  }

  renderLoader() {
    const { isMapsReady } = this.state;

    return (
      !isMapsReady && (
        <div className={`${MAIN_CLASS}__loader`}>
          <span className="spinner" />
        </div>
      )
    );
  }

  render() {
    const { view } = this.state;

    return (
      <div className={MAIN_CLASS}>
        <div
          className={classNames(`${MAIN_CLASS}__viewport`, {
            [`${MAIN_CLASS}__viewport--map`]: view === constants.VIEW_MAP,
            [`${MAIN_CLASS}__viewport--panorama`]:
              view === constants.VIEW_PANORAMA,
          })}
        >
          {this.renderMap()}
          {this.renderPanorama()}
          {this.renderViewControl()}
          {this.renderLoader()}
        </div>
      </div>
    );
  }
}

OfferMapYandexMap.propTypes = {
  ad: PropTypes.object,
  intl: intlShape.isRequired,
  locale: PropTypes.string, // Вынести в window.LOCALE
};

export default OfferMapYandexMap;
