import React from 'react';
import { intlShape } from 'react-intl';

import { AUTH_FB_PATH } from '../../constants/paths';
import { defaultMessages } from '../../../../libs/i18n/default';

const AuthFbButton = ({ intl: { formatMessage } }) => (
  <a
    className="button button-fb"
    href={AUTH_FB_PATH}
    title={formatMessage(defaultMessages.jsSocialButtonFacebook)}
  >
    {formatMessage(defaultMessages.jsSocialButtonFacebook)}
  </a>
);

AuthFbButton.propTypes = {
  intl: intlShape.isRequired,
};

export default AuthFbButton;
