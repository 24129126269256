import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';

import { defaultMessages } from '../../../../libs/i18n/default';

const AdFormStepLabel = React.memo(function({
  stepNumber = null,
  intl: { formatMessage },
}) {
  return (
    stepNumber !== null && (
      <span>
        <b className="highlight">
          {formatMessage(defaultMessages.jsAdFormStep)} {stepNumber}
        </b>{' '}
        &mdash;{' '}
      </span>
    )
  );
});

AdFormStepLabel.propTypes = {
  intl: intlShape.isRequired,
  stepNumber: PropTypes.number,
};

export default AdFormStepLabel;
