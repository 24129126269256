import anime from 'animejs';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './AssistantChatAnimatedBlock.scss';

const translateByDirection = {
  left: [-50, 0],
  right: [100, 0],
  null: [],
};

class AssistantChatAnimatedBlock extends React.Component {
  constructor(props) {
    super(props);

    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.animateBlock();
  }

  animateBlock = () => {
    const { onProgressEnd, direction } = this.props;

    anime({
      targets: this.containerRef.current,
      opacity: [0, 1],
      translateX: translateByDirection[direction],
      duration: 300,
      easing: 'easeInOutSine',
      complete: () => {
        onProgressEnd && onProgressEnd();
      },
    });
  };

  render() {
    const { index, children } = this.props;

    return (
      <div
        className={styles.container}
        style={{ zIndex: -1 * index }}
        ref={this.containerRef}
      >
        {children}
      </div>
    );
  }
}

AssistantChatAnimatedBlock.propTypes = {
  children: PropTypes.any,
  direction: PropTypes.oneOf(['right', 'left', 'null']),
  index: PropTypes.number,
  onProgressEnd: PropTypes.func,
};

AssistantChatAnimatedBlock.defaultProps = {
  direction: 'null',
  index: 1,
};

export default AssistantChatAnimatedBlock;
