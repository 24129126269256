/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import { defaultMessages } from '../../../../libs/i18n/default';
import FlashNotifierService from '../../services/FlashNotifier/FlashNotifier';
import SvgIcon from '../SvgIcon/SvgIcon';

const SlideContainer = ({ children }) => {
  return children || null;
};

const TRANSITION_ENTER_TIMEOUT = 200;
const TRANSITION_LEAVE_TIMEOUT = 200;

class OfferCardSlider extends React.Component {
  constructor(props) {
    super(props);

    const { activeIndex } = this.props;

    this.state = {
      activeIndex,
      isActiveImageNotLoaded: false,
      loadedImages: {},
    };
  }

  componentDidMount() {
    const { activeIndex } = this.state;
    const { isVisible } = this.props;

    if (isVisible) {
      this.loadImageByIndex(activeIndex);
    }

    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    const { activeIndex } = this.state;
    const { isVisible } = this.props;

    if (!prevProps.isVisible && isVisible) {
      this.loadImageByIndex(activeIndex);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleArrowPrevClick = () => {
    const { activeIndex } = this.state;
    const { images } = this.props;

    const nextActiveIndex =
      activeIndex <= 0 ? images.length - 1 : activeIndex - 1;

    this.setState({
      activeIndex: nextActiveIndex,
      isActiveImageNotLoaded: false,
    });

    this.loadImageByIndex(nextActiveIndex);
  };

  handleArrowNextClick = () => {
    const { activeIndex } = this.state;
    const { images } = this.props;

    const nextActiveIndex =
      activeIndex >= images.length - 1 ? 0 : activeIndex + 1;

    this.setState({
      activeIndex: nextActiveIndex,
      isActiveImageNotLoaded: false,
    });

    this.loadImageByIndex(nextActiveIndex);
  };

  loadImageByIndex(index) {
    const { loadedImages } = this.state;
    const {
      images,
      intl: { formatMessage },
    } = this.props;

    if (loadedImages[index] || !images.length) {
      return;
    }

    let img = new Image();

    img.onload = () => {
      img = void 0;

      if (this._isMounted) {
        this.setState({
          loadedImages: { ...loadedImages, [index]: images[index] },
        });
      }
    };

    img.onerror = () => {
      img = void 0;

      if (this._isMounted) {
        this.setState({
          isActiveImageNotLoaded: true,
        });
      }

      FlashNotifierService.notifyError(
        formatMessage(defaultMessages.jsFlashNotifierCouldNotLoadImage),
      );
    };

    img.src = images[index];
  }

  renderSlideByIndex(index) {
    const { activeIndex, loadedImages, isActiveImageNotLoaded } = this.state;
    const { images, title, isVisible, blurClosedBaseImages } = this.props;
    const image = images[index];
    const loadedImage = loadedImages[index];
    const isActive = index === activeIndex;

    return (
      <div
        key={`slideImage-${index}`}
        className={classNames('OfferCard__sliderSlide', {
          'OfferCard__sliderSlide--active': isActive,
          'OfferCard__sliderSlide--loading':
            isVisible && isActive && !loadedImage && !isActiveImageNotLoaded,
          'OfferCard__sliderSlide--error': isActiveImageNotLoaded,
          'blur': blurClosedBaseImages,
        })}
      >
        <div className="OfferCard__sliderSlideImage">
          <noscript
            dangerouslySetInnerHTML={{
              __html: `<img class="OfferCard__imageImg"${
                !image ? '' : ` src="${image}"`
              } alt="${index === 0 ? title : ''}" />`,
            }}
          />
          <div
            className="OfferCard__imageBg"
            style={
              loadedImage ? { backgroundImage: `url(${loadedImage})` } : null
            }
          />
        </div>
      </div>
    );
  }

  render() {
    const { activeIndex } = this.state;
    const { images, isCompactStatic } = this.props;

    const isNeedRenderArrows = !isCompactStatic && images.length > 1;

    return (
      <div className="OfferCard__slider">
        {isNeedRenderArrows && (
          <div
            className="OfferCard__sliderArrow OfferCard__sliderArrow--prev"
            onClick={this.handleArrowPrevClick}
          >
            <SvgIcon icon="left-chevron" />
          </div>
        )}
        {isNeedRenderArrows && (
          <div
            className="OfferCard__sliderArrow OfferCard__sliderArrow--next"
            onClick={this.handleArrowNextClick}
          >
            <SvgIcon icon="right-chevron" />
          </div>
        )}
        <div className="OfferCard__sliderList">
          <ReactCSSTransitionGroup
            transitionName="OfferCard__sliderSlide-"
            transitionEnterTimeout={TRANSITION_ENTER_TIMEOUT}
            transitionLeaveTimeout={TRANSITION_LEAVE_TIMEOUT}
            component={SlideContainer}
          >
            {this.renderSlideByIndex(activeIndex)}
          </ReactCSSTransitionGroup>
        </div>
      </div>
    );
  }
}

OfferCardSlider.propTypes = {
  activeIndex: PropTypes.number,
  images: PropTypes.array,
  intl: intlShape.isRequired,
  isCompactStatic: PropTypes.bool,
  isVisible: PropTypes.bool,
  title: PropTypes.string,
};

OfferCardSlider.defaultProps = {
  isVisible: true,
};

export default OfferCardSlider;
