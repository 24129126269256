export const STEPS_COUNT = 7;

export const FIELD_NAMES = {
  ANIMALS: 'animals',
  COMMISSION: 'commission',
  ROOM_QUANTITY: 'room_amount',
  PRICE: 'price',
  MIN_SPACE: 'min_space',
  REPAIRS: 'repairs',
  WISHES: 'wishes',
  OWNER: 'owner',
  RESIDENTS: 'residents',
  RESIDENTS_OTHER: 'residents_other',
  WORK: 'work',
  URGENCY: 'urgency',
  URGENCY_DATE: 'urgency_date',
  PRIORITIES: 'priorities',
  RECEIVE_TYPE: 'receive_type',
  RECEIVE_VALUE: 'receive_value',
  LOCATION_TYPE: 'location_type',
  SUBWAY_IDS: 'subway_ids',
  GEO_AREAS: 'geo_areas',
  FROM_SUBWAY: 'from_subway',
  FROM_SUBWAY_TIME: 'from_subway_time',
  CUSTOM_LOCATION: 'custom_location',
};

export const FIELD_NAMES_TRANSLATION = {
  [FIELD_NAMES.ANIMALS]: 'Питомцы',
  [FIELD_NAMES.COMMISSION]: 'Максимальная комиссия',
  [FIELD_NAMES.ROOM_QUANTITY]: 'Количество комнат',
  rooms_amount: 'Количество комнат',
  [FIELD_NAMES.PRICE]: 'Бюджет не больше',
  [FIELD_NAMES.MIN_SPACE]: 'Минимальная площадь',
  [FIELD_NAMES.REPAIRS]: 'Ремонт',
  [FIELD_NAMES.WISHES]: 'Особые пожелания',
  [FIELD_NAMES.OWNER]: 'Собственник/агент',
  [FIELD_NAMES.RESIDENTS]: 'Кто будет жить в квартире',
  [FIELD_NAMES.RESIDENTS_OTHER]: 'Кто будет жить в квартире',
  [FIELD_NAMES.WORK]: 'Кем вы работаете',
  [FIELD_NAMES.URGENCY]: 'Насколько срочно нужно заселиться?',
  [FIELD_NAMES.URGENCY_DATE]: 'Насколько срочно нужно заселиться?',
  [FIELD_NAMES.PRIORITIES]: 'Что для вас самое важное?',
  [FIELD_NAMES.RECEIVE_TYPE]: 'Куда присылать подборки от личного помощника?',
  [FIELD_NAMES.RECEIVE_VALUE]: 'Куда присылать подборки от личного помощника?',
  [FIELD_NAMES.LOCATION_TYPE]: 'Где будем искать?',
  [FIELD_NAMES.SUBWAY_IDS]: 'Метро',
  [FIELD_NAMES.GEO_AREAS]: 'Область поиска',
  [FIELD_NAMES.FROM_SUBWAY]: 'Как будете добираться до метро',
  [FIELD_NAMES.FROM_SUBWAY_TIME]: 'Время в пути до метро',
  [FIELD_NAMES.CUSTOM_LOCATION]: 'Где будем искать?',
};

export const STEPS_REQUIRED_FIELDS = {
  0: [],
  1: [FIELD_NAMES.ROOM_QUANTITY],
  2: [FIELD_NAMES.PRICE],
  3: [FIELD_NAMES.MIN_SPACE],
  4: [
    FIELD_NAMES.LOCATION_TYPE,
    FIELD_NAMES.SUBWAY_IDS,
    FIELD_NAMES.FROM_SUBWAY,
    FIELD_NAMES.FROM_SUBWAY_TIME,
    FIELD_NAMES.GEO_AREAS,
    FIELD_NAMES.CUSTOM_LOCATION,
  ],
  5: [FIELD_NAMES.REPAIRS],
  6: [FIELD_NAMES.RESIDENTS, FIELD_NAMES.WORK, FIELD_NAMES.RESIDENTS_OTHER],
  7: [],
  8: [FIELD_NAMES.OWNER],
  9: [],
  10: [FIELD_NAMES.PRIORITIES],
  11: [],
  12: [FIELD_NAMES.RECEIVE_TYPE, FIELD_NAMES.RECEIVE_VALUE],
};

export const ROOM_QUANTITY_VALUES = [
  {
    value: 1,
    title: '1',
  },
  {
    value: 2,
    title: '2',
  },
  {
    value: 3,
    title: '3',
  },
  {
    value: 4,
    title: '4+',
  },
];

export const LOCATION_TYPE_VALUES = {
  SUBWAY: 'subway',
  AREA: 'area',
  CUSTOM: 'custom',
};

export const FROM_SUBWAY_VALUES = [
  { value: 'walk', title: 'Пешком' },
  { value: 'transport', title: 'На транспорте' },
];

export const FROM_SUBWAY_TIME_VALUES = [
  { value: 5, title: '5' },
  { value: 10, title: '10' },
  { value: 15, title: '15' },
  { value: 20, title: '20' },
];

export const PRIORITIY_VALUES = [
  {
    value: 'price',
    title: 'Цена',
  },
  {
    value: 'repairs',
    title: 'Ремонт',
  },
  {
    value: 'geography',
    title: 'География поиска',
  },
];

export const RECEIVE_TYPE_VALUES = {
  TELEGRAM: 'Telegram',
  WHATSAPP: 'WhatsApp',
  EMAIL: 'Email',
};

export const RECEIVE_SWITCHER_VALUES = [
  { value: RECEIVE_TYPE_VALUES.TELEGRAM, title: 'Telegram' },
  { value: RECEIVE_TYPE_VALUES.WHATSAPP, title: 'WhatsApp' },
  { value: RECEIVE_TYPE_VALUES.EMAIL, title: 'Email' },
];

export const RECEIVE_CONTACTS_PLACEHOLDERS = {
  [RECEIVE_TYPE_VALUES.TELEGRAM]: '+7 (999) 111-22-33',
  [RECEIVE_TYPE_VALUES.WHATSAPP]: '+7 (999) 111-22-33',
  [RECEIVE_TYPE_VALUES.EMAIL]: 'email@email.com',
};
