import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Nouislider from 'nouislider-react';
import debounce from 'lodash/debounce';

import { FIELD_NAMES } from '../constants';
import CustomInput from '../CustomInput';
import formatPrice from '../../../utils/formatPrice';
import styles from '../styles.scss';

const INPUT_OPTIONS = {
  numeral: true,
  numeralThousandsGroupStyle: 'thousand',
  delimiter: ' ',
};
const DEFAULT_SLIDER_STEP = 1000;
const PRICE_MIN = 0;
const PRICE_MAX = 10000000;
const RANGE = {
  min: [PRICE_MIN],
  '65%': [80000, 5000],
  '80%': [300000, 50000],
  max: [PRICE_MAX],
};

function StepMaxPrice({
  price = PRICE_MIN,
  errors,
  setMainValues,
  goToNextStep,
}) {
  const debouncedInputChange = React.useRef(null);
  const [priceValue, setPriceValue] = React.useState(price);
  const [sliderPrice, setSliderPrice] = React.useState(price);

  const isValid = !errors[FIELD_NAMES.PRICE];

  const handleInputChange = React.useCallback(
    rawValue => {
      if (debouncedInputChange && debouncedInputChange.current) {
        debouncedInputChange.current.cancel();
      }

      debouncedInputChange.current = debounce(() => {
        setSliderPrice(sliderPrice =>
          rawValue !== sliderPrice ? rawValue : sliderPrice,
        );

        setMainValues({ [FIELD_NAMES.PRICE]: rawValue });
      }, 500);

      return debouncedInputChange.current();
    },
    [sliderPrice],
  );

  const handleSliderUpdate = React.useCallback(
    (render, handle, value, un, percent) => {
      setPriceValue(parseInt(value[0], 10));
    },
  );

  const handleSliderSet = React.useCallback(
    (render, handle, value, un, percent) => {
      setMainValues({ [FIELD_NAMES.PRICE]: parseInt(value[0], 10) });
    },
  );

  const handleSubmit = () => {
    if (!isValid) {
      return null;
    }

    goToNextStep();
  };

  return (
    <React.Fragment>
      <div className={classNames(styles.inner, styles.innerTopbarSpace)}>
        <div className={classNames(styles.title, styles['title--mb25'])}>
          Бюджет не больше
        </div>
        <div className={styles.formItem}>
          <CustomInput
            value={priceValue}
            min={PRICE_MIN}
            max={PRICE_MAX}
            suffix="₽"
            isValid={isValid}
            options={INPUT_OPTIONS}
            formatFunction={formatPrice}
            onChange={handleInputChange}
          />
          <Nouislider
            id="price-range-slider"
            key="price-range-slider"
            className={styles.rangeSlider}
            range={RANGE}
            start={sliderPrice}
            connect={[true, false]}
            step={DEFAULT_SLIDER_STEP}
            onSlide={handleSliderUpdate}
            onSet={handleSliderSet}
          />
        </div>
      </div>
      <div className={styles.cta}>
        <div className={styles.buttonWrapper}>
          <button
            className={styles.button}
            type="button"
            disabled={!isValid}
            onClick={handleSubmit}
          >
            Дальше
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

StepMaxPrice.propTypes = {
  errors: PropTypes.object.isRequired,
  goToNextStep: PropTypes.func.isRequired,
  price: PropTypes.number,
  setMainValues: PropTypes.func.isRequired,
};

export default StepMaxPrice;
