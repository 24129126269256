import { intlShape } from 'react-intl';
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import AdNewsLetterForm from './AdNewsLetterForm';
import Modal from '../Modal/Modal';
import TranslationProvider from '../../../common/components/HOC/TranslationProvider';

const SUBSCRIPTION_STATES = {
  FORM: 'form',
  SUBSCRIBED: 'successSubscription',
  UNSUBSCRIBED: 'successUnsubscription',
};

class AdNewsletterModal extends React.Component {
  constructor(props) {
    super(props);

    const { state = SUBSCRIPTION_STATES.FORM } = this.props;

    this.state = {
      currentState: state,
    };
  }

  handleClose = e => {
    ReactDOM.unmountComponentAtNode(document.getElementById('modals'));
  };

  handleSuccessSubscribe = () => {
    this.setState({
      currentState: SUBSCRIPTION_STATES.SUBSCRIBED,
    });
  };

  renderSuccessSubscription() {
    const {
      intl: { formatMessage },
      email,
    } = this.props;

    return (
      <div className="modal-message modal-message-success">
        <div className="modal-title">
          {formatMessage(defaultMessages.jsAdNewsletterModalSuccessTitle)}
        </div>
        <div className="modal-text">
          {formatMessage(defaultMessages.jsAdNewsletterModalSuccessText)}
          {email !== null && (
            <React.Fragment>
              <br />
              <span
                dangerouslySetInnerHTML={{
                  __html: formatMessage(
                    defaultMessages.jsAdNewsletterModalSuccessTextSubscriptionStatus,
                    {
                      section: `<a class="link link-green" href="/cabinet/notifications">${formatMessage(
                        defaultMessages.jsAdNewsletterModalSuccessTextSubscriptionStatusSection,
                      )}</a>`,
                    },
                  ),
                }}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }

  renderSuccessUnsubscription() {
    const { unsubscriptionMessage } = this.props;

    return (
      <div className="modal-message modal-message-unsubscription">
        <div
          className="modal-lead"
          dangerouslySetInnerHTML={{ __html: unsubscriptionMessage }}
        />
      </div>
    );
  }

  renderContent() {
    const { currentState } = this.state;
    const { intl, email, filter } = this.props;

    switch (currentState) {
      case SUBSCRIPTION_STATES.FORM:
        return (
          <AdNewsLetterForm
            intl={intl}
            email={email}
            filter={filter}
            onSuccessSubscribe={this.handleSuccessSubscribe}
          />
        );
      case SUBSCRIPTION_STATES.UNSUBSCRIBED:
        return this.renderSuccessUnsubscription();
      case SUBSCRIPTION_STATES.SUBSCRIBED:
      default:
        return this.renderSuccessSubscription();
    }
  }

  render() {
    return (
      <Modal
        handleClose={this.handleClose}
        containerClass="modal-subscription-wizard"
      >
        {this.renderContent()}
      </Modal>
    );
  }
}

AdNewsletterModal.propTypes = {
  email: PropTypes.string,
  filter: PropTypes.object,
  intl: intlShape.isRequired,
  state: PropTypes.string,
  unsubscriptionMessage: PropTypes.string,
};

export default TranslationProvider(AdNewsletterModal);
