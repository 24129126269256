import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { defaultMessages } from '../../../../libs/i18n/default';
import { FIELD_NAMES } from './constants';
import filterOptions from '../../../common/helpers/ReactSelect/defaultFilterOptions';
import FlashNotifierService from '../../services/FlashNotifier/FlashNotifier';

class AdFormDistrict extends React.PureComponent {
  handleChange = district => {
    const { setAdState } = this.props;

    setAdState({
      [FIELD_NAMES.DISTRICT_ID]: district !== null ? district.value : null,
    });
  };

  optionRenderer = option => {
    return (
      <span className="Select-option-metro">
        <span className="Select-option-metro-text">{option.label}</span>
      </span>
    );
  };

  render() {
    const {
      intl: { formatMessage },
      districtId,
      districts
    } = this.props;

    const options = districts.map(({ id, title }) => ({
      value: id,
      label: title,
    }));

    return (
      <div className="form-row">
        <div className="form-row-label">
          {formatMessage(defaultMessages.jsAdFormDistrictLabel)}
        </div>
        <div className="form-row-content">
          <Select
            name="ad[district_id]"
            noResultsText={formatMessage(
              defaultMessages.jsReactSelectNoResults,
            )}
            value={districtId}
            options={options}
            placeholder=""
            filterOptions={filterOptions}
            optionRenderer={this.optionRenderer}
            onChange={this.handleChange}
          />
        </div>
      </div>
    );
  }
}

AdFormDistrict.propTypes = {
  intl: intlShape.isRequired,
  setAdState: PropTypes.func,
  districtId: PropTypes.any,
  districts: PropTypes.array,
};

export default AdFormDistrict;
