import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Textarea from 'react-textarea-autosize/dist/react-textarea-autosize';

import { FIELD_NAMES } from '../constants';
import styles from '../styles.scss';

const RentSearchApplicationStepWishes = ({
  wishes,
  errors,
  setMainValues,
  goToNextStep,
}) => {
  const isValid = !errors[FIELD_NAMES.WISHES];

  const isEmpty = !wishes || wishes.length === 0;

  const handleChange = e => {
    setMainValues({ [FIELD_NAMES.WISHES]: e.target.value });
  };

  const handleSubmitClick = () => {
    goToNextStep();
  };

  return (
    <React.Fragment>
      <div className={classNames(styles.inner, styles.innerTopbarSpace)}>
        <div className={styles.title}>Особые пожелания</div>
        <div className={styles.formItem}>
          <Textarea
            className={styles.textarea}
            value={wishes}
            minRows={1}
            placeholder="Срочный поиск, домашние животные, которых нужно согласовать с собственником, детская площадка во дворе и другие важные моменты."
            onChange={handleChange}
          />
        </div>
      </div>
      <div className={styles.cta}>
        <div className={styles.buttonWrapper}>
          {isEmpty ? (
            <button
              className={classNames(styles.button, styles.buttonGrey)}
              type="button"
              onClick={handleSubmitClick}
            >
              Мне не важно
            </button>
          ) : (
            <button
              className={styles.button}
              type="button"
              disabled={!isValid}
              onClick={handleSubmitClick}
            >
              Дальше
            </button>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

RentSearchApplicationStepWishes.propTypes = {
  errors: PropTypes.object.isRequired,
  goToNextStep: PropTypes.func.isRequired,
  setMainValues: PropTypes.func.isRequired,
  wishes: PropTypes.string.isRequired,
};

export default RentSearchApplicationStepWishes;
