/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Textarea from 'react-textarea-autosize/dist/react-textarea-autosize';

import { FIELD_NAMES, URGENCY_VALUES } from '../constants';
import Switcher from '../Switcher';
import styles from '../styles.scss';

const StepUrgency = ({
  urgency,
  urgencyDate,
  errors,
  goToNextStep,
  setMainValues,
}) => {
  const [isUrgencyDateOpened, setIsUrgencyOpened] = React.useState(
    urgency === 'other',
  );
  const [urgencyDateValue, setUrgencyDateValue] = React.useState(urgencyDate);

  const isValid =
    !errors[FIELD_NAMES.URGENCY] && !errors[FIELD_NAMES.URGENCY_DATE];

  const isEmpty = !urgency || urgency.length === 0;

  const handleUrgencyChange = value => {
    setIsUrgencyOpened(value === 'other');

    setMainValues({ [FIELD_NAMES.URGENCY]: value });
  };

  const onSubmitClick = () => {
    if (!isValid) {
      return null;
    }

    goToNextStep();
  };

  const handleUrgencyDateChange = ({ target: { value } }) => {
    setUrgencyDateValue(value);

    setMainValues({ [FIELD_NAMES.URGENCY_DATE]: event.target.value });
  };

  return (
    <React.Fragment>
      <div className={classNames(styles.inner, styles.innerTopbarSpace)}>
        <div className={styles.title}>Насколько срочно нужно заселиться?</div>
        <div className={styles.formItemBig}>
          <Switcher
            canDisable
            values={URGENCY_VALUES}
            initialActiveValue={urgency}
            onChange={handleUrgencyChange}
          />
        </div>
        {isUrgencyDateOpened && (
          <div className={styles.formItemBig}>
            <Textarea
              className={styles.textarea}
              value={urgencyDateValue}
              minRows={1}
              placeholder="29 февраля"
              onChange={handleUrgencyDateChange}
            />
          </div>
        )}
      </div>
      <div className={styles.cta}>
        <div className={styles.buttonWrapper}>
          {isEmpty ? (
            <button
              className={classNames(styles.button, styles.buttonGrey)}
              type="button"
              onClick={onSubmitClick}
            >
              Мне не важно
            </button>
          ) : (
            <button
              className={styles.button}
              type="button"
              disabled={!isValid}
              onClick={onSubmitClick}
            >
              Дальше
            </button>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

StepUrgency.propTypes = {
  errors: PropTypes.object.isRequired,
  goToNextStep: PropTypes.func.isRequired,
  setMainValues: PropTypes.func.isRequired,
  urgency: PropTypes.string,
  urgencyDate: PropTypes.string.isRequired,
};

export default StepUrgency;
