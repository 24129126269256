/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Textarea from 'react-textarea-autosize/dist/react-textarea-autosize';

import { FIELD_NAMES, RESIDENTS_VALUES } from '../constants';
import Switcher from '../Switcher';
import getElementOffset from '../../../utils/getElementOffset';
import styles from '../styles.scss';

const RentSearchApplicationStepResidents = ({
  residents,
  residentsOther,
  work,
  errors,
  goToNextStep,
  setMainValues,
}) => {
  const workRef = React.useRef();
  const [isOtherOpened, setIsOtherOpened] = React.useState(
    residents === 'other',
  );
  const [otherValue, setOtherValue] = React.useState(residentsOther);
  const [workValue, setWorkValue] = React.useState(work);

  const isValid =
    !errors[FIELD_NAMES.RESIDENTS] &&
    !errors[FIELD_NAMES.WORK] &&
    !errors[FIELD_NAMES.RESIDENTS_OTHER];

  const isSubmitHidden = !residents;

  const handleResidentsChange = value => {
    setIsOtherOpened(value === 'other');

    setMainValues({ [FIELD_NAMES.RESIDENTS]: value });

    scrollToWork();
  };

  const handleSubmitClick = () => {
    if (!isValid) {
      return null;
    }

    goToNextStep();
  };

  const handleOtherChange = ({ target: { value } }) => {
    setOtherValue(value);
    setMainValues({ [FIELD_NAMES.RESIDENTS_OTHER]: event.target.value });
  };

  const handleWorkChange = ({ target: { value } }) => {
    setWorkValue(value);
    setMainValues({ [FIELD_NAMES.WORK]: event.target.value });
  };

  const scrollToWork = React.useCallback(() => {
    if (workRef.current) {
      const workTop = getElementOffset(workRef.current).top;

      if (window.scrollY < workTop) {
        window.scrollTo({ top: workTop, behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <React.Fragment>
      <div className={classNames(styles.inner, styles.innerTopbarSpace)}>
        <div className={styles.title}>Жильцы</div>
        <div className={styles.formItemBig}>
          <div className={classNames(styles.formLabel, styles.formLabelBig)}>
            Кто будет жить в квартире
          </div>
          <Switcher
            canDisable
            values={RESIDENTS_VALUES}
            initialActiveValue={residents}
            onChange={handleResidentsChange}
          />
        </div>
        <div
          className={classNames(styles.formPartTransition, {
            [styles.hidden]: !isOtherOpened,
          })}
        >
          <div className={styles.formItemBig}>
            <Textarea
              className={styles.textarea}
              value={otherValue}
              minRows={1}
              placeholder="Впишите свой вариант"
              onChange={handleOtherChange}
            />
          </div>
        </div>
        <div ref={workRef}>
          <div className={styles.formLabel}>Кем вы работаете</div>
          <Textarea
            className={styles.textarea}
            value={workValue}
            minRows={1}
            placeholder="Менеджер в ИТ"
            onChange={handleWorkChange}
          />
        </div>
      </div>
      <div className={styles.cta}>
        <div className={styles.buttonWrapper}>
          <button
            className={classNames(styles.button, {
              [styles.hidden]: isSubmitHidden,
            })}
            type="button"
            disabled={!isValid}
            onClick={handleSubmitClick}
          >
            Дальше
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

RentSearchApplicationStepResidents.propTypes = {
  errors: PropTypes.object.isRequired,
  goToNextStep: PropTypes.func.isRequired,
  residents: PropTypes.string,
  residentsOther: PropTypes.string.isRequired,
  setMainValues: PropTypes.func.isRequired,
  work: PropTypes.string.isRequired,
};

export default RentSearchApplicationStepResidents;
