import React from 'react';
import PropTypes from 'prop-types';

const Cards = ({ children }) => (
  <div className="Cards">
    <div className="Cards__list">{children}</div>
  </div>
);

Cards.propTypes = {
  children: PropTypes.any,
};

export default Cards;
