import { intlShape } from 'react-intl';
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';
import find from 'lodash/find';
import Sortable from 'sortablejs';
import Uploadzone from 'react-dropzone';

import { defaultMessages } from '../../../../libs/i18n/default';
import { uploadImage } from '../../api/images';
import { FIELD_NAMES } from './constants';
import FlashNotifierService from '../../services/FlashNotifier/FlashNotifier';
import AdFormValidatable from './AdFormValidatableMixin';
import AdFormImage from './AdFormImage';

const moveArrayElement = (array, fromIndex, toIndex) => {
  array.splice(toIndex, 0, array.splice(fromIndex, 1)[0]);

  return array;
};

const generateFileUploadId = ({ name, size }) => `${name}-${size}`;

const findListElementById = (list, needleId) =>
  find(list, ({ id }) => id === needleId);

const COMPRESSED_WIDTH = 4;

class AdFormImages extends React.Component {
  constructor(props) {
    super(props);

    const { images, imageIds } = this.props;

    this.state = {
      images,
      previews: [],
      placeholders: [],
      isDragEnter: false,
      isDisabledSortable: imageIds.length <= 1,
    };

    this.sortableRef = React.createRef();
  }

  componentDidMount() {
    const { isDisabledSortable } = this.state;

    this.sortable = Sortable.create(this.sortableRef.current, {
      animation: 150,
      draggable: '.uploadzone-thumb',
      disabled: isDisabledSortable,
      handle: '.uploadzone-thumb-sortable-handle',
      forceFallback: true,
      onSort: this.handleSortImages,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { isDisabledSortable, placeholders } = this.state;
    const { imageIds } = this.props;

    this.sortable.option('disabled', isDisabledSortable);

    if (imageIds.length <= 1) {
      if (!isDisabledSortable) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ isDisabledSortable: true });
      }
    } else {
      if (isDisabledSortable && placeholders.length === 0) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ isDisabledSortable: false });
      }
    }
  }

  handleSortImages = event => {
    const { images } = this.state;
    const { imageIds, setAdState } = this.props;
    const { oldIndex, newIndex } = event;

    const newImageIds = moveArrayElement(imageIds, oldIndex, newIndex).map(
      id => id,
    );

    setAdState({ [FIELD_NAMES.IMAGE_IDS]: newImageIds });

    this.setState({
      images: moveArrayElement(images, oldIndex, newIndex),
    });
  };

  handleDrop = (acceptedFiles, rejectedFiles) => {
    if (!DeviceSupports.formData) {
      console.log('Your browser does not support FormData');
      return;
    }

    this.setState({ isDragEnter: false });

    if (Array.isArray(acceptedFiles) && acceptedFiles.length > 0) {
      this.setState({ isDisabledSortable: true });
      this.addNewPlaceholders(acceptedFiles);
      this.addNewPreviews(acceptedFiles);

      acceptedFiles.map(file => {
        const formData = new FormData();

        formData.append('files[]', file);

        this.sendData(formData, file);
      });
    }
  };

  handleDropRejected = files => {
    FlashNotifierService.notifyError('Загружать можно только jpg и png');
  };

  handleDragEnter = () => {
    this.setState({ isDragEnter: true });
  };

  handleDragLeave = () => {
    this.setState({ isDragEnter: false });
  };

  updateImage = updatedImage => {
    const { images } = this.state;

    const updatedImages = images.map(image => {
      if (image.id === updatedImage.id) {
        return updatedImage;
      }

      return image;
    });

    this.setState({ images: updatedImages });
  };

  handleRemoveImage = id => {
    const { images, isDisabledSortable } = this.state;

    const newImages = filter(images, image => image.id !== id);

    this.setState({
      images: newImages,
      isDisabledSortable: newImages.length < 2 ? true : isDisabledSortable,
    });
  };

  addNewImage(image) {
    let {
      placeholders: newPlaceholders,
      previews: newPreviews,
      images,
    } = this.state;
    let { imageIds, mainImageId, setAdState } = this.props;

    newPlaceholders.shift();
    newPreviews.shift();

    imageIds.push(image.id);
    images.push(image);

    setAdState({ [FIELD_NAMES.IMAGE_IDS]: imageIds });

    this.setState({
      images,
      previews: newPreviews,
      placeholders: newPlaceholders,
    });

    if (mainImageId === null) {
      setAdState({ [FIELD_NAMES.MAIN_IMAGE_ID]: image.id });
    }
  }

  footNoteContent() {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <div
        className="footnote-content"
        dangerouslySetInnerHTML={{
          __html: formatMessage(defaultMessages.jsAdFormImagesFootnote),
        }}
      />
    );
  }

  uploadProgress(file, progress) {
    const { placeholders } = this.state;

    const updatedPlaceholders = placeholders.map(placeholder => {
      if (placeholder.id === generateFileUploadId(file)) {
        return { ...placeholder, progress };
      }

      return placeholder;
    });

    this.setState({ placeholders: updatedPlaceholders });
  }

  sendData(formData, file) {
    uploadImage(formData, {
      onUploadProgress: ({ lengthComputable, loaded, total }) => {
        if (lengthComputable) {
          const progress = loaded / total;

          this.uploadProgress(file, progress);
        }
      },
    }).then(data => {
      this.addNewImage(data);
    });
  }

  addNewPlaceholders(files) {
    const { placeholders } = this.state;

    const newPlaceholders = files.map(file => ({
      id: generateFileUploadId(file),
      progress: 0,
      file,
    }));

    this.setState({ placeholders: placeholders.concat(newPlaceholders) });
  }

  addNewPreviews(files) {
    const supportFileAPI =
      window.File && window.FileReader && window.FileList && window.Blob;

    if (supportFileAPI) {
      let { previews } = this.state;

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      files.map(file => {
        const id = generateFileUploadId(file);
        const reader = new FileReader();

        previews.push({ id, file });

        reader.onload = event => {
          const img = new Image();

          img.onload = () => {
            let { width, height } = img;
            const compressedHeight = height * (COMPRESSED_WIDTH / width);

            img.width = COMPRESSED_WIDTH;
            canvas.width = COMPRESSED_WIDTH;
            canvas.height = compressedHeight;

            ctx.drawImage(img, 0, 0, COMPRESSED_WIDTH, compressedHeight);

            const updatedPreviews = previews.map(preview => {
              if (preview.id === id) {
                return { ...preview, dataURL: canvas.toDataURL() };
              }

              return preview;
            });

            this.setState({ previews: updatedPreviews }, () => {});
          };

          img.src = event.target.result;
        };

        reader.readAsDataURL(file);
      });
    }
  }

  renderImages() {
    const { images, isDisabledSortable } = this.state;
    const { mainImageId, imageIds, setAdState, intl } = this.props;

    return imageIds.map((imageId, index) => {
      const image = findListElementById(images, imageId);

      return (
        <AdFormImage
          key={`image_${imageId}`}
          imageIds={imageIds}
          mainImageId={mainImageId}
          intl={intl}
          image={image}
          isDisabledSortable={isDisabledSortable}
          setAdState={setAdState}
          updateImage={this.updateImage}
          onRemove={this.handleRemoveImage}
        />
      );
    });
  }

  renderPlaceholders() {
    const { placeholders } = this.state;

    return placeholders.map(({ id, progress, file: { name: fileName } }) => (
      <div className="uploadzone-thumb __uploading" key={fileName}>
        <div className="uploadzone-thumb-image">
          <div className="uploadzone-thumb-image-content">
            {this.renderPreview(id)}
          </div>
          <div className="uploadzone-thumb-progress">
            <div
              className="uploadzone-thumb-progress-bar"
              style={{ width: progress * 100 + '%' }}
            />
          </div>
        </div>
      </div>
    ));
  }

  renderPreview(id) {
    const { previews } = this.state;

    const image = findListElementById(previews, id);

    if (image) {
      const { dataURL } = image;

      return (
        <div
          className="uploadzone-thumb-image-bg __blur"
          style={{
            backgroundImage: dataURL ? `url(${dataURL})` : null,
          }}
        />
      );
    } else {
      return null;
    }
  }

  render() {
    const { isDragEnter } = this.state;
    const {
      intl: { formatMessage },
      errorMessage,
    } = this.props;

    return (
      <div className="form-row">
        <div className="form-row-label" id="ad_input_image_ids">
          {formatMessage(defaultMessages.jsAdFormImagesLabel)}
        </div>
        <div className="form-row-content">
          <div className="footnote">{this.footNoteContent()}</div>
          <Uploadzone
            onDrop={this.handleDrop}
            onDropRejected={this.handleDropRejected}
            onDragEnter={this.handleDragEnter}
            onDragLeave={this.handleDragLeave}
            style={null}
            className={classNames('uploadzone', { __hover: isDragEnter })}
            disableClick
            inputProps={{ id: 'image-files' }}
            accept="image/jpg,image/png,image/jpeg"
            name="files[]"
          >
            <div className="uploadzone-content">
              <i className="uploadzone-icon uploadzone-icon-photo" />
              <div
                className="uploadzone-desc"
                dangerouslySetInnerHTML={{
                  __html: formatMessage(
                    defaultMessages.jsAdFormImagesSelectText,
                  ),
                }}
              />
              <div className="uploadzone-buttons">
                <label className="uploadzone-button" htmlFor="image-files">
                  {formatMessage(defaultMessages.jsAdFormImagesSelectButton)}
                </label>
              </div>
            </div>
            <div ref={this.sortableRef} className="uploadzone-thumbs">
              {this.renderImages()}
              {this.renderPlaceholders()}
            </div>
          </Uploadzone>
          <div className="footnote footnote-static hidden-lg">
            {this.footNoteContent()}
          </div>
          {errorMessage()}
        </div>
      </div>
    );
  }
}

AdFormImages.propTypes = {
  errorMessage: PropTypes.func,
  imageIds: PropTypes.array,
  images: PropTypes.array,
  intl: intlShape.isRequired,
  mainImageId: PropTypes.number,
  setAdState: PropTypes.func,
};

export default AdFormValidatable(AdFormImages, [FIELD_NAMES.IMAGE_IDS]);
