import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Textarea from 'react-textarea-autosize/dist/react-textarea-autosize';

import { FIELD_NAMES } from '../constants';
import styles from '../styles.scss';

const StepYourPhoneNumber = ({
  phoneNumber,
  errors,
  setMainValues,
  goToNextStep,
}) => {
  const isEmpty = !phoneNumber || phoneNumber.length === 0;

  const handleChange = e => {
    setMainValues({ [FIELD_NAMES.PHONE_NUMBER]: e.target.value });
  };

  const handleSubmitClick = () => {
    goToNextStep();
  };

  return (
    <React.Fragment>
      <div className={classNames(styles.inner, styles.innerTopbarSpace)}>
        <div className={styles.title}>Ваш номер телефона для связи</div>
        <div className={styles.formItem}>
          <Textarea
            className={styles.textarea}
            value={phoneNumber}
            minRows={1}
            placeholder="Укажите ваш контактный номер телефона и мы обязательно с вами свяжемся"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className={styles.cta}>
        <div className={styles.buttonWrapper}>
          <button
            className={styles.button}
            type="button"
            disabled={isEmpty}
            onClick={handleSubmitClick}
          >
            Дальше
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

StepYourPhoneNumber.propTypes = {
  errors: PropTypes.object.isRequired,
  goToNextStep: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  setMainValues: PropTypes.func.isRequired,
};

export default StepYourPhoneNumber;
