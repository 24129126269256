import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Textarea from 'react-textarea-autosize/dist/react-textarea-autosize';

import { FIELD_NAMES, LOCATION_TYPE_VALUES } from '../constants';
import getElementOffset from '../../../utils/getElementOffset';
import ChooseGeoSubway from '../ChooseGeoSubway/ChooseGeoSubway';
import ChooseGeoArea from '../ChooseGeoArea/ChooseGeoArea';
import styles from '../styles.scss';

function StepGeo({
  city,
  errors,
  subways,
  subwayIds,
  isSubwaysLoading,
  locationType,
  geoAreas,
  fromSubway,
  subwayTime,
  customLocation,
  setMainValues,
  goToNextStep,
}) {
  const choosenGeoRef = React.useRef();
  const customLocationRef = React.useRef();
  const [choosenGeo, setChoosenGeo] = React.useState(locationType);
  const [customValue, setCustomValue] = React.useState(customLocation);

  const isValid =
    !errors[FIELD_NAMES.LOCATION_TYPE] &&
    !errors[FIELD_NAMES.CUSTOM_LOCATION] &&
    !errors[FIELD_NAMES.GEO_AREAS] &&
    !errors[FIELD_NAMES.SUBWAY_IDS] &&
    !errors[FIELD_NAMES.FROM_SUBWAY] &&
    !errors[FIELD_NAMES.FROM_SUBWAY_TIME];

  const isSubmitHidden =
    errors[FIELD_NAMES.LOCATION_TYPE] ||
    (locationType === LOCATION_TYPE_VALUES.SUBWAY &&
      errors[FIELD_NAMES.FROM_SUBWAY]);

  React.useEffect(() => {
    if (locationType !== choosenGeo) {
      setChoosenGeo(locationType);

      if (
        locationType === LOCATION_TYPE_VALUES.CUSTOM &&
        customLocationRef.current
      ) {
        customLocationRef.current.focus();
      }

      setTimeout(() => {
        scrollAfterChooseType(locationType);
      }, 300);
    }
  }, [locationType]);

  const handleChangeLocationType = value => {
    if (value === choosenGeo) {
      return null;
    }

    setMainValues({ [FIELD_NAMES.LOCATION_TYPE]: value });
  };

  const handleFromSubwayChange = React.useCallback(value => {
    setMainValues({ [FIELD_NAMES.FROM_SUBWAY]: value });
  }, []);

  const handleFromSubwayTimeChange = React.useCallback(value => {
    setMainValues({ [FIELD_NAMES.FROM_SUBWAY_TIME]: value });
  }, []);

  const handleSubwayIdsChange = React.useCallback(values => {
    const nextSubwayIds = values.map(item =>
      Number.isInteger(item) ? item : Number(item.value),
    );
    setMainValues({ [FIELD_NAMES.SUBWAY_IDS]: nextSubwayIds });
  }, []);

  const handleRemove = React.useCallback(
    removeId => {
      let currentValue = subwayIds;
      const index = currentValue.findIndex(id => id === removeId);

      setMainValues({
        [FIELD_NAMES.SUBWAY_IDS]: [
          ...currentValue.slice(0, index),
          ...currentValue.slice(index + 1),
        ],
      });
    },
    [subwayIds],
  );

  const handleDrawAreaChange = React.useCallback(geoAreas => {
    setMainValues({ [FIELD_NAMES.GEO_AREAS]: geoAreas });
  });

  const handleDrawAreaClose = newValues => {
    if (
      (!newValues && (!geoAreas || geoAreas.length === 0)) ||
      newValues.length === 0
    ) {
      handleChangeLocationType(null);
    }
  };

  const scrollAfterChooseType = React.useCallback(value => {
    if (value === null) {
      window.scrollTo({ top: 0, behavior: 'smooth' });

      return null;
    }

    if (choosenGeoRef.current) {
      const choosenGeoTop = getElementOffset(choosenGeoRef.current).top;

      if (window.scrollY < choosenGeoTop) {
        window.scrollTo({
          top: choosenGeoTop - 70,
          behavior: 'smooth',
        });
      }
    }
  }, []);

  const handleOtherChange = React.useCallback(({ target: { value } }) => {
    setCustomValue(value);
    setMainValues({ [FIELD_NAMES.CUSTOM_LOCATION]: event.target.value });
  }, []);

  const handleSubmit = () => {
    if (!isValid) {
      return null;
    }

    goToNextStep();
  };

  return (
    <React.Fragment>
      <div className={styles.inner}>
        <div className={styles.geoStepContainer}>
          <div className={classNames(styles.title, styles['title--mb40'])}>
            Где будем искать?
          </div>
          <div className={styles.formItemBig}>
            { (subways && subways.length > 0) ? (
              <button
                type="button"
                className={classNames(styles.buttonGeoSwitch, {
                  [styles.buttonGeoSwitchActive]:
                    choosenGeo === LOCATION_TYPE_VALUES.SUBWAY,
                })}
                onClick={() =>
                  handleChangeLocationType(LOCATION_TYPE_VALUES.SUBWAY)
                }
              >
                <span className={styles.buttonGeoSwitchTitle}>Рядом с метро</span>
                <span className={styles.buttonGeoSwitchNotice}>
                  Помощник будет искать квартиры рядом с выбранными станциями
                  метро
                </span>
              </button>
            ) : null }
            <button
              type="button"
              className={classNames(styles.buttonGeoSwitch, {
                [styles.buttonGeoSwitchActive]:
                  choosenGeo === LOCATION_TYPE_VALUES.AREA,
              })}
              onClick={() =>
                handleChangeLocationType(LOCATION_TYPE_VALUES.AREA)
              }
            >
              <span className={styles.buttonGeoSwitchTitle}>
                Внутри выделенной области
              </span>
              <span className={styles.buttonGeoSwitchNotice}>
                Помощник будет искать квартиры внутри области, которую вы
                выделите на карте.
              </span>
            </button>
            <button
              type="button"
              className={classNames(styles.buttonGeoSwitch, {
                [styles.buttonGeoSwitchActive]:
                  choosenGeo === LOCATION_TYPE_VALUES.CUSTOM,
              })}
              onClick={() =>
                handleChangeLocationType(LOCATION_TYPE_VALUES.CUSTOM)
              }
            >
              <span className={styles.buttonGeoSwitchTitle}>
                Напишу текстом
              </span>
            </button>
          </div>
          <div ref={choosenGeoRef}>
            <div
              className={classNames(styles.formPartTransition, {
                [styles.hidden]: choosenGeo === null,
              })}
            >
              {choosenGeo === LOCATION_TYPE_VALUES.SUBWAY ? (
                <ChooseGeoSubway
                  city={city}
                  fromSubway={fromSubway}
                  subwayTime={subwayTime}
                  subways={subways}
                  subwayIds={subwayIds}
                  isSubwaysLoading={isSubwaysLoading}
                  onSubwayChange={handleSubwayIdsChange}
                  onSubwayRemove={handleRemove}
                  onFromSubwayChange={handleFromSubwayChange}
                  onSubwayTimeChange={handleFromSubwayTimeChange}
                />
              ) : null}
              {choosenGeo === LOCATION_TYPE_VALUES.AREA ? (
                <React.Fragment>
                  <p className={styles.formLabel}>Область поиска</p>
                  <ChooseGeoArea
                    city={city}
                    geoAreas={geoAreas}
                    onChange={handleDrawAreaChange}
                    onClose={handleDrawAreaClose}
                  />
                </React.Fragment>
              ) : null}
              {choosenGeo === LOCATION_TYPE_VALUES.CUSTOM ? (
                <React.Fragment>
                  <p className={styles.formLabel}>
                    Напишите, где вы хотите жить
                  </p>
                  <Textarea
                    inputRef={tag => (customLocationRef.current = tag)}
                    className={styles.textarea}
                    value={customValue}
                    minRows={1}
                    placeholder="В свободной форме"
                    onChange={handleOtherChange}
                  />
                </React.Fragment>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.cta}>
        <div className={styles.buttonWrapper}>
          <button
            className={classNames(styles.button, {
              [styles.hidden]: isSubmitHidden,
            })}
            type="button"
            disabled={!isValid}
            onClick={handleSubmit}
          >
            Дальше
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

StepGeo.propTypes = {
  city: PropTypes.object.isRequired,
  customLocation: PropTypes.string,
  errors: PropTypes.object.isRequired,
  fromSubway: PropTypes.string,
  geoAreas: PropTypes.any,
  goToNextStep: PropTypes.func.isRequired,
  isSubwaysLoading: PropTypes.bool.isRequired,
  locationType: PropTypes.any,
  setMainValues: PropTypes.func.isRequired,
  subwayIds: PropTypes.any,
  subwayTime: PropTypes.number,
  subways: PropTypes.array.isRequired,
};

export default StepGeo;
