/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';

const CLASS_HOVER = '__hover';
const CLASS_LOADING = '__loading';
const CLASS_ACTIVE = '__active';
const CLASS_VISIBLE = '__visible';

const FavoriteButton = React.forwardRef(
  (
    {
      intl: { formatMessage },
      isLoading,
      isFavorited,
      isHovered,
      onMouseEnter,
      onMouseLeave,
      onClick,
    },
    ref,
  ) => {
    let text;

    if (isFavorited) {
      text = formatMessage(
        defaultMessages.jsControlFavoriteRemoveFromFavorites,
      );
    } else {
      text = formatMessage(defaultMessages.jsControlFavoriteAddToFavorites);
    }

    if (isLoading) {
      if (isFavorited) {
        text = formatMessage(
          defaultMessages.jsControlFavoriteRemovingFromFavorites,
        );
      } else {
        text = formatMessage(
          defaultMessages.jsControlFavoriteAddingToFavorites,
        );
      }
    }

    return (
      <div
        className={
          'control-favorite' +
          ` ${CLASS_VISIBLE}` +
          (isFavorited ? ` ${CLASS_ACTIVE}` : '') +
          (isLoading ? ` ${CLASS_LOADING}` : '') +
          (isHovered ? ` ${CLASS_HOVER}` : '')
        }
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        ref={ref}
      >
        <div className="control-favorite-text">{text}</div>
        <div className="control-favorite-icon">
          {isLoading && <span className="spinner" />}
        </div>
      </div>
    );
  },
);

FavoriteButton.propTypes = {
  intl: intlShape.isRequired,
  isFavorited: PropTypes.bool,
  isHovered: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

export default FavoriteButton;
