import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import classNames from 'classnames';

import SvgIcon from '../SvgIcon/SvgIcon';
import styles from './CitySelector.scss';

class CitySelector extends React.Component {
  constructor(props) {
    super(props);

    const { currentCityId } = this.props;

    this.state = {
      currentCityId,
      isFocus: false,
    };
  }

  getCityById(id) {
    const { cities } = this.props;

    return find(cities, { id });
  }

  handleChange = event => {
    const id = parseInt(event.target.value);
    const currentCity = this.getCityById(id);

    this.setState({ currentCityId: id });

    window.location = currentCity.url;
  };

  handleFocus = () => {
    this.setState({ isFocus: true });
  };

  handleBlur = () => {
    this.setState({ isFocus: false });
  };

  render() {
    const { isFocus, currentCityId } = this.state;
    const { cities } = this.props;

    const currentCity = this.getCityById(currentCityId);

    return (
      <div
        className={classNames(`${styles.citySelector}`, {
          [`${styles.focus}`]: isFocus,
        })}
      >
        <div className={styles.value}>{currentCity.title}</div>
        <div className={styles.icon}>
          <SvgIcon icon="location-arrow" />
        </div>
        <select
          className={styles.control}
          value={currentCityId}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
        >
          {cities.map(({ id, title }) => (
            <option key={`City-${id}`} value={id}>
              {title}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

CitySelector.propTypes = {
  cities: PropTypes.array.isRequired,
  currentCityId: PropTypes.number.isRequired,
};

export default CitySelector;
