/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import getElementOffset from '../../utils/getElementOffset';
import getWindowScrollTop from '../../utils/getWindowScrollTop';
import isElementContains from '../../utils/isElementContains';

class DropdownPopup extends React.PureComponent {
  constructor(props) {
    super(props);

    this.popupComponentRef = React.createRef();
    this.popupArrowRef = React.createRef();

    this.container = this.getContainer();

    this.relationClass = '';
  }

  componentDidMount() {
    const popupElement = this.popupComponentRef.current;

    popupElement.style.display = 'block';
    this.setStyles();

    document.addEventListener('click', this.handleDocumentClick, true);
    window.addEventListener('resize', this.recalcPosition, false);
    window.addEventListener('scroll', this.recalcPosition, false);
  }

  componentWillUnmount() {
    if (this.scheduledAnimationFrame) {
      cancelAnimationFrame(this.scheduledAnimationFrame);
    }

    document.removeEventListener('click', this.handleDocumentClick, true);
    window.removeEventListener('resize', this.recalcPosition, false);
    window.removeEventListener('scroll', this.recalcPosition, false);
  }

  getContainer = () => {
    let container = document.querySelector('[data-dropdown-container]');

    if (container === null) {
      container = document.createElement('div');
      container.setAttribute('data-dropdown-container', '');

      document.body.appendChild(container);
    }

    return container;
  };

  recalcPosition = () => {
    if (this.scheduledAnimationFrame) {
      return;
    }

    this.scheduledAnimationFrame = requestAnimationFrame(this.setStyles);
  };

  setStyles = () => {
    const { offset, toggleButtonRef } = this.props;

    if (!toggleButtonRef.current) {
      return;
    }

    const popupElement = this.popupComponentRef.current;
    const popupArrowElement = this.popupArrowRef.current;
    const { offsetWidth: popupWidth, offsetHeight: popupHeight } = popupElement;

    const {
      clientWidth: windowWidth,
      clientHeight: windowHeight,
    } = document.documentElement;
    const scrollTop = getWindowScrollTop();

    const {
      offsetWidth: togglerWidth,
      offsetHeight: togglerHeight,
    } = toggleButtonRef.current;
    const { top: togglerTop, left: togglerLeft } = getElementOffset(
      toggleButtonRef.current,
    );

    let popupTop = togglerTop + togglerHeight + offset;
    let popupLeft = togglerLeft + togglerWidth / 2 - popupWidth / 2;

    if (this.relationClass) {
      popupElement.classList.remove(this.relationClass);
    }

    this.relationClass = '__to-bottom';

    const belowLowerLimit = popupTop + popupHeight > scrollTop + windowHeight;
    const belowUpperLimit = togglerTop - popupHeight - offset >= 0;
    if (belowLowerLimit && belowUpperLimit) {
      this.relationClass = '__to-top';
      popupTop = togglerTop - popupHeight - offset;
    }

    const greaterRightlimit = popupLeft + popupWidth > windowWidth;
    if (greaterRightlimit) {
      popupLeft = windowWidth - popupWidth;
    }

    const lessLeftlimit = popupLeft < 0;
    if (lessLeftlimit) {
      popupLeft = 0;
    }

    const { offsetWidth: popupArrowSize } = popupArrowElement;
    const popupArrowOffset =
      Math.sqrt(Math.pow(popupArrowSize, 2) + Math.pow(popupArrowSize, 2)) / 2;

    let popupArrowLeft;

    if (greaterRightlimit || lessLeftlimit) {
      popupArrowLeft =
        togglerWidth / 2 + Math.abs(togglerLeft - popupLeft) - popupArrowOffset;
    } else {
      popupArrowLeft = popupWidth / 2 - popupArrowOffset;
    }

    popupElement.style.top = popupTop + 'px';
    popupElement.style.left = popupLeft + 'px';
    popupElement.style.display = 'block';
    popupElement.classList.add(this.relationClass);

    popupArrowElement.style.left = popupArrowLeft + 'px';

    this.scheduledAnimationFrame = null;
  };

  handleDocumentClick = ({ target }) => {
    const { toggleButtonRef, onOutsideClick } = this.props;

    if (
      !isElementContains(toggleButtonRef.current, target) &&
      !isElementContains(this.popupComponentRef.current, target)
    ) {
      onOutsideClick();
    }
  };

  render() {
    const {
      extraClassName,
      children,
      showButtonClose,
      onButtonCloseClick,
    } = this.props;

    return (
      this.container !== null &&
      ReactDOM.createPortal(
        <div
          className={
            'dropdown-popup dropdown-popup-smart' +
            (extraClassName !== '' ? ` ${extraClassName}` : '')
          }
          ref={this.popupComponentRef}
        >
          <div className="dropdown-popup-arrow" ref={this.popupArrowRef} />
          {showButtonClose && (
            <div
              onClick={onButtonCloseClick}
              className="dropdown-popup-close"
            />
          )}
          <div className="dropdown-popup-content">{children}</div>
        </div>,
        this.container,
      )
    );
  }
}

DropdownPopup.propTypes = {
  children: PropTypes.any,
  extraClassName: PropTypes.string,
  offset: PropTypes.any,
  showButtonClose: PropTypes.bool,
  toggleButtonRef: PropTypes.any,
  onButtonCloseClick: PropTypes.func,
  onOutsideClick: PropTypes.func,
};

DropdownPopup.defaultProps = {
  showButtonClose: false,
};

export default DropdownPopup;
