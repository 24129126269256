import anime from 'animejs';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import AssistantChatTypingDots from './AssistantChatTypingDots';
import styles from './AssistantChatMessage.scss';

const SIZE_ANIMATION_TIME = 300;
const DEFAULT_TYPING_TIME = 0;

class AssistantChatMessage extends React.PureComponent {
  constructor(props) {
    super(props);

    const { forceVisible } = this.props;

    this.state = {
      isTyping: !forceVisible,
      messageSize: {
        width: 'auto',
        height: 'auto',
      },
    };

    this.typingTimeout = null;
    this.showingTimeout = null;

    this.containerRef = React.createRef();
    this.messageRef = React.createRef();
  }

  componentDidMount() {
    const { forceVisible, typingTime } = this.props;

    if (!forceVisible && typingTime >= 0) {
      this.typingTimeout = setTimeout(this.setOffTyping, typingTime);

      if (this.containerRef.current) {
        anime({
          targets: this.containerRef.current,
          opacity: [0, 1],
          duration: 200,
          easing: 'easeInOutSine',
        });
      }
    } else {
      this.setMessageSize();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.setMessageSize();
  }

  componentWillUnmount() {
    clearTimeout(this.typingTimeout);
    clearTimeout(this.showingTimeout);
  }

  setMessageSize = () => {
    const { messageSize } = this.state;

    if (this.messageRef.current && messageSize.width === 'auto') {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        messageSize: {
          width: this.messageRef.current.offsetWidth + 4,
          height: this.messageRef.current.offsetHeight,
        },
      });
    }
  };

  setShowingTimeout = () => {
    const { showTime, onShowEnd } = this.props;

    this.showingTimeout = setTimeout(() => {
      onShowEnd && onShowEnd();
    }, showTime);
  };

  setOffTyping = () => {
    this.setState({ isTyping: false }, () => {
      if (this.containerRef.current && this.messageRef.current) {
        anime({
          targets: this.messageRef.current,
          opacity: [0, 1],
          duration: 100,
        });

        anime({
          targets: this.containerRef.current,
          opacity: [0, 1],
          duration: SIZE_ANIMATION_TIME,
          easing: 'easeInOutSine',
          complete: this.setShowingTimeout,
        });
      }
    });
  };

  render() {
    const { isTyping, messageSize } = this.state;
    const { message, theme, direction } = this.props;

    return (
      <div
        className={classNames(
          styles.cloudMessage,
          styles[`cloudMessage--${theme}`],
          styles[`cloudMessage--${direction}`],
          {
            [styles.typingMessage]: isTyping,
          },
        )}
        ref={this.containerRef}
      >
        {typeof message === 'string' ? (
          <div
            className={classNames(styles.messageContent, {
              [styles.messageContentTyping]: isTyping,
            })}
            ref={this.messageRef}
            style={messageSize}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        ) : (
          <div
            className={classNames(styles.messageContent, {
              [styles.messageContentTyping]: isTyping,
            })}
            ref={this.messageRef}
            style={messageSize}
          >
            {message}
          </div>
        )}
        {isTyping && (
          <div className={styles.typingDotsContainer}>
            <AssistantChatTypingDots />
          </div>
        )}
      </div>
    );
  }
}

AssistantChatMessage.propTypes = {
  direction: PropTypes.oneOf(['left', 'right']),
  forceVisible: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showTime: PropTypes.number,
  theme: PropTypes.oneOf(['green', 'white']),
  typingTime: PropTypes.number,
  onShowEnd: PropTypes.func,
};

AssistantChatMessage.defaultProps = {
  direction: 'right',
  forceVisible: false,
  theme: 'green',
  typingTime: DEFAULT_TYPING_TIME,
};

export default AssistantChatMessage;
