/* eslint-disable jsx-a11y/label-has-associated-control */
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';

import { defaultMessages } from '../../../../libs/i18n/default';
import { FIELD_NAMES } from './constants';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';
import AdFormFootnote from './AdFormFootnoteMixin';
import AdFormValidatable from './AdFormValidatableMixin';
import AdFormInputPhone from './AdFormInputPhone';

class AdFormPhone extends React.Component {
  handlePhoneChange = event => {
    const { setAdState } = this.props;

    setAdState({ [FIELD_NAMES.PHONE]: event.target.value });
  };

  handleSecurePhoneChange = event => {
    const { setAdState } = this.props;

    setAdState({ [FIELD_NAMES.SECURE_PHONE]: event.currentTarget.checked });
  };

  phonePlaceholder() {
    const { country } = this.props;

    if (country === 'ru') {
      return '+7 ';
    } else {
      return '+380 ';
    }
  }

  phoneMask() {
    const { country } = this.props;

    if (country === 'ua') {
      return '+38 099 999-99-99';
    } else {
      return '+7 999 999-99-99';
    }
  }

  renderSecure() {
    const {
      intl: { formatMessage },
      slug,
      securePhone,
      country,
      adPhoneForwardingPrice,
      defaultCurrency,
    } = this.props;

    return (
      country === 'ru' &&
      slug == null && (
        <div className="secure-phone-wrapper">
          <div className="secure-phone">
            <div className="secure-phone-inner">
              <div className="secure-phone-icon" />
              <div className="secure-phone-desc">
                <div className="secure-phone-title">
                  <span className="color-green">
                    {formatMessage(defaultMessages.jsAdFormPhoneProtectTitle)}
                  </span>{' '}
                  {formatMessage(defaultMessages.jsAdFormPhoneProtectAction)}
                </div>
                <ul className="secure-phone-benefits">
                  <li
                    className="secure-phone-benefits-item"
                    dangerouslySetInnerHTML={{
                      __html: formatMessage(
                        defaultMessages.jsAdFormPhoneProtectBenefitsFirst,
                      ),
                    }}
                  />
                  <li
                    className="secure-phone-benefits-item"
                    dangerouslySetInnerHTML={{
                      __html: formatMessage(
                        defaultMessages.jsAdFormPhoneProtectBenefitsSecond,
                      ),
                    }}
                  />
                  <li
                    className="secure-phone-benefits-item"
                    dangerouslySetInnerHTML={{
                      __html: formatMessage(
                        defaultMessages.jsAdFormPhoneProtectBenefitsThird,
                      ),
                    }}
                  />
                </ul>
                <div className="secure-phone-checkbox">
                  <label className="checkbox checkbox-flat2">
                    <input
                      id="ad_input_secure_phone"
                      className="checkbox-control"
                      type="checkbox"
                      name="ad[secure_phone]"
                      value="1"
                      onChange={this.handleSecurePhoneChange}
                      checked={securePhone}
                    />
                    <span className="checkbox-fake" />
                    <span className="checkbox-content">
                      <span className="checkbox-text">
                        {formatMessage(defaultMessages.jsAdFormPhoneProtectBuy)}
                        <b>
                          {' '}
                          {adPhoneForwardingPrice}&nbsp;
                          {formatMessage(
                            defaultMessages[
                              `jsCurrencies${capitalizeFirstLetter(
                                defaultCurrency,
                              )}`
                            ],
                          )}
                          .
                        </b>
                      </span>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  }

  render() {
    const {
      intl: { formatMessage },
      phone,
      bigFootnote,
      hasErrors,
      smallFootnote,
      errorMessage,
    } = this.props;

    return (
      <div className="form-row">
        <div className="form-row-label">
          {formatMessage(defaultMessages.jsAdFormPhoneLabel)}
        </div>
        <div className="form-row-content">
          <div>
            {bigFootnote()}
            <AdFormInputPhone
              value={phone}
              placeholder={this.phonePlaceholder()}
              mask={this.phoneMask()}
              error={hasErrors()}
              onChange={this.handlePhoneChange}
            />
          </div>
          {smallFootnote()}
          {errorMessage()}
        </div>
      </div>
    );
  }
}

AdFormPhone.propTypes = {
  adPhoneForwardingPrice: PropTypes.number,
  bigFootnote: PropTypes.func,
  country: PropTypes.string,
  defaultCurrency: PropTypes.string,
  errorMessage: PropTypes.func,
  hasErrors: PropTypes.func,
  intl: intlShape.isRequired,
  phone: PropTypes.string,
  securePhone: PropTypes.bool,
  setAdState: PropTypes.func,
  slug: PropTypes.string,
  smallFootnote: PropTypes.func,
};

export default injectIntl(
  AdFormFootnote(
    AdFormValidatable(AdFormPhone, FIELD_NAMES.PHONE),
    FIELD_NAMES.PHONE,
  ),
);
