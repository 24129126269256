import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { defaultMessages } from '../../../../libs/i18n/default';
import FlashNotifierService from '../../services/FlashNotifier/FlashNotifier';
import filterOptions from '../../../common/helpers/ReactSelect/defaultFilterOptions';

class MainFiltersSelectDealTypes extends React.Component {
  state = { dealType: 'rent' };
  options = [
    { value: 'rent', label: 'Аренда длительно' },
    { value: 'daily_rent', label: 'Аренда посуточно' },
    { value: 'sale', label: 'Продажа' }
  ]

  handleChange = selectedOption => {
    const { updateState } = this.props;
    updateState({ dealType: selectedOption.value === null ? 'rent' : selectedOption.value });
  };

  dealTypeOptionRender = ({ label }) => {
    return (
      <span className="Select-option-metro">
        <span className="Select-option-metro-text">{label}</span>
      </span>
    );
  };

  render() {
    const {
      intl: { formatMessage },
      dealType,
    } = this.props;

    return (
      <div className="MainFilters__select">
        <Select
          clearable={false}
          options={this.options}
          value={dealType}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

MainFiltersSelectDealTypes.propTypes = {
  cityId: PropTypes.number.isRequired,
  intl: intlShape.isRequired,
  dealType: PropTypes.number.isRequired,
  updateState: PropTypes.func.isRequired,
};

export default MainFiltersSelectDealTypes;
