/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

const AdFormCheckbox = React.memo(function({
  icon,
  classMods,
  customIcon,
  children,
  id = null,
  name = null,
  onChange,
  required,
  checked,
  value,
  text,
}) {
  let classNamespace = 'checkbox';
  let className = classNamespace;

  if (classMods) {
    classMods.forEach(mod => {
      className += ` ${classNamespace}-${mod}`;
    });
  }

  let iconNode = null;

  if (icon) {
    iconNode = <span className={'checkbox-icon checkbox-icon-' + icon} />;
  }

  return (
    <label className={className}>
      <input
        id={id}
        className={classNamespace + '-control'}
        type="checkbox"
        name={name}
        onChange={onChange}
        required={required}
        checked={checked}
        value={value}
      />
      <span className={classNamespace + '-content'}>
        {customIcon ? children : iconNode}
        <span className={classNamespace + '-text'}>{text}</span>
      </span>
    </label>
  );
});

AdFormCheckbox.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
  classMods: PropTypes.array,
  customIcon: PropTypes.bool,
  icon: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  text: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default AdFormCheckbox;
