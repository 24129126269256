import React from 'react';
import { injectIntl, intlShape } from 'react-intl';

import { defaultMessages } from '../../../../libs/i18n/default';
import { LANDING_SEARCH_ASSISTANT_PATH } from '../../constants/paths';
import AdCatalogBanner from './AdCatalogBanner';

const AdCatalogClosedAccessBanner = ({ intl: { formatMessage } }) => {
  return (
    <AdCatalogBanner
      type="access"
      imageSrc={require('../../../../images/client/AdCatalog/banner-closed-access.svg')}
      imageWidth={220}
      imageHeight={124}
      title={formatMessage(defaultMessages.jsCatalogClosedAccessCardTitle)}
      notice={formatMessage(defaultMessages.jsCatalogClosedAccessCardText)}
      submitText={formatMessage(
        defaultMessages.jsCatalogClosedAccessCardRequest,
      )}
      href={LANDING_SEARCH_ASSISTANT_PATH}
    />
  );
};

AdCatalogClosedAccessBanner.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(AdCatalogClosedAccessBanner);
