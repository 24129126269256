import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import MainFiltersCheckboxRooms from './MainFiltersCheckboxRooms';

class MainFiltersCheckboxListRooms extends React.Component {
  getTooltip(num) {
    const {
      intl: { formatMessage },
    } = this.props;

    switch (num) {
      case 1:
        return formatMessage(defaultMessages.jsMainFiltersOneRoomApartments);
      case 2:
        return formatMessage(defaultMessages.jsMainFiltersTwoRoomApartments);
      case 3:
        return formatMessage(defaultMessages.jsMainFiltersThreeRoomApartments);
      default:
        return formatMessage(defaultMessages.jsMainFiltersMultiRoomApartments);
    }
  }

  render() {
    const { roomQuantity, updateState } = this.props;

    return (
      <React.Fragment>
        {[...Array(4).keys()].map((value, i, arr) => {
          const num = i + 1;

          return (
            <MainFiltersCheckboxRooms
              key={`RoomQuantity-${num}`}
              isChecked={roomQuantity.indexOf(num) !== -1}
              roomQuantity={roomQuantity}
              text={num === arr.length ? `${arr.length}+` : `${num}`}
              tooltip={this.getTooltip(num)}
              value={num}
              updateState={updateState}
            />
          );
        })}
      </React.Fragment>
    );
  }
}

MainFiltersCheckboxListRooms.propTypes = {
  intl: intlShape.isRequired,
  roomQuantity: PropTypes.array.isRequired,
  updateState: PropTypes.func.isRequired,
};

export default MainFiltersCheckboxListRooms;
