/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

class Modal extends React.Component {
  componentWillMount() {
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflow = null;
  }

  render() {
    const { containerClass, children, disallowClose, handleClose } = this.props;

    return (
      <div>
        <div className="arcticmodal-overlay" />
        <div className="arcticmodal-container">
          <table className="arcticmodal-container_i">
            <tbody>
              <tr>
                <td className="arcticmodal-container_i2">
                  <div className={classNames('modal', containerClass)}>
                    <div className="modal-dialog">
                      {!disallowClose && (
                        <div
                          className="modal-close arcticmodal-close"
                          onClick={handleClose}
                        >
                          <i className="modal-close-icon" />
                        </div>
                      )}
                      <div className="modal-content">{children}</div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.node,
  containerClass: PropTypes.string,
  disallowClose: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default Modal;
