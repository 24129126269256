/* eslint-disable jsx-a11y/label-has-associated-control */
import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import { KINDS } from '../AdCatalog/AdCatalog.constants';

class MainFiltersCheckboxKind extends React.Component {
  handleChange = event => {
    const { isDisabled, updateState } = this.props;

    if (isDisabled) {
      return null;
    }

    updateState({
      kind: event.target.checked ? KINDS.ROOM : '',
      roomQuantity: [],
    });
  };

  render() {
    const {
      intl: { formatMessage },
      isDisabled,
      kind,
    } = this.props;

    return (
      <label className="MainFilters__checkbox MainFilters__checkbox--kind">
        <input
          className="MainFilters__checkboxControl"
          checked={kind === KINDS.ROOM}
          disabled={isDisabled}
          type="checkbox"
          onChange={this.handleChange}
        />
        <span className="MainFilters__checkboxContent">
          {formatMessage(defaultMessages.jsMainFiltersRoom)}
        </span>
      </label>
    );
  }
}

MainFiltersCheckboxKind.propTypes = {
  intl: intlShape.isRequired,
  isDisabled: PropTypes.bool,
  kind: PropTypes.string.isRequired,
  updateState: PropTypes.func.isRequired,
};

export default MainFiltersCheckboxKind;
