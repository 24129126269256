import React from 'react';
import PropTypes from 'prop-types';

import AdFormInputHOC from './AdFormInputMixin';

const AdFormInput = React.memo(function({
  wrapperClass,
  iconComponent,
  id = null,
  name = null,
  value = null,
  placeholder = null,
  onBlur,
  onChange,
  onFocus,
  onKeyUp,
  inputProps = {},
}) {
  return (
    <div className={wrapperClass('input')}>
      {iconComponent}
      <input
        id={id}
        className="input-control"
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onKeyUp={onKeyUp}
        value={value === null ? '' : value}
        placeholder={placeholder}
        {...inputProps}
      />
    </div>
  );
});

AdFormInput.propTypes = {
  iconComponent: PropTypes.node,
  id: PropTypes.string,
  inputProps: PropTypes.any,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  wrapperClass: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyUp: PropTypes.func,
};

export default AdFormInputHOC(AdFormInput);
