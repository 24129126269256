import { intlShape } from 'react-intl';
import React from 'react';

import { defaultMessages } from '../../../../libs/i18n/default';

const OfferFavoriteDropdown = ({ intl: { formatMessage } }) => (
  <div className="dropdown-popup-text">
    <span>
      {formatMessage(defaultMessages.jsControlFavoriteAuthDropdownYouNeedTo)}{' '}
    </span>
    <a
      href="#"
      title={formatMessage(defaultMessages.jsControlFavoriteAuthDropdownSignIn)}
      data-auth-modal-trigger=""
      className="link link-green"
    >
      {formatMessage(defaultMessages.jsControlFavoriteAuthDropdownSignIn)}
    </a>
    <span>
      {' '}
      {formatMessage(defaultMessages.jsControlFavoriteAuthDropdownOr)}{' '}
    </span>
    <a
      href="#"
      title={formatMessage(defaultMessages.jsControlFavoriteAuthDropdownSignUp)}
      data-auth-modal-trigger=""
      data-auth-modal-form="registerByPhone"
      className="link link-green"
    >
      {formatMessage(defaultMessages.jsControlFavoriteAuthDropdownSignUp)}
    </a>
  </div>
);

OfferFavoriteDropdown.propTypes = {
  intl: intlShape.isRequired,
};

export default OfferFavoriteDropdown;
