import axios from 'axios';

const instance = axios.create({
  // baseURL: 'https://thelocals.ru/',
  responseType: 'json',
  withCredentials: true,
});

export const CancelToken = axios.CancelToken;

export default instance;
