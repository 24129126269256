/* eslint-disable react/no-string-refs */
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import * as constants from './OfferMap.constants';
import OfferMapViewControl from './OfferMapViewControl';
import loadScript from '../../utils/loadScript';

const { MAIN_CLASS } = constants;

class OfferMapGoogleMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      view: constants.VIEW_MAP,
      isMapsReady: false,
      isMapInited: false,
      isPanoramaInited: false,
    };

    this.resizeTimer = null;
    this.map = null;
    this.marker = null;
    this.markerPopup = null;
    this.markerPopupContent = null;
    this.panorama = null;
  }

  componentDidMount() {
    GoogleMapsLoader(
      {
        key: window.GOOGLE_MAPS_KEY,
        language: window.SITE_LANG,
        version: '3',
      },
      () => {
        Promise.all([
          loadScript('js/googlemaps/richmarker-compiled.js'),
          loadScript('js/googlemaps/infobox-compiled.js'),
        ]).then(() => {
          this.onMapsReady();
        });
      },
    );
  }

  onMapsReady = () => {
    const { view } = this.state;

    this.setState({ isMapsReady: true });

    switch (view) {
      case constants.VIEW_MAP:
        this.initMap();
        break;
      case constants.VIEW_PANORAMA:
        this.initPanorama();
        break;
    }
  };

  onMapViewToggle = () => {
    const { isMapInited, isPanoramaInited } = this.state;

    this.setState({ view: constants.VIEW_MAP }, () => {
      if (!isMapInited) {
        this.initMap();
      } else {
        google.maps.event.trigger(this.map, 'resize');
        this.map.panTo(this.getLatLng());
        if (this.markerPopupContent) this.setCenterMarkerPopupLeftOffset();
        // Если переключились на панораму (карта скрыта) и сделали ресайз страницы - метка смещается.
        // Поэтому обновляем позицию метки.
        this.marker.setPosition(this.getLatLng());
      }

      if (isPanoramaInited) {
        this.panorama.setVisible(false);
      }
    });
  };

  onPanoramaViewToggle = () => {
    const { isPanoramaInited } = this.state;

    this.setState({ view: constants.VIEW_PANORAMA }, () => {
      if (!isPanoramaInited) {
        this.initPanorama();
      } else {
        // Если переключились на карту (панорама скрыты) и сделали ресайз страницы - панорама становится чёрной.
        // Поэтому хитрим.
        this.panorama.setVisible(true);
      }
    });
  };

  getLatLng() {
    const {
      ad: { lat, lng },
    } = this.props;

    return new google.maps.LatLng(lat, lng);
  }

  getMarkerLayout = () => {
    return (
      '<div class="map-marker map-marker-green-stroke">' +
      '<div class="map-marker-icon">' +
      '</div>' +
      '</div>'
    );
  };

  getMarkerPopupLayout = () => {
    return (
      '<div class="map-popup map-popup-flat">' +
      '<div class="map-popup-arrow"></div>' +
      '<div class="map-popup-content">' +
      '{{address}}' +
      '</div>' +
      '</div>'
    );
  };

  setCenterMarkerPopupLeftOffset() {
    this.markerPopup.setOptions({
      pixelOffset: new google.maps.Size(
        -(this.markerPopupContent.clientWidth / 2),
        -60,
      ),
    });
  }

  isMapsPanoramaReady = () => {
    return (
      window &&
      window.google &&
      window.google.maps &&
      typeof window.google.maps.StreetViewPanorama === 'function'
    );
  };

  initMap() {
    const {
      ad: { lat, lng, rooms, address },
    } = this.props;

    this.setState({ isMapInited: true }, () => {
      this.map = new google.maps.Map(this.refs.map, {
        zoom: constants.ZOOM,
        center: {
          lat,
          lng,
        },
        maxZoom: constants.ZOOM_MAX,
        minZoom: constants.ZOOM_MIN,
        mapTypeControl: false,
        scrollwheel: false,
        streetViewControl: false,
        clickableIcons: false,
      });

      this.addMarker(this.getLatLng(), rooms);
      this.addMarkerPopup(address);
      this.openMarkerPopup(this.marker, this.markerPopup);

      google.maps.event.addListenerOnce(this.map, 'tilesloaded', () => {
        this.map.panTo(this.getLatLng());
        if (this.markerPopupContent) {
          this.setCenterMarkerPopupLeftOffset();
        }
      });

      google.maps.event.addDomListener(window, 'resize', () => {
        if (this.resizeTimer) clearTimeout(this.resizeTimer);

        this.resizeTimer = setTimeout(() => {
          this.map.panTo(this.getLatLng());
          if (this.markerPopupContent) {
            this.setCenterMarkerPopupLeftOffset();
          }
        }, 100);
      });
    });
  }

  addMarker(latLng, rooms) {
    this.marker = new RichMarker({
      position: latLng,
      map: this.map,
      draggable: false,
      flat: true,
      content: this.getMarkerLayout().replace(
        '{{rooms}}',
        rooms >= 4 ? '4+' : rooms,
      ),
    });
  }

  addMarkerPopup(address) {
    this.markerPopupContent = document.createElement('div');
    this.markerPopupContent.innerHTML = this.getMarkerPopupLayout().replace(
      '{{address}}',
      address,
    );

    this.markerPopup = new InfoBox({
      alignBottom: true,
      boxStyle: {
        width: 'auto',
      },
      closeBoxURL: '',
      content: this.markerPopupContent,
      pixelOffset: new google.maps.Size(-50, -60),
      infoBoxClearance: new google.maps.Size(1, 1),
      enableEventPropagation: true,
    });

    google.maps.event.addDomListener(this.markerPopup, 'domready', () => {
      this.setCenterMarkerPopupLeftOffset();
    });
  }

  openMarkerPopup() {
    this.markerPopup.open(this.map, this.marker);
  }

  initPanorama() {
    this.setState({ isPanoramaInited: true }, () => {
      this.panorama = new google.maps.StreetViewPanorama(this.refs.panorama, {
        position: this.getLatLng(),
        panControl: false,
        zoomControl: false,
        fullscreenControl: false,
        enableCloseButton: false,
      });

      this.map.setStreetView(this.panorama);
    });
  }

  renderViewControl() {
    const { view, isMapsReady } = this.state;
    const { intl } = this.props;

    return (
      <OfferMapViewControl
        view={view}
        intl={intl}
        isVisible={isMapsReady && this.isMapsPanoramaReady()}
        onMapViewToggle={this.onMapViewToggle}
        onPanoramaViewToggle={this.onPanoramaViewToggle}
      />
    );
  }

  renderMap() {
    const { isMapInited } = this.state;

    if (isMapInited) {
      return <div ref="map" className={`${MAIN_CLASS}__map`} />;
    }

    return null;
  }

  renderPanorama() {
    const { isPanoramaInited } = this.state;

    if (isPanoramaInited) {
      return <div ref="panorama" className={`${MAIN_CLASS}__panorama`} />;
    }

    return null;
  }

  renderLoader() {
    const { isMapsReady } = this.state;

    return (
      !isMapsReady && (
        <div className={`${MAIN_CLASS}__loader`}>
          <span className="spinner" />
        </div>
      )
    );
  }

  render() {
    const { view } = this.state;

    return (
      <div className={`${MAIN_CLASS} ${MAIN_CLASS}--google`}>
        <div
          className={classNames(`${MAIN_CLASS}__viewport`, {
            [`${MAIN_CLASS}__viewport--map`]: view === constants.VIEW_MAP,
            [`${MAIN_CLASS}__viewport--panorama`]:
              view === constants.VIEW_PANORAMA,
          })}
        >
          {this.renderMap()}
          {this.renderPanorama()}
          {this.renderViewControl()}
          {this.renderLoader()}
        </div>
      </div>
    );
  }
}

OfferMapGoogleMap.propTypes = {
  ad: PropTypes.object,
  intl: intlShape.isRequired,
};

export default OfferMapGoogleMap;
