import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import styles from './Tabs.scss';
import AnimateOnMount, {
  ANIMATION_TYPES,
} from '../AnimateOnMount/AnimateOnMount';
import Tab from './Tab';

class Tabs extends React.Component {
  constructor(props) {
    super(props);

    const { children } = this.props;

    this.state = {
      activeTab: children[0].props.label,
    };
  }

  handleClickTabItem = tab => {
    this.setState({ activeTab: tab });
  };

  render() {
    const { activeTab } = this.state;
    const {
      containerClass,
      labelClass,
      tabContentClass,
      children,
    } = this.props;

    return (
      <div
        className={classNames(styles.tabs, {
          [containerClass]: containerClass !== null,
        })}
      >
        <ol className={styles.list}>
          {children.map(({ props: { label } }) => (
            <Tab
              activeTab={activeTab}
              key={label}
              label={label}
              className={labelClass}
              onClick={this.handleClickTabItem}
            />
          ))}
        </ol>
        <div
          className={classNames(styles.tabContent, {
            [tabContentClass]: tabContentClass !== null,
          })}
        >
          <AnimateOnMount
            isOpen
            transitionName={ANIMATION_TYPES.FADE}
            transitionEnterTimeout={400}
            transitionLeaveTimeout={200}
          >
            {children.map(({ props }) => {
              if (props.label !== activeTab) {
                return void 0;
              }

              return (
                <div
                  key={`content-${props.label}`}
                  className={styles.tabContentInner}
                >
                  {props.children}
                </div>
              );
            })}
          </AnimateOnMount>
        </div>
      </div>
    );
  }
}

Tabs.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired,
  containerClass: PropTypes.string,
  labelClass: PropTypes.string,
  tabContentClass: PropTypes.string,
};

Tabs.defaultProps = {
  containerClass: null,
  labelClass: null,
  tabContentClass: null,
};

export default Tabs;
