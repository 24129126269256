/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

class AuthModalTab extends React.PureComponent {
  handleClick = () => {
    const { id, isActive, onClick } = this.props;

    if (!isActive && onClick) {
      onClick(id);
    }
  };

  render() {
    const { isActive, title } = this.props;

    return (
      <span
        className={classNames('modal-form-tab', {
          'modal-form-tab-active': isActive,
        })}
        onClick={this.handleClick}
      >
        {title}
      </span>
    );
  }
}

AuthModalTab.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isActive: PropTypes.bool,
  title: PropTypes.string,
  onClick: PropTypes.func,
};

AuthModalTab.defaultProps = {
  isActive: false,
};

export default AuthModalTab;
