import { intlShape } from 'react-intl';
import classNames from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import loadScript from '../../utils/loadScript';
import getScreenWidth from './utils/getScreenWidth';
import AdCatalogGoogleMapMarkerPopup from './AdCatalogGoogleMapMarkerPopup';
import AdCatalogGoogleMapClusterPopup from './AdCatalogGoogleMapClusterPopup';

const ZOOM_MIN = 2;
const ZOOM_MAX = 17;
const ZOOM_REFRACTION = 14;
const CLASS_VIEWED = '__viewed';
const CLASS_UNVERIFIED = '__unverified';

const MAX_CLUSTER_SIZE_FOR_INFO_BOX = 5;

const getId = x => x.id;

class AdCatalogGoogleMap extends React.Component {
  constructor(props) {
    super(props);

    this.mapRef = React.createRef();
  }

  componentDidMount() {
    const {
      options: { zoom },
    } = this.props;

    this.cache = {}; // Ads data
    this.zoom = zoom;
    this.zoomSegment =
      this.zoom >= ZOOM_REFRACTION ? ZOOM_REFRACTION : ZOOM_MIN;

    this.activeMarker = null;
    this.markers = [];
    this.markerInfoBox = null;
    this.markerInfoBoxContent = null;
    this.markerInfoBoxPlacement = 'top';
    this.shouldCloseMarkerInfoBox = true;

    this.activeCluster = null;
    this.markerClusterer = null;
    this.clusterInfoBox = null;
    this.clusterInfoBoxContent = null;
    this.clusterInfoBoxPlacement = 'top';
    this.shouldCloseClusterInfoBox = true;

    GoogleMapsLoader(
      {
        key: window.GOOGLE_MAPS_KEY,
        language: window.SITE_LANG,
        version: '3',
      },
      () => {
        Promise.all([
          loadScript('js/googlemaps/richmarker-compiled.js'),
          loadScript('js/googlemaps/infobox-compiled.js'),
          loadScript('js/googlemaps/markerclusterer-compiled.js'),
        ]).then(() => {
          this.initMap();
        });
      },
    );
  }

  componentWillReceiveProps(nextProps) {
    const {
      options: { zoom },
      isVisible,
    } = this.props;

    if (this.map && !isVisible && nextProps.isVisible) {
      // setTimeout нужен, чтобы адекватно обновить карту при переходе из списка.
      // Иначе картинки тайлов не подгружаются.
      setTimeout(() => {
        google.maps.event.trigger(this.map, 'resize');

        if (zoom === this.zoom) {
          this.map.panTo(
            new google.maps.LatLng(this.getOptionLat(), this.getOptionLng()),
          );
        }
      }, 0);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { ads } = this.props;

    const prevAdIds = ads.map(getId);
    const nextAdIds = nextProps.ads.map(getId);

    return prevAdIds.toString() !== nextAdIds.toString();
  }

  componentDidUpdate() {
    const { ads } = this.props;

    if (this.map) {
      this.clearClusters();
      this.clearMarkers();

      setTimeout(() => {
        this.addMarkers(ads);
        this.addClusters();
      }, 0);
    }
  }

  // eslint-disable-next-line react/sort-comp
  initMap() {
    const { ads, onInit } = this.props;

    this.map = new google.maps.Map(this.mapRef.current, {
      zoom: this.zoom,
      center: {
        lat: this.getOptionLat(),
        lng: this.getOptionLng(),
      },
      maxZoom: ZOOM_MAX,
      minZoom: ZOOM_MIN,
      mapTypeControl: false,
      streetViewControl: false,
      clickableIcons: false,
    });

    this.addMarkers(ads);
    this.addClusters();
    this.addEvents();

    onInit();
  }

  enableMapDraggingAndScrollwheel() {
    this.map.setOptions({
      draggable: true,
      scrollwheel: true,
    });
  }

  disableMapDraggingAndScrollwheel() {
    this.map.setOptions({
      draggable: false,
      scrollwheel: false,
    });
  }

  addEvents() {
    google.maps.event.addDomListener(window, 'resize', () => {
      if (this.activeMarker && this.markerInfoBox) {
        this.updateMarkerInfoBoxPixelOffset(this.activeMarker.position);
      }

      if (this.activeCluster && this.clusterInfoBox) {
        this.updateClusterInfoBoxPixelOffset(this.activeCluster.getCenter());
      }
    });

    google.maps.event.addListener(this.map, 'bounds_changed', () => {
      const { ads } = this.props;
      const zoom = this.map.getZoom();

      let shouldUpdateMarkers = false;

      if (zoom === this.zoom) {
        return;
      }

      if (zoom >= ZOOM_REFRACTION) {
        if (this.zoomSegment !== ZOOM_REFRACTION) {
          shouldUpdateMarkers = true;
          this.zoomSegment = ZOOM_REFRACTION;
        }
      } else {
        if (this.zoomSegment !== ZOOM_MIN) {
          shouldUpdateMarkers = true;
          this.zoomSegment = ZOOM_MIN;
        }
      }

      this.zoom = zoom;

      if (!shouldUpdateMarkers) return;

      this.clearClusters();
      this.clearMarkers();

      setTimeout(() => {
        this.addMarkers(ads);
        this.addClusters();
      }, 0);
    });

    google.maps.event.addListener(this.map, 'zoom_changed', () => {
      this.closeMarkerInfoBox();
      this.closeClusterInfoBox();
    });

    google.maps.event.addListener(this.map, 'click', () => {
      if (this.shouldCloseMarkerInfoBox) {
        this.closeMarkerInfoBox();
      }

      if (this.shouldCloseClusterInfoBox) {
        this.closeClusterInfoBox();
      }

      this.shouldCloseMarkerInfoBox = true;
      this.shouldCloseClusterInfoBox = true;
    });
  }

  addMarkers(ads) {
    for (let i = 0, len = ads.length; i < len; i++) {
      this.addMarker(ads[i]);
    }
  }

  addMarker(ad) {
    let marker = new RichMarker({
      position: new google.maps.LatLng(ad.lat, ad.lng),
      // map: this.map,
      draggable: false,
      flat: true,
      content: this.getMarkerContent(ad),
    });

    marker.id = ad.id;

    google.maps.event.addListener(marker, 'click', () => {
      this.onMarkerClick(ad, marker);
    });

    this.markers.push(marker);
  }

  clearMarkers() {
    var i, len, marker;

    for (i = 0, len = this.markers.length; i < len; i++) {
      marker = this.markers[i];
      marker.setMap(null);
      google.maps.event.clearInstanceListeners(marker);
    }

    marker = null;
    this.markers = [];
  }

  createMarkerInfoBoxContent() {
    if (!this.markerInfoBoxContent) {
      this.markerInfoBoxContent = document.createElement('div');
      this.markerInfoBoxContent.addEventListener('click', () => {
        this.shouldCloseMarkerInfoBox = false;
      });
    }
  }

  createMarkerInfoBox() {
    if (!this.markerInfoBox) {
      this.markerInfoBox = new InfoBox({
        alignBottom: true,
        boxStyle: {
          width: 'auto',
        },
        closeBoxURL: '',
        content: this.markerInfoBoxContent,
        disableAutoPan: true,
        pixelOffset: new google.maps.Size(-110, -10),
        infoBoxClearance: new google.maps.Size(15, 15),
        enableEventPropagation: true,
      });
    }
  }

  closeMarkerInfoBox() {
    if (this.activeMarker && this.markerInfoBox) {
      this.removeMarkerPopup();
      this.markerInfoBox.close();
      this.activeMarker.setVisible(true);
      this.activeMarker = null;
      this.enableMapDraggingAndScrollwheel();
    }
  }

  updateMarkerInfoBoxPlacement(geoCoords) {
    const placement = this.getInfoBoxPlacement(geoCoords);

    this.markerInfoBoxPlacement = placement;

    this.markerInfoBox.setOptions({
      alignBottom: !!(placement === 'top'),
    });
  }

  updateMarkerInfoBoxPixelOffset(geoCoords) {
    const placement = this.getInfoBoxPlacement(geoCoords);

    this.markerInfoBoxPlacement = placement;

    this.markerInfoBox.setOptions({
      pixelOffset: new google.maps.Size(
        -(this.markerInfoBoxContent.clientWidth / 2),
        10 * (placement === 'top' ? -1 : 1),
      ),
    });
  }

  addClusters() {
    this.markerClusterer = new MarkerClusterer(this.map, this.markers, {
      gridSize: 32,
      zoomOnClick: false,
      styles: [
        {
          url: 'images/map-cluster-green.svg',
          height: 38,
          width: 38,
          anchor: [0, 0],
          textColor: '#fff',
          textSize: 13,
        },
      ],
    });

    google.maps.event.addListener(
      this.markerClusterer,
      'clusterclick',
      cluster => {
        this.onClusterClick(cluster);
      },
    );
  }

  clearClusters() {
    if (this.markerClusterer) {
      google.maps.event.clearInstanceListeners(this.markerClusterer);
      this.markerClusterer.clearMarkers();
    }
  }

  createClusterInfoBoxContent() {
    if (!this.clusterInfoBoxContent) {
      this.clusterInfoBoxContent = document.createElement('div');

      this.clusterInfoBoxContent.addEventListener('click', () => {
        this.shouldCloseClusterInfoBox = false;
      });

      this.clusterInfoBoxContent.addEventListener('mouseover', () => {
        this.disableMapDraggingAndScrollwheel();
      });

      this.clusterInfoBoxContent.addEventListener('mouseout', () => {
        this.enableMapDraggingAndScrollwheel();
      });

      this.clusterInfoBoxContent.addEventListener('touchstart', () => {
        this.disableMapDraggingAndScrollwheel();
      });

      this.clusterInfoBoxContent.addEventListener('touchend', () => {
        this.enableMapDraggingAndScrollwheel();
      });
    }
  }

  createClusterInfoBox() {
    if (!this.clusterInfoBox) {
      this.clusterInfoBox = new InfoBox({
        alignBottom: true,
        boxStyle: {
          width: 'auto',
        },
        closeBoxURL: '',
        content: this.clusterInfoBoxContent,
        disableAutoPan: true,
        pixelOffset: new google.maps.Size(-110, -10),
        infoBoxClearance: new google.maps.Size(
          getScreenWidth() <= 320 ? 0 : 15,
          15,
        ),
        enableEventPropagation: true,
      });
    }
  }

  closeClusterInfoBox() {
    if (this.activeCluster && this.clusterInfoBox) {
      this.removeClusterPopup();
      this.clusterInfoBox.close();
      this.activeCluster.clusterIcon_.show();
      this.activeCluster = null;
      this.enableMapDraggingAndScrollwheel();
    }
  }

  updateClusterInfoBoxPlacement(geoCoords) {
    let placement = this.getInfoBoxPlacement(geoCoords);

    this.clusterInfoBoxPlacement = placement;

    this.clusterInfoBox.setOptions({
      alignBottom: !!(placement === 'top'),
    });
  }

  updateClusterInfoBoxPixelOffset(geoCoords) {
    let placement = this.getInfoBoxPlacement(geoCoords);

    this.clusterInfoBoxPlacement = placement;

    this.clusterInfoBox.setOptions({
      pixelOffset: new google.maps.Size(
        -(this.clusterInfoBoxContent.clientWidth / 2),
        10 * (placement === 'top' ? -1 : 1),
      ),
    });
  }

  onOfferFavorite = id => {
    this.cache[id].favorite = true;
  };

  onOfferUnfavorite = id => {
    this.cache[id].favorite = false;
  };

  onMarkerClick = (ad, marker) => {
    this.shouldCloseMarkerInfoBox = false;

    this.closeMarkerInfoBox();
    this.createMarkerInfoBoxContent();
    this.createMarkerInfoBox();

    this.updateMarkerInfoBoxPlacement(marker.position);

    this.markerInfoBox.open(this.map, marker);

    if (!this.getMarkerViewedStateFromStorage(ad.id)) {
      this.setViewedMarkerToStorage(ad.id);
      let markerElement = marker.markerContent_.firstChild;
      markerElement.className += ' ' + CLASS_VIEWED;
      markerElement.setAttribute('title', this.getMarkerTooltip(ad));
    }

    ReactDOM.render(
      this.renderMarkerPopup(ad.id),
      this.markerInfoBoxContent,
      () => {
        this.activeMarker = marker;
        this.activeMarker.setVisible(false);
        setTimeout(() => {
          this.updateMarkerInfoBoxPixelOffset(marker.position);
        }, 0);
      },
    );

    // return false;
  };

  onMarkerPopupClose = () => {
    this.closeMarkerInfoBox();
  };

  onMarkerPopupAjaxSuccess = response => {
    if (!this.cache[response.id]) {
      this.cache[response.id] = response;
    }
  };

  onClusterClick = cluster => {
    this.shouldCloseClusterInfoBox = false;
    this.closeClusterInfoBox();

    if (this.markerClusterer.isZoomOnClick()) {
      return;
    }

    if (
      cluster.getSize() <= MAX_CLUSTER_SIZE_FOR_INFO_BOX ||
      this.map.getZoom() >= ZOOM_MAX
    ) {
      this.createClusterInfoBoxContent();
      this.createClusterInfoBox();

      const clusterCoords = cluster.getCenter();

      this.updateClusterInfoBoxPlacement(clusterCoords);

      this.clusterInfoBox.open(this.map, {
        getPosition: function() {
          return clusterCoords;
        },
      });

      ReactDOM.render(
        this.renderClusterPopup(this.getClusterIds(cluster)),
        this.clusterInfoBoxContent,
        () => {
          this.activeCluster = cluster;
          this.activeCluster.clusterIcon_.hide();
          setTimeout(() => {
            this.updateClusterInfoBoxPixelOffset(clusterCoords);
          }, 0);
        },
      );
    } else {
      this.map.fitBounds(cluster.getBounds());
    }
  };

  onClusterPopupClose = () => {
    this.closeClusterInfoBox();
  };

  onClusterPopupAjaxSuccess = response => {
    if (!this.cache[response.id]) this.cache[response.id] = response;
  };

  getMarkerViewedStateFromStorage = id => {
    if (DeviceSupports.localStorage) {
      return !!localStorage.getItem('offerId' + id + '_isViewed');
    }

    return false;
  };

  getMarkerStateClasses(ad) {
    const { id, moderated } = ad;

    let stateClasses = [''];

    if (this.getMarkerViewedStateFromStorage(id)) {
      stateClasses.push(CLASS_VIEWED);
    }

    if (!moderated) {
      stateClasses.push(CLASS_UNVERIFIED);
    }

    return stateClasses.join(' ');
  }

  getMarkerTooltip(ad) {
    const {
      intl: { formatMessage },
    } = this.props;
    const { id, moderated } = ad;

    const isViewed = this.getMarkerViewedStateFromStorage(id);

    let tooltip = null;

    if (!isViewed) {
      tooltip = formatMessage(
        defaultMessages.jsCatalogMapPlacemarkHintCurrentOffer,
      );
    } else {
      tooltip = formatMessage(defaultMessages.jsCatalogMapPlacemarkHintViewed);
    }

    if (!moderated) {
      tooltip = 'не проверено';
    }

    return tooltip;
  }

  getMarkerContent(ad) {
    return (
      '<div class="map-marker map-marker-circle map-marker-circle-big' +
      this.getMarkerStateClasses(ad) +
      '" title="' +
      this.getMarkerTooltip(ad) +
      '">' +
      '<div class="map-marker-icon">' +
      (ad.rooms >= 4 ? '4+' : ad.rooms.toString()) +
      '</div>' +
      '<div class="map-marker-price">' +
      ad.price +
      (ad.price_currency_code === 'usd'
        ? ' $'
        : '<i class="currency currency-' + ad.price_currency_code + '"></i>') +
      '</div>' +
      '</div>'
    );
  }

  getClusterIds = cluster => {
    return cluster.getMarkers().map(marker => marker.id);
  };

  getInfoBoxPlacement(geoCoords) {
    const { y } = this.getPositionInPixels(geoCoords);

    if (y - 0 < this.map.getDiv().offsetHeight - y) {
      return 'bottom';
    }

    return 'top';
  }

  getOptionLat() {
    const {
      options: { lat },
    } = this.props;

    return parseFloat(lat);
  }

  getOptionLng() {
    const {
      options: { lng },
    } = this.props;

    return parseFloat(lng);
  }

  getCurrencyCode = currency => {
    switch (currency) {
      case 'руб':
        return 'rub';
      case 'грн':
        return 'uah';
    }
  };

  getPositionInPixels(object) {
    const scale = Math.pow(2, this.map.getZoom());

    const nw = new google.maps.LatLng(
      this.map
        .getBounds()
        .getNorthEast()
        .lat(),
      this.map
        .getBounds()
        .getSouthWest()
        .lng(),
    );

    const worldCoordinateNW = this.map.getProjection().fromLatLngToPoint(nw);
    const worldCoordinate = this.map.getProjection().fromLatLngToPoint(object);

    return new google.maps.Point(
      Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale),
      Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale),
    );
  }

  setViewedMarkerToStorage = id => {
    if (DeviceSupports.localStorage) {
      localStorage.setItem(`offerId${id}_isViewed`, true);
    }
  };

  removeMarkerPopup() {
    ReactDOM.unmountComponentAtNode(this.markerInfoBoxContent);
  }

  removeClusterPopup() {
    ReactDOM.unmountComponentAtNode(this.clusterInfoBoxContent);
  }

  renderMarkerPopup(id) {
    const { intl, isUserLoggedIn } = this.props;

    if (this.cache[id]) {
      return (
        <AdCatalogGoogleMapMarkerPopup
          isLoaded
          ad={this.cache[id]}
          intl={intl}
          isUserLoggedIn={isUserLoggedIn}
          placement={this.markerInfoBoxPlacement}
          onClose={this.onMarkerPopupClose}
          onOfferFavorite={this.onOfferFavorite}
          onOfferUnfavorite={this.onOfferUnfavorite}
        />
      );
    } else {
      return (
        <AdCatalogGoogleMapMarkerPopup
          id={id}
          intl={intl}
          isUserLoggedIn={isUserLoggedIn}
          placement={this.markerInfoBoxPlacement}
          onClose={this.onMarkerPopupClose}
          onAjaxSuccess={this.onMarkerPopupAjaxSuccess}
          onOfferFavorite={this.onOfferFavorite}
          onOfferUnfavorite={this.onOfferUnfavorite}
        />
      );
    }
  }

  renderClusterPopup(ids) {
    const { intl, isUserLoggedIn } = this.props;

    let unloadedAdIds = [];
    let ads = [];

    ids.forEach(id => {
      if (this.cache[id]) {
        unloadedAdIds.push(id);
      } else {
        ads.push(this.cache[id]);
      }
    });

    return (
      <AdCatalogGoogleMapClusterPopup
        unloadedAdIds={unloadedAdIds}
        ads={ads}
        intl={intl}
        isLoaded={unloadedAdIds.length === 0}
        isUserLoggedIn={isUserLoggedIn}
        placement={this.clusterInfoBoxPlacement}
        onClose={this.onClusterPopupClose}
        onAjaxSuccess={this.onClusterPopupAjaxSuccess}
        onOfferFavorite={this.onOfferFavorite}
        onOfferUnfavorite={this.onOfferUnfavorite}
      />
    );
  }

  render() {
    const { isVisible } = this.props;

    return (
      <div
        className={classNames('catalog-map', {
          'catalog-map--hidden': !isVisible,
        })}
      >
        <div
          ref={this.mapRef}
          className="catalog-map-canvas"
          style={{ height: 840 + 'px' }}
        />
      </div>
    );
  }
}

AdCatalogGoogleMap.propTypes = {
  ads: PropTypes.array,
  intl: intlShape.isRequired,
  isUserLoggedIn: PropTypes.bool,
  isVisible: PropTypes.bool,
  options: PropTypes.object,
  onInit: PropTypes.func,
};

export default AdCatalogGoogleMap;
