import { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { defaultMessages } from '../../../../libs/i18n/default';
import classNames from 'classnames';

import CardValidator from 'card-validator';
import InputMask from 'react-input-mask';

import setRailsContext from '../../../common/utils/setRailsContext';
import TranslationProvider from '../../../common/components/HOC/TranslationProvider';
import PaymentCardErrorIcon from './PaymentCardErrorIcon';
import PaymentCardHelpIcon from './PaymentCardHelpIcon';

['american-express', 'diners-club', 'discover', 'elo', 'jcb', 'unionpay'].forEach(cardType => {
  CardValidator.creditCardType.removeCard(cardType);
});

const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
        return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS';
    }

    return 'unknown';
}

const triggerEvent = (el, type) => {
  if ('createEvent' in document) {
    // modern browsers, IE9+
    let e = document.createEvent('HTMLEvents');
    e.initEvent(type, false, true);
    el.dispatchEvent(e);
  } else {
    // IE 8
    let e = document.createEventObject();
    e.eventType = type;
    el.fireEvent('on' + e.eventType, e);
  }
}

class PaymentCard extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    isRememberSwitchActive: PropTypes.bool,
    isRememberSwitchVisible: PropTypes.bool,
    onInvalid: PropTypes.func,
    onValid: PropTypes.func,
    // redux?
    setPaymentFormState: PropTypes.func,
    submitForm: PropTypes.func
  }

  state = {
    cardType: null,
    errors: {},
    isCvcValid: false,
    isExpiryValid: false,
    isNumberValid: false
  }

  componentDidMount() {
    if (getMobileOperatingSystem() === 'Android') {
      (document.documentElement).classList.add('is-android');
    }

    this.CvcInput.input.addEventListener('touchstart', function(){
      this.focus();
    });
  }

  componentDidUpdate() {
    if (this.state.isCvcValid && this.state.isExpiryValid && this.state.isNumberValid) {
      this.props.onValid();
    } else {
      this.props.onInvalid();
    }
  }

  getErrorMessages() {
    const { formatMessage } = this.props.intl;

    return {
      number: formatMessage(defaultMessages.jsPaymentCardErrorsNumber),
      expiry: formatMessage(defaultMessages.jsPaymentCardErrorsExpiry)
    };
  }

  getHelpMessages() {
    const { formatMessage } = this.props.intl;

    return {
      cvc: formatMessage(defaultMessages.jsPaymentCardTooltipsCvc),
      remember: formatMessage(defaultMessages.jsPaymentCardTooltipsRemember)
    }
  }

  onInputBlur = ({ target }) => {
    if (target.dataset.name === 'number') {
      this.wasNumberBlurred = true;
      this.setState({
        isNumberValid: this.isValid(target.dataset.name, target.value)
      });
    } else if (target.dataset.name === 'expiry') {
      this.wasExpiryBlurred = true;
      this.setState({
        isExpiryValid: this.isValid(target.dataset.name, target.value)
      });
    } else if (target.dataset.name === 'cvc') {
      this.wasCvcBlurred = true;
      this.setState({
        isCvcValid: this.isValid(target.dataset.name, target.value)
      });
    }
  }

  onInputChange = ({ target }) => {
    if (target.dataset.name === 'number') {
      // this.setState({
      //   [target.dataset.name]: target.value
      // });
      this.props.setPaymentFormState({
        cardNumber: target.value
      })

      const card = CardValidator.number(target.value).card;

      this.setState({
        cardType: card ? card.type : null,
        isNumberValid: this.isValid(target.dataset.name, target.value)
      });
    } else if (target.dataset.name === 'expiry') {
      // this.setState({
      //   [target.dataset.name]: target.value
      // });
      this.props.setPaymentFormState({
        expDateMonthYear: target.value
      })

      const isExpiryValid = this.isValid(target.dataset.name, target.value);

      if (isExpiryValid) {
        this.CvcInput.input.focus();

        if (DeviceSupports.touch) {
          triggerEvent(this.CvcInput.input, 'touchstart');
        }
      }

      this.setState({
        isExpiryValid: isExpiryValid
      });
    } else if (target.dataset.name === 'cvc') {
      // this.setState({
      //   [target.dataset.name]: target.value
      // });
      this.props.setPaymentFormState({
        cvv: target.value
      })

      this.setState({
        isCvcValid: this.isValid(target.dataset.name, target.value)
      });
    }
  }

  onInputKeyDown = ({ which }) => {
    // Enter
    if (which === 13) {
      this.props.submitForm();
    }
  }

  toggleRememberCard = () => {
    this.props.setPaymentFormState({
      isRememberCard: !this.props.isRememberSwitchActive
    })
  }

  isValid(name, value) {
    if (name === 'number') {
      return (CardValidator.number(value)).isValid;
    } else if (name === 'expiry') {
      return (CardValidator.expirationDate(value)).isValid;
    } else if (name === 'cvc') {
      return (CardValidator.cvv(value, 3)).isValid;
    }

    return false;
  }

  render() {
    const { formatMessage } = this.props.intl;

    return (
      <div className="PaymentCard">
        <div className="PaymentCard__inner">
          <div className="PaymentCard__systems">
            <div
              className={classNames({
                'PaymentCard__systemsIcon': true,
                'PaymentCard__systemsIcon--mastercard': true,
                'PaymentCard__systemsIcon--notActive': (this.state.cardType !== 'mastercard' && this.state.cardType)
              })}
            />
            <div
              className={classNames({
                'PaymentCard__systemsIcon': true,
                'PaymentCard__systemsIcon--maestro': true,
                'PaymentCard__systemsIcon--notActive': (this.state.cardType !== 'maestro' && this.state.cardType)
              })}
            />
            <div className={classNames({
                'PaymentCard__systemsIcon': true,
                'PaymentCard__systemsIcon--visa': true,
                'PaymentCard__systemsIcon--notActive': (this.state.cardType !== 'visa' && this.state.cardType)
              })}
            />
            <div className={classNames({
                'PaymentCard__systemsIcon': true,
                'PaymentCard__systemsIcon--mir': true,
                'PaymentCard__systemsIcon--notActive': (this.state.cardType !== 'mir' && this.state.cardType)
              })}
            />
          </div>
          <div className="PaymentCard__row">
            <div className="PaymentCard__cell PaymentCard__cell--number">
              <label className="PaymentCard__label">
                <span className="PaymentCard__labelText">
                  {formatMessage(defaultMessages.jsPaymentCardNumber)}
                </span>
              </label>
              <div className={classNames({
                'PaymentCard__input': true,
                'PaymentCard__input--error': this.wasNumberBlurred && !this.state.isNumberValid,
              })}>
                <InputMask
                  className="PaymentCard__inputControl"
                  mask="9999 9999 9999 9999 999"
                  maskChar=""
                  data-name="number"
                  placeholder="0000 0000 0000 0000"
                  size="20"
                  type="tel"
                  onBlur={this.onInputBlur}
                  onFocus={this.onInputFocus}
                  onChange={this.onInputChange}
                  onKeyDown={this.onInputKeyDown}
                />
                {this.wasNumberBlurred && !this.state.isNumberValid
                  ? (
                    <PaymentCardErrorIcon
                      id='number'
                      tooltip={this.getErrorMessages().number}
                    />
                  )
                  : null
                }
              </div>
            </div>
          </div>
          <div className="PaymentCard__row">
            <div className="PaymentCard__cell PaymentCard__cell--expiry">
              <label className="PaymentCard__label">
                <span className="PaymentCard__labelText">
                  {formatMessage(defaultMessages.jsPaymentCardExpiry)}
                </span>
              </label>
              <div className={classNames({
                'PaymentCard__input': true,
                'PaymentCard__input--error': this.wasExpiryBlurred && !this.state.isExpiryValid,
              })}>
                <InputMask
                  className="PaymentCard__inputControl"
                  mask="99/99"
                  maskChar=""
                  data-name="expiry"
                  placeholder={formatMessage(defaultMessages.jsPaymentCardPlaceholdersExpiry)}
                  type="tel"
                  onBlur={this.onInputBlur}
                  onFocus={this.onInputFocus}
                  onChange={this.onInputChange}
                  onKeyDown={this.onInputKeyDown}
                />
                {this.wasExpiryBlurred && !this.state.isExpiryValid
                  ? (
                    <PaymentCardErrorIcon
                      id='expiry'
                      tooltip={this.getErrorMessages().expiry}
                    />
                  )
                  : null
                }
              </div>
            </div>
            <div className="PaymentCard__cell PaymentCard__cell--cvc">
              <label className="PaymentCard__label">
                <span className="PaymentCard__labelText">
                  {`${formatMessage(defaultMessages.jsPaymentCardCvc)} `}
                </span>
                <PaymentCardHelpIcon
                  id='cvc'
                  tooltip={this.getHelpMessages().cvc}
                />
              </label>
              <div className={classNames({
                'PaymentCard__input': true,
                'PaymentCard__input--error': this.wasCvcBlurred && !this.state.isCvcValid
              })}>
                <InputMask
                  ref={input => this.CvcInput = input}
                  className="PaymentCard__inputControl"
                  mask="999"
                  maskChar=""
                  data-name="cvc"
                  placeholder="000"
                  type="tel"
                  onBlur={this.onInputBlur}
                  onFocus={this.onInputFocus}
                  onChange={this.onInputChange}
                  onKeyDown={this.onInputKeyDown}
                />
              </div>
            </div>
            {this.props.isRememberSwitchVisible &&
              <div className="PaymentCard__cell PaymentCard__cell--remember">
                <label className="PaymentCard__label">
                  <span
                    className="PaymentCard__labelText"
                    dangerouslySetInnerHTML={{
                      __html: `${formatMessage(defaultMessages.jsPaymentCardRememberCard)} `
                    }}
                  />
                  <PaymentCardHelpIcon
                    id='remember'
                    tooltip={this.getHelpMessages().remember}
                  />
                </label>
                <label className="PaymentCard__switch">
                  <input
                    className="PaymentCard__switchControl"
                    tabIndex="-1"
                    type="checkbox"
                    defaultChecked={this.props.isRememberSwitchActive}
                    onChange={this.toggleRememberCard}
                  />
                  <span className="PaymentCard__switchButton" />
                </label>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
};

export default setRailsContext(TranslationProvider(PaymentCard));
