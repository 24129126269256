import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import { STATUS_TYPES } from './constants';

const STATUSES = [
  STATUS_TYPES.INITIAL,
  STATUS_TYPES.DECLINED,
  STATUS_TYPES.ARCHIVED,
];

class AdFormActions extends React.PureComponent {
  buttonText() {
    const {
      intl: { formatMessage },
      status,
    } = this.props;

    if (STATUSES.includes(status)) {
      return formatMessage(defaultMessages.jsAdFormActionsPend);
    } else {
      return formatMessage(defaultMessages.jsAdFormActionsSave);
    }
  }

  renderEditingNote() {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <div className="row form-row-pt30">
        <div className="col-xs-12 col-sm-6 col-sm-offset-3">
          <p className="text-muted">
            {formatMessage(defaultMessages.jsAdFormEditingNote)}
          </p>
        </div>
      </div>
    );
  }

  render() {
    const { status, submitForm, disabledSubmit } = this.props;

    return (
      <div className="form-row form-row-actions text-center">
        <button
          className="button button-green button-xlarge"
          name="button"
          type="submit"
          onClick={submitForm}
          disabled={disabledSubmit}
        >
          {this.buttonText()}
        </button>
        {status === STATUS_TYPES.PUBLISHED && this.renderEditingNote()}
      </div>
    );
  }
}

AdFormActions.propTypes = {
  disabledSubmit: PropTypes.bool,
  intl: intlShape.isRequired,
  status: PropTypes.string,
  submitForm: PropTypes.func,
};

export default AdFormActions;
