import SimpleBar from 'simplebar';

import { defaultMessages } from '../../../../../libs/i18n/default';
import isDOMElement from '../utils/isDOMElement';

class YmapPlacemarkWrapper {
  constructor({
    mapInstance,
    ymaps,
    intl,
    renderOfferCardToContainer,
    destroyOfferCardFromContainer,
  }) {
    this.map = mapInstance;
    this.ymaps = ymaps;
    this.intl = intl;
    this.renderOfferCardToContainer = renderOfferCardToContainer; // TODO: сделать так чтобы не пришлось тащить это свойство
    this.destroyOfferCardFromContainer = destroyOfferCardFromContainer; // TODO: сделать так чтобы не пришлось тащить это свойство
  }

  getMarkerPopupLayout() {
    const { map, ymaps } = this;

    return ymaps.templateLayoutFactory.createClass(
      '<div class="map-popup map-popup-marker">' +
        '<div class="map-popup-close">' +
        '<div class="map-popup-close-icon"></div>' +
        '</div>' +
        '<div class="map-popup-arrow"></div>' +
        '$[[options.contentLayout]]' +
        '</div>',
      {
        build: function() {
          this.constructor.superclass.build.call(this);
          this.popupElement = this.getParentElement().querySelector(
            '.map-popup',
          );
          this.popupElement.classList.add('map-popup-' + this.getPlacement());
          this.applyElementOffset();

          this.popupElement
            .querySelector('.map-popup-close')
            .addEventListener('click', this.handleCloseClick.bind(this));
          window.addEventListener('resize', this.applyElementOffset.bind(this));
        },
        clear: function() {
          this.popupElement
            .querySelector('.map-popup-close')
            .removeEventListener('click', this.handleCloseClick.bind(this));
          window.removeEventListener(
            'resize',
            this.applyElementOffset.bind(this),
          );
          this.constructor.superclass.clear.call(this);
        },
        onSublayoutSizeChange: function() {
          this.constructor.superclass.onSublayoutSizeChange.apply(
            this,
            arguments,
          );

          if (!isDOMElement(this.popupElement)) {
            return;
          }

          this.applyElementOffset();
          this.events.fire('shapechange');
        },
        handleCloseClick: function(e) {
          e.preventDefault();
          this.events.fire('userclose');
        },
        applyElementOffset: function() {
          this.popupElement.style.left = this.getElementLeftOffset() + 'px';
          this.popupElement.style.top = this.getElementTopOffset() + 'px';
        },
        getShape: function() {
          if (!isDOMElement(this.popupElement)) {
            return this.constructor.superclass.getShape.call(this);
          }

          const left = this.popupElement.offsetLeft;
          const top = this.popupElement.offsetTop;

          return new ymaps.shape.Rectangle(
            new ymaps.geometry.pixel.Rectangle([
              [left, top],
              [
                left + this.popupElement.offsetWidth,
                top +
                  this.popupElement.offsetHeight +
                  this.popupElement.querySelector('.map-popup-arrow')
                    .offsetHeight,
              ],
            ]),
          );
        },
        getPlacement: function() {
          const projection = map.options.get('projection');
          const zoom = map.getZoom();
          const [mapWidth, mapHeight] = map.container.getSize();
          const [mapCenterX, mapCenterY] = map.converter.globalToPage(
            projection.toGlobalPixels(map.getCenter(), zoom),
          );
          const mapBounds = [
            [
              (mapCenterX - mapWidth > 0 ? mapCenterX - mapWidth : 0) / 2,
              mapCenterY - mapHeight / 2,
            ],
            [mapCenterX + mapWidth / 2, mapCenterY + mapHeight / 2],
          ];
          const pointPosition = map.converter.globalToPage(
            projection.toGlobalPixels(
              this._data.object.geometry.coordinates,
              zoom,
            ),
          );

          if (
            pointPosition[1] - mapBounds[0][1] >=
            mapBounds[1][1] - pointPosition[1]
          ) {
            return 'top';
          }

          return 'bottom';
        },
        getElementTopOffset: function() {
          const popupArrowElement = this.popupElement.querySelector(
            '.map-popup-arrow',
          );
          switch (this.getPlacement()) {
            case 'bottom':
              return popupArrowElement.offsetHeight;
            default:
              return -(
                this.popupElement.offsetHeight + popupArrowElement.offsetHeight
              );
          }
        },
        getElementLeftOffset: function() {
          return -(this.popupElement.offsetWidth / 2);
        },
      },
    );
  }

  getMarkerPopupContentLayout() {
    const {
      ymaps,
      renderOfferCardToContainer,
      destroyOfferCardFromContainer,
    } = this;

    return ymaps.templateLayoutFactory.createClass(
      '<div class="map-popup-content">' +
        '{% if properties.isLoaded %}' +
        '<div class="OfferCardContainer"></div>' +
        '{% else %}' +
        '<div class="map-popup-loader">' +
        '<span class="spinner"></span>' +
        '</div>' +
        '{% endif %}' +
        '</div>',
      {
        build: function() {
          this.constructor.superclass.build.call(this);
          this.contentElement = this.getParentElement().querySelector(
            '.map-popup-content',
          );
          this.offerCardContainer = this.contentElement.querySelector(
            '.OfferCardContainer',
          );

          if (this.getData().properties.get('isLoaded')) {
            renderOfferCardToContainer({
              id: this.getData().object.id,
              container: this.offerCardContainer,
              props: { isVertical: true },
            });
          }
        },
        clear: function() {
          destroyOfferCardFromContainer(this.offerCardContainer);
          this.contentElement = null;
          this.offerCardContainer = null;
          this.constructor.superclass.clear.call(this);
        },
      },
    );
  }

  getClusterPopupLayout() {
    const {
      map,
      ymaps,
      intl: { formatMessage },
    } = this;

    return ymaps.templateLayoutFactory.createClass(
      '<div class="map-popup map-popup-cluster">' +
        '<div class="map-popup-close">' +
        '<div class="map-popup-close-icon"></div>' +
        '</div>' +
        '<div class="map-popup-arrow"></div>' +
        '{% if properties.isLoaded %}' +
        '<div class="map-popup-header">' +
        '$[properties.numberLoaded] ' +
        formatMessage(defaultMessages.jsCatalogSortingAds) +
        '</div>' +
        '{% endif %}' +
        '$[[options.contentLayout]]' +
        '</div>',
      {
        build: function() {
          this.constructor.superclass.build.call(this);
          this.popupElement = this.getParentElement().querySelector(
            '.map-popup',
          );
          this.popupElement.classList.add('map-popup-' + this.getPlacement());
          this.applyElementOffset();
          this.popupElement
            .querySelector('.map-popup-close')
            .addEventListener('click', this.handleCloseClick.bind(this));
          window.addEventListener('resize', this.applyElementOffset.bind(this));
        },
        clear: function() {
          this.popupElement
            .querySelector('.map-popup-close')
            .removeEventListener('click', this.handleCloseClick.bind(this));
          window.removeEventListener(
            'resize',
            this.applyElementOffset.bind(this),
          );
          this.constructor.superclass.clear.call(this);
        },
        onSublayoutSizeChange: function() {
          this.constructor.superclass.onSublayoutSizeChange.apply(
            this,
            arguments,
          );

          if (!isDOMElement(this.popupElement)) {
            return;
          }

          this.applyElementOffset();
          this.events.fire('shapechange');
        },
        applyElementOffset: function() {
          this.popupElement.style.left = this.getElementLeftOffset() + 'px';
          this.popupElement.style.top = this.getElementTopOffset() + 'px';
        },
        handleCloseClick: function(e) {
          e.preventDefault();
          this.events.fire('userclose');
        },
        getShape: function() {
          if (!isDOMElement(this.popupElement)) {
            return this.constructor.superclass.getShape.call(this);
          }

          const left = this.popupElement.offsetLeft;
          const top = this.popupElement.offsetTop;

          return new ymaps.shape.Rectangle(
            new ymaps.geometry.pixel.Rectangle([
              [left, top],
              [
                left + this.popupElement.offsetWidth,
                top +
                  this.popupElement.offsetHeight +
                  this.popupElement.querySelector('.map-popup-arrow')
                    .offsetHeight,
              ],
            ]),
          );
        },
        getPlacement: function() {
          const projection = map.options.get('projection');
          const zoom = map.getZoom();
          const [mapWidth, mapHeight] = map.container.getSize();
          const [mapCenterX, mapCenterY] = map.converter.globalToPage(
            projection.toGlobalPixels(map.getCenter(), zoom),
          );
          const mapBounds = [
            [
              (mapCenterX - mapWidth > 0 ? mapCenterX - mapWidth : 0) / 2,
              mapCenterY - mapHeight / 2,
            ],
            [mapCenterX + mapWidth / 2, mapCenterY + mapHeight / 2],
          ];
          const pointPosition = map.converter.globalToPage(
            projection.toGlobalPixels(
              this._data.object.geometry.coordinates,
              zoom,
            ),
          );

          if (
            pointPosition[1] - mapBounds[0][1] >=
            mapBounds[1][1] - pointPosition[1]
          ) {
            return 'top';
          }

          return 'bottom';
        },
        getElementTopOffset: function() {
          const popupArrowElement = this.popupElement.querySelector(
            '.map-popup-arrow',
          );
          switch (this.getPlacement()) {
            case 'bottom':
              return popupArrowElement.offsetHeight;
            default:
              return -(
                this.popupElement.offsetHeight + popupArrowElement.offsetHeight
              );
          }
        },
        getElementLeftOffset: function() {
          return -(this.popupElement.offsetWidth / 2);
        },
      },
    );
  }

  getClusterPopupContentLayout() {
    const {
      ymaps,
      renderOfferCardToContainer,
      destroyOfferCardFromContainer,
    } = this;

    return ymaps.templateLayoutFactory.createClass(
      '<div class="map-popup-content">' +
        '{% if properties.isLoaded %}' +
        '{% for loadedId in properties.loadedIds %}' +
        '<div class="OfferCardContainer"></div>' +
        '{% endfor %}' +
        '{% else %}' +
        '<div class="map-popup-loader">' +
        '<span class="spinner"></span>' +
        '</div>' +
        '{% endif %}' +
        '</div>',
      {
        build: function() {
          this.constructor.superclass.build.call(this);
          this.contentElement = this.getParentElement().querySelector(
            '.map-popup-content',
          );
          this.offerCardContainers = this.contentElement.querySelectorAll(
            '.OfferCardContainer',
          );

          this.loadedIds = this.getData().properties.get('loadedIds');

          if (this.loadedIds) {
            this.loadedIds.forEach((id, index, array) => {
              renderOfferCardToContainer({
                id,
                container: this.offerCardContainers[index],
                props: { isHorizontal: true },
              });

              if (index + 1 === array.length) {
                this.initCustomScrollbar();
              }
            });
          }

          this.scrollBar = null;
        },
        clear: function() {
          if (this.loadedIds) {
            this.loadedIds.forEach((id, index, array) => {
              destroyOfferCardFromContainer(this.offerCardContainers[index]);
            });
          }

          this.constructor.superclass.clear.call(this);
        },
        initCustomScrollbar: function() {
          if (!DeviceSupports.touch) {
            this.scrollBar = new SimpleBar(this.contentElement);
          }
        },
      },
    );
  }

  getMarkerTemplate = () => {
    const { ymaps } = this;

    return ymaps.templateLayoutFactory.createClass(
      '<div class="' +
        'ymaps-circle-placemark ymaps-circle-placemark-big' +
        '{% if properties.viewed %} __viewed{% endif %}' +
        '{% if (properties.moderated == true) %} __verified{% endif %}' +
        '{% if (properties.moderated == false) %} __unverified{% endif %}' +
        '">' +
        '<div class="ymaps-circle-placemark-icon">{{ properties.rooms }}</div>' +
        '<div class="ymaps-circle-placemark-price">{{ properties.price }} ' +
        '{% if properties.priceCurrencyCode == "usd" %}' +
        '$' +
        '{% else %}' +
        '<i class="currency currency-{{ properties.priceCurrencyCode }}"></i>' +
        '{% endif %}' +
        '</div>' +
        '</div>',
    );
  };
}

export default YmapPlacemarkWrapper;
