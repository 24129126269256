import { injectIntl, intlShape } from 'react-intl';
import React from 'react';

import { defaultMessages } from '../../../../libs/i18n/default';
import { LANDING_SEARCH_ASSISTANT_PATH } from '../../constants/paths';
import AdCatalogBanner from './AdCatalogBanner';

const AdCatalogSearchAssistantBanner = ({ intl: { formatMessage } }) => {
  return (
    <AdCatalogBanner
      type="search"
      imageSrc={require('../../../../images/client/AdCatalog/banner-search.svg')}
      imageWidth={220}
      imageHeight={124}
      title={formatMessage(defaultMessages.jsCatalogSearchAssistantCardTitle)}
      notice={formatMessage(defaultMessages.jsCatalogSearchAssistantCardText)}
      submitText={formatMessage(
        defaultMessages.jsCatalogSearchAssistantCardRequest,
      )}
      href={LANDING_SEARCH_ASSISTANT_PATH}
    />
  );
};

AdCatalogSearchAssistantBanner.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(AdCatalogSearchAssistantBanner);
