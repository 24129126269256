import axios from './axios';

const adModerationRequest = ({ id, comments }) => {
  return axios
    .post('/api/frontend/services/ad_moderation_requests', {
      ad_moderation_request: {
        ad_id: id,
        question: comments,
      },
    })
    .then(({ data }) => data)
    .catch(({ response: { data: { errors } } }) => Promise.reject(errors));
};

export const servicesApi = {
  adModerationRequest,
};

export default servicesApi;
