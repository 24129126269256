import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';

import * as paths from '../../constants/paths';
import { defaultMessages } from '../../../../libs/i18n/default';
import { registerByEmail } from '../../api/auth';
import AuthModalSocials from './AuthModalSocials';
import AuthModalInput from './AuthModalInput';
import AuthModalTabs from './AuthModalTabs';
import FlashNotifierService from '../../services/FlashNotifier/FlashNotifier';

class AuthModalRegisterByEmail extends React.Component {
  constructor(props) {
    super(props);

    const { email } = this.props;

    this.state = {
      email,
      canSubmit: true,
      isRegistered: false,
    };

    this.setValidationRules();
  }

  getEmailValue() {
    const { email } = this.props;

    return this.registerForm ? this.registerForm.getModel().user.email : email;
  }

  setValidationRules() {
    const {
      intl: { formatMessage },
    } = this.props;

    this.validations = {
      email: {
        isEmail: true,
      },
    };

    this.validationErrors = {
      email: {
        isDefaultRequiredValue: formatMessage(
          defaultMessages.jsAuthModalValidationFieldRequired,
        ),
        isEmail: formatMessage(
          defaultMessages.jsAuthModalValidationFieldWrongFormat,
        ),
      },
      password: {
        isDefaultRequiredValue: formatMessage(
          defaultMessages.jsAuthModalValidationFieldRequired,
        ),
      },
    };
  }

  handleLoginByEmailLinkClick = e => {
    const { onChangeForm } = this.props;

    e.preventDefault();

    onChangeForm('loginByEmail', this.getEmailValue());
  };

  handleTabsClick = tab => {
    const { onChangeForm } = this.props;

    switch (tab) {
      case 'email':
        onChangeForm('registerByEmail');
        break;
      case 'phone':
        onChangeForm('registerByPhone');
    }
  };

  handleValid = () => {
    this.enableSubmit();
  };

  handleInvalid = () => {
    this.disableSubmit();
  };

  handleSubmit = (model, reset, invalidate) => {
    const { canSubmit } = this.state;
    const {
      intl: { formatMessage },
      toggleLoading,
      redirectPath,
    } = this.props;

    // Во втором случае проверяем валидны ли поля без учёта внешних ошибок (в ответе ajax)
    // TODO: Написать чувакам из formsy, чтобы добавили флаг ignoreExternalErrorsDuringValidation
    if (
      canSubmit ||
      (this.isEmailValidOmittingExternalErrors() &&
        this.isPasswordValidOmittingExternalErrors())
    ) {
      toggleLoading();

      registerByEmail(model)
        .then(data => {
          const redirectLocation =
            redirectPath || data.redirect_location || window.location.href;

          toggleLoading();
          // Таким способом чистим внешнюю ошибку
          this.registerFormEmail.setValue(this.getEmailValue());

          if (data.type === 'registration') {
            this.setState({ isRegistered: true });

            // setTimeout чтобы успеть прочитать сообщение об успешной регистрации
            setTimeout(() => {
              window.location.href = redirectLocation;
            }, 1000);
          }

          if (data.type === 'session') {
            if (window.location.href === redirectLocation) {
              window.location.reload(true);
            } else {
              window.location.href = redirectLocation;
            }
          }
        })
        .catch(errors => {
          toggleLoading();

          if (errors && errors.email) {
            invalidate({ 'user[email]': errors.email });
          } else {
            FlashNotifierService.notifyError(
              formatMessage(
                defaultMessages.jsFlashNotifierErrorHasOccurredCheckConnection,
              ),
            );
          }

          this.disableSubmit();
        });
    }
  };

  disableSubmit = () => {
    this.setState({ canSubmit: false });
  };

  enableSubmit = () => {
    this.setState({ canSubmit: true });
  };

  isEmailValidOmittingExternalErrors() {
    return !this.registerFormEmail.state.validationError.length;
  }

  isPasswordValidOmittingExternalErrors() {
    return !this.registerFormPassword.state.validationError.length;
  }

  render() {
    const { isRegistered, email } = this.state;
    const { intl } = this.props;
    const { formatMessage } = intl;

    if (!isRegistered) {
      return (
        <Formsy
          ref={registerForm => (this.registerForm = registerForm)}
          className="modal-form"
          acceptCharset="UTF-8"
          method="post"
          onValid={this.handleValid}
          onInvalid={this.handleInvalid}
          onSubmit={this.handleSubmit}
        >
          <div className="modal-form-header">
            <div className="modal-form-title">
              {formatMessage(defaultMessages.jsAuthModalRegisterTitle)}
            </div>
            <div className="modal-form-text">
              <span>
                {formatMessage(defaultMessages.jsAuthModalRegisterTitleText)}{' '}
              </span>
              <a
                href="#"
                className="link link-green"
                onClick={this.handleLoginByEmailLinkClick}
              >
                {formatMessage(defaultMessages.jsAuthModalRegisterTitleLink)}
              </a>
            </div>
          </div>
          <div className="modal-form-content">
            <div className="modal-form-item">
              <AuthModalTabs
                intl={intl}
                active="email"
                onTabClick={this.handleTabsClick}
              />
            </div>
            <div className="modal-form-item">
              {/* {this.renderRegisterByPhoneLink()} */}
              <AuthModalInput
                ref={registerFormEmail =>
                  (this.registerFormEmail = registerFormEmail)
                }
                name="user[email]"
                intl={intl}
                placeholder={formatMessage(
                  defaultMessages.jsAuthModalEmailPlaceholder,
                )}
                required
                type="email"
                validations={this.validations.email}
                validationErrors={this.validationErrors.email}
                value={email}
              />
            </div>
            <div className="modal-form-item">
              <AuthModalInput
                ref={registerFormPassword =>
                  (this.registerFormPassword = registerFormPassword)
                }
                name="user[password]"
                intl={intl}
                placeholder={formatMessage(
                  defaultMessages.jsAuthModalPasswordPlaceholder,
                )}
                required
                type="password"
                validationErrors={this.validationErrors.password}
                value=""
              />
            </div>
            <div className="modal-form-item modal-form-item-actions">
              <div className="modal-form-item-cell">
                <button
                  className="button button-green modal-form-submit"
                  type="submit"
                  formNoValidate
                >
                  {formatMessage(defaultMessages.jsAuthModalSignUpButton)}
                </button>
              </div>
              <div className="modal-form-item-cell">
                <div className="modal-form-text modal-form-text-terms">
                  <span>
                    {formatMessage(defaultMessages.jsAuthModalAcceptTermsOfUse)}{' '}
                  </span>
                  <a
                    href={paths.PAGE_AGREEMENT_PATH}
                    className="link"
                    title={formatMessage(
                      defaultMessages.jsAuthModalAcceptTermsOfUseLink,
                    )}
                  >
                    <b>
                      {formatMessage(
                        defaultMessages.jsAuthModalAcceptTermsOfUseLink,
                      )}
                    </b>
                  </a>
                  <span>
                    {' '}
                    {formatMessage(
                      defaultMessages.jsAuthModalAcceptTermsOfUseAndAgree,
                    )}{' '}
                  </span>
                  <a
                    href={paths.PAGE_PERSONAL_DATA_PATH}
                    className="link"
                    title={formatMessage(
                      defaultMessages.jsAuthModalAcceptTermsOfUsePersonalDataLink,
                    )}
                  >
                    <b>
                      {formatMessage(
                        defaultMessages.jsAuthModalAcceptTermsOfUsePersonalDataLink,
                      )}
                    </b>
                  </a>
                </div>
              </div>
            </div>
            {/*<AuthModalSocials intl={intl} type="register" />*/}
          </div>
        </Formsy>
      );
    } else {
      return (
        <div className="modal-message modal-message-success">
          <div
            className="modal-title"
            dangerouslySetInnerHTML={{
              __html: formatMessage(
                defaultMessages.jsAuthModalRegisterSuccessTitle,
              ),
            }}
          />
          <div
            className="modal-text"
            dangerouslySetInnerHTML={{
              __html: formatMessage(
                defaultMessages.jsAuthModalRegisterSuccessText,
              ),
            }}
          />
        </div>
      );
    }
  }
}

AuthModalRegisterByEmail.propTypes = {
  email: PropTypes.string,
  intl: intlShape.isRequired,
  redirectPath: PropTypes.string,
  toggleLoading: PropTypes.func.isRequired,
  onChangeForm: PropTypes.func.isRequired,
};

export default AuthModalRegisterByEmail;
