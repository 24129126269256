import React from 'react';
import PropTypes from 'prop-types';
import InputElement from 'react-input-mask';

import AdFormTextareaHOC from './AdFormInputMixin';

const AdFormInputPhone = React.memo(function({
  id = null,
  iconComponent = null,
  mask,
  onChange,
  value,
  wrapperClass,
}) {
  return (
    <div className={wrapperClass('input')}>
      {iconComponent}
      <InputElement
        id={id}
        className="input-control"
        mask={mask}
        onChange={onChange}
        defaultValue={value}
      />
    </div>
  );
});

AdFormInputPhone.propTypes = {
  iconComponent: PropTypes.node,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mask: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  wrapperClass: PropTypes.func,
  onChange: PropTypes.func,
};

export default AdFormTextareaHOC(AdFormInputPhone);
