import { intlShape } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { FILTER_KEYS } from './AdCatalog.constants';
import { defaultMessages } from '../../../../libs/i18n/default';
import SvgIcon from '../SvgIcon/SvgIcon';

class AdCatalogFilterOpenButton extends Component {
  // TODO: Возможно стоит сделать нормальный подсчёт активных фильтров внутри компонентов фильтров
  // и записывать состояние в ActiveFiltersNumber или удалять пустые поля из this.state.filter
  renderNumber() {
    const { filter, priceMinLimit, priceMaxLimit } = this.props;

    let number = 0;
    let priceFilter = false;
    let spaceFilter = false;
    let floorFilter = false;

    for (let key in filter) {
      switch (key) {
        case FILTER_KEYS.ROOM_QUANTITY:
        case FILTER_KEYS.SUBWAYS:
        case FILTER_KEYS.DISTRICTS:
        case FILTER_KEYS.AMENITIES:
          if (filter[key].length) number++;
          break;
        case FILTER_KEYS.PRICE_MIN:
          if (filter[key] !== priceMinLimit) priceFilter = true;
          break;
        case FILTER_KEYS.PRICE_MAX:
          if (filter[key] !== priceMaxLimit) priceFilter = true;
          break;
        case FILTER_KEYS.SPACE_MIN:
        case FILTER_KEYS.SPACE_MAX:
          if (!isNaN(parseFloat(filter[key]))) spaceFilter = true;
          break;
        case FILTER_KEYS.FLOOR_MIN:
        case FILTER_KEYS.FLOOR_MAX:
          if (!isNaN(parseFloat(filter[key]))) floorFilter = true;
          break;
        default:
          number++;
          break;
      }
    }

    if (priceFilter) number++;
    if (spaceFilter) number++;
    if (floorFilter) number++;

    if (number > 0) {
      return <span className="filter-activator-button-number">{number}</span>;
    }

    return null;
  }

  render() {
    const {
      isFilterOpen,
      onClick,
      intl: { formatMessage },
    } = this.props;

    return (
      <button
        className={classNames(
          'filter-activator-button',
          'button',
          'button-green',
          {
            __active: isFilterOpen,
          },
        )}
        type="button"
        onClick={onClick}
      >
        <SvgIcon icon="filter" />
        <span className="filter-activator-button-text">
          {formatMessage(defaultMessages.jsCatalogFilterTitle)}
        </span>
        {this.renderNumber()}
      </button>
    );
  }
}

AdCatalogFilterOpenButton.propTypes = {
  filter: PropTypes.object,
  intl: intlShape.isRequired,
  isFilterOpen: PropTypes.bool,
  priceMaxLimit: PropTypes.number,
  priceMinLimit: PropTypes.number,
  onClick: PropTypes.func,
};

export default AdCatalogFilterOpenButton;
