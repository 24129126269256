/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { defaultMessages } from '../../../../libs/i18n/default';
import { VIEW_MODES } from './AdCatalog.constants';
import SvgIcon from '../SvgIcon/SvgIcon';

const TAB_ICON_BY_VIEW = {
  [VIEW_MODES.LIST]: 'photos',
  [VIEW_MODES.MAP]: 'map',
};

class AdCatalogFilterViewTabs extends React.PureComponent {
  onTabClick = view => {
    const { onChange } = this.props;

    onChange(view);
  };

  getTabLabel(view) {
    const {
      intl: { formatMessage },
    } = this.props;

    switch (view) {
      case VIEW_MODES.LIST:
        return formatMessage(defaultMessages.jsCatalogViewList);
      case VIEW_MODES.MAP:
        return formatMessage(defaultMessages.jsCatalogViewMap);
      default:
        return '';
    }
  }

  renderTab(tabView) {
    const { view } = this.props;

    return (
      <div
        className={classNames('filter-tab', `filter-tab-${tabView}`, {
          __active: view === tabView,
        })}
        onClick={this.onTabClick.bind(this, tabView)}
      >
        <SvgIcon icon={TAB_ICON_BY_VIEW[tabView]} />
        <span>{this.getTabLabel(tabView)}</span>
      </div>
    );
  }

  render() {
    return (
      <div className={classNames('filter-tabs')}>
        {this.renderTab(VIEW_MODES.LIST)}
        {this.renderTab(VIEW_MODES.MAP)}
      </div>
    );
  }
}

AdCatalogFilterViewTabs.propTypes = {
  intl: intlShape.isRequired,
  view: PropTypes.string,
  onChange: PropTypes.func,
};

export default AdCatalogFilterViewTabs;
