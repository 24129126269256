import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import { FIELD_NAMES } from './constants';
import AdFormSelect from './AdFormSelect';

class AdFormCity extends React.PureComponent {
  handleChange = event => {
    const { setAdState } = this.props;

    setAdState({ [FIELD_NAMES.CITY_ID]: Number(event.currentTarget.value) });
  };

  render() {
    const {
      intl: { formatMessage },
      cityId,
      cities,
    } = this.props;

    return (
      <div className="form-row">
        <div className="form-row-label">
          {formatMessage(defaultMessages.jsAdFormCityLabel)}
        </div>
        <div className="form-row-content">
          <AdFormSelect
            value={cityId}
            options={cities}
            onChange={this.handleChange}
          />
        </div>
      </div>
    );
  }
}

AdFormCity.propTypes = {
  cities: PropTypes.array,
  cityId: PropTypes.number,
  intl: intlShape.isRequired,
  setAdState: PropTypes.func,
};

export default AdFormCity;
