/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import { searchAssistanceApi } from '../../api/searchAssistance';
import TranslationProvider from '../../../common/components/HOC/TranslationProvider';
import FlashNotifierService from '../../services/FlashNotifier/FlashNotifier';

class SearchCabinetOfferCardFavoriteControl extends React.Component {
  constructor(props) {
    super(props);

    const { isFavorite } = this.props;

    this.state = {
      isFavorite,
      isLoading: false,
    };
  }

  handleClick = () => {
    const { isLoading, isFavorite } = this.state;

    if (isLoading) {
      return;
    }

    if (!isFavorite) {
      this.favorite();
    } else {
      this.unfavorite();
    }
  };

  favorite() {
    const {
      intl: { formatMessage },
      offerId,
    } = this.props;

    this.setState({ isLoading: true });

    searchAssistanceApi
      .fovoriteAdVariant(offerId)
      .then(response => {
        this.setState({
          isFavorite: true,
          isLoading: false,
        });

        // TODO: Текст + перевод
        FlashNotifierService.notifySuccess('Добавлено в избранное');
      })
      .catch(() => {
        this.setState({ isLoading: false });

        FlashNotifierService.notifyError(
          formatMessage(
            defaultMessages.jsFlashNotifierErrorHasOccurredTryAgain,
          ),
        );
      });
  }

  unfavorite() {
    const {
      intl: { formatMessage },
      offerId,
    } = this.props;

    this.setState({ isLoading: true });

    searchAssistanceApi
      .unfovoriteAdVariant(offerId)
      .then(response => {
        this.setState({
          isFavorite: false,
          isLoading: false,
        });

        // TODO: Текст + перевод
        FlashNotifierService.notifySuccess('Удалили из избранного');
      })
      .catch(() => {
        this.setState({ isLoading: false });

        FlashNotifierService.notifyError(
          formatMessage(
            defaultMessages.jsFlashNotifierErrorHasOccurredTryAgain,
          ),
        );
      });
  }

  render() {
    const { isLoading, isFavorite } = this.state;

    return (
      <div
        className={classNames('SearchCabinet__offerCardFavorite', {
          'SearchCabinet__offerCardFavorite--active': isFavorite,
          'SearchCabinet__offerCardFavorite--loading': isLoading,
        })}
        onClick={this.handleClick}
      >
        <div className="SearchCabinet__offerCardFavoriteIcon" />
        {isLoading && <span className="spinner" />}
      </div>
    );
  }
}

SearchCabinetOfferCardFavoriteControl.propTypes = {
  intl: intlShape.isRequired,
  isFavorite: PropTypes.bool.isRequired,
  offerId: PropTypes.number.isRequired,
};

export default TranslationProvider(SearchCabinetOfferCardFavoriteControl);
