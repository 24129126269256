/* eslint-disable jsx-a11y/alt-text */
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'react-tooltip-lite';

import { defaultMessages } from '../../../../libs/i18n/default';
import { profileApi } from '../../api/profile';
import { USER_AVATAR_DEFAULT_PATH } from '../../constants/paths';
import FlashNotifierService from '../../services/FlashNotifier/FlashNotifier';

const CLASS_LOADING = '__loading';
const CLASS_HOVER = '__hover';

class UserSettingsModalAvatar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDragEnter: false,
      isLoading: false,
    };

    this.preloadCount = 0;
  }

  componentDidMount() {
    this.loadImage();
  }

  componentDidUpdate(prevProps, prevState) {
    const { src } = this.props;

    if (prevProps.src !== src) {
      this.loadImage();
    }
  }

  componentWillUnmount() {
    if (this.requestCancel) {
      this.requestCancel();
      this.requestCancel = null;
    }
  }

  handleDrop = (acceptedFiles, rejectedFiles) => {
    const { isLoading } = this.state;

    this.setState({ isDragEnter: false });

    if (!DeviceSupports.formData) {
      // TODO: i18n
      FlashNotifierService.notifyError(
        'Вы используете устаревший браузер, который мы не поддерживаем!',
      );
      return;
    }

    if (isLoading) {
      // TODO: i18n
      FlashNotifierService.notifyError(
        'Не торопитесь! Мы всё ещё сохраняем ваши штучки-дрючки.',
      );
      return;
    }

    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];

      let formData = new FormData();
      formData.append('avatar', file);

      this.uploadImage(formData);
    }
  };

  handleDragEnter = () => {
    this.setState({ isDragEnter: true });
  };

  handleDragLeave = () => {
    this.setState({ isDragEnter: false });
  };

  handleDropRejected = files => {
    // TODO: i18n
    FlashNotifierService.notifyError(
      'Загружать можно только 1 файл формата jpg или png.',
    );
  };

  loadImage() {
    const { src } = this.props;

    const img = new Image();

    this.setState({ isLoading: true });
    this.preloadCount++;

    img.onload = img.onerror = () => {
      if (this.preloadCount < 2) {
        this.setState({ isLoading: false });
      }

      this.preloadCount--;
    };

    img.src = src;
  }

  uploadImage(formData) {
    const {
      intl: { formatMessage },
      onUpdate,
    } = this.props;

    this.setState({ isLoading: true });

    const [request, cancel] = profileApi.uploadProfileAvatar(formData, {
      withCancel: true,
    });

    request
      .then(data => {
        this.setState({ isLoading: false });

        onUpdate(data.avatar);
      })
      .catch(error => {
        this.setState({ isLoading: false });

        console.error(error);

        FlashNotifierService.notifyError(
          formatMessage(
            defaultMessages.jsFlashNotifierErrorHasOccurredTryAgain,
          ),
        );
      });

    this.requestCancel = cancel;
  }

  render() {
    const { isLoading, isDragEnter } = this.state;
    const {
      intl: { formatMessage },
      src,
    } = this.props;

    return (
      <Tooltip
        className="uploadzone-thumb-tooltip"
        arrow
        arrowSize={7}
        direction="down"
        distance={12}
        tipContentHover={false}
        content={formatMessage(defaultMessages.jsSettingsModalChangeAvatar)}
      >
        <Dropzone
          className={classNames('settings-avatar', {
            [CLASS_LOADING]: isLoading,
            [CLASS_HOVER]: isDragEnter,
          })}
          ref={avatar => (this.avatar = avatar)}
          style={null}
          name="settings-avatar"
          accept="image/jpg,image/png,image/jpeg"
          multiple={false}
          disableClick={isLoading}
          onDrop={this.handleDrop}
          onDragEnter={this.handleDragEnter}
          onDragLeave={this.handleDragLeave}
          onDropRejected={this.handleDropRejected}
        >
          <img className="settings-avatar-img" src={src} />
          <div className="settings-avatar-overlay" />
          <div className="settings-avatar-upload">
            <i className="settings-avatar-upload-icon" />
          </div>
          <div className="settings-avatar-loader">
            <span className="spinner" />
          </div>
        </Dropzone>
      </Tooltip>
    );
  }
}

UserSettingsModalAvatar.propTypes = {
  intl: intlShape.isRequired,
  src: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
};

UserSettingsModalAvatar.defaultProps = {
  src: USER_AVATAR_DEFAULT_PATH,
};

export default UserSettingsModalAvatar;
