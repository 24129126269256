import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import { favoriteAd, unfavoriteAd } from '../../api/rooms';
import FlashNotifierService from '../../services/FlashNotifier/FlashNotifier';

class OfferFavorite extends React.PureComponent {
  constructor(props) {
    super(props);

    const { isInitialFavorited } = this.props;

    this.state = {
      isFavorited: isInitialFavorited,
      isLoading: false,
    };
  }

  componentWillUnmount() {
    if (this.favoriteRequestCancel) {
      this.favoriteRequestCancel();
    }

    if (this.unfavoriteRequestCancel) {
      this.unfavoriteRequestCancel();
    }
  }

  onFavoriteClick = e => {
    const { isLoading, isFavorited } = this.state;

    if (!isLoading) {
      isFavorited ? this.unfavorite() : this.favorite();
    }

    e.preventDefault();
  };

  favorite() {
    const {
      intl: { formatMessage },
      id,
      onFavorite,
    } = this.props;

    this.setState({ isLoading: true });

    const [requestPromise, cancel] = favoriteAd(id);

    this.favoriteRequestCancel = cancel;

    requestPromise
      .then(({ total }) => {
        this.setState({ isLoading: false, isFavorited: true });

        FlashNotifierService.notifySuccess(
          formatMessage(defaultMessages.jsFlashNotifierAddedToFavorites, {
            link: '/cabinet/favorite/',
          }),
        );

        document.querySelector('[data-favorite-counter]').innerHTML = total;

        if (typeof onFavorite === 'function') {
          onFavorite(id);
        }

        if (window && typeof window.fbq !== 'undefined') {
          fbq('track', 'InitiateCheckout', {
            content_type: 'home_listing',
            content_ids: id,
          });
        }
      })
      .catch(() => {
        this.setState({ isLoading: false });

        FlashNotifierService.notifyError(
          formatMessage(
            defaultMessages.jsFlashNotifierErrorHasOccurredTryAgain,
          ),
        );
      });
  }

  unfavorite() {
    const {
      intl: { formatMessage },
      id,
      onUnfavorite,
    } = this.props;

    this.setState({ isLoading: true });

    const [requestPromise, cancel] = unfavoriteAd(id);

    this.unfavoriteRequestCancel = cancel;

    requestPromise
      .then(({ total }) => {
        this.setState({ isLoading: false, isFavorited: false });

        document.querySelector('[data-favorite-counter]').innerHTML = total;

        if (typeof onUnfavorite === 'function') {
          onUnfavorite(id);
        }
      })
      .catch(() => {
        this.setState({ isLoading: false });

        FlashNotifierService.notifyError(
          formatMessage(
            defaultMessages.jsFlashNotifierErrorHasOccurredTryAgain,
          ),
        );
      });
  }

  render() {
    const { isFavorited, isLoading } = this.state;
    const { children } = this.props;

    return children({
      isFavorited,
      isLoading,
      onFavoriteClick: this.onFavoriteClick,
    });
  }
}

OfferFavorite.propTypes = {
  children: PropTypes.func.isRequired,
  id: PropTypes.any,
  intl: intlShape.isRequired,
  isInitialFavorited: PropTypes.bool,
  onFavorite: PropTypes.any,
  onUnfavorite: PropTypes.any,
};

OfferFavorite.defaultProps = {
  isInitialFavorited: false,
};

export default OfferFavorite;
