import React from 'react';
import PropTypes from 'prop-types';

import {
  ControlContainer,
  ControlContainerDivider,
} from '../RentSearchApplication/MapAreaDraw/ControlContainer';
import {
  ControlButton,
  BUTTON_TYPES,
} from '../RentSearchApplication/MapAreaDraw/ControlButton';
import { subwaysApi } from '../../api/subways';
import MetroSchemeModalSchemeLineCheckbox from './MetroSchemeModalSchemeLineCheckbox';
import MetroSchemeModalSchemeMap from './MetroSchemeModalSchemeMap';

class MetroSchemeModalScheme extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stations: [],
      lines: [],
      stationsOnLines: {},
      selectedStations: props.selectedStations,
      selectedStationsOnLines: {},
      selectedLines: [],
    };
  }

  componentWillMount() {
    const { toggleLoading } = this.props;

    toggleLoading();

    const [request, cancel] = subwaysApi.fetchSubwaysMap({ withCancel: true });

    request
      .then(({ subways, subway_lines: lines }) => {
        const { selectedStations, selectedLines } = this.state;

        let stationsOnLines = {};
        let selectedStationsOnLines = {};

        let stations = [];

        subways.forEach(station => {
          if (
            station.line_ids ||
            station.scheme_data.offset_top ||
            station.scheme_data.offset_left
          ) {
            stations.push(station);

            station.line_ids.forEach(id => {
              if (!stationsOnLines[id]) {
                stationsOnLines[id] = [];
              }

              stationsOnLines[id].push(station.id);

              if (selectedStations.indexOf(station.id) !== -1) {
                if (!selectedStationsOnLines[id]) {
                  selectedStationsOnLines[id] = [];
                }

                selectedStationsOnLines[id].push(station.id);
              }

              if (selectedStationsOnLines[id] && stationsOnLines[id]) {
                if (
                  selectedStationsOnLines[id].length ===
                  stationsOnLines[id].length
                ) {
                  if (selectedLines.indexOf(id) === -1) {
                    selectedLines.push(id);
                  }
                } else {
                  if (selectedLines.indexOf(id) !== -1) {
                    selectedLines.splice(selectedLines.indexOf(id), 1);
                  }
                }
              }
            });
          }
        });

        toggleLoading();

        this.setState({
          lines,
          stations,
          stationsOnLines,
          selectedStationsOnLines,
          selectedLines,
        });
      })
      .catch(() => {
        toggleLoading();
      });

    this.requestCancel = cancel;
  }

  componentWillUnmount() {
    if (this.requestCancel) {
      this.requestCancel();
      this.requestCancel = null;
    }
  }

  handleApplyButtonClick = e => {
    const { selectedStations } = this.state;
    const { updateOffers, onClose } = this.props;

    updateOffers(selectedStations);
    onClose();
  };

  onClearButtonClick = e => {
    this.setState({
      selectedStations: [],
      selectedStationsOnLines: {},
      selectedLines: [],
    });
  };

  handleLineChange = id => {
    const {
      stationsOnLines,
      selectedStationsOnLines,
      selectedLines,
    } = this.state;

    let selectedStations = [];
    let willSelect = false;

    if (selectedLines.indexOf(id) !== -1) {
      selectedLines.splice(selectedLines.indexOf(id), 1);
    } else {
      selectedLines.push(id);
      willSelect = true;
    }

    if (!willSelect) {
      selectedStationsOnLines[id] = [];

      for (let key in selectedStationsOnLines) {
        key = parseInt(key);

        if (selectedLines.indexOf(key) === -1) {
          let temper = [];

          selectedStationsOnLines[key].forEach(selectedStationOnLine => {
            if (selectedLines.indexOf(key) === -1) {
              let exist = false;

              stationsOnLines[id].forEach(station => {
                if (selectedStationOnLine === station) {
                  exist = true;
                }
              });

              if (!exist) {
                temper.push(selectedStationOnLine);
              }
            }
          });

          selectedStationsOnLines[key] = temper;
        }
      }
    } else {
      selectedStationsOnLines[id] = [].concat(stationsOnLines[id]);
    }

    for (let key in selectedStationsOnLines) {
      selectedStationsOnLines[key].forEach(station => {
        if (selectedStations.indexOf(station) === -1) {
          selectedStations.push(station);
        }
      });
    }

    this.setState({
      selectedStations,
      selectedStationsOnLines,
      selectedLines,
    });
  };

  handleStationChange = station => {
    const {
      stationsOnLines,
      selectedStationsOnLines,
      selectedStations,
      selectedLines,
    } = this.state;

    let willSelect = false;

    if (selectedStations.indexOf(station.id) !== -1) {
      selectedStations.splice(selectedStations.indexOf(station.id), 1);
    } else {
      selectedStations.push(station.id);
      willSelect = true;
    }

    station.lines.forEach((id, i) => {
      if (!willSelect) {
        if (selectedStationsOnLines[id]) {
          selectedStationsOnLines[id].splice(
            selectedStationsOnLines[id].indexOf(station.id),
            1,
          );
        }
      } else {
        if (!selectedStationsOnLines[id]) {
          selectedStationsOnLines[id] = [];
        }

        selectedStationsOnLines[id].push(station.id);
      }
    });

    for (let key in stationsOnLines) {
      key = parseInt(key);

      if (selectedStationsOnLines[key] && stationsOnLines[key]) {
        if (
          selectedStationsOnLines[key].length === stationsOnLines[key].length
        ) {
          if (selectedLines.indexOf(key) === -1) {
            selectedLines.push(key);
          }
        } else {
          if (selectedLines.indexOf(key) !== -1) {
            selectedLines.splice(selectedLines.indexOf(key), 1);
          }
        }
      }
    }

    this.setState({
      selectedStations: selectedStations,
      selectedStationsOnLines: selectedStationsOnLines,
      selectedLines: selectedLines,
    });
  };

  renderLines() {
    const { lines, selectedLines } = this.state;

    const len = lines.filter(line => line.scheme_data).length;
    const columns = len < 4 ? len : 4;

    let nodes = [];

    if (len > 0) {
      const elColumn = Math.ceil(len / columns);
      let start = 0;
      let end = elColumn;

      for (let i = 0; i < columns; i++) {
        let list = [];

        for (let j = start; j < end; j++) {
          if (lines[j].scheme_data) {
            const {
              id,
              scheme_data: {
                label,
                title,
                mod,
                color,
                color_active: colorActive,
              },
              bg_color: bgColor,
              bg_color_active: bgColorActive,
            } = lines[j];

            list.push(
              <li className="metro-scheme-list-item" key={`col-item-${i}-${j}`}>
                <MetroSchemeModalSchemeLineCheckbox
                  key={id}
                  id={id}
                  label={label}
                  title={title}
                  classModificator={'line-' + mod}
                  bgColor={bgColor}
                  bgColorActive={bgColorActive}
                  color={color}
                  colorActive={colorActive}
                  isActive={selectedLines.indexOf(id) !== -1}
                  onChange={this.handleLineChange}
                />
              </li>,
            );
          }
        }

        nodes.push(
          <div key={`col-${i}`} className="metro-scheme-lines-col">
            <ul className="metro-scheme-list">{list}</ul>
          </div>,
        );

        const isLastColumn = !!(i >= columns - 1);

        if (!isLastColumn) {
          nodes.push(<span key={`col-span-${i}`}> </span>);
        }

        start = start + elColumn;
        end = end + elColumn > len ? len : end + elColumn;
      }
    }

    return <div className="metro-scheme-lines">{nodes}</div>;
  }

  render() {
    const { stations, lines, selectedStations } = this.state;
    const { city } = this.props;

    return (
      <div className="metro-scheme">
        <header className="metro-scheme-header">{this.renderLines()}</header>
        <div className="metro-scheme-body">
          <MetroSchemeModalSchemeMap
            city={city}
            stations={stations}
            lines={lines}
            selectedStations={selectedStations}
            onStationChange={this.handleStationChange}
            onLineChange={this.handleLineChange}
          />
        </div>
        <ControlContainer>
          <ControlButton
            type={BUTTON_TYPES.CLEAR}
            onClick={this.onClearButtonClick}
          />
          <ControlContainerDivider />
          <ControlButton
            type={BUTTON_TYPES.SAVE}
            onClick={this.handleApplyButtonClick}
          />
        </ControlContainer>
      </div>
    );
  }
}

MetroSchemeModalScheme.propTypes = {
  city: PropTypes.object.isRequired,
  selectedStations: PropTypes.array.isRequired,
  toggleLoading: PropTypes.func.isRequired,
  updateOffers: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MetroSchemeModalScheme;
