/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

import * as masks from '../../../constants/masks';
import {
  FIELD_NAMES,
  RECEIVE_TYPE_VALUES,
  RECEIVE_SWITCHER_VALUES,
  RECEIVE_CONTACTS_PLACEHOLDERS,
} from '../constants';
import Switcher from '../Switcher';
import styles from '../styles.scss';

function ReceiveInput({
  type = null,
  value = '',
  placeholder = '',
  error = null,
  onChange,
}) {
  const handleChange = event => {
    onChange(event.target.value, type);
  };

  return (
    <div>
      {type === RECEIVE_TYPE_VALUES.TELEGRAM && (
        <InputMask
          className={classNames(styles.textarea, {
            [styles.textareaInvalid]: error !== null,
          })}
          mask={masks.PHONE_RU}
          type="tel"
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
        />
      )}
      {type === RECEIVE_TYPE_VALUES.WHATSAPP && (
        <InputMask
          className={classNames(styles.textarea, {
            [styles.textareaInvalid]: error !== null,
          })}
          mask={masks.PHONE_RU}
          type="tel"
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
        />
      )}
      {type === RECEIVE_TYPE_VALUES.EMAIL && (
        <input
          className={classNames(styles.textarea, {
            [styles.textareaInvalid]: error !== null,
          })}
          type="email"
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
        />
      )}
      {error !== null && <p className={styles.errorText}>{error}</p>}
    </div>
  );
}

ReceiveInput.propTypes = {
  error: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

function RentSearchApplicationStepFinal({
  receiveType,
  receiveValue,
  errors,
  setMainValues,
  goToNextStep,
}) {
  const [showErrors, setShowErrors] = React.useState(false);

  const receiveValueError = Array.isArray(
    errors[`${FIELD_NAMES.RECEIVE_VALUE}.${receiveType}`],
  )
    ? errors[`${FIELD_NAMES.RECEIVE_VALUE}.${receiveType}`][0]
    : null;

  const isValid = !errors[FIELD_NAMES.RECEIVE_TYPE] && !receiveValueError;

  const handleTypeChange = value => {
    setMainValues({ [FIELD_NAMES.RECEIVE_TYPE]: value });
  };

  const handleValueChange = React.useCallback((value, type) => {
    setShowErrors(false);

    setMainValues({
      [FIELD_NAMES.RECEIVE_VALUE]: { ...receiveValue, [type]: value },
    });
  });

  const handleSubmitClick = () => {
    if (!isValid) {
      return null;
    }

    goToNextStep();
  };

  return (
    <React.Fragment>
      <div className={classNames(styles.inner, styles.innerTopbarSpace)}>
        <div className={styles.title}>
          Куда присылать подборки от личного помощника?
        </div>
        <div className={styles.formItemBig}>
          <Switcher
            values={RECEIVE_SWITCHER_VALUES}
            initialActiveValue={receiveValue}
            onChange={handleTypeChange}
          />
        </div>
        <div
          className={classNames(styles.field, styles.singleField, {
            [styles.hidden]: receiveType === null,
          })}
        >
          <ReceiveInput
            type={receiveType}
            value={receiveValue[receiveType]}
            placeholder={RECEIVE_CONTACTS_PLACEHOLDERS[receiveType]}
            error={showErrors ? receiveValueError : null}
            onChange={handleValueChange}
          />
        </div>
      </div>
      <div className={styles.cta}>
        <div className={styles.buttonWrapper}>
          <button
            className={classNames(styles.button, {
              [styles.hidden]: !receiveType,
            })}
            type="button"
            disabled={!isValid}
            onClick={handleSubmitClick}
          >
            Продолжить
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

RentSearchApplicationStepFinal.propTypes = {
  errors: PropTypes.object.isRequired,
  goToNextStep: PropTypes.func.isRequired,
  receiveType: PropTypes.string,
  receiveValue: PropTypes.object,
  setMainValues: PropTypes.func.isRequired,
};

export default RentSearchApplicationStepFinal;
