import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

export const ControlContainerDivider = () => {
  return <div className={styles.controlContainerDivider} />;
};

export const ControlContainer = ({ children }) => {
  return <div className={styles.controlContainer}>{children}</div>;
};

ControlContainer.propTypes = {
  children: PropTypes.any,
};
