import React from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import styles from './AnimateOnMount.scss';

function AnimateContainer({ children }) {
  return <div className={styles.container}>{children}</div>;
}

AnimateContainer.propTypes = {
  children: PropTypes.any,
};

export const ANIMATION_TYPES = {
  FADE: 'fade',
  SLIDE: 'slide',
};

const getAnimationClass = (type = null) => {
  if (type === null) {
    return null;
  }

  return {
    appear: styles[`${type}--appear`],
    appearActive: styles[`${type}--appear-active`],
    enter: styles[`${type}--enter`],
    enterActive: styles[`${type}--enterActive`],
    leave: styles[`${type}--leave`],
    leaveActive: styles[`${type}--leaveActive`],
  };
};

// eslint-disable-next-line react/prop-types
const AnimateOnMount = ({
  children,
  component,
  animationType,
  isOpen,
  transitionAppear,
  transitionAppearTimeout,
  transitionEnterTimeout,
  transitionLeaveTimeout,
}) => (
  <ReactCSSTransitionGroup
    transitionName={getAnimationClass(animationType) || 'fade'}
    transitionAppear={transitionAppear}
    transitionAppearTimeout={transitionAppearTimeout}
    transitionEnterTimeout={transitionEnterTimeout}
    transitionLeaveTimeout={transitionLeaveTimeout}
    component={component || AnimateContainer}
  >
    {isOpen ? children : null}
  </ReactCSSTransitionGroup>
);

AnimateOnMount.propTypes = {
  animationType: PropTypes.oneOf(Object.values(ANIMATION_TYPES)),
  children: PropTypes.any,
  component: PropTypes.any,
  isOpen: PropTypes.bool,
  transitionAppear: PropTypes.bool,
  transitionAppearTimeout: PropTypes.number,
  transitionEnterTimeout: PropTypes.number,
  transitionLeaveTimeout: PropTypes.number,
};

AnimateOnMount.defaultProps = {
  animationType: null,
  component: null,
  isOpen: false,
  transitionAppear: false,
  transitionAppearTimeout: 400,
  transitionEnterTimeout: 400,
  transitionLeaveTimeout: 200,
};

export default AnimateOnMount;
