import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import validate from 'validate.js';

import { defaultMessages } from '../../../../libs/i18n/default';
import { profileApi } from '../../api/profile';
import {
  SUPPORT_EMAIL,
  SUPPORT_PHONE,
} from '../../constants/corporateConstants';
import formatPhoneToTelAttr from '../../utils/formatPhoneToTelAttr';
import AuthVkButton from '../AuthButtons/AuthVkButton';
import AuthFbButton from '../AuthButtons/AuthFbButton';
import UserSettingsModalAvatar from './UserSettingsModalAvatar';
import UserSettingsModalPhoneGroup from './UserSettingsModalPhoneGroup';

class UserSettingsModalForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      avatarSrc: '',
      email: '',
      name: '',
      phone: '',
      password: '',
      password_confirmation: '',
      isPhoneFormFocus: false,
      errors: {},
    };
  }

  componentDidMount() {
    this.getUserData();
  }

  componentWillUnmount() {
    if (this.requestGettingUserDataCancel) {
      this.requestGettingUserDataCancel();
      this.requestGettingUserDataCancel = null;
    }

    if (this.requestSettingUserDataCancel) {
      this.requestSettingUserDataCancel();
      this.requestSettingUserDataCancel = null;
    }
  }

  getValidationRules() {
    const {
      intl: { formatMessage },
    } = this.props;

    const rules = {
      email: {
        email: true,
      },
      password: {
        length: {
          minimum: 1,
          tooShort: formatMessage(
            defaultMessages.jsSettingsModalValidationPasswordMinlength,
          ),
        },
      },
      password_confirmation: {
        equality: {
          attribute: 'password',
          message: formatMessage(
            defaultMessages.jsSettingsModalValidationPasswordEqualTo,
          ),
        },
      },
    };

    return rules;
  }

  getUserData() {
    const { toggleLoading } = this.props;

    toggleLoading();

    const [request, cancel] = profileApi.getUserProfileData({
      withCancel: true,
    });

    request
      .then(({ avatar, name, email, phone }) => {
        toggleLoading();

        this.setState({
          name: name || '',
          email: email || '',
          phone: phone || '',
          avatarSrc: avatar || '',
        });
      })
      .catch(() => {
        toggleLoading();
      });

    this.requestGettingUserDataCancel = cancel;
  }

  handleChangeField = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  handleUpdateAvatar = src => {
    this.setState({ avatarSrc: src });

    // Пока так обновляем все аватарки юзера на странице
    document.querySelector(
      '.MobileNav__userAvatar',
    ).style.backgroundImage = `url('${src}')`;
    document.querySelector('.Header__userAvatarImg').setAttribute('src', src);
    document
      .querySelector('.HeaderDropdown__popupAvatarImg')
      .setAttribute('src', src);
  };

  handlePhoneFormFocus = () => {
    const { isPhoneFormFocus } = this.state;

    if (!isPhoneFormFocus) {
      this.setState({ isPhoneFormFocus: true });
    }
  };

  handlePhoneFormBlur = () => {
    const { isPhoneFormFocus } = this.state;

    if (isPhoneFormFocus) {
      this.setState({ isPhoneFormFocus: false });
    }
  };

  handleSubmit = event => {
    const {
      name,
      email,
      password,
      password_confirmation: passwordConfirmation,
      isPhoneFormFocus,
    } = this.state;

    if (isPhoneFormFocus) {
      event.preventDefault();

      return null;
    }

    let values = {
      name,
      email,
    };

    if (password.length > 0) {
      values['password'] = password;
      values['password_confirmation'] = passwordConfirmation;
    }

    const errors = validate(values, this.getValidationRules(), {
      fullMessages: false,
    });

    if (errors) {
      this.setState({ errors });
    } else {
      this.saveUserData(values);
    }

    event.preventDefault();

    return null;
  };

  saveUserData(values) {
    const { toggleLoading, onSuccessSave } = this.props;

    toggleLoading();

    const [request, cancel] = profileApi.saveUserProfileData(values, {
      withCancel: true,
    });

    request
      .then(({ status = 'ok', errors }) => {
        toggleLoading();

        if (status === 'ok') {
          onSuccessSave();
        } else if (errors) {
          this.setState({ errors });
        }
      })
      .catch(() => {
        toggleLoading();
      });

    this.requestSettingUserDataCancel = cancel;
  }

  renderFieldError(fieldName) {
    const { errors } = this.state;
    const fieldError = errors[fieldName];

    return (
      fieldError &&
      fieldError.length > 0 && (
        <span className="error error-message">{fieldError[0]}</span>
      )
    );
  }

  render() {
    const { avatarSrc, name, email, phone } = this.state;
    const { intl, country, toggleLoading } = this.props;
    const { formatMessage } = intl;

    return (
      <div className="settings">
        <form onSubmit={this.handleSubmit}>
          <div className="settings-group">
            <div className="settings-group-right">
              <div className="settings-item settings-item-avatar">
                <UserSettingsModalAvatar
                  intl={intl}
                  src={avatarSrc}
                  onUpdate={this.handleUpdateAvatar}
                />
              </div>
            </div>
            <div className="settings-group-left">
              <div className="settings-title">
                {formatMessage(
                  defaultMessages.jsSettingsModalTitlePersonalInformation,
                )}
              </div>
              <div className="settings-item">
                <div className="input">
                  <input
                    className="input-control"
                    type="text"
                    name="name"
                    placeholder={formatMessage(
                      defaultMessages.jsSettingsModalNamePlaceholder,
                    )}
                    value={name}
                    onChange={this.handleChangeField}
                  />
                  {this.renderFieldError('name')}
                </div>
              </div>
              <div className="settings-item">
                <div className="input">
                  <input
                    className="input-control"
                    type="text"
                    name="email"
                    placeholder={formatMessage(
                      defaultMessages.jsSettingsModalEmailPlaceholder,
                    )}
                    value={email}
                    onChange={this.handleChangeField}
                  />
                  {this.renderFieldError('email')}
                </div>
              </div>
            </div>
          </div>
          <div className="settings-group">
            <div className="settings-group-left">
              <div className="settings-title">
                {formatMessage(
                  defaultMessages.jsSettingsModalTitleChangePassword,
                )}
              </div>
              <div className="settings-item">
                <div className="input">
                  <input
                    className="input-control"
                    type="password"
                    name="password"
                    onChange={this.handleChangeField}
                    placeholder={formatMessage(
                      defaultMessages.jsSettingsModalNewPasswordPlaceholder,
                    )}
                  />
                  {this.renderFieldError('password')}
                </div>
              </div>
              <div className="settings-item">
                <div className="input">
                  <input
                    className="input-control"
                    type="password"
                    name="password_confirmation"
                    onChange={this.handleChangeField}
                    placeholder={formatMessage(
                      defaultMessages.jsSettingsModalConfirmPasswordPlaceholder,
                    )}
                  />
                  {this.renderFieldError('password_confirmation')}
                </div>
              </div>
            </div>
          </div>
          <UserSettingsModalPhoneGroup
            intl={intl}
            country={country}
            phone={phone}
            toggleLoading={toggleLoading}
            onFocus={this.handlePhoneFormFocus}
            onBlur={this.handlePhoneFormBlur}
          />
          <div className="settings-group settings-group-full">
            <div className="settings-group-left">
              <div className="settings-title">
                {formatMessage(
                  defaultMessages.jsSettingsModalTitleSocialAccounts,
                )}
              </div>
              <div className="settings-item">
                <AuthVkButton intl={intl} />
                <AuthFbButton intl={intl} />
              </div>
            </div>
          </div>
          <div className="settings-group settings-group-full">
            <div className="settings-item settings-item-actions">
              <div className="settings-item-cell">
                <button
                  type="submit"
                  className="button button-orange button-large"
                >
                  {formatMessage(defaultMessages.jsSettingsModalSaveButton)}
                </button>
              </div>
              <div className="settings-item-cell">
                <p
                  dangerouslySetInnerHTML={{
                    __html: formatMessage(
                      defaultMessages.jsSettingsModalContactUs,
                      {
                        email: `<a class="link link-green" href="mailto:${SUPPORT_EMAIL}">${SUPPORT_EMAIL}</a>`,
                        phone: `<a class="link link-green" href="tel:${formatPhoneToTelAttr(
                          SUPPORT_PHONE,
                        )}">${SUPPORT_PHONE}</a>`,
                      },
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

UserSettingsModalForm.propTypes = {
  country: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  toggleLoading: PropTypes.func.isRequired,
  onSuccessSave: PropTypes.func,
};

export default UserSettingsModalForm;
