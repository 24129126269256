import React from 'react';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';

import { fetchAds, fetchGoogleMapAds } from '../../api/rooms';
import { VIEW_MODES, DEAL_TYPES, FILTER_KEYS } from './AdCatalog.constants';
import { FilterContext } from './contexts/FilterContext';
import { defaultMessages } from '../../../../libs/i18n/default';
import setRailsContext from '../../../common/utils/setRailsContext';
import TranslationProvider from '../../../common/components/HOC/TranslationProvider';
import getWindowScrollTop from '../../utils/getWindowScrollTop';
import setWindowScrollTop from '../../utils/setWindowScrollTop';
import FlashNotifierService from '../../services/FlashNotifier/FlashNotifier';
import LoaderBar from '../LoaderBar/LoaderBar';
import AdCatalogHeader from './AdCatalogHeader';
import AdCatalogContent from './AdCatalogContent';
import AdCatalogFilterOpenButton from './AdCatalogFilterOpenButton';

const UPDATE_DELAY = 300;
const PRICE_MIN_LIMIT = 0;
const FILTER_OPEN_CLASSNAME = '__filter-open';

const nullableFilterKeys = []; // [FILTER_KEYS.REALTOR_COMISSION];

class AdCatalog extends React.Component {
  constructor(props) {
    super(props);

    const {
      h1,
      view,
      filter,
      ads = [],
      allAdsAmount,
      map_options: mapOptions,
      email,
      breadcrumbs,
      childLinks,
    } = this.props;

    this.state = {
      h1,
      view,
      filter,
      ads,
      allAdsAmount,
      mapOptions,
      email,
      breadcrumbs,
      childLinks,
      isLoading: false,
      adsMap: [],
      // adsNewMap: {
      //   clusters: [],
      //   points: [],
      // },
      shouldResetLoaderBar: false,
      shouldUpdateNewMap: false,
      page: 1,
      isFilterOpen: false,
    };

    this.updateFilterTimer = null;
    this.scrollTop = 0;
  }

  componentDidMount() {
    const { view } = this.state;

    this.toggleDocumentView();
    this.setPageView(view);

    this.html = document.documentElement;
  }

  componentDidUpdate() {
    this.toggleDocumentView();
  }

  getPriceMaxLimit() {
    const { filter } = this.state;
    const { salePriceMaxLimit, rentPriceMaxLimit } = this.props;

    switch (filter[FILTER_KEYS.DEAL_TYPE] || DEAL_TYPES.RENT || DEAL_TYPES.DAILY_RENT) {
      case DEAL_TYPES.RENT:
        return rentPriceMaxLimit;
      case DEAL_TYPES.DAILY_RENT:
        return rentPriceMaxLimit;
      case DEAL_TYPES.SALE:
        return salePriceMaxLimit;
    }
  }

  toggleDocumentView = () => {
    const { view } = this.state;

    if (document) {
      document.documentElement.classList.toggle(
        '__map',
        view === VIEW_MODES.MAP,
      );
    }
  };

  onViewChange = nextView => {
    const { view } = this.state;

    if (view === nextView) {
      return false;
    }

    this.clearRequest();
    this.setPageView(nextView, view);

    this.setState(
      ({ shouldUpdateNewMap }) => ({
        view: nextView,
        shouldUpdateNewMap: nextView === VIEW_MODES.MAP || shouldUpdateNewMap,
      }),
      this.reloadAds,
    );
  };

  resetFilter = () => {
    this.updateFilter({});
  };

  openFilter = () => {
    const { isFilterOpen } = this.state;

    if (!isFilterOpen) {
      this.scrollTop = getWindowScrollTop();

      this.html.classList.add(FILTER_OPEN_CLASSNAME);

      this.setState({ isFilterOpen: true });
    }
  };

  closeFilter = () => {
    const { isFilterOpen } = this.state;

    if (isFilterOpen) {
      this.html.classList.remove(FILTER_OPEN_CLASSNAME);

      this.setState({ isFilterOpen: false });

      setWindowScrollTop(this.scrollTop);
    }
  };

  updateFilter = (newFilter, cb) => {
    this.setState(
      {
        filter: newFilter,
        shouldUpdateNewMap: true,
        shouldResetLoaderBar: true,
      },
      () => {
        this.setState({ shouldResetLoaderBar: false });
        this.reloadAds(cb);
      },
    );

    if (DeviceSupports.localStorage) {
      localStorage.setItem('lastFilter', JSON.stringify(newFilter));
    }
  };

  newUpdateFilter = (values, callback = null, delay = 0) => {
    const { filter } = this.state;

    let nextFilter = Object.assign({}, filter);

    for (let key in values) {
      if (isNil(values[key]) && nullableFilterKeys.indexOf(key) === -1) {
        delete nextFilter[key];
      } else {
        nextFilter[key] = values[key];
      }
    }

    if (this.updateFilterTimer) {
      clearTimeout(this.updateFilterTimer);
    }

    this.setState(
      {
        filter: nextFilter,
        shouldResetLoaderBar: true,
        shouldUpdateNewMap: true,
      },
      () => {
        this.updateFilterTimer = setTimeout(() => {
          this.setState({ shouldResetLoaderBar: false });
          this.reloadAds(callback);
        }, delay);
      },
    );

    if (DeviceSupports.localStorage) {
      localStorage.setItem('lastFilter', JSON.stringify(nextFilter));
    }
  };

  handleReadyMap = () => {
    const { view } = this.state;

    if (view === VIEW_MODES.MAP) {
      this.setState({ isLoading: true });
      this.reloadMapAds();
    }
  };

  reloadAds = cb => {
    const { view } = this.state;

    this.showLoader();

    if (view === VIEW_MODES.LIST) {
      this.reloadListAds();
    } else {
      this.reloadMapAds(cb);
    }
  };

  showLoader = () => {
    this.setState({ isLoading: true });
  };

  hideLoader = () => {
    this.setState({ isLoading: false });
  };

  loadMore = () => {
    const { ads, allAdsAmount, isLoading, filter, page } = this.state;
    const {
      intl: { formatMessage },
    } = this.props;

    if (ads.length < allAdsAmount && !isLoading) {
      const nextPage = page + 1;

      this.setState({
        isLoading: true,
        page: nextPage,
      });

      fetchAds({ filter, page: nextPage })
        .then(data => {
          this.setState({
            ads: ads.concat(data.ads),
            isLoading: false,
          });
        })
        .catch(error => {
          console.error(error);

          this.setState({ isLoading: false });

          FlashNotifierService.notifyError(
            formatMessage(
              defaultMessages.jsFlashNotifierCouldNotLoadAllAdsTryAgain,
            ),
          );
        });
    }
  };

  historyChangePath = path => {
    history.pushState(null, null, path);

    try {
      ga('send', 'pageview', path);
      yaCounter14087668.hit(path);
    } catch (e) {}
  };

  htmlHistorySupported = () => window.history && window.history.pushState;

  setPageView = (newView, oldView) => {
    const pageElement = document.querySelector('.layout');

    pageElement.classList.add('__' + newView);

    if (oldView) {
      pageElement.classList.remove('__' + oldView);
    }
  };

  deleteGeoAreas = () => {
    const { filter } = this.state;

    let nextFilter = Object.assign({}, filter);

    if (!isNil(nextFilter[FILTER_KEYS.GEO_AREAS])) {
      delete nextFilter[FILTER_KEYS.GEO_AREAS];

      this.updateFilter(nextFilter);
    }
  };

  onMapLastAjaxSuccessLoadingObjects = () => {
    this.setState({
      shouldUpdateNewMap: false,
      isLoading: false,
    });
  };

  onMapLastAjaxErrorLoadingObjects = isCanceled => {
    if (isCanceled) {
      this.setState({
        shouldUpdateNewMap: false,
      });
    } else {
      this.setState({
        shouldUpdateNewMap: false,
        isLoading: false,
      });
    }
  };

  reloadListAds() {
    const {
      intl: { formatMessage },
    } = this.props;

    this.clearRequest();

    this.requestTimer = setTimeout(() => {
      const { filter } = this.state;

      fetchAds({ filter })
        .then(({ h1, ads, allAdsAmount, breadcrumbs, childLinks, path }) => {
          if (this.htmlHistorySupported()) {
            this.setState({
              h1,
              ads,
              allAdsAmount,
              breadcrumbs,
              childLinks,
              isLoading: false,
              page: 1,
            });
            this.historyChangePath(path);
          } else {
            window.location.href = path;
          }
        })
        .catch(error => {
          console.error(error);

          this.setState({ isLoading: false });

          FlashNotifierService.notifyError(
            formatMessage(
              defaultMessages.jsFlashNotifierCouldNotLoadAllAdsTryAgain,
            ),
          );
        });
    }, UPDATE_DELAY);
  }

  reloadMapAds(cb) {
    const { isUkraine } = this.props;

    if (!isUkraine) {
      return;
    }

    this.clearRequest();

    this.requestTimer = setTimeout(() => {
      const { filter } = this.state;

      const [requestPromise, cancel] = fetchGoogleMapAds({ filter });

      this.requestCancel = cancel;

      requestPromise.then(({ ads, path }) => {
        if (typeof cb === 'function') {
          cb();
        }

        if (this.htmlHistorySupported()) {
          this.setState({
            adsMap: ads,
            isLoading: false,
          });
          this.historyChangePath(path);
        } else {
          window.location.href = path;
        }
      });
    }, UPDATE_DELAY);
  }

  clearRequest() {
    if (this.requestTimer) {
      clearTimeout(this.requestTimer);
    }

    if (this.requestCancel) {
      this.requestCancel();
      this.requestCancel = null;
    }
  }

  render() {
    const {
      allAdsAmount,
      view,
      isLoading,
      shouldResetLoaderBar,
      filter,
      isFilterOpen,
      adsMap,
      mapOptions,
      shouldUpdateNewMap,
      ads,
      email,
      breadcrumbs,
      childLinks,
      h1,
    } = this.state;

    const {
      intl,
      adsAmountFromOwner,
      amenities,
      showScheme,
      signedIn,
      city,
      cityHasSubways,
      districts,
      queries,
      text,
      priceMax,
      isUkraine,
      isTheLocalsRuHost,
    } = this.props;

    return (
      <div className={`catalog __${view}`}>
        <LoaderBar isLoading={isLoading} shouldReset={shouldResetLoaderBar} />
        <AdCatalogHeader
          intl={intl}
          allAdsAmount={allAdsAmount}
          breadcrumbs={breadcrumbs}
          childLinks={childLinks}
          filter={filter}
          h1={h1}
          updateFilter={this.updateFilter}
        />
        <FilterContext.Provider
          value={{
            intl,
            city,
            cityHasSubways,
            districts,
            filter,
            view,
            isFilterOpen,
            amenities,
            priceMinLimit: PRICE_MIN_LIMIT,
            priceMaxLimit: this.getPriceMaxLimit(),
            shouldShowScheme: showScheme,
            closeFilter: this.closeFilter,
            deleteGeoAreas: this.deleteGeoAreas,
            openFilter: this.openFilter,
            resetFilter: this.resetFilter,
            updateFilter: this.newUpdateFilter,
            onViewChange: this.onViewChange,
          }}
        >
          <AdCatalogContent
            intl={intl}
            ads={ads}
            adsMap={adsMap}
            adsAmountFromOwner={adsAmountFromOwner}
            allAdsAmount={allAdsAmount}
            email={email}
            filter={filter}
            mapOptions={mapOptions}
            view={view}
            queries={queries}
            signedIn={signedIn}
            shouldUpdateNewMap={shouldUpdateNewMap}
            text={text}
            isFilterOpen={isFilterOpen}
            isLoading={isLoading}
            isTheLocalsRuHost={isTheLocalsRuHost}
            isUkraine={isUkraine}
            hideLoader={this.hideLoader}
            loadMore={this.loadMore}
            showLoader={this.showLoader}
            updateFilter={this.updateFilter}
            onReadyMap={this.handleReadyMap}
            onMapLastAjaxErrorLoadingObjects={
              this.onMapLastAjaxErrorLoadingObjects
            }
            onMapLastAjaxSuccessLoadingObjects={
              this.onMapLastAjaxSuccessLoadingObjects
            }
          />
        </FilterContext.Provider>
        <AdCatalogFilterOpenButton
          intl={intl}
          filter={filter}
          isFilterOpen={isFilterOpen}
          priceMinLimit={PRICE_MIN_LIMIT}
          priceMaxLimit={priceMax}
          onClick={this.openFilter}
        />
      </div>
    );
  }
}

AdCatalog.propTypes = {
  ads: PropTypes.any,
  adsAmountFromOwner: PropTypes.number,
  allAdsAmount: PropTypes.any,
  amenities: PropTypes.any,
  breadcrumbs: PropTypes.any,
  childLinks: PropTypes.any,
  city: PropTypes.any,
  cityHasSubways: PropTypes.any,
  districts: PropTypes.any,
  email: PropTypes.any,
  filter: PropTypes.any,
  h1: PropTypes.any,
  intl: PropTypes.any,
  isTheLocalsRuHost: PropTypes.any,
  isUkraine: PropTypes.any,
  map_options: PropTypes.any,
  priceMax: PropTypes.any,
  queries: PropTypes.any,
  rentPriceMaxLimit: PropTypes.any,
  salePriceMaxLimit: PropTypes.any,
  showScheme: PropTypes.any,
  signedIn: PropTypes.any,
  text: PropTypes.any,
  view: PropTypes.any,
};

AdCatalog.defaultProps = {
  showScheme: false,
};

export default setRailsContext(TranslationProvider(AdCatalog));
