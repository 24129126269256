import { intlShape } from 'react-intl';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import InputMask from 'react-input-mask';

import * as regExps from '../../constants/regExps';
import * as masks from '../../constants/masks';
import { profileApi } from '../../api/profile';
import { defaultMessages } from '../../../../libs/i18n/default';
import UserSettingsModalPhoneGroupButton from './UserSettingsModalPhoneGroupButton';
import UserSettingsModalPhoneSmsCodeAction from './UserSettingsModalPhoneSmsCodeAction';
import FlashNotifierService from '../../services/FlashNotifier/FlashNotifier';

const getTimeDiff = (start, end) => end - start;

const getTimeDiffSeconds = (start, end) =>
  Math.round(getTimeDiff(start, end) / 1000);

class UserSettingsModalPhoneGroup extends React.Component {
  constructor(props) {
    super(props);

    const { phone } = this.props;

    this.state = {
      phone,
      errors: {},
      isPhoneDisabled: !validate.isEmpty(phone),
      isPhoneSaved: !validate.isEmpty(phone),
      isSmsCodeSent: false,
      smsCode: '',
      smsResendTimer: 0,
      isPhoneWasBlurred: false,
      isSmsCodeWasBlurred: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps, prevState) {
    const { phone } = this.props;

    if (prevProps.phone !== phone) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isPhoneDisabled: !validate.isEmpty(phone),
        isPhoneSaved: !validate.isEmpty(phone),
        phone,
      });
    }
  }

  componentWillUnmount() {
    if (this.countdownId) {
      clearTimeout(this.countdownId);
    }

    if (this.requestGettingSmsCodeCancel) {
      this.requestGettingSmsCodeCancel();
      this.requestGettingSmsCodeCancel = null;
    }

    if (this.requestSavingPhoneCancel) {
      this.requestSavingPhoneCancel();
      this.requestSavingPhoneCancel = null;
    }

    this._isMounted = false;
  }

  getPhoneMask() {
    const { country } = this.props;

    switch (country) {
      case 'ua':
        return masks.PHONE_UA;
      default:
        // ru
        return masks.PHONE_RU;
    }
  }

  getValidationRules() {
    const {
      intl: { formatMessage },
      country,
    } = this.props;

    return {
      phone: {
        format: {
          pattern: country === 'ua' ? regExps.PHONE_UA : regExps.PHONE_RU,
          message: formatMessage(
            defaultMessages.jsSettingsModalValidationPhoneWrongFormat,
          ),
        },
      },
      smsCode: {
        format: {
          pattern: regExps.SMS_CODE,
          message: formatMessage(
            defaultMessages.jsSettingsModalValidationFieldWrongFormat,
          ),
        },
      },
    };
  }

  getSmsCodeError() {
    const {
      errors: { smsCode: smsCodeErrors },
    } = this.state;

    return smsCodeErrors && Array.isArray(smsCodeErrors)
      ? smsCodeErrors[0]
      : null;
  }

  handlePhoneFocus = () => {
    const { onFocus } = this.props;

    onFocus();
  };

  handlePhoneBlur = ({ target: { value } }) => {
    const { isPhoneWasBlurred } = this.state;
    const { onBlur } = this.props;

    if (!isPhoneWasBlurred) {
      this.setState({ isPhoneWasBlurred: true });
    }

    setTimeout(() => {
      if (!this._isMounted) {
        return;
      }

      this.validatePhone(value);
    }, 0);

    onBlur();
  };

  handlePhoneChange = ({ target: { value } }) => {
    const { isPhoneDisabled } = this.state;

    if (!isPhoneDisabled) {
      this.validatePhone(value);
      this.setState({ phone: value });
    }
  };

  handlePhoneKeyDown = ({ which }) => {
    if (which === 13 && this.isPossibleToSendSmsCode()) {
      this.sendSmsCode();
    }
  };

  handlePhoneChangeButtonClick = () => {
    this.setState(
      {
        isPhoneDisabled: false,
        isPhoneSaved: false,
        isSmsCodeSent: false,
        smsCode: '',
      },
      () => {
        this.phoneInput.input.focus();
      },
    );
  };

  handleSendSmsCodeButtonClick = () => {
    if (this.isPossibleToSendSmsCode()) {
      this.sendSmsCode();
    }
  };

  handleSendSmsCodeLinkClick = e => {
    if (this.isPossibleToSendSmsCode()) {
      this.sendSmsCode();
    }

    e.preventDefault();
  };

  handleSmsCodeFocus = () => {
    const { onFocus } = this.props;

    onFocus();
  };

  handleSmsCodeBlur = ({ target: { value } }) => {
    const { isSmsCodeWasBlurred } = this.state;
    const { onBlur } = this.props;

    if (!isSmsCodeWasBlurred) {
      this.setState({ isSmsCodeWasBlurred: true });
    }

    setTimeout(() => {
      if (!this._isMounted) {
        return;
      }

      this.validateSmsCode(value);
    }, 0);

    onBlur();
  };

  handleSmsCodeChange = ({ target: { value } }) => {
    const { isSmsCodeSent } = this.state;

    if (isSmsCodeSent) {
      this.validateSmsCode(value);
      this.setState({ smsCode: value });
    }
  };

  handleSmsCodeKeyDown = ({ which }) => {
    if (which === 13) {
      if (this.isPossibleToSavePhone()) {
        // Т.о. валидируем поле, если до этого не было потери фокуса
        this.smsCodeInput.input.blur();
      }

      this.savePhone();
    }
  };

  handleSaveButtonClick = () => {
    this.savePhone();
  };

  isPhoneEmpty() {
    const { phone } = this.state;

    return validate.isEmpty(phone);
  }

  isPhoneValid() {
    const { errors } = this.state;

    return !this.isPhoneEmpty() && !errors.phone;
  }

  isPossibleToSavePhone() {
    return this.isPhoneValid() && this.isSmsCodeValid();
  }

  isPossibleToSendSmsCode() {
    const { smsResendTimer } = this.state;

    return this.isPhoneValid() && smsResendTimer === 0;
  }

  isSmsCodeValid() {
    const { errors } = this.state;

    return !errors.smsCode;
  }

  countdown(seconds) {
    const startTime = new Date().getTime();
    const endTime = startTime + seconds * 1000;

    const tick = () => {
      this.countdownId = setTimeout(() => {
        const diff = getTimeDiffSeconds(new Date().getTime(), endTime);

        if (diff >= 0) {
          this.setState({ smsResendTimer: diff });
        }

        if (diff > 0) {
          tick();
        }
      }, 1000);
    };

    this.setState({
      smsResendTimer: getTimeDiffSeconds(startTime, endTime),
    });

    tick();
  }

  savePhone() {
    const { phone, errors, smsCode } = this.state;
    const {
      intl: { formatMessage },
      toggleLoading,
    } = this.props;

    if (this.isPossibleToSavePhone()) {
      toggleLoading();

      const [request, cancel] = profileApi.savePhone(
        {
          phone,
          code: smsCode.replace(/\s/g, ''),
        },
        { withCancel: true },
      );

      request
        .then(() => {
          toggleLoading();

          this.setState({
            isPhoneDisabled: true,
            isPhoneSaved: true,
            isSmsCodeSent: false,
            smsCode: '',
          });
        })
        .catch(responseErrors => {
          toggleLoading();

          if (responseErrors instanceof Error) {
            FlashNotifierService.notifyError(
              formatMessage(
                defaultMessages.jsFlashNotifierErrorHasOccurredCheckConnection,
              ),
            );
          }

          if (responseErrors) {
            let nextErrors = Object.assign({}, errors);
            const { phone, code } = responseErrors;

            if (phone) {
              if (!nextErrors['phone']) {
                nextErrors['phone'] = [];
              }

              nextErrors['phone'].push(phone);
            }

            if (code) {
              if (!nextErrors['smsCode']) {
                nextErrors['smsCode'] = [];
              }

              nextErrors['smsCode'].push(code);
            }

            this.setState({ errors: nextErrors });
          }
        });

      this.requestSavingPhoneCancel = cancel;
    }
  }

  sendSmsCode() {
    const { phone, errors } = this.state;
    const {
      intl: { formatMessage },
      toggleLoading,
    } = this.props;

    if (!this.isPhoneEmpty()) {
      toggleLoading();

      const [request, cancel] = profileApi.sendSmsCode(
        { phone },
        { withCancel: true },
      );

      request
        .then(() => {
          toggleLoading();

          this.setState({
            isPhoneDisabled: true,
            isSmsCodeSent: true,
            smsCode: '',
          });

          this.countdown(60);

          this.smsCodeInput.input.focus();
        })
        .catch(responseErrors => {
          toggleLoading();

          if (responseErrors instanceof Error) {
            FlashNotifierService.notifyError(
              formatMessage(
                defaultMessages.jsFlashNotifierErrorHasOccurredCheckConnection,
              ),
            );
          }

          if (responseErrors) {
            let nextErrors = Object.assign({}, errors);
            const { phone } = responseErrors;

            if (phone) {
              if (!nextErrors['phone']) {
                nextErrors['phone'] = [];
              }

              nextErrors['phone'].push(phone);
            }

            this.setState({ errors: nextErrors });
          }
        });

      this.requestGettingSmsCodeCancel = cancel;
    }
  }

  validatePhone(value) {
    const { errors } = this.state;

    const phoneErrors = validate.single(value, this.getValidationRules().phone);
    let nextErrors = Object.assign({}, errors);

    if (!validate.isEmpty(phoneErrors)) {
      nextErrors.phone = phoneErrors;

      this.setState({ errors: nextErrors });
    } else {
      if (!validate.isEmpty(nextErrors.phone)) {
        delete nextErrors.phone;

        this.setState({ errors: nextErrors });
      }
    }
  }

  validateSmsCode(value) {
    const { errors } = this.state;
    const smsCodeErrors = validate.single(
      value,
      this.getValidationRules().smsCode,
    );
    let nextErrors = Object.assign({}, errors);

    if (!validate.isEmpty(smsCodeErrors)) {
      nextErrors.smsCode = smsCodeErrors;

      this.setState({ errors: nextErrors });
    } else {
      if (!validate.isEmpty(nextErrors.smsCode)) {
        delete nextErrors.smsCode;

        this.setState({ errors: nextErrors });
      }
    }
  }

  renderPhoneError() {
    const { errors } = this.state;

    return <div className="error-message">{errors.phone[0]}</div>;
  }

  renderPhoneItem() {
    const {
      phone,
      smsResendTimer,
      isPhoneDisabled,
      isPhoneWasBlurred,
    } = this.state;
    const {
      intl: { formatMessage },
    } = this.props;

    const isPhoneValid = this.isPhoneValid();

    const isShowEditButton = isPhoneDisabled && smsResendTimer === 0;
    const isShowError = !isPhoneValid && isPhoneWasBlurred;

    return (
      <div className="settings-item">
        <div
          className={classNames('input', 'input-phone', {
            'input-phone-with-button': isShowEditButton,
            __disabled: isPhoneDisabled,
            __error: isShowError,
            __success: isPhoneValid,
          })}
        >
          <InputMask
            ref={ref => (this.phoneInput = ref)}
            className="input-control"
            type="tel"
            name="phone"
            mask={this.getPhoneMask()}
            placeholder={formatMessage(
              defaultMessages.jsSettingsModalPhonePlaceholder,
            )}
            value={phone}
            disabled={isPhoneDisabled}
            onFocus={this.handlePhoneFocus}
            onBlur={this.handlePhoneBlur}
            onChange={this.handlePhoneChange}
            onKeyDown={this.handlePhoneKeyDown}
          />
          {isShowEditButton && (
            <button
              className="button input-phone-button"
              title={formatMessage(defaultMessages.jsSettingsModalChangeButton)}
              type="button"
              onClick={this.handlePhoneChangeButtonClick}
            />
          )}
          {isShowError && this.renderPhoneError()}
        </div>
      </div>
    );
  }

  renderSubmitItem() {
    const { isSmsCodeSent, isPhoneDisabled, smsResendTimer } = this.state;
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <div className="settings-item">
        {!isPhoneDisabled && (
          <UserSettingsModalPhoneGroupButton
            isDisabled={smsResendTimer !== 0}
            onClick={this.handleSendSmsCodeButtonClick}
          >
            {formatMessage(defaultMessages.jsSettingsModalGetSMSCodeButton)}
          </UserSettingsModalPhoneGroupButton>
        )}
        {isPhoneDisabled && isSmsCodeSent && (
          <UserSettingsModalPhoneGroupButton
            isDisabled={!this.isPossibleToSavePhone()}
            onClick={this.handleSaveButtonClick}
          >
            {formatMessage(defaultMessages.jsSettingsModalSaveButton)}
          </UserSettingsModalPhoneGroupButton>
        )}
      </div>
    );
  }

  render() {
    const {
      isPhoneSaved,
      isPhoneDisabled,
      isSmsCodeSent,
      isSmsCodeWasBlurred,
      smsResendTimer,
      smsCode,
    } = this.state;
    const { intl } = this.props;
    const { formatMessage } = intl;

    const isPhoneValid = this.isPhoneValid();
    const isSmsCodePendingConfirmation = isSmsCodeSent && !isPhoneSaved;
    const isShowSubmitAction =
      isPhoneValid && !isPhoneSaved && (!isPhoneDisabled || isSmsCodeSent);

    return (
      <div className="settings-group">
        <div className="settings-group-left">
          <div className="settings-title">
            {isPhoneSaved
              ? formatMessage(defaultMessages.jsSettingsModalTitlePhone)
              : formatMessage(defaultMessages.jsSettingsModalTitlePhoneBind)}
          </div>
          {this.renderPhoneItem()}
          {isSmsCodePendingConfirmation && (
            <UserSettingsModalPhoneSmsCodeAction
              intl={intl}
              smsCode={smsCode}
              smsCodeInputRef={ref => (this.smsCodeInput = ref)}
              smsResendTimer={smsResendTimer}
              showError={isSmsCodeWasBlurred}
              errorMessage={this.getSmsCodeError()}
              onResendClick={this.handleSendSmsCodeLinkClick}
              onFocus={this.handleSmsCodeFocus}
              onBlur={this.handleSmsCodeBlur}
              onChange={this.handleSmsCodeChange}
              onKeyDown={this.handleSmsCodeKeyDown}
            />
          )}
          {isShowSubmitAction && this.renderSubmitItem()}
        </div>
      </div>
    );
  }
}

UserSettingsModalPhoneGroup.propTypes = {
  country: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  phone: PropTypes.string.isRequired,
  toggleLoading: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

export default UserSettingsModalPhoneGroup;
