import { injectIntl, intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import { FIELD_NAMES } from './constants';
import AdFormTextarea from './AdFormTextarea';
import AdFormFootnote from './AdFormFootnoteMixin';

class AdFormContacts extends React.PureComponent {
  constructor(props) {
    super(props);

    this.contactsInputRef = React.createRef();
  }

  handleChange = event => {
    const { setAdState } = this.props;

    setAdState({ [FIELD_NAMES.OTHER_CONTACTS]: event.currentTarget.value });
  };

  render() {
    const {
      intl: { formatMessage },
      otherContacts,
      bigFootnote,
      showFootnote,
      hideFootnote,
      smallFootnote,
    } = this.props;

    return (
      <div className="form-row">
        <div
          className="form-row-label"
          dangerouslySetInnerHTML={{
            __html: formatMessage(defaultMessages.jsAdFormOtherContactsLabel),
          }}
        />
        <div className="form-row-content">
          {bigFootnote()}
          <AdFormTextarea
            ref={this.contactsInputRef}
            value={otherContacts}
            placeholder={formatMessage(
              defaultMessages.jsAdFormOtherContactsPlaceholder,
            )}
            onChange={this.handleChange}
            onFocus={showFootnote}
            onBlur={hideFootnote}
          />
          {smallFootnote()}
        </div>
      </div>
    );
  }
}

AdFormContacts.propTypes = {
  bigFootnote: PropTypes.func,
  hideFootnote: PropTypes.func,
  intl: intlShape.isRequired,
  otherContacts: PropTypes.string,
  setAdState: PropTypes.func,
  showFootnote: PropTypes.func,
  smallFootnote: PropTypes.func,
};

export default injectIntl(
  AdFormFootnote(AdFormContacts, FIELD_NAMES.OTHER_CONTACTS),
);
