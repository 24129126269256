import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.scss';

export const BUTTON_TYPES = {
  CLEAR: 'clear',
  CANCEL: 'cancel',
  DRAW: 'draw',
  DRAG: 'drag',
  SAVE: 'save',
};

export const ControlButton = ({ type, disabled = false, onClick }) => {
  switch (type) {
    case BUTTON_TYPES.CLEAR:
      return (
        <button
          className={classNames(
            styles.controlButton,
            styles.controlButtonClear,
          )}
          onClick={onClick}
          disabled={disabled}
          type="button"
        >
          <span className={styles.controlButtonCircle}>
            <img
              alt="Очистить"
              src={require('../../../../../images/client/RentSearchApplication/trash.svg')}
              width="18"
              height="20"
            />
          </span>
          <span className={styles.controlButtonText}>
            Стереть<span className={styles.mobileHidden}> все</span>
          </span>
        </button>
      );
    case BUTTON_TYPES.CANCEL:
      return (
        <button
          className={classNames(
            styles.controlButton,
            styles.controlButtonCancel,
          )}
          onClick={onClick}
          disabled={disabled}
          type="button"
        >
          <span className={styles.controlButtonCircle}>
            <img
              alt="Отменить"
              src={require('../../../../../images/client/RentSearchApplication/back.svg')}
              width="18"
              height="10"
            />
          </span>
          <span className={styles.controlButtonText}>
            <span className={styles.mobileHidden}>Шаг </span>назад
          </span>
        </button>
      );

    case BUTTON_TYPES.DRAW:
      return (
        <button
          className={classNames(styles.controlButton, styles.controlButtonDraw)}
          onClick={onClick}
          type="button"
        >
          <span className={styles.controlButtonCircle}>
            <img
              alt="Рисовать"
              src={require('../../../../../images/client/RentSearchApplication/hand-pic.svg')}
              width="28"
              height="28"
            />
          </span>
          <span className={styles.controlButtonText}>Рисовать</span>
        </button>
      );

    case BUTTON_TYPES.DRAG:
      return (
        <button
          className={classNames(styles.controlButton, styles.controlButtonDrag)}
          onClick={onClick}
          type="button"
        >
          <span className={styles.controlButtonCircle}>
            <img
              alt="Двигать"
              src={require('../../../../../images/client/RentSearchApplication/drag.svg')}
              width="25"
              height="26"
            />
          </span>
          <span className={styles.controlButtonText}>Двигать</span>
        </button>
      );
    case BUTTON_TYPES.SAVE:
      return (
        <button
          className={classNames(styles.controlButton, styles.controlButtonSave)}
          onClick={onClick}
          disabled={disabled}
          type="button"
        >
          <span className={styles.controlButtonCircle}>
            <img
              alt="Сохранить"
              src={require('../../../../../images/client/RentSearchApplication/success.svg')}
              width="18"
              height="12"
            />
          </span>
          <span className={styles.controlButtonText}>Сохранить</span>
        </button>
      );
  }
};

ControlButton.propTypes = {
  disabled: PropTypes.bool,
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};
