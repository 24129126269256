import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';

import { KINDS, FILTER_KEYS } from './AdCatalog.constants';
import AdCatalogFilterBodyKind from './AdCatalogFilterBodyKind';
import AdCatalogFilterBodyRooms from './AdCatalogFilterBodyRooms';
import AdCatalogFilterBodySex from './AdCatalogFilterBodySex';
import AdCatalogFilterBodySubways from './AdCatalogFilterBodySubways';
import AdCatalogFilterBodyDistricts from './AdCatalogFilterBodyDistricts';
import AdCatalogFilterBodyRouteType from './AdCatalogFilterBodyRouteType';
import AdCatalogFilterBodyRouteTime from './AdCatalogFilterBodyRouteTime';
import AdCatalogFilterBodyPrices from './AdCatalogFilterBodyPrices';
import AdCatalogFilterBodyVerification from './AdCatalogFilterBodyVerification';
import AdCatalogFilterBodyRealtor from './AdCatalogFilterBodyRealtor';
import AdCatalogFilterBodyExtra from './AdCatalogFilterBodyExtra';

const UPDATE_DELAY = 300;

class AdCatalogFilterBody extends React.PureComponent {
  constructor(props) {
    super(props);

    this.updateTimer = null;
  }

  handleUpdateDelayedFilter = (newOptions = {}) => {
    const { updateFilter } = this.props;

    updateFilter(newOptions, null, UPDATE_DELAY);
  };

  render() {
    const {
      intl,
      city,
      cityHasSubways,
      districts,
      filter,
      priceMaxLimit,
      priceMinLimit,
      shouldShowScheme,
      deleteGeoAreas,
      updateFilter,
      amenities,
    } = this.props;

    const kind = filter[FILTER_KEYS.KIND];
    const routeType = filter[FILTER_KEYS.ROUTE_TYPE];
    const routeTime = filter[FILTER_KEYS.ROUTE_TIME];
    const isVerificationActive = filter[FILTER_KEYS.VERIFICATION];
    const withRealtor = filter[FILTER_KEYS.REALTOR];
    const realtorComission = filter[FILTER_KEYS.REALTOR_COMISSION];

    return (
      <div className="filter-body">
        <AdCatalogFilterBodyKind
          intl={intl}
          kind={kind}
          updateFilter={updateFilter}
        />
        {kind === KINDS.APARTMENT && (
          <AdCatalogFilterBodyRooms
            intl={intl}
            roomQuantity={filter[FILTER_KEYS.ROOM_QUANTITY]}
            updateFilter={updateFilter}
          />
        )}
        {kind === KINDS.ROOM && (
          <AdCatalogFilterBodySex
            intl={intl}
            sex={filter[FILTER_KEYS.SEX]}
            updateFilter={updateFilter}
          />
        )}
        {cityHasSubways &&(
          <AdCatalogFilterBodySubways
            intl={intl}
            city={city}
            geoAreas={filter[FILTER_KEYS.GEO_AREAS]}
            shouldShowScheme={shouldShowScheme}
            subways={filter[FILTER_KEYS.SUBWAYS]}
            deleteGeoAreas={deleteGeoAreas}
            updateFilter={updateFilter}
          />
        )}
        {cityHasSubways && (
          <AdCatalogFilterBodyRouteType
            intl={intl}
            routeType={routeType}
            routeTime={routeTime}
            updateFilter={updateFilter}
          />
        )}
        {cityHasSubways && routeType && routeType !== 0 && (
          <AdCatalogFilterBodyRouteTime
            intl={intl}
            routeTime={routeTime}
            updateFilter={updateFilter}
          />
        )}
        {districts && districts.length > 0 && (
          <AdCatalogFilterBodyDistricts
            intl={intl}
            city={city}
            geoAreas={filter[FILTER_KEYS.GEO_AREAS]}
            shouldShowScheme={shouldShowScheme}
            subways={filter[FILTER_KEYS.SUBWAYS]}
            selected={filter[FILTER_KEYS.DISTRICTS]}
            districts={districts}
            deleteGeoAreas={deleteGeoAreas}
            updateFilter={updateFilter}
          />
        )}
        <AdCatalogFilterBodyPrices
          intl={intl}
          priceMin={filter[FILTER_KEYS.PRICE_MIN]}
          priceMax={filter[FILTER_KEYS.PRICE_MAX]}
          priceMaxLimit={priceMaxLimit}
          priceMinLimit={priceMinLimit}
          updateFilter={this.handleUpdateDelayedFilter}
        />
        <AdCatalogFilterBodyVerification
          intl={intl}
          isActive={isVerificationActive}
          updateFilter={updateFilter}
        />
        {!isVerificationActive && (
          <AdCatalogFilterBodyRealtor
            intl={intl}
            isActive={withRealtor}
            comission={realtorComission}
            updateFilter={updateFilter}
          />
        )}
        <AdCatalogFilterBodyExtra
          intl={intl}
          filter={filter}
          amenities={amenities}
          updateFilter={updateFilter}
          updateDelay={UPDATE_DELAY}
        />
      </div>
    );
  }
}

AdCatalogFilterBody.propTypes = {
  amenities: PropTypes.array,
  city: PropTypes.object.isRequired,
  deleteGeoAreas: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  priceMaxLimit: PropTypes.number.isRequired,
  priceMinLimit: PropTypes.number.isRequired,
  shouldShowScheme: PropTypes.bool,
  updateFilter: PropTypes.func.isRequired,
};

export default AdCatalogFilterBody;
