import React from 'react';
import PropTypes from 'prop-types';

import OfferPromoControl from '../OfferPromoControl/OfferPromoControl';

const OfferCardPromotionControl = ({
  isOfferOwned,
  isStatic,
  isUserLoggedIn,
}) => {
  return (
    <span className="OfferCard__control OfferCard__control--promotion">
      <OfferPromoControl
        isOwned={isOfferOwned}
        isStatic={isStatic}
        signedIn={isUserLoggedIn}
      />
    </span>
  );
};

OfferCardPromotionControl.propTypes = {
  isOfferOwned: PropTypes.bool,
  isStatic: PropTypes.bool,
  isUserLoggedIn: PropTypes.bool,
};

OfferCardPromotionControl.defaultProps = {
  isOfferOwned: false,
  isStatic: false,
  isUserLoggedIn: false,
};

export default OfferCardPromotionControl;
