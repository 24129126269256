import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import setRailsContext from '../../../common/utils/setRailsContext';
import TranslationProvider from '../../../common/components/HOC/TranslationProvider';
import PricingPlans from '../PricingPlans/PricingPlans';

class OfferFeaturingForm extends React.Component {
  constructor(props) {
    super(props);

    const { currentPlanId = 0 } = this.props;

    this.state = {
      currentPlanId,
    };
  }

  onPlanChange = id => {
    this.setState({ currentPlanId: id });
  };

  render() {
    const { currentPlanId } = this.state;
    const {
      ad: { path, title },
      defaultCurrency,
      intl,
      plans,
    } = this.props;
    const { formatMessage } = intl;

    return (
      <form className="form form-offer" action="feature" method="POST">
        <h1 className="section-title">
          Продвижение квартиры{' '}
          <a className="link link-green" href={path} title={title}>
            {title}
          </a>
        </h1>
        <div className="form-row">
          <PricingPlans
            intl={intl}
            plans={plans}
            currentPlanId={currentPlanId}
            defaultCurrency={defaultCurrency}
            onChange={this.onPlanChange}
          />
        </div>
        <div className="form-row form-row-actions text-center">
          <button className="button button-green button-xlarge" type="submit">
            {formatMessage(defaultMessages.jsOfferFeaturingFormActionsSubmit)}
          </button>
        </div>
      </form>
    );
  }
}

OfferFeaturingForm.propTypes = {
  ad: PropTypes.object,
  currentPlanId: PropTypes.number,
  defaultCurrency: PropTypes.string,
  intl: intlShape.isRequired,
  plans: PropTypes.array,
};

export default setRailsContext(TranslationProvider(OfferFeaturingForm));
